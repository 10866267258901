import React, { FC } from 'react';

import { getLinkUrl } from '../../../utils/getVoucherVerification';

interface Props {
  message: string;
  voucherCode: string;
  status: string;
  linkGroup: {
    homepage: string | null;
    landingPage: string | null;
    support: string | null;
  };
}

const VoucherErrorMessage: FC<Props> = ({ message, voucherCode, status, linkGroup }) => {
  const messageParts = message?.split(/(\{voucher_code\}|\{here\})/);
  return (
    <div>
      {messageParts?.map((part: string, index: string | number | null | undefined) => {
        if (part === '{voucher_code}') {
          return <strong key={index}>{voucherCode}</strong>;
        }
        if (part === '{here}') {
          return (
            <a
              key={index}
              href={getLinkUrl(status, linkGroup)?.linkUrl}
              style={{ textDecoration: 'underline' }}
              target="_blank"
              rel="noreferrer"
            >
              {getLinkUrl(status, linkGroup)?.linkText}
            </a>
          );
        }
        return part;
      })}
    </div>
  );
};

export default VoucherErrorMessage;
