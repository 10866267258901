import React from 'react';
import { RichText as PrismicRichText } from 'prismic-reactjs';
import { linkResolver } from '../../../../../../src/services/linkResolver';
import TermsHtmlSerializer from './legalHtmlSerializer';

interface TermsRichTextProps {
  text: any;
}

const TermsRichText: React.FC<TermsRichTextProps> = ({ text }) => {
  const richText = Array.isArray(text) ? text : text.richText;

  return (
    <PrismicRichText
      render={richText}
      htmlSerializer={TermsHtmlSerializer}
      linkResolver={linkResolver}
    />
  );
};
export default TermsRichText;
