/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { FC, useState, useEffect, useRef, useContext } from 'react';
import { useFlags } from 'gatsby-plugin-launchdarkly';
import { ReactComponent as Logo } from '@assets/lmi-plus-logo.svg';
import { Disclosure, Transition } from '@headlessui/react';
import { List, CaretLeft } from 'phosphor-react';
import { ReactComponent as Search } from '@assets/icons/search.svg';
import { ReactComponent as SupportIcon } from '@assets/icons/question_mark.svg';
import { ReactComponent as AdidasLogo } from '@assets/icons/adidas_logo.svg';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import NavMenu from '@components/molecules/header/navMenu';
import throttle from 'lodash/throttle';
import { isBrowser } from '@services';
import { graphql, useStaticQuery } from 'gatsby';
import { Avatar, Link } from '@components/atoms';
import ProfileDropdown from '@components/molecules/header/profileDropdown';
import { authContext, userContext } from '@context';
import NavButton from '@components/molecules/header/navButton';
import HeaderButton from '@components/molecules/header/headerButton';
import SearchOverlay from '@components/molecules/search';
import { TIERS, NAV_TABS, PARAM_VALUE } from '@constants';
import { logoutTracking, pushTracking } from '@src/utils';
import { Event, Params } from '@src/type';
import NavLink from './navLink';
import HeaderLink from './headerLink';

const Header: FC = () => {
  const showHeader = useHeaderHiding({ headerHeight: 48 });
  const [menu, setMenu] = useState<null | 'main' | 'account'>(null);
  const [showSearchOverlay, setShowSearchOverlay] = useState(false);
  const [activeMenuButton, setActiveMenuButton] = useState(null);
  const flags = useFlags();
  const { rtTierNavigation } = useFlags();

  const { isAuthenticated, handleSignOut } = useContext(authContext);
  const { givenName, familyName, vimeoUserId } = useContext(userContext);

  let { tier } = useContext(userContext);
  if (!isAuthenticated) {
    tier = TIERS.BASE;
  }

  const { prismicNavigation, prismicNavigationV2 } = useStaticQuery<Queries.HeaderQuery>(graphql`
    query Header {
      prismicNavigation {
        data {
          left_links {
            link_item {
              url
            }
            link_text
          }
        }
      }
      prismicNavigationV2 {
        data {
          left_links {
            link_item {
              url
            }
            link_text
            parent
          }
          right_links {
            link_item {
              url
            }
            link_text
          }
        }
      }
    }
  `);

  const prismicNavData = rtTierNavigation ? prismicNavigationV2 : prismicNavigation;

  // Not everything on the nav bar has been configured on LD hence hard coded ones are needed here
  const additionalTabs = tier === TIERS.PREMIUM ? NAV_TABS.PREMIUM : NAV_TABS.BASE;

  const allowedOnly = Object.entries(flags).filter(([, v]) => v === 'allow');
  const allowedOnlyArray = Object.keys(Object.fromEntries(allowedOnly));
  // We want to filter out not not allowed tabs before rendering
  const tierBasedNavData = prismicNavData?.data?.left_links?.filter((link) =>
    [...allowedOnlyArray, ...additionalTabs].some((item) =>
      item.includes(link?.link_text?.replaceAll(' ', '') as string)
    )
  );
  const navData = rtTierNavigation ? tierBasedNavData : prismicNavData?.data?.left_links;
  // change structure of prismicNavigationV2 to have nested children for dropdown menu
  // leftNavButtons is an array of clickable buttons on the nav
  const leftNavButtons = navData.filter((e) => e?.parent === null);
  // leftNavChildren is an array of all dropdown buttons on the nav, which should be nested under a parent
  const leftNavChildren = navData.filter((e) => e?.parent !== null);
  // get unique list of parent names
  const leftNavParentsList = Array.from(
    new Set(leftNavChildren?.map((e) => e?.parent?.toLowerCase()))
  ); // creates an array of only parent names
  // create leftNavItems array of all navigation items, with nested children items
  const leftNavItems = leftNavButtons?.map((e) =>
    leftNavParentsList.indexOf(e?.link_text.toLowerCase()) >= 0
      ? {
          link_text: e?.link_text,
          link_item: e?.link_item,
          children: leftNavChildren?.filter(
            (item) => item?.parent.toLowerCase() === e?.link_text.toLowerCase()
          ),
        }
      : { link_text: e?.link_text, link_item: e?.link_item }
  );

  const itemsToRender = rtTierNavigation ? leftNavItems : navData;

  // Disable the parent scroll bar when overlay shows
  useEffect(() => {
    if (showSearchOverlay) {
      document.documentElement.style.overflow = 'hidden';
      return;
    }
    // Need to use overlay to prevent Windows laptop scale screen width when scroll bar is hidden
    document.documentElement.style.overflow = 'overlay';
  }, [showSearchOverlay]);

  useEffect(() => {
    const handleScroll = () => {
      if (menu === 'main') {
        setMenu(null);
      }
    };

    // Add the scroll event listener to the document
    document.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [menu]);

  const handleOnSearchStart = () => {
    pushTracking({
      event: Event.SEARCH_START,
      [Params.SEARCH_TYPE]: 'lmplus main nav',
      [Params.SEARCH_TERM]: '',
      [Params.SEARCH_STEP_LABEL]: '1',
      [Params.SEARCH_RESULTS]: '',
    });
    setShowSearchOverlay(true);
  };

  const handleSupportClick = () => {
    if (isBrowser()) {
      window.location.href = prismicNavData?.data?.right_links?.[0]?.link_item?.url;
    }
    pushTracking({
      event: Event.NAV_SELECT,
      [Params.MENU_CATEGORY_1]: 'Support',
      [Params.MENU_TYPE]: PARAM_VALUE.MENU_TYPE_HEADER,
    });
  };

  return (
    <Disclosure as="nav" className="bg-black z-30 h-12">
      <Helmet
        htmlAttributes={{
          class: classNames(menu && 'overflow-hidden lg:overflow-auto'),
        }}
      />
      <Transition
        show={showHeader}
        enter="transition-transform ease-out duration-150"
        enterFrom="transform -translate-y-full"
        enterTo="transform translate-y-0"
        leave="transition-transform ease-in duration-150"
        leaveFrom="transform translate-y-0"
        leaveTo="transform -translate-y-full"
        as="header"
        className="w-full px-6 flex items-center justify-between border-b border-black"
        style={{
          height: '3.25em',
          background: '#222222',
        }}
      >
        <div className="flex lg:hidden">
          {menu === null ? (
            <button onClick={() => setMenu('main')} title="Open Main Menu" type="button">
              <List color="white" weight="bold" className="w-6 h-6" />
            </button>
          ) : (
            <button onClick={() => setMenu(null)} title="Close Menu" type="button">
              <CaretLeft color="white" weight="bold" className="w-6 h-6" />
            </button>
          )}
        </div>
        <div>
          <Link to="/" title="lesmills-logo">
            <Logo className="text-grey-review fill-current h-3" />
          </Link>
        </div>
        <div className="hidden lg:flex space-x-8 mx-4" role="menu">
          {itemsToRender?.map(({ link_text, link_item, children }, index) => (
            <HeaderLink
              key={index}
              linkText={link_text}
              linkItem={link_item}
              childrenItem={children}
              activeMenuButton={activeMenuButton}
              setActiveMenuButton={setActiveMenuButton}
            />
          ))}
        </div>
        <div className="hidden lg:flex space-x-4">
          <div>
            <Link to={prismicNavData?.data?.right_links?.[1]?.link_item?.url} title="adidas-logo">
              <AdidasLogo className="mt-1" />
            </Link>
          </div>
          {isAuthenticated && (
            <HeaderButton onClick={() => handleOnSearchStart()} title="Search" icon={<Search />} />
          )}
          <HeaderButton
            onClick={() => handleSupportClick()}
            title="Support"
            icon={<SupportIcon />}
          />
          <ProfileDropdown />
        </div>
        <div className="flex lg:hidden">
          {!menu ? (
            <div className="flex space-x-4">
              {isAuthenticated && (
                <HeaderButton
                  onClick={() => handleOnSearchStart()}
                  title="Search"
                  icon={<Search />}
                />
              )}
              <ProfileDropdown />
            </div>
          ) : (
            <div className="w-6" />
          )}
        </div>
      </Transition>

      <NavMenu show={menu === 'main'}>
        <div className="bg-black flex flex-col h-full">
          <div className="p-6 space-y-6">
            {navData?.map(
              (link) =>
                link?.link_text &&
                link.link_item?.url && (
                  <NavLink
                    key={link?.link_text}
                    to={link?.link_item?.url}
                    title={link?.link_text}
                  />
                )
            )}
          </div>
        </div>
      </NavMenu>
      <NavMenu show={menu === 'account'}>
        <div className="flex flex-col h-full">
          {isAuthenticated ? (
            <>
              <div className="px-6 py-12 flex flex-col items-center border-b border-grey-new-light">
                <div className="max-w-sm mx-auto flex items-center space-x-6">
                  {givenName && familyName && (
                    <Avatar firstName={givenName} lastName={familyName} avatarUrl={null} />
                  )}

                  <h2 className="text-grey-new text-20 font-inter-semibold font-extrabold uppercase leading-tight">
                    {givenName}
                    <br />
                    {familyName}
                  </h2>
                </div>
              </div>
              <div className="p-6 space-y-6 border-b border-grey-new-light">
                <NavLink
                  to={
                    process.env.GATSBY_RT_19_02_2024_ACCOUNT_OVERVIEW === 'true'
                      ? '/account'
                      : `${process.env.GATSBY_MAIN_LES_MILLS_URL}/customer/account`
                  }
                  title="My Account"
                />
                <NavButton
                  onClick={() => {
                    logoutTracking(vimeoUserId);
                    handleSignOut?.();
                  }}
                  title="Sign Out"
                />
              </div>
            </>
          ) : (
            <div className="p-6 space-y-6 border-b border-grey-new-light">
              <NavLink to="/login" title="Sign In" />
            </div>
          )}
        </div>
      </NavMenu>
      {showSearchOverlay && <SearchOverlay onClose={() => setShowSearchOverlay(false)} />}
    </Disclosure>
  );
};

const useHeaderHiding = ({ headerHeight }: { headerHeight: number }) => {
  const [show, setShow] = useState(true);
  const lastScrollPosition = useRef(isBrowser() ? window.scrollY : 0);

  const THROTTLE_WAIT = 200;

  useEffect(() => {
    const handleScroll = throttle(() => {
      if (!isBrowser()) return;

      const belowThreshold = window.scrollY < headerHeight;
      const scrollingUp = lastScrollPosition.current > window.scrollY;

      if (belowThreshold || scrollingUp) {
        setShow(true);
      } else {
        setShow(false);
      }

      lastScrollPosition.current = window.scrollY;
    }, THROTTLE_WAIT);

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [headerHeight]);

  return show;
};

export default Header;
