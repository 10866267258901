import { BackgroundImage, EquipmentList } from '@components/atoms';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';

interface PlanCardProps {
  tutor?: string;
  title: string;
  tags: string[];
  equipment: string[];
  backgroundImage: {
    gatsbyImageData: IGatsbyImageData;
  };
  ButtonComp: JSX.Element;
}

const PlanCard = ({
  tutor = '',
  title,
  tags,
  equipment,
  backgroundImage,
  ButtonComp,
}: PlanCardProps) => (
  <BackgroundImage image={backgroundImage}>
    <div className="h-96 md:h-80 p-2.5 flex flex-col-reverse justify-start rounded-md max-w-2xl">
      <div>{ButtonComp}</div>
      <div className="flex">
        {tags.map((item) => (
          <div
            className="pt-1.5 pb-1 px-2.5 bg-black bg-opacity-50 rounded-md mr-1 text-white uppercase font-inter-light text-10 mt-2"
            key={item}
          >
            {item}
          </div>
        ))}
      </div>
      <div className="w-2/3 md:w-1/3 break-words text-white font-inter-semibold text-36 font-bold uppercase tracking-tight leading-none mt-1">
        {title}
      </div>
      <div className="text-white font-inter-light text-16 tracking-wide">{tutor}</div>
      <div className="self-end flex-grow">
        <EquipmentList equipments={equipment} />
      </div>
    </div>
  </BackgroundImage>
);

export default PlanCard;
