import { useContext, useEffect } from 'react';
import { pageViewTracking, getLocalStorage } from '@src/utils';
import { authContext, userContext } from '@context';

// This collects the user data and send page_view to GTM/Google analytics

const useTrackUserAttribute = (getProductInfoFromStorage?: boolean) => {
  const { vimeoUserId: userContextViemoUserId, lmodSubscription } = useContext(userContext);
  const { isAuthenticated } = useContext(authContext);

  useEffect(() => {
    const userSelectedPlan = JSON.parse(getLocalStorage('selected-plan') || 'null');
    const { vimeoUserId: storageVimeoUserId } =
      JSON.parse(getLocalStorage('user-info') || 'null') || {};
    const product = getProductInfoFromStorage ? userSelectedPlan : lmodSubscription?.product;
    const vimeoUserId = getProductInfoFromStorage ? storageVimeoUserId : userContextViemoUserId;
    const userData = { product, vimeoUserId };

    pageViewTracking({
      userData,
      loginStatus: isAuthenticated ? 'true' : 'false',
    });
  }, [getProductInfoFromStorage, userContextViemoUserId, lmodSubscription, isAuthenticated]);
};

export default useTrackUserAttribute;
