import React, { FC } from 'react';

import { graphql } from 'gatsby';

import { Product } from '@src/type/Product';

import PlanCard from '../../signup/selectPlan/PlanCardPromotions';

import { Wrapper, PlansContainer } from './style';

interface Props {
  prismicData: {
    current_plan_text: string;
  };
  planCardPrismicData: {
    free_trial_text?: string;
    monthly_text?: string;
    no_trial_text?: string;
    annually_text?: string;
    three_monthly_text?: string;
    best_value?: string;
    plan_monthly_disclaimer?: string;
    plan_trimonthly_disclaimer?: string;
    plan_annually_disclaimer?: string;
  };
  countryCode: string;
  availablePlans: Array<Product> | null;
  currentProductHandle: string;
  getSelectedPlan: (newSelectedPlan: Product) => void;
  selectedPlan: Product;
}

const AvailableSubscriptions: FC<Props> = ({
  prismicData,
  planCardPrismicData,
  countryCode,
  availablePlans,
  currentProductHandle,
  getSelectedPlan,
  selectedPlan,
}) => {
  /* eslint-disable @typescript-eslint/naming-convention */
  const { current_plan_text } = prismicData;

  if (!availablePlans) {
    return null;
  }

  const handleSelectPlan = (newSelectedPlanId: string) => {
    availablePlans.forEach((plan) => {
      if (newSelectedPlanId === plan.product_handle) {
        getSelectedPlan(plan);
      }
    });
  };

  return (
    <Wrapper>
      <PlansContainer>
        {availablePlans.length > 0 &&
          countryCode &&
          availablePlans.map((plan) => (
            <PlanCard
              plan={plan}
              key={plan.name}
              selectedPlan={selectedPlan}
              handleSelectPlan={handleSelectPlan}
              pricingData={planCardPrismicData}
              countryCode={countryCode}
              isCurrentPlan={currentProductHandle === plan.product_handle}
              noTrialDisplay
              currentPlanText={current_plan_text}
            />
          ))}
      </PlansContainer>
    </Wrapper>
  );
};

export default AvailableSubscriptions;

export const PricingSectionCommonQuery = graphql`
  fragment PricingSection on PrismicLesMillsPlusCommonLandingPageDataBodyPricing {
    slice_type
    primary {
      free_trial_text
      monthly_text
      no_trial_text
      annually_text
      billed_text
      three_monthly_text
      best_value
      plan_monthly_disclaimer
      plan_trimonthly_disclaimer
      plan_annually_disclaimer
    }
  }
`;

export const PricingSectionTieringQuery = graphql`
  fragment PricingSectionTiering on PrismicLesMillsPlusTieringLandingPageDataBodyPricing {
    slice_type
    primary {
      annually_text
      billed_text
      cancellation_text
      change_plan
      day
      days
      free_trial_text
      month
      monthly_text
      months
      no_trial_text
      pricing_cta_button
      pricing_cta_label
      pricing_disclaimer
      pricing_subtitle
      pricing_title
      best_value
      year
      plan_monthly_disclaimer
      plan_trimonthly_disclaimer
      plan_annually_disclaimer
      billed_text
      base_tier_title
      premium_tier_title
      base_tier_description
      premium_tier_description
      switch_default_value
      monthly_badge
      annually_badge
      badge_text
      best_value_option
    }
    items {
      tier_type
      benefit_item_icon
      benefit_item_text
      benefit_item_type
      children_content
      tooltip_children_content
      children_title
    }
  }
`;
