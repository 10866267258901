import { useContext } from 'react';
import { authContext } from '../context/authContext';
import { createPublicClient } from '../services/client';
import useGetHeaders from './queries/useGetHeaders';
import captureException from '../utils/sentry';

const useGetCountryCode = () => {
  const { isAuthenticated } = useContext(authContext);

  const { data, loading, error } = useGetHeaders({
    client: createPublicClient,

    onError: (err) => {
      if (isAuthenticated) {
        captureException({
          action: 'getHeaders',
          ...err,
        });
      }
    },
  });

  const countryCode = data?.getHeaders?.countryCode;
  const errorMessage = error?.message;

  return { countryCode, loading, errorMessage };
};

export default useGetCountryCode;
