import React, { FC, useEffect, useState, useCallback, useMemo, ReactNode } from 'react';
import { Auth } from '@aws-amplify/auth';
import { navigate } from 'gatsby';
import { isBrowser } from '../utils/utilities';
import { authStorage } from '../../../gatsby-theme-engagement/src/services/amplifyStorage';

Auth.configure({
  ...{
    storage: authStorage,
  },
  region: process.env.GATSBY_AWS_COGNITO_REGION,
  userPoolId: process.env.GATSBY_AWS_COGNITO_USER_POOL_ID,
  userPoolWebClientId: process.env.GATSBY_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID,
  authenticationFlowType: 'USER_PASSWORD_AUTH',
});

type SignInFunc = (
  username: string,
  password: string,
  validationData: any,
  successCallback: () => void,
  errorCallback: (message: string) => void
) => void;

type SignOutFunc = () => void;

interface AuthState {
  isAuthenticated: boolean;
  isLoading: boolean;
}

interface AuthProviderProps {
  children?: ReactNode;
}

interface AuthContext extends AuthState {
  handleSignIn?: SignInFunc;
  handleSignOut?: SignOutFunc;
}

const authContext = React.createContext<AuthContext>({
  isLoading: true,
  isAuthenticated: false,
});

const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [state, setState] = useState<AuthState>({
    isLoading: true,
    isAuthenticated: false,
  });

  useEffect(() => {
    if (isBrowser) {
      Auth.currentAuthenticatedUser()
        .then(() =>
          setState({
            isLoading: false,
            isAuthenticated: true,
          })
        )
        .catch(() => setState({ ...state, isLoading: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Intentionally left empty so use effect only runs when the component is first loaded

  const handleSignIn: SignInFunc = useCallback(
    (username, password, validationData, successCallback, errorCallback) => {
      Auth.signIn(username.toLowerCase(), password, validationData)
        .then(() => {
          setState({ ...state, isAuthenticated: true });
          successCallback();
        })
        .catch((error) => errorCallback(error.message));
    },
    [state]
  );

  const handleSignOut: SignOutFunc = useCallback(() => {
    Auth.signOut()
      .then(() => {
        setState({ ...state, isAuthenticated: false });
        navigate('/login/');
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
  }, [state]);

  const providerValue = useMemo(
    () => ({
      ...state,
      handleSignOut,
      handleSignIn,
    }),
    [state, handleSignOut, handleSignIn]
  );
  return <authContext.Provider value={providerValue}>{children}</authContext.Provider>;
};

export { AuthProvider, authContext };
