import React from 'react';

import { ReactComponent as MasterCard } from '../../../assets/icons/mastercard-payment.svg';
import { ReactComponent as Visa } from '../../../assets/icons/visa-payment.svg';
import { ReactComponent as Paypal } from '../../../assets/icons/paypal-payment.svg';

export type PaymentIconType = 'visa' | 'paypal' | 'mastercard' | 'creditcard';

export interface Props {
  iconType: PaymentIconType;
}

const PaymentIcon = ({ iconType }: Props) => {
  const mapIcon = {
    creditcard: <Visa data-testid="creditcard-icon" />,
    visa: <Visa data-testid="creditcard-icon" />,
    paypal: <Paypal data-testid="paypal-icon" />,
    mastercard: <MasterCard data-testid="creditcard-icon" />,
  };

  return mapIcon[iconType];
};

export default PaymentIcon;
