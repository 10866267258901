import React from 'react';
import PropTypes from 'prop-types';
import FFProvider from './src/provider';

// eslint-disable-next-line import/prefer-default-export
export const wrapRootElement = ({ element }) => {
  const App = () => (
    // eslint-disable-next-line react/jsx-filename-extension
    <FFProvider allowOverride={process.env.NODE_ENV !== 'production'}>{element}</FFProvider>
  );
  return <App />;
};
wrapRootElement.propTypes = {
  element: PropTypes.element.isRequired,
};
