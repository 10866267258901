import { FeatureFlags } from './src/types';

/**
 * Feature flags should be prefixed with `FF_`.
 * Not only does it make feature flags more readable,
 * but the implementation relies on this convention to
 * clear flags in local storage
 */
const fflags: FeatureFlags = {
  RT_17_02_2022_NEW_PLAN: process.env.RT_17_02_2022_NEW_PLAN === 'true',
};

export default fflags;
