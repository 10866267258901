import React, { FC } from 'react';

import { ReactComponent as SuccessIcon } from '../../../assets/icons/success-green.svg';
import Button from '../../common/button/Button';
import { Wrapper, Title, Description, ButtonWrapper } from './style';

interface Props {
  title: string;
  description: string;
  buttonText?: string;
  onButtonClick?: () => void;
}

const SuccessMsg: FC<Props> = ({ title, description, buttonText, onButtonClick }) => (
  <Wrapper>
    <SuccessIcon />
    <Title>{title}</Title>
    <Description>{description}</Description>
    {buttonText && (
      <ButtonWrapper>
        <Button onClick={onButtonClick} borderRadius={100}>
          {buttonText}
        </Button>
      </ButtonWrapper>
    )}
  </Wrapper>
);

export default SuccessMsg;
