import { gql } from '@apollo/client';

const UpdateSubscriptionPreferences = gql`
  mutation updateSubscriptionPreference($rolloverOption: Boolean!) {
    updateSubscriptionPreference(default_to_monthly: $rolloverOption) {
      default_to_monthly
    }
  }
`;

export default UpdateSubscriptionPreferences;
