/* eslint-disable @typescript-eslint/naming-convention */
import React, { useContext } from 'react';
import { ListItem, ListItemProps } from '@lesmills-international/components';
import { navigate } from 'gatsby';
import { useTheme } from '@mui/styles';
import { SectionTitle } from './styles';

import { userContext } from '../../../../gatsby-theme-engagement/src/context';
import { SignupChannel, isIAP } from '../../utils/subscriptions';
import PaymentDetails from './PaymentDetails';
import ROUTES from '../../../../../src/constants/routes';
import mapPaymentName from '../../utils/normalizePaymentInfo';

type PageProps = Pick<
  Queries.PrismicAccountOverviewPageData & Queries.PrismicSignupChannelsData,
  | 'payment_section_title'
  | 'update_payment_details_title'
  | 'update_payment_details_description'
  | 'billing_history'
  | 'signup_channel'
>;

interface Props {
  data: PageProps;
}

const PaymentSection = ({ data }: Props) => {
  const { chargifyPaymentProfile, signupChannel, hasActiveSubscription } = useContext(userContext);
  const { themeColor } = useTheme();

  const {
    payment_section_title,
    update_payment_details_title,
    update_payment_details_description,
    billing_history,
    signup_channel,
  } = data;

  const isIAPUser = isIAP(signup_channel as unknown as SignupChannel[], signupChannel);

  const paymentType = mapPaymentName(chargifyPaymentProfile);
  const showUpdatePayment = chargifyPaymentProfile?.last4 || paymentType === 'paypal';

  // Hide payment section when it's IAP.
  if (isIAPUser || !hasActiveSubscription) {
    return null;
  }

  const items: ListItemProps = [
    {
      title: billing_history,
      onClick: () => navigate(ROUTES().BILLING_HISTORY),
    },
  ];

  // Display update payment only when user has any stored payment details.
  if (showUpdatePayment) {
    items.unshift({
      title: update_payment_details_title,
      description: <PaymentDetails cardDescription={update_payment_details_description} />,
      onClick: () => navigate(ROUTES().UPDATE_PAYMENT_DETAILS),
    });
  }

  return (
    <div>
      <SectionTitle>{payment_section_title}</SectionTitle>
      <ListItem theme={themeColor} items={items} />
    </div>
  );
};

export default PaymentSection;
