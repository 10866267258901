import React, { FC, useState } from 'react';

import { ReactComponent as ArrowDownIcon } from '@assets/icons/arrow_down.svg';
import { ReactComponent as ArrowUpIcon } from '@assets/icons/arrow_up.svg';

import ShowMoreWrapper from './style';

interface ShowMoreProps {
  showMoreText: string;
  showLessText?: string;
  onClick?: (opened: boolean) => void;
  hoverState?: boolean;
}

const ShowMore: FC<ShowMoreProps> = ({
  showMoreText,
  showLessText,
  onClick,
  hoverState = true,
}) => {
  const [opened, setOpened] = useState(false);

  const handleOnClick = () => {
    setOpened(!opened);
    onClick && onClick(opened);
  };
  return (
    <>
      {((showLessText && opened) || !opened) && (
        <ShowMoreWrapper
          role="button"
          aria-label={opened ? showLessText : showMoreText}
          onClick={handleOnClick}
          hoverState={hoverState}
        >
          <span>{opened ? showLessText : showMoreText}</span>
          {opened ? <ArrowUpIcon /> : <ArrowDownIcon />}
        </ShowMoreWrapper>
      )}
    </>
  );
};

export default ShowMore;
