import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import { Query } from '../../../../gatsby-theme-engagement/src/backend';
import getRolloverCountries from '../../graphql/getRolloverCountries';
import { createPublicClient } from '../../services/client';

type RolloverResponse = Pick<Query, 'getRolloverCountries'>;

const useGetRolloverCountries = (
  options?: QueryHookOptions<RolloverResponse>
): QueryResult<RolloverResponse> =>
  useQuery<RolloverResponse>(getRolloverCountries, {
    ...options,
    client: createPublicClient,
  });

export default useGetRolloverCountries;
