import React from 'react';
import { Container, Label, Input } from './style';
import { RadioProps } from './types';

const Radio: React.FC<RadioProps> = ({
  displayText,
  checked = false,
  index,
  onChange,
}: RadioProps) => {
  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => onChange(index, event);
  return (
    <Container data-active={checked}>
      <Input
        type="radio"
        aria-hidden="true"
        id={displayText}
        name={displayText}
        value={index}
        onChange={onChangeHandler}
        checked={checked}
      />
      <Label htmlFor={displayText}>{displayText}</Label>
    </Container>
  );
};

export default Radio;
