import React from 'react';
import SpinnerWapper from './style';
import { ReactComponent as Plus } from '../../../assets/icons/plus.svg';

const SpinnerPlus = () => (
  <SpinnerWapper>
    <Plus height={48} width={48} />
  </SpinnerWapper>
);

export default SpinnerPlus;
