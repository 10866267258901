export const DURATIONS = {
  SHORT: 'Short',
  MIN_30: '30 mins',
  MIN_45: '45 mins',
  MIN_55: '55 mins',
};

export const TYPES = {
  COOL_DOWN: 'Cool down',
  LEARN: 'Learn',
  REMIX: 'Remix',
};

export const SORTING = {
  DEF: 'Default',
  NEW: 'Newest first',
  OLD: 'Newest last',
};

// filter out the workouts belonging to bike category @program/index.ts
export const PRODUCT = 'lmod';
