import { useLayoutEffect, useState } from 'react';
import throttle from 'lodash/throttle';

const useWindowSize = (wait: number = 500) => {
  const [size, setSize] = useState(0);

  useLayoutEffect(() => {
    function updateSize() {
      setSize(window.innerWidth);
    }
    const throttled = throttle(updateSize, wait);
    window.addEventListener('resize', throttled);
    updateSize();
    return () => window.removeEventListener('resize', throttled.cancel);
  }, [wait]);
  return size;
};

export default useWindowSize;
