import styled from 'styled-components';
import { breakpoint } from '@themes';
import COLORS from '@themes/colors';

export interface ContainerProps {
  type: string;
  'data-type': string;
  'data-active': boolean;
  isFlexible: boolean;
}

export const Container = styled.div<ContainerProps>`
  font-size: 16px;
  background: ${COLORS.darkGrey};
  display: ${(props) => (props.isFlexible ? 'flex' : 'block')};
  align-items: center;
  justify-content: center;
  &:hover {
    background: ${COLORS.greyLetter};
    transition: ease-in-out 0.15s;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.25);
  }
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  color: ${COLORS.white};
  width: ${(props) => (props.isFlexible ? 'auto' : '148px')};
  height: ${(props) => (props.isFlexible ? '50px' : '104px')};
  line-height: ${(props) => (props.isFlexible ? '22px' : '50px')};
  border: 1px solid transparent; /* To prevent tiny jump when adding active item */
  border-radius: ${(props) => (props.isFlexible ? '100px' : '10px')};
  box-sizing: border-box;
  padding: ${(props) => (props.isFlexible ? '0 24px' : '0')};
  label {
    aspect-ratio: ${(props) => (props.isFlexible ? 0 : 3 / 2.2)};
  }
  ${breakpoint('md')`
    width: ${(props) => (props.isFlexible ? 'auto' : '168px')};
  `}

  &[data-active='true'] {
    background: ${COLORS.greyLetter};
    border: 1px solid ${COLORS.white};
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.25);
    transition: ease-in-out 0.15s;
  }
`;

export const Label = styled.label`
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  svg {
    transform: scale(1.8);
  }
`;

export const Select = styled.select`
  display: none;
`;

export const Input = styled.input`
  display: none !important;
`;
