/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { breakpoint } from '../../../themes/breakpoints';

export const ProgramHeroWrapper = styled.div`
  .hero--card {
    background-position: top;

    .gradient--overlay--bottom {
      background: linear-gradient(0deg, rgb(17 17 17) 0%, rgba(0, 0, 0, 0) 50%);
    }

    ${breakpoint('sm')`
      height: 405px;
    `}

    ${breakpoint('md')`
      height: 480px;
    `}

    ${breakpoint('xl')`
      height: 675px;
    `}
  }

  .hero--card .content--left {
    height: 90%;

    ${breakpoint('xl')`
      height: 84%;
    `}

    .button--row .button {
      height: 48px;
    }
  }

  .hero--card .content--left .desc--row {
    display: block;
  }
`;
