export const DOLLAR_CURRENCIES = {
  SGD: 'SGD', // Singapore Dollar
  CAD: 'CAD', // Canadian Dollar
  AUD: 'AUD', // Australian Dollar
  NZD: 'NZD', // New Zealand Dollar
  AED: 'AED', // United Arab Emirates Dirham
  USD: 'USD', // United States Dollar
  MYR: 'RM', // Malaysian Ringgit
  HKD: 'HKD', // Hong Kong Dollar
  BRL: 'BRL', // Brazilian real
  ZAR: 'ZAR', // South African Rand
  CHF: 'CHF', // Swiss Franc
};

export const DOLLAR = '$';

export const OTHER_CURRENCIES = {
  EUR: '€', // Euro
  GBP: '£', // Great British Pound
  INR: '₹', // Indian Rupee
  JPY: '¥', // Japanese yen
};

export const TAX_INCLUDED_CURRENCIES = {
  USD: 'USD', // United States Dollar
  CAD: 'CAD', // Canadian Dollar
};
