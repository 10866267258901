import type { CategoryType, ProgramType } from '@types/program';

/**
 * get the categories data from each of the program data - categories field(CMDS)
 * then sort out by our Filter component props needs - label, id, active
 *
 * @param {ProgramType[]} listPrograms The programs data to format.
 * @return {Array} formatedCategories raised to the n-th power.
 */
const formatProgramCategories = (listPrograms: ProgramType[]) => {
  let categoriesArray: string[] = [];

  listPrograms?.forEach((program) => {
    if (program.categories) {
      categoriesArray = categoriesArray.concat(program.categories);
    }
  });

  // get all of the categories and remove the duplicated items
  const categoryResult = [...new Set(categoriesArray)];
  categoryResult.unshift('All');
  // remove the Hide category
  const hideCategoryIndex = categoryResult.indexOf('Hide');
  const otherCategoryIndex = categoryResult.indexOf('Other');
  if (hideCategoryIndex > -1) {
    categoryResult.splice(hideCategoryIndex, 1);
  }

  if (otherCategoryIndex > -1) {
    categoryResult.splice(otherCategoryIndex, 1);
  }
  const sortedCategories = sortCategoriesArray(categoryResult);
  const formatedCategories: CategoryType[] = [];

  sortedCategories.forEach((category) => {
    const obj = { label: '', id: '', active: false };
    obj.label = category;
    obj.id = category;
    obj.active = category === 'All';
    formatedCategories.push(obj);
  });
  return formatedCategories;
};

/**
 * sort the category by hardcode
 *
 * @param {string[]} categories The original categories array.
 * @return {string[]} sorted categories array.
 */
const sortCategoriesArray = (categories: string[]) => {
  const sortedCategories = [
    'All',
    'Strength',
    'Cardio & Martial Arts',
    'HIIT & Functional',
    'Yoga & Flexibility',
    'Dance',
    'Cycle',
    'No Equipment',
    'Kids',
  ];

  const sorter = (firstCategory: string, secondCategory: string) => {
    if (sortedCategories.indexOf(firstCategory) < 0) {
      return 1;
    }
    if (sortedCategories.indexOf(secondCategory) < 0) {
      return -1;
    }
    return sortedCategories.indexOf(firstCategory) - sortedCategories.indexOf(secondCategory);
  };

  return categories.sort(sorter);
};

export default formatProgramCategories;
