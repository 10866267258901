import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client';
import UpdateSubscriptionPreferences from '../../graphql/updateSubscriptionPreference';

type UpdateSubscriptionPreferenceResponse = {
  updateSubscriptionPreference: {
    default_to_monthly: boolean;
  };
};

type MutationUpdateSubscriptionPreferenceArgs = {
  rolloverOption: boolean;
};

const useUpdateSubscriptionPreference = (
  options?: MutationHookOptions<
    UpdateSubscriptionPreferenceResponse,
    MutationUpdateSubscriptionPreferenceArgs
  >
): MutationTuple<UpdateSubscriptionPreferenceResponse, MutationUpdateSubscriptionPreferenceArgs> =>
  useMutation<UpdateSubscriptionPreferenceResponse, { rolloverOption: boolean }>(
    UpdateSubscriptionPreferences,
    options
  );

export default useUpdateSubscriptionPreference;
