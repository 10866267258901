/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import Box from '@mui/material/Box';

import { Button, TextField, Alert } from '@lesmills-international/components';
import RadioGroup from '../../common/radioGroup';
import Title from '../../common/title';
import VoucherErrorMessage from '../../common/voucherErrorMessage';

import SignUpUid from '../../../constants/d2c-partner-signup';

import {
  formErrorTracking,
  formSubmitTracking,
  registrationFlowTracking,
} from '../../../utils/dataTracking';

import {
  addErrorInDatadogRum,
  setLocalStorage,
  getLocalStorage,
  getLoginUrl,
} from '../../../utils/utilities';
import { formValidator } from '../../../utils/validator';
import { ACTIVATION_CODE_FORMATTED } from '../../../constants/localStorageKeys';
import { getVoucherVerification } from '../../../utils/getVoucherVerification';
import useAffiliateVouchercodeVerification from '../../../hooks/useAffiliateVoucherCodeVerification';
import VoucherStatus from '../../../constants/voucherVerificationStatus';

import { ButtonWrapper, LoginLink, Privacy, ReceiveEmailLabel, Wrapper } from './style';
import useFormStartPushTracking from '../../../hooks/useFormStartPushTracking';
import { FormErrorName, FormName, StepLabel } from '../../../types/DataTracking';

import useCheckUserExists from '../../../hooks/useCheckDoesUserExists';

const Form = styled(Box)((isPartnerSignup) => ({
  '& .MuiTextField-root': { margin: '8px 0', width: '320px' },
  maxWidth: isPartnerSignup ? '436px' : '320px',
  textAlign: 'center',
  '& .MuiInputBase-input:focus': { boxShadow: 'none' },
}));

const ConfirmEmail = ({
  data,
  createAccountValues,
  lang,
  isProcessing,
  serverError,
  updateEmail,
  updateReceiveEmail,
  handleConfirmEmail,
  handleRecaptchaValidateData,
  discountInfo,
  countryCode,
  mode,
  tiering,
}) => {
  const [emailError, setEmailError] = useState({
    errorMsg: '',
    valid: false,
  });
  const [emailHelperText, setEmailHelperText] = useState('');
  const [submitDisabled, setSubmitDisabled] = useState();
  const [emailExisted, setEmailExisted] = useState(false);

  const [receiveEmailHelperText, setReceiveEmailHelperText] = useState('');
  const [isEmailDisabled, setIsEmailDisabled] = useState(false);

  const { voucherVerificationStatus, voucherVerificationError } =
    useAffiliateVouchercodeVerification(discountInfo, countryCode);

  const {
    confirm_email_title,
    receive_email_label,
    confirm_email_button_text,
    receive_email_required,
    existing_email_message,
    log_in_link_label,
    receive_email_yes,
    receive_email_no,
    privacy_policy_link,
    privacy_policy_disclaimer,
    privacy_policy_link_label,
    have_an_account_text,
    voucher_success,
    voucher_is_invalid,
    voucher_is_required,
    voucher_is_expired,
    voucher_has_been_redeemed,
    voucher_is_not_required,
    country_code_missing,
    bau_landing_page_link,
    homepage_link,
    customer_support_link,
  } = data;

  const voucherVerificationMessages = {
    success: voucher_success || '',
    invalid: voucher_is_invalid || '',
    required: voucher_is_required || '',
    expired: voucher_is_expired || '',
    redeemed: voucher_has_been_redeemed || '',
    notRequired: voucher_is_not_required || '',
    countryCodeMissing: country_code_missing || '',
  };

  const alertStyle = { marginBottom: '10px' };

  const defaultTrackingData = useMemo(
    () => ({
      formName: FormName.CONFIRM_EMAIL_FORM,
      stepLabel: StepLabel.CONFIRM_PLAN,
    }),
    []
  );

  const handleSubmitFormErrorTracking = useCallback(
    (formErrorName) => {
      formErrorTracking({
        ...defaultTrackingData,
        formErrorName,
      });
    },
    [defaultTrackingData]
  );

  const isSubmitDisabled = useCallback(
    ({ receiveEmail, email }) =>
      !handleSingleValidate('email', null, email, true) || receiveEmail === '',
    [handleSingleValidate]
  );

  useEffect(() => {
    if (serverError || emailCheckServerError) {
      handleSubmitFormErrorTracking(FormErrorName.SERVER_ERROR);
    }
  }, [serverError, emailCheckServerError, handleSubmitFormErrorTracking]);

  // get activationCode information from local storage
  const activationCodeInfo = JSON.parse(getLocalStorage(ACTIVATION_CODE_FORMATTED) || null);
  const isPartnerSignup = mode === SignUpUid.Partner;
  const isD2CSignup = mode === SignUpUid.D2C;

  const loginPage = useMemo(
    () => getLoginUrl(discountInfo, lang, isPartnerSignup, tiering),
    [discountInfo, lang, isPartnerSignup, tiering]
  );

  const { handleFormStarted } = useFormStartPushTracking(defaultTrackingData);

  // TODO: can be moved to custom hook when it's needed in multiple places
  useEffect(() => {
    if (submitDisabled) return;

    const listener = (event) => {
      if (event.code === 'Enter') {
        event.preventDefault();
        handleSubmit(event);
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitDisabled]);

  useEffect(() => {
    setSubmitDisabled(isSubmitDisabled(createAccountValues));
  }, [createAccountValues, isSubmitDisabled]);

  const { handleCheckUserExists, loading, emailCheckServerError } = useCheckUserExists();

  useEffect(() => {
    if (emailError.errorMsg) {
      setEmailHelperText(emailError.errorMsg);
    } else if (!emailExisted) {
      setEmailHelperText('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailError]);

  const handleEmailChange = (event) => {
    const { receiveEmail } = createAccountValues;
    handleSingleValidate('email', event);

    let { value } = event.target;
    value = value.trim();
    if (emailExisted) {
      setEmailHelperText('');
      setEmailExisted(false);
    }

    updateEmail(value);
    setSubmitDisabled(isSubmitDisabled({ receiveEmail, email: value }));
  };

  // this will only work for partner signup journey
  const checkEmailFromPartnerSignup = () => {
    if (activationCodeInfo && isPartnerSignup && activationCodeInfo.email) {
      // eslint-disable-next-line no-param-reassign
      createAccountValues.email = activationCodeInfo.email;
      setIsEmailDisabled(true);
    }
  };

  useEffect(() => {
    checkEmailFromPartnerSignup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateReceiveEmail = () => {
    const { receiveEmail } = createAccountValues;
    const hasSelectedAnOption = receiveEmail !== '';

    if (!hasSelectedAnOption) {
      setReceiveEmailHelperText(receive_email_required?.text);
      setSubmitDisabled(true);
    }

    return hasSelectedAnOption;
  };

  const validateSingleField = useCallback(
    (name, value) =>
      formValidator(['REQUIRED', name.toUpperCase()], value, {
        requiredError: data[`${name}_required`].text,
        invalidError: data[`${name}_invalid`].text,
      }),
    [data]
  );

  const handleSingleValidate = useCallback(
    (field, event, valueIfNoEvent, ignorePrompt) => {
      const error = validateSingleField(field, valueIfNoEvent || event?.target?.value);
      if (!ignorePrompt) setEmailError({ ...emailError, ...error });
      return error.valid;
    },
    [emailError, validateSingleField]
  );

  const handleRadioChange = (event) => {
    try {
      const { email } = createAccountValues;
      const optInSelectedOption = event.target.value; // Is either "yes" or "no" or "" being no selection

      setLocalStorage('opt_in', optInSelectedOption);

      if (optInSelectedOption) {
        setReceiveEmailHelperText('');

        setSubmitDisabled(!handleSingleValidate('email', null, email, true));
      } else setSubmitDisabled(true);

      updateReceiveEmail(optInSelectedOption);
    } catch (error) {
      addErrorInDatadogRum(error);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    registrationFlowTracking(defaultTrackingData);

    const { email } = createAccountValues;

    handleSingleValidate('email', null, email);

    if (emailError.errorMsg || !validateReceiveEmail()) {
      handleSubmitFormErrorTracking(FormErrorName.INCOMPELTE_FIELDS);
      return;
    }

    const onSuccess = (userExists) => {
      if (userExists) {
        setSubmitDisabled(userExists);
        setEmailHelperText(
          <span className="email-existed-helper">
            {`${existing_email_message?.text} `}
            <a href={loginPage} className="existing-email-login-link">
              {log_in_link_label?.text}
            </a>
          </span>
        );

        return;
      }
      const successCallback = formSubmitTracking(defaultTrackingData);
      handleConfirmEmail(successCallback);
    };

    handleCheckUserExists({ email, handleRecaptchaValidateData, successCallback: onSuccess });
  };

  const { email, receiveEmail } = createAccountValues;
  if (!countryCode) return null;

  return (
    <Wrapper id="confirm-email-wrapper">
      <Title
        className={isD2CSignup ? 'confirm-email-title' : 'confirm-email-title'}
        id="confirm-email-title"
        variant="h1"
      >
        <>{confirm_email_title.text}</>
      </Title>
      <LoginLink id="have-an-account">
        {have_an_account_text.text}
        <a href={loginPage} id="log-in-link">
          {log_in_link_label.text}
        </a>
      </LoginLink>
      {voucherVerificationStatus &&
        voucherVerificationStatus !== VoucherStatus.SUCCESS_REGULAR_AFFILIATE && (
          <Alert
            severity={
              getVoucherVerification(voucherVerificationStatus, voucherVerificationMessages)?.type
            }
            style={{ marginBottom: '10px' }}
          >
            <VoucherErrorMessage
              message={
                getVoucherVerification(voucherVerificationStatus, voucherVerificationMessages)
                  ?.message
              }
              voucherCode={discountInfo?.data?.voucherCode}
              status={voucherVerificationStatus}
              linkGroup={{
                homepage: homepage_link,
                landingPage: bau_landing_page_link,
                support: customer_support_link,
              }}
            />
          </Alert>
        )}
      {serverError && (
        <Alert severity="error" style={alertStyle}>
          {serverError}
        </Alert>
      )}
      {emailCheckServerError && (
        <Alert severity="error" style={alertStyle}>
          {emailCheckServerError}
        </Alert>
      )}
      <Form id="confirm-email-form" component="form" noValidate autoComplete="off">
        <TextField
          required
          id="confirm-email-field"
          name="confirm-email"
          label="Email"
          variant="filled"
          disabled={(isPartnerSignup && isEmailDisabled) || voucherVerificationError}
          value={email}
          onClick={() => handleFormStarted()}
          error={!!emailError.errorMsg}
          helperText={emailHelperText}
          onChange={handleEmailChange}
        />
        {countryCode !== 'us' && (
          <>
            <ReceiveEmailLabel id="receive-email-label">
              {receive_email_label.text}
            </ReceiveEmailLabel>
            <RadioGroup
              // countryCode is us, receiveEmail is default true, and radioGroup is hidden
              // Rest of world, receiveEmail is "" and radioGroup is shown
              row
              id="receive-email-radio-group"
              ariaLabel="receive-email"
              name="row-radio-buttons-group"
              currentValue={receiveEmail}
              handleOnChange={(event) => {
                handleRadioChange(event);
              }}
              radioData={[
                { value: 'yes', label: receive_email_yes.text, disabled: voucherVerificationError },
                { value: 'no', label: receive_email_no.text, disabled: voucherVerificationError },
              ]}
            />
            {receiveEmailHelperText && (
              <div className="form-field-helper">{receiveEmailHelperText}</div>
            )}
          </>
        )}
        <Privacy
          id="privacy"
          dangerouslySetInnerHTML={{
            __html: privacy_policy_disclaimer.replace(
              // eslint-disable-next-line no-template-curly-in-string
              '${privacy_policy_link}',
              `<a href=${privacy_policy_link.text} target='_blank'>${privacy_policy_link_label.text}</a>`
            ),
          }}
        />
        <ButtonWrapper id="confirm-email-button-wrapper">
          <Button
            type="submit"
            loading={isProcessing || loading}
            disabled={submitDisabled || voucherVerificationError}
            id="confirm-email-button"
            ctaButton
            style={{ width: '100%' }}
            onClick={handleSubmit}
          >
            <>{confirm_email_button_text.text}</>
          </Button>
        </ButtonWrapper>
      </Form>
    </Wrapper>
  );
};

ConfirmEmail.defaultProps = {
  data: {},
  lang: '',
  email: '',
  isProcessing: false,
  serverError: '',
  updateEmail: () => {},
  handleRecaptchaValidateData: () => {},
};

ConfirmEmail.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  data: PropTypes.object,
  lang: PropTypes.string,
  email: PropTypes.string,
  isProcessing: PropTypes.bool,
  serverError: PropTypes.string,
  updateEmail: PropTypes.func,
  handleRecaptchaValidateData: PropTypes.func,
};

export default ConfirmEmail;
