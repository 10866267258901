import { gql } from '@apollo/client';

const getUser = gql`
  query GetUser {
    getUser {
      userId
      type
      vimeoUserId
      email
      givenName
      familyName
      lmodEntitlement
      subComms
      subCommsConfirmed
      addressStreet
      addressStreet2
      addressCountry
      addressPostalCode
      addressRegion
      addressStateCode
      addressLocality
      cartProductHandle
      cartOfferId
      cartOfferHandle
      cartAffiliateId
      cartExpiryDate
      cartTransactionId
      userEntitlements {
        LMOD {
          vimeoUserTicket
        }
      }
      paymentMethod {
        last4
        cardType
        email
        type
      }
      chargifyPaymentProfile {
        last4
        cardType
        email
        type
      }
      isForceUpdateAddress
      lmodSubscription {
        user_id
        tune_offer_id
        offer_id
        product_handle
        offer_handle
        coupon_code
        total_price_in_cents
        promotion_label
        product {
          product_handle
          name
          description
          currency
          rollover_product_handle
          product_price_point {
            price_in_cents
            interval
            interval_unit
            trial_interval
            trial_interval_unit
            final_price_in_cents
            tax_in_cents
            final_tax_in_cents
          }
        }
        state
        previous_state
        reactive
        current_period_ends_at
        canceled_at
        next_product_id
        next_product_handle
        delayed_cancel_at
        default_to_monthly
        rollover_status
      }
      hasOtherActiveSub
    }
  }
`;

export default getUser;
