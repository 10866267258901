import React, { FC, useContext } from 'react';
import { navigate } from 'gatsby';
import { Hero } from '@lesmills-international/components';
import { formatHeroEquipments, isVimeoUrl } from '@services';
import { Carousel } from '@components/atoms';
import { userContext } from '@context';
import { HeroSliceWrapper, CarouselWrapper } from './style';

interface Props {
  content: Queries.PrismicExploreDataBodyExploreHeroCarousel;
}

const ExploreHeroCarouselSlice: FC<Props> = ({ content }) => {
  const { userEntitlements } = useContext(userContext);
  const vimeoTicket = userEntitlements?.LMOD?.vimeoUserTicket;
  const SLIDES_PER_VIEW = 1;
  const SLIDE_SPACE_BETWEEN = 0;

  const listOfHeroCards = content?.items.map((item) => {
    const promoColor = item?.promo_color?.slice(item?.promo_color?.indexOf('#'));
    const heroProps = {
      promoText: item?.promo_text,
      promoColor,
      showPromo: item?.show_promo,
      tagLine: item?.lead_text,
      mainText: item?.main_text,
      descText: item?.desc_text,
      backgroundImg: item?.image?.url,
      buttonPrimaryText: item?.button_primary_text ?? '',
      buttonSecondaryText: item?.button_secondary_text,
      duration: item?.tag,
      equipmentIcons: formatHeroEquipments(item, item?.display_equipment_section),
      onStart: () => {
        const redirectionUrl = isVimeoUrl(item?.button_primary?.url)
          ? `${item?.button_primary?.url}?ticket=${vimeoTicket}`
          : item?.button_primary?.url;
        navigate(redirectionUrl);
      },
      // Hide the save button
      onSave: null,
    };

    return <Hero {...heroProps} />;
  });

  return (
    <HeroSliceWrapper className="hero--slice--wrapper">
      <CarouselWrapper>
        <Carousel
          items={listOfHeroCards}
          slidesPerView={SLIDES_PER_VIEW}
          slidesPerGroup={SLIDES_PER_VIEW}
          spaceBetween={SLIDE_SPACE_BETWEEN}
          paginationClickable
        />
      </CarouselWrapper>
    </HeroSliceWrapper>
  );
};

export default ExploreHeroCarouselSlice;
