import { ArrowLeft } from 'phosphor-react';
import React, { FC } from 'react';
import { Link } from '@components/atoms';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

interface Props {
  title?: string;
  lang: string;
  image?: {
    gatsbyImageData?: IGatsbyImageData;
    alt?: string;
  };
}

const LearnHeader: FC<Props> = ({ title, image, lang }) => (
  <div className="lg:px-4 lg:mt-4 mb-2">
    <div className="relative">
      {image?.gatsbyImageData && (
        <GatsbyImage
          image={image.gatsbyImageData}
          alt={image.alt ?? ''}
          className="w-full h-full object-cover"
          style={{
            position: 'absolute',
          }}
        />
      )}

      <div className="relative p-4 bg-grey bg-opacity-50 flex items-center justify-between text-center">
        <Link to={lang === 'en-nz' ? '/fit-planet/' : `/${lang}/fit-planet/`} title="Back">
          <ArrowLeft weight="bold" color="white" size={24} />
        </Link>

        {title && (
          <h1 className="text-white font-inter-semibold uppercase text-32 leading-relaxed tracking-tight">
            {title}
          </h1>
        )}

        <div />
      </div>
    </div>
  </div>
);

export default LearnHeader;
