import { PaymentIconType } from '../components/common/payment/PaymentIcon';
import { PaymentInfo } from '../types/Payment';

const mapPaymentName = (paymentInfo: PaymentInfo): PaymentIconType => {
  const type = paymentInfo?.cardType || paymentInfo?.type || '';
  const cardType = type.toLowerCase();
  const iconName = cardType === 'paypalaccount' ? 'paypal' : cardType;

  return iconName as PaymentIconType;
};

export const formatPaymentDesc = (lastFourDigit?: string, description?: string | null) => {
  if (description && lastFourDigit) {
    const prismicPlaceholder = '{last_four_digit}';
    return description.replace(prismicPlaceholder, lastFourDigit);
  }
};

export default mapPaymentName;
