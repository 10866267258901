import React from 'react';
import { ReactComponent as BandLogo } from '@assets/equipment/band.svg';
import { ReactComponent as BarbellLogo } from '@assets/equipment/barbell.svg';
import { ReactComponent as BikeLogo } from '@assets/equipment/bike.svg';
import { ReactComponent as MatLogo } from '@assets/equipment/mat.svg';
import { ReactComponent as StepLogo } from '@assets/equipment/step.svg';
import { ReactComponent as WeightLogo } from '@assets/equipment/weight.svg';

enum Equipment {
  BARBELL = 'Barbell',
  RESISTANCEBANE = 'Resistance Band',
  STATIONARYBIKE = 'Stationary Bike',
  STEP = 'Step/Bench',
  WEIGHTPLATE = 'Weights',
  YOGAMATS = 'Yoga Mat',
}

type EquipmentListProps = {
  equipments: string[];
};

const EquipmentList = ({ equipments }: EquipmentListProps) => (
  <div className="flex gap-1 text-white">
    {equipments?.map((item) => {
      switch (item) {
        case Equipment.BARBELL:
          return <BarbellLogo key={item} />;
        case Equipment.RESISTANCEBANE:
          return <BandLogo key={item} />;
        case Equipment.STATIONARYBIKE:
          return <BikeLogo key={item} />;
        case Equipment.STEP:
          return <StepLogo key={item} />;
        case Equipment.WEIGHTPLATE:
          return <WeightLogo key={item} />;
        case Equipment.YOGAMATS:
          return <MatLogo key={item} />;
        default:
          return null;
      }
    })}
  </div>
);

export default EquipmentList;
