/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';

import { formValidator, checkHaveErrors } from '../../../utils/validator';
import { createPublicClient } from '../../../services/client';
import getLocation from '../../../graphql/getLocation';
import { addErrorInDatadogRum } from '../../../utils/utilities';

import Button from '../button';
import TextField from '../textField';
import Select from '../select';

import { ReactComponent as CloseIcon } from '../../../assets/icons/dialog-close.svg';

import { AddressFormTitle } from './style';

const CustomizeDialog = styled(Dialog)(() => ({
  position: 'absolute !important',
  '& .MuiBackdrop-root': {
    backgroundColor: 'transparent',
  },
  '& .MuiPaper-root': {
    borderRadius: '0',
    boxShadow: '0 4px 15px rgba(0, 0, 0, 0.25)',
  },
  '& .MuiDialogContent-root': {
    padding: '55px 35px 58px 35px',
  },
  '& .MuiTextField-root': {
    margin: '0 !important',
  },
  '& .MuiButton-root': {
    width: '100% !important',
    marginTop: '3px',
  },
  '& .MuiFilledInput-root': {
    margin: '3px 0 !important',
  },
  '& .manual-address-dialog-title': {
    padding: '27px 24px',
  },
}));

const ManualAddressDialog = ({
  prismicData,
  open,
  handleClose,
  defaultValues,
  countryCode,
  handleAddManualAddress,
  countriesData,
  statesData,
}) => {
  const {
    address_form_title,
    address_1_field_label,
    address_2_field_label,
    city_field_label,
    state_field_label,
    postal_code_field_label,
    country_field_label,
    address_form_button,
    postal_code_field_error,
  } = prismicData;

  const currentCountryName = countriesData?.items.filter(
    (country) => country.country_code?.text.toLowerCase() === countryCode
  )[0]?.country_name?.text;

  let currentCountryState = [];
  statesData.forEach((wrapper) => {
    if (wrapper.primary?.country_code?.text.toLowerCase() === countryCode) {
      currentCountryState = wrapper.items;
    }
  });

  const countriesEnablePostalCodeValidation = ['us', 'ca'];
  const countriesRequiredStateSelect = ['us', 'ca', 'de', 'au', 'at', 'mx'];
  const countriesRequiredStateTextFiled = ['br', 'et', 'in', 'ng', 'fm', 'nm', 'ss', 'sd', 've'];
  const countriesDisabledPostalCodeValidation = ['ie', 'ae'];
  const countriesWithHiddenPostalCode = ['ae'];

  const [values, setValues] = useState(defaultValues);
  const [formErrors, setFormErrors] = useState({
    city: {
      errorMsg: '',
      valid: false,
    },
    state: {
      errorMsg: '',
      valid: false,
    },
    postal_code: {
      errorMsg: '',
      valid: false,
    },
  });

  useEffect(() => {
    const cloneDefaultValues = { ...defaultValues };
    if (!defaultValues.countryCode) {
      cloneDefaultValues.countryCode = countryCode;
      cloneDefaultValues.country = currentCountryName;
    }
    setValues({
      ...values,
      ...cloneDefaultValues,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  const handleChange = (field, event, isSelect) => {
    const { value } = event.target;
    if (field === 'state' && isSelect) {
      const stateCode = currentCountryState.filter((data) => data.state_name.text === value)[0]
        .state_code?.text;
      setValues({ ...values, [field]: value, stateCode });
    } else {
      setValues({ ...values, [field]: value });
    }
  };

  const validateSingleField = (name, value) =>
    formValidator(['REQUIRED', name.toUpperCase()], value, {
      requiredError: prismicData[`${name}_field_error`],
    });

  const handleSingleValidate = (field, event) => {
    const error = validateSingleField(field, event.target.value);
    setFormErrors({ ...formErrors, [field]: error });
  };

  const handleValidate = () => {
    const cityError = validateSingleField('city', values.city);
    const postalCodeError = validateSingleField('postal_code', values.postalCode);
    let errors = {
      city: cityError,
      postal_code: postalCodeError,
    };

    if (countriesDisabledPostalCodeValidation.includes(countryCode)) {
      errors = {
        city: cityError,
      };
    }

    if (
      countriesRequiredStateTextFiled.includes(countryCode) ||
      countriesRequiredStateSelect.includes(countryCode)
    ) {
      const stateError = validateSingleField('state', values.state);
      errors = {
        city: cityError,
        state: stateError,
        postal_code: postalCodeError,
      };
    }

    setFormErrors(errors);
    return errors;
  };

  const handleSubmit = async () => {
    try {
      const errors = handleValidate();
      const cloneValues = { ...values };
      if (!checkHaveErrors(errors)) {
        if (countriesEnablePostalCodeValidation.includes(countryCode)) {
          const response = await createPublicClient.query({
            query: getLocation,
            variables: {
              country: countryCode,
              postalCode: cloneValues.postalCode,
            },
          });

          const stateCode = response?.data?.getLocation?.stateCode;
          if (stateCode) {
            if (stateCode !== cloneValues.stateCode) {
              cloneValues.stateCode = stateCode;
              cloneValues.state = currentCountryState.filter(
                (data) => data.state_code.text === stateCode
              )[0].state_name?.text;
            }
          } else {
            setFormErrors({
              ...errors,
              postal_code: {
                errorMsg: postal_code_field_error,
                valid: false,
              },
            });
            return;
          }
        }

        if (countriesWithHiddenPostalCode.includes(countryCode)) {
          cloneValues.postalCode = '000000';
        }
        handleAddManualAddress(cloneValues);
      }
    } catch (error) {
      addErrorInDatadogRum(error);
    }
  };

  const closeDialog = () => {
    setFormErrors({
      city: {
        errorMsg: '',
        valid: false,
      },
      state: {
        errorMsg: '',
        valid: false,
      },
      postal_code: {
        errorMsg: '',
        valid: false,
      },
    });
    handleClose();
  };

  const renderStateField = () => {
    if (countriesRequiredStateSelect.includes(countryCode)) {
      return (
        <Select
          required
          label={state_field_label}
          value={values.state}
          items={currentCountryState}
          error={!!formErrors.state.errorMsg}
          helperText={formErrors.state.errorMsg}
          handleChange={(event) => {
            handleChange('state', event, true);
            handleSingleValidate('state', event);
          }}
        />
      );
    }

    if (countriesRequiredStateTextFiled.includes(countryCode)) {
      return (
        <TextField
          required={countriesRequiredStateTextFiled.includes(countryCode)}
          label={state_field_label}
          variant="filled"
          name="state"
          id="state-field"
          value={values.state}
          error={!!formErrors.state.errorMsg}
          helperText={formErrors.state.errorMsg}
          onBlur={(event) => {
            handleSingleValidate('state', event);
          }}
          onChange={(event) => {
            handleChange('state', event);
          }}
        />
      );
    }
    return (
      <TextField
        label={state_field_label}
        variant="filled"
        name="state"
        id="state-field"
        value={values.state}
        onChange={(event) => {
          handleChange('state', event);
        }}
      />
    );
  };

  const renderPostalCode = () => {
    if (countriesWithHiddenPostalCode.includes(countryCode)) {
      return null;
    }

    if (countriesDisabledPostalCodeValidation.includes(countryCode)) {
      return (
        <TextField
          label={postal_code_field_label}
          variant="filled"
          name="postal_code"
          id="postal-code-field"
          value={values.postalCode}
          onChange={(event) => {
            handleChange('postalCode', event);
          }}
        />
      );
    }

    return (
      <TextField
        required={!countriesDisabledPostalCodeValidation.includes(countryCode)}
        label={postal_code_field_label}
        variant="filled"
        name="postal_code"
        id="postal-code-field"
        value={values.postalCode}
        error={!!formErrors.postal_code.errorMsg}
        helperText={formErrors.postal_code.errorMsg}
        onBlur={(event) => {
          handleSingleValidate('postal_code', event);
        }}
        onChange={(event) => {
          handleChange('postalCode', event);
        }}
      />
    );
  };

  return (
    <CustomizeDialog
      aria-labelledby="manual-address-dialog"
      open={open}
      container={() => document.getElementById('layout-rightsection-wrapper')}
    >
      <DialogTitle className="manual-address-dialog-title">
        <IconButton
          aria-label="close"
          onClick={closeDialog}
          sx={{
            position: 'absolute',
            right: 14,
            top: 14,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box
          id="manual-address-form-wrapper"
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: '271px' },
            textAlign: 'center',
          }}
          noValidate
          autoComplete="off"
        >
          <AddressFormTitle>{address_form_title}</AddressFormTitle>
          <TextField
            label={address_1_field_label}
            variant="filled"
            name="address_1"
            id="address-1-field"
            value={values.address}
            onChange={(event) => {
              handleChange('address', event);
            }}
          />
          <TextField
            label={address_2_field_label}
            defaultValue=""
            variant="filled"
            name="address_2"
            id="address-2-field"
            value={values.address2}
            onChange={(event) => {
              handleChange('address2', event);
            }}
          />
          <TextField
            required
            label={city_field_label}
            variant="filled"
            name="city"
            id="city-field"
            value={values.city}
            error={!!formErrors.city.errorMsg}
            helperText={formErrors.city.errorMsg}
            onBlur={(event) => {
              handleSingleValidate('city', event);
            }}
            onChange={(event) => {
              handleChange('city', event);
            }}
          />
          {renderStateField()}
          {renderPostalCode()}
          <TextField
            required
            disabled
            label={country_field_label}
            variant="filled"
            name="country"
            id="country-field"
            value={values.country || currentCountryName}
          />
          <Button
            id="address-submit-button"
            variant="contained"
            className="submit-button"
            onClick={handleSubmit}
          >
            <>{address_form_button}</>
          </Button>
        </Box>
      </DialogContent>
    </CustomizeDialog>
  );
};

export default ManualAddressDialog;
