/* eslint-disable @typescript-eslint/naming-convention */
import React, { FC } from 'react';
import styled from 'styled-components';

import { BREAKPOINTS, useMediaQuery } from '@hooks';
import { ReactComponent as CloseIcon } from '@assets/icons/close-nocolor.svg';
import { getMobileOperatingSystem } from '../../../utils/utilities';
import {
  trackDownloadAppLinkClickedEvent,
  trackDownloadAppLearnMoreLinkClickedEvent,
  trackDownloadAppCloseButtonClickedEvent,
} from '../../../utils/segment/segment';
import { BannerIdType } from '../../molecules/layout/layout';
import type { BannerDataProps } from '../../molecules/layout/layout';
import COLORS from '../../../themes/colors';

const BannerWrapper = styled.div`
  background-color: ${(props) => props.color};
  display: flex;
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  font-weight: 900;
  padding: 12px 18px;
  position: relative;
  color: ${COLORS.darkBlack};
`;

const CenterSection = styled.div`
  flex: 1;
  gap: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Inter Medium';
`;

const LinkSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  position: absolute;
  right: 48px;
  top: 12px;

  & a {
    text-decoration: underline;
    cursor: pointer;
    font-family: 'Inter SemiBold';
  }
`;

const CloseButton = styled.div`
  position: absolute;
  right: 18px;
  top: 14px;
  display: flex;
  align-items: center;

  & svg {
    cursor: pointer;
  }
`;

const MobileBannerWrapper = styled.div`
  background-color: ${(props) => props.color};
  display: flex;
  width: 100%;
  font-size: 16px;
  line-height: 18px;
  font-weight: 900;
  padding: 12px 18px;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  color: ${COLORS.darkBlack};
`;
const MobileCenterSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-family: 'Inter Medium';
  letter-spacing: normal;
`;
const MobileLinksSection = styled.div`
  display: flex;
  gap: 16px;
  font-weight: 800;

  & a {
    text-decoration: underline;
    font-family: 'Inter SemiBold';
  }
`;

interface Props {
  data: BannerDataProps;
  handleBannerHidden: (bannerId: string | undefined) => void;
}

const GlobalBanner: FC<Props> = ({ data, handleBannerHidden }) => {
  const {
    banner_background_color,
    banner_content,
    banner_logo,
    banner_id,
    display_close_button,
    download_app_button_link_apple,
    download_app_button_link_google,
    download_app_button_text,
    learn_more_button_link,
    learn_more_button_text,
  } = data;

  const IOS = 'iOS';
  const ANDROID = 'Android';

  const matches = useMediaQuery(BREAKPOINTS.MD);

  const mobileOperatingSystem = getMobileOperatingSystem();
  const displayDownloadAppLink = mobileOperatingSystem === IOS || mobileOperatingSystem === ANDROID;
  const downloadAppLink =
    mobileOperatingSystem === IOS
      ? download_app_button_link_apple
      : download_app_button_link_google;

  return (
    <>
      {matches && (
        <BannerWrapper color={banner_background_color}>
          <CenterSection>
            <img src={banner_logo?.url} alt="partner-logo" />
            <span>{banner_content}</span>
          </CenterSection>
          <LinkSection>
            {displayDownloadAppLink && (
              <a
                href={downloadAppLink}
                target="_blank"
                rel="noreferrer"
                onClick={() => {
                  if (banner_id === BannerIdType.DOWNLOAD_APP_BANNER_ID) {
                    trackDownloadAppLinkClickedEvent();
                  }
                }}
              >
                {download_app_button_text}
              </a>
            )}
            <a
              href={learn_more_button_link}
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                if (banner_id === BannerIdType.DOWNLOAD_APP_BANNER_ID) {
                  trackDownloadAppLearnMoreLinkClickedEvent();
                }
              }}
            >
              {learn_more_button_text}
            </a>
          </LinkSection>
          {display_close_button && (
            <CloseButton>
              <CloseIcon
                onClick={() => {
                  if (banner_id === BannerIdType.DOWNLOAD_APP_BANNER_ID) {
                    trackDownloadAppCloseButtonClickedEvent();
                  }
                  handleBannerHidden(banner_id);
                }}
              />
            </CloseButton>
          )}
        </BannerWrapper>
      )}
      {!matches && (
        <MobileBannerWrapper color={banner_background_color}>
          <img src={banner_logo?.url} alt="partner-logo" />
          <MobileCenterSection>
            <span>{banner_content}</span>
            <MobileLinksSection>
              {displayDownloadAppLink && (
                <a
                  href={downloadAppLink}
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => {
                    if (banner_id === BannerIdType.DOWNLOAD_APP_BANNER_ID) {
                      trackDownloadAppLinkClickedEvent();
                    }
                  }}
                >
                  {download_app_button_text}
                </a>
              )}
              <a
                href={learn_more_button_link}
                target="_blank"
                rel="noreferrer"
                onClick={() => {
                  if (banner_id === BannerIdType.DOWNLOAD_APP_BANNER_ID) {
                    trackDownloadAppLearnMoreLinkClickedEvent();
                  }
                }}
              >
                {learn_more_button_text}
              </a>
            </MobileLinksSection>
          </MobileCenterSection>
          {display_close_button && (
            <CloseIcon
              onClick={() => {
                if (banner_id === BannerIdType.DOWNLOAD_APP_BANNER_ID) {
                  trackDownloadAppCloseButtonClickedEvent();
                }
                handleBannerHidden(banner_id);
              }}
            />
          )}
        </MobileBannerWrapper>
      )}
    </>
  );
};

export default GlobalBanner;
