/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-promise-executor-return */
/* eslint-disable no-param-reassign */
// eslint-disable-next-line import/no-unresolved
import { getConvertedPrice } from '@src/utils';
import {
  DOLLAR,
  DOLLAR_CURRENCIES,
  OTHER_CURRENCIES,
  TAX_INCLUDED_CURRENCIES,
} from '../constants/currency';

import ENABLED_3DS_COUNTRIES from '../constants/enabled3DSCountries';

export const isBrowser = typeof window !== 'undefined';

export const isEmpty = (obj) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }
  return JSON.stringify(obj) === JSON.stringify({});
};

export const setLocalStorage = (key, data) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  isBrowser && window.localStorage && window.localStorage.setItem(key, data);
};

export const getLocalStorage = (key) =>
  isBrowser && window.localStorage ? window.localStorage.getItem(key) : undefined;

export const deleteLocalStorage = (key) =>
  isBrowser && window.localStorage && window.localStorage.removeItem(key);

export const setSessionStorage = (key, data) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  isBrowser && window.sessionStorage && window.sessionStorage.setItem(key, data);
};

// Get automation reCaptcha auth and nonce from local storage
export const getAutomationRecaptcha = () => {
  const automationRecaptcha = getLocalStorage('automation-recaptcha');

  if (!automationRecaptcha) {
    return null;
  }
  const parsedCaptcha = JSON.parse(automationRecaptcha);
  return parsedCaptcha;
};

export const checkHaveValue = (values) => Object.keys(values).some((key) => values[key]);

export const priceFormat = ({ cents, currency, addTax, toFixedNumber, taxLabel = '' }) => {
  const priceObj = {
    currency,
    price: getConvertedPrice(cents, currency, toFixedNumber),
    addTax,
    taxLabel,
  };

  return currencyFormat(priceObj);
};

export const currencyFormat = ({ currency, price, addTax, taxLabel = '' }) => {
  // first, check if the price is .10, .20, .30, .40, .50, .60, .70, .80, or .90, then ensure there are 2 decimal places
  const formattedPrice = Number(price).toFixed(2);
  if (!Number.isInteger(Number(price)) && formattedPrice.slice(-1) === '0') {
    price = formattedPrice;
  }
  // next, check if tax is included in this currency and output the appropriate text
  // todo, make this a backend thing instead of frontend
  const isTaxIncluded = TAX_INCLUDED_CURRENCIES[currency] && addTax;
  let result = isTaxIncluded ? `$${price} ${taxLabel}` : `$${price}`;
  if (DOLLAR_CURRENCIES[currency]) {
    result = isTaxIncluded
      ? `${DOLLAR}${price} ${DOLLAR_CURRENCIES[currency]} ${taxLabel}`
      : `${DOLLAR}${price} ${DOLLAR_CURRENCIES[currency]}`;
  } else if (OTHER_CURRENCIES[currency]) {
    result = isTaxIncluded
      ? `${OTHER_CURRENCIES[currency]}${price} ${taxLabel}`
      : `${OTHER_CURRENCIES[currency]}${price}`;
  }
  return result;
};

export const firstPaymentDateFormat = (trialDays, lang) => {
  const date = new Date().setDate(new Date().getDate() + trialDays);
  const options = { month: 'long', day: '2-digit' };
  const firstPaymentDate = new Date(date).toLocaleDateString(lang, options);
  const shortMonth = firstPaymentDate.split(' ')[1].slice(0, 3);
  return `${firstPaymentDate.split(' ')[0]} ${shortMonth}`;
};

export const enabled3DSBillingAddress = (addressCountry) =>
  ENABLED_3DS_COUNTRIES.includes(addressCountry.toLowerCase()) === true;

const isLargerThanOneMonthSubscription = (subscription) => {
  const { interval, interval_unit } = subscription || {};
  return (interval > 1 && interval_unit === 'month') || interval_unit === 'year';
};

const isTwelveMonthSubscription = (subscription) => {
  const { interval, interval_unit } = subscription || {};
  return interval === 12 && interval_unit === 'month';
};

export const checkMultiMonthRolloverCountry = (rolloverCountries, userCountry) =>
  rolloverCountries?.threeMonth?.includes(userCountry.toLowerCase());

export const checkIsTwelveMonthRolloverCountry = (rolloverCountries, userCountry) =>
  rolloverCountries?.twelveMonth?.includes(userCountry.toLowerCase());

export const allowRolloverPreferences = (userCountry, subscription, rolloverCountries) => {
  if (rolloverCountries) {
    const isMultiMonthRollover =
      isLargerThanOneMonthSubscription(subscription) &&
      checkMultiMonthRolloverCountry(rolloverCountries, userCountry);

    const isTwelveMonthRollover =
      isTwelveMonthSubscription(subscription) &&
      checkIsTwelveMonthRolloverCountry(rolloverCountries, userCountry);

    return isMultiMonthRollover || isTwelveMonthRollover;
  }
};

export const defaultToMonthly = ({
  productHandle,
  countryCode,
  productPricePoint,
  rolloverValue,
  rolloverCountries,
}) => {
  const isAllowRollover = allowRolloverPreferences(
    countryCode,
    productPricePoint,
    rolloverCountries
  );
  let result = 'false';
  if (isAllowRollover) {
    result = rolloverValue && rolloverValue === 'monthly' ? 'true' : 'false';
  } else {
    if (countryCode === 'nl') {
      result = productHandle.includes('12monthly') ? 'true' : 'false';
    }
    if (countryCode === 'dk') {
      result = productHandle.includes('6monthly') ? 'true' : 'false';
    }
    if (countryCode === 'de' && productHandle.includes('6monthly')) {
      result = 'false';
    }
  }
  return result;
};

export const addErrorInDatadogRum = (error) => {
  if (isBrowser && window.datadogRum) {
    const userInfo = JSON.parse(getLocalStorage('user-info') || 'null');
    window.datadogRum.addError(error, {
      context: {
        userId: userInfo.userId,
      },
    });
  }
};

const generateAffiliateUrl = (discountInfo) => {
  const { affiliateId, offerId, transactionId, voucherCode } = discountInfo?.data || {};
  const queryParams = [];

  if (affiliateId) queryParams.push(`aff_id=${affiliateId}`);
  if (offerId) queryParams.push(`offer_id=${offerId}`);
  if (transactionId) queryParams.push(`transaction_id=${transactionId}`);
  if (voucherCode) queryParams.push(`voucher_code=${voucherCode}`);

  const queryString = queryParams.length > 0 ? `&${queryParams.join('&')}` : '';

  return queryString;
};

// We are getting winback info from BE now
export const getLoginUrl = (discountInfo, lang, isPartnerSignUp, tiering) => {
  const currentLang = lang !== 'en-nz' ? `/${lang}` : '';
  // note the / prefixes a non-English language in currentLang

  if (isPartnerSignUp) {
    return `/login${currentLang}`;
  }

  let path = '';

  if (discountInfo) {
    if (discountInfo?.type === 'promotionCode') {
      path = `/${discountInfo?.data?.promotionCode}`;
    }
    if (discountInfo?.type === 'affiliate') {
      path = `/affiliate`;
    }
  }

  const selectPlanPage = `/signup/select-plan/${path}/${currentLang}`;
  let loginUrl = `/login${currentLang}?redirect_to=${encodeURIComponent(selectPlanPage)}`;
  if (tiering !== null) {
    loginUrl += `&tiering=${tiering}`;
  }
  // if the user came from a promotional link or an affiliate link, and they are an old/expired customer, retain the promo code in the login url so it stays in LocalStorage
  if (discountInfo?.type === 'promotionCode') {
    loginUrl += `&promo=${discountInfo?.data?.promotionCode}`;
  } else if (discountInfo?.type === 'affiliate') {
    const affiliateUrl = generateAffiliateUrl(discountInfo);
    loginUrl += affiliateUrl;
  }

  return loginUrl;
};

export const waitForElementToLoad = (querySelector, timeout) =>
  new Promise((resolve, reject) => {
    let timer = false;

    if (document.querySelectorAll(querySelector).length) return resolve();
    const observer = new MutationObserver(() => {
      if (document.querySelectorAll(querySelector).length) {
        observer.disconnect();
        if (timer !== false) clearTimeout(timer);
        return resolve();
      }
    });
    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
    if (timeout)
      timer = setTimeout(() => {
        observer.disconnect();
        reject();
      }, timeout);
  });

export const getDiscountInfo = () => JSON.parse(getLocalStorage('discountInfo') || null);
