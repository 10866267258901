import styled, { css } from 'styled-components';
// eslint-disable-next-line import/no-unresolved
import { breakpoint } from '@themes';

const programsSliceWrapperShared = styled.div`
  width: 100%;
  padding-top: 24px;
  padding-bottom: 24px;
  background: #000;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
`;

export const ProgramsSliceWrapperShort = styled.div`
  ${programsSliceWrapperShared};
  background: none;
  padding: 12px 0;
`;

export const ProgramsSliceWrapperTall = styled.div`
  ${programsSliceWrapperShared};
  gap: 16px;
  ${breakpoint('sm')`
    margin-left: 16px;
  `}
  ${breakpoint('md')`
    margin-left: 32px;
  `}
  ${breakpoint('lg')`
    margin-left: 80px;
  `}
`;

const programSliceGridShared = css`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 8px;
  ${breakpoint('md')`
    grid-template-columns: repeat(4,minmax(0,1fr));
  `}
`;
export const ProgramsSliceGridShort = styled.div`
  ${programSliceGridShared}
`;
export const ProgramsSliceGridTall = styled.div`
  ${programSliceGridShared}
  gap: 16px;
`;

export const TitleWrapperShort = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 16px;
  margin-right: 16px;
  ${breakpoint('md')`
    margin-left: 32px;
    margin-right: 32px;
  `}
  ${breakpoint('xl')`
    margin-left: 80px;
    margin-right: 80px;
  `}
`;

export const TitleWrapperTall = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.h4`
  color: ${(props) => props.theme.colors.lightishGrey};
  font-size: 18px;
  line-height: 25px;
  font-family: 'Inter SemiBold';
  ${breakpoint('lg')`
    font-size: 24px;
    line-height: 33px;
  `}
`;

const ProgramWrapperShared = css`
  width: 100%;
  display: flex;
  align-items: center;
  // This helps us support the hover animation
  & .swiper-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
  }
  // This is to handle how it displays in different pages. Potentially is a new component?
  & .logo {
    filter: none;
  }
  & .swiper {
    padding-left: 16px;
    padding-right: 16px;
    ${breakpoint('md')`
      padding-left: 24px;
      padding-right: 24px;
    `}
    ${breakpoint('xl')`
      padding-left: 80px;
      padding-right: 80px;
    `}
  }
`;

export const ProgramWrapperShort = styled.div`
  ${ProgramWrapperShared};
  height: 90px;
  ${breakpoint('sm')`
    height: 132px;
  `}
  ${breakpoint('md')`
    height: 155px;
  `}
`;

export const ProgramWrapperTall = styled.div`
  ${ProgramWrapperShared};
  height: 120px;
  ${breakpoint('sm')`
    height: 120px;
  `}
  ${breakpoint('md')`
    height: 244px;
  `};
  & .programCard {
    height: 120px;
    ${breakpoint('md')`
      height: 200px;
    `}
  }
  &:hover .programCard {
    height: 120px;
    ${breakpoint('md')`
      height: 224px;
    `}
  }
`;
