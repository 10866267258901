/* eslint-disable no-template-curly-in-string */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable arrow-body-style */
/* eslint arrow-body-style: ["error", "always"] */
/* eslint-disable no-unsafe-optional-chaining */

// 👇️ ts-nocheck ignores all ts errors in the file
// This is a JSX file that has been converted to TSX
// as we want to have type checking on new TS-based methods,
// without having to rewrite the whole page into TS first.
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect, useState } from 'react';

import LeavePageDialog from '../../common/leavePageDialog/LeavePageDialog';

import { normalizePlans } from './normalizePlansPromotions';
import {
  setLocalStorage,
  getLocalStorage,
  addErrorInDatadogRum,
  isBrowser,
} from '../../../utils/utilities';
import { filterBauPlans } from '../../../utils/tiering';
import PlanCard from './PlanCardPromotions';
import { createPublicClient } from '../../../services/client';
import getValidProducts from '../../../graphql/getValidProducts';
import getPromotionProducts from '../../../graphql/getPromotionProducts';

import { ReactComponent as BackIcon } from '../../../assets/icons/back.svg';

import {
  ChangePlan,
  Description,
  PlansContainer,
  PlansScrollContainer,
  SubTitle,
  Title,
  Wrapper,
  BackBtn,
} from './selectPlanCommonStyle';

const getSubtitleText = (selectedPlan, subtitle, notrialdescription) =>
  selectedPlan?.trial_interval
    ? subtitle.replace('{days}', `${selectedPlan.trial_interval}`)
    : notrialdescription;

const SelectPlan = ({
  pageData,
  pageContext,
  voucherContent,
  discountInfo,
  leavePageDialogContent,
  countryOverridesData,
  isWinback,
  selectedPlan,
  onSelectPlan,
}) => {
  const [countryCode, setCountryCode] = useState('');
  const [subtitleText, setSubtitleText] = useState('');
  const [hidden, setHidden] = useState(false);
  const [displayChangePlanBtn, setDisplayChangePlanBtn] = useState(false);
  const [openLeavePageDialog, setOpenLeavePageDialog] = useState(false);
  const [showTotalPrice, setShowTotalPrice] = useState(false);
  const {
    pricing_title,
    winback_pricing_title,
    pricing_subtitle,
    pricing_disclaimer,
    change_plan,
    no_trial_text,
    raf_promo_code,
  } = pageData;
  const [plans, setPlans] = useState([]);

  const urlParams = isBrowser && new URLSearchParams(window.location.search);
  setLocalStorage('winback', isWinback);
  const currentPlanId = urlParams?.get('plan_id');
  if (process.env.GATSBY_RT_05_10_2022_ABANDONED_CART === 'true' && currentPlanId) {
    setLocalStorage('planId', currentPlanId);
  }
  // this is pre-selected planId
  const planId = getLocalStorage('planId');

  const { uid } = pageContext;
  const referPromo = discountInfo?.type === 'refer' ? 'refer' : null;
  const promotionCode = uid || referPromo;
  const titleText = isWinback ? winback_pricing_title : pricing_title;
  const getProductsVariables = promotionCode ? { promotionCode } : {};
  const getProducts = promotionCode ? getPromotionProducts : getValidProducts;
  useEffect(() => {
    const GetValidProductsQuery = async () => {
      try {
        const prices = await createPublicClient.query({
          query: getProducts,
          variables: getProductsVariables,
        });
        const normalizedPlans = normalizePlans(
          prices,
          promotionCode ? 'promotion' : 'normal',
          isWinback
        );
        const bauPlans = filterBauPlans(normalizedPlans);
        setPlans(bauPlans);
        setCountryCode(prices.data.getHeaders.countryCode);
        const preselectedPlan = bauPlans.find((plan) => plan.id === planId);
        if (preselectedPlan) {
          setHidden(true);
          setDisplayChangePlanBtn(true);
          onSelectPlan(preselectedPlan);
        }

        const newSubtitleText = getSubtitleText(
          preselectedPlan,
          pricing_disclaimer,
          no_trial_text,
          isWinback
        );
        setSubtitleText(newSubtitleText);
      } catch (error) {
        addErrorInDatadogRum(error);
      }
    };
    GetValidProductsQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageContext, no_trial_text, pricing_disclaimer, getProducts, raf_promo_code, isWinback]);

  useEffect(() => {
    if (countryOverridesData?.length && countryCode) {
      countryOverridesData.forEach((overrideCountry) => {
        if (overrideCountry.country === countryCode.toLowerCase()) {
          setShowTotalPrice(overrideCountry?.show_total_price);
        }
      });
    }
  }, [countryCode, countryOverridesData]);

  const handleSelectPlan = (newSelectedPlanId) => {
    const newSelectedPlan = plans.find((plan) => plan.id === newSelectedPlanId);
    onSelectPlan(newSelectedPlan);
    setHidden(true);
    setDisplayChangePlanBtn(true);
    const newSubtitleText = getSubtitleText(newSelectedPlan, pricing_disclaimer, no_trial_text);
    setSubtitleText(newSubtitleText);
  };
  const handleChangePlan = () => {
    setHidden(false);
    setDisplayChangePlanBtn(false);
  };

  const handleBack = () => {
    setOpenLeavePageDialog(true);
  };

  const voucher = voucherContent
    ? voucherContent.find(
        (voucherInstance) => voucherInstance?.country?.toUpperCase() === countryCode?.toUpperCase()
      )
    : null;
  const hasHighlightedPlan = plans ? plans[0]?.sortOrder === 0 : null;
  return (
    <Wrapper id="plansWrapper">
      <BackBtn onClick={handleBack} id="back-button">
        <BackIcon />
      </BackBtn>
      <Title>{titleText}</Title>
      <SubTitle>{subtitleText}</SubTitle>
      <Description>{pricing_subtitle}</Description>
      <PlansScrollContainer id="PlansScrollContainer">
        <PlansContainer id="PlansContainer">
          {plans.length > 0 &&
            countryCode &&
            plans.map((plan) => {
              return (
                <PlanCard
                  plan={plan}
                  key={plan.name}
                  handleSelectPlan={handleSelectPlan}
                  selectedPlan={selectedPlan}
                  isHidden={hidden}
                  pricingData={pageData}
                  countryCode={countryCode}
                  voucher={voucher}
                  hasHighlightedPlan={hasHighlightedPlan}
                  noTrialDisplay={isWinback}
                  showTotalPrice={showTotalPrice}
                />
              );
            })}
          {displayChangePlanBtn && (
            <ChangePlan onClick={handleChangePlan}>{change_plan}</ChangePlan>
          )}
        </PlansContainer>
      </PlansScrollContainer>

      <LeavePageDialog
        title={leavePageDialogContent?.title}
        description={leavePageDialogContent?.description}
        stayBtnText={leavePageDialogContent?.stay_btn_text}
        leavePageBtnText={leavePageDialogContent?.leave_btn_text}
        open={openLeavePageDialog}
        handleClose={() => {
          setOpenLeavePageDialog(false);
        }}
        leaveToUrl={process.env.GATSBY_LANDING_PAGES_HOME}
      />
    </Wrapper>
  );
};

export default SelectPlan;
