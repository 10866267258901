import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { Grid } from '@mui/material';
import { debounce } from 'lodash';
import { Filter, AlbumCard } from '@lesmills-international/components';

import { formatProgramCategories, getActiveProgramCategories, formatPrograms } from '@services';
import { userContext } from '@context';
import type { CategoryType, ProgramType } from '@types/program';

interface Props {
  listPrograms: ProgramType[];
}

const ProgramResultAmount = styled.p`
  font-size: 16px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.lightGreyB0};
  margin-top: 24px;
  margin-bottom: 35px;
`;

const ProgramGridWithCategoryFilters: React.FC<Props> = ({ listPrograms }) => {
  const { tier } = useContext(userContext);
  const [programCategories, setProgramCategories] = useState<CategoryType[]>(
    formatProgramCategories(listPrograms)
  );
  const [filteredPrograms, setFilteredPrograms] = useState<ProgramType[]>(
    formatPrograms(listPrograms, 'All', tier)
  );
  const [currentWidth, setCurrentWidth] = useState(window.innerWidth);

  useEffect(() => {
    const resizeInterval = 100;
    const handleResize = debounce(() => setCurrentWidth(window.innerWidth), resizeInterval);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const GRID_BREAKPOINTS = [
    {
      width: 319,
      gridSize: 12,
      padding: '16px',
    },
    {
      width: 599,
      gridSize: 6,
      padding: '32px',
    },
    {
      width: 799,
      gridSize: 4,
      padding: '32px',
    },
    {
      width: 1023,
      gridSize: 3,
      padding: '80px',
    },
    {
      width: 1439,
      gridSize: 2.4,
      padding: '80px',
    },
  ];

  const getGridInfo = (width: number) => {
    let breakPointIndex = 0;
    GRID_BREAKPOINTS.forEach((breakPoint, index) => {
      if (breakPoint.width < width) breakPointIndex = index;
    });
    return GRID_BREAKPOINTS[breakPointIndex];
  };

  const { gridSize } = getGridInfo(currentWidth);

  const handleChangeProgramCategory = (_, id: string) => {
    const updatedCategories = getActiveProgramCategories(programCategories, id);
    setProgramCategories(updatedCategories.categories);
    setFilteredPrograms(formatPrograms(listPrograms, updatedCategories.activeCategory.id, tier));
  };

  return (
    <Filter items={programCategories} handleClickItem={handleChangeProgramCategory}>
      <ProgramResultAmount>{`${filteredPrograms?.length} programs`}</ProgramResultAmount>
      <Grid container spacing={2} role="list">
        {filteredPrograms?.map((program, index) => (
          <Grid item xs={gridSize} key={index} role="listitem">
            <AlbumCard
              title={program?.name}
              description={program?.alternateName}
              backgroundImg={program?.imageWide}
              link={`/program/${program?.name
                ?.replaceAll("'", '-')
                .replaceAll(' ', '-')
                .toLowerCase()}`}
            />
          </Grid>
        ))}
      </Grid>
    </Filter>
  );
};

export default ProgramGridWithCategoryFilters;
