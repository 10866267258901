/* eslint-disable @typescript-eslint/naming-convention */
import React, { useContext, useState } from 'react';
import { Dialog, DialogContent, DialogActions, Button } from '@lesmills-international/components';
import { userContext } from '../../../../gatsby-theme-engagement/src/context';
import {
  RestartSubscriptionPopUpContentTitle,
  RestartSubscriptionPopUpContentDescription,
} from './styles';
import { getBillingPeriod } from '../../utils/currencyPromotions';
import {
  formatCurrentBillingDetail,
  formatNextBillingPriceDetail,
  formatSubscriptionDetail,
} from './utils';
import PaymentDetails from './PaymentDetails';
import useRestartSubscription from '../../hooks/mutation/useRestartSubscription';
import { NotificationState } from '../../hooks/useAccountNotification';
import { addErrorInDatadogRum } from '../../utils/utilities';

interface Props {
  isOpened: boolean;
  handleClose: () => void;
  handleNotification: ({ message, severity }: NotificationState) => void;
  data: Pick<
    Queries.PrismicAccountOverviewPageData,
    | 'subscription_status_title'
    | 'subscription'
    | 'restart_subscription'
    | 'current_billing_detail'
    | 'includes_tax'
    | 'payment_details'
    | 'what_happens_next'
    | 'next_billing_detail'
    | 'confirm_button_text'
    | 'month'
    | 'year'
    | 'three_month'
    | 'trimonthly_subscription'
    | 'monthly_subscription'
    | 'annual_subscription'
    | 'update_payment_details_description'
    | 'restart_subscription_success'
    | 'restart_subscription_error'
  >;
}

const RestartSubscriptionPopUp = ({ isOpened, handleClose, data, handleNotification }: Props) => {
  const [isLoading, setIsloading] = useState(false);
  const { lmodSubscription, tier, refetchUser } = useContext(userContext);

  const {
    subscription_status_title,
    subscription,
    restart_subscription,
    payment_details,
    what_happens_next,
    confirm_button_text,
    trimonthly_subscription,
    annual_subscription,
    monthly_subscription,
    update_payment_details_description,
    restart_subscription_success,
    restart_subscription_error,
  } = data;

  const billingPeriod = getBillingPeriod(lmodSubscription?.product?.product_price_point?.interval, {
    quarterly: trimonthly_subscription,
    yearly: annual_subscription,
    monthly: monthly_subscription,
  });

  const subscriptionDetail = formatSubscriptionDetail(
    subscription_status_title,
    tier,
    billingPeriod
  );

  const currentBillingDetail = formatCurrentBillingDetail({
    subscription: lmodSubscription,
    prismicData: data,
    interval: lmodSubscription?.product?.product_price_point?.interval,
  });

  const nextBillingDetail = formatNextBillingPriceDetail({
    subscription: lmodSubscription,
    prismicData: data,
    interval: lmodSubscription?.product?.product_price_point?.interval,
  });

  const [handleRestartSubscription] = useRestartSubscription({
    onCompleted: async () => {
      // fetch user query again to get the updated user after subscription restarted
      refetchUser && (await refetchUser());

      // update loading state to false after user query refetched
      setIsloading(false);
      handleClose();
      handleNotification({ message: restart_subscription_success, severity: 'success' });
    },
    onError: (err) => {
      handleNotification({ message: restart_subscription_error, severity: 'error' });
      addErrorInDatadogRum(err);
    },
  });

  const handleSubmit = () => {
    setIsloading(true);
    handleRestartSubscription();
  };

  const onModalClose = () => {
    if (isLoading) {
      return false;
    }
    handleClose();
  };

  if (!isOpened) {
    return null;
  }

  return (
    <Dialog
      title={restart_subscription}
      open={isOpened}
      onClose={onModalClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogContent>
        <RestartSubscriptionPopUpContentTitle>{subscription}</RestartSubscriptionPopUpContentTitle>
        <RestartSubscriptionPopUpContentDescription>
          <p>{subscriptionDetail}</p>
          <p>{currentBillingDetail}</p>
        </RestartSubscriptionPopUpContentDescription>
        <RestartSubscriptionPopUpContentTitle>
          {payment_details}
        </RestartSubscriptionPopUpContentTitle>
        <RestartSubscriptionPopUpContentDescription>
          <PaymentDetails cardDescription={update_payment_details_description} />
        </RestartSubscriptionPopUpContentDescription>
        <RestartSubscriptionPopUpContentTitle>
          {what_happens_next}
        </RestartSubscriptionPopUpContentTitle>
        <RestartSubscriptionPopUpContentDescription>
          <ul>
            <li>
              <span>{nextBillingDetail}</span>
            </li>
          </ul>
        </RestartSubscriptionPopUpContentDescription>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit} ctaButton style={{ width: '100px' }} loading={isLoading}>
          {confirm_button_text}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RestartSubscriptionPopUp;
