import { useState, useEffect } from 'react';
import { createPublicClient } from '../services/client';
import verifyAffiliateVoucherCode from '../graphql/verifyAffiliateVoucherCode';
import { addErrorInDatadogRum } from '../utils/utilities';
import VoucherStatus from '../constants/voucherVerificationStatus';

interface DiscountInfo {
  type?: string;
  data?: {
    affiliateId?: string | null;
    offerId?: string | null;
    voucherCode?: string | null;
  };
}

const useAffiliateVouchercodeVerification = (discountInfo: DiscountInfo, countryCode: string) => {
  const [voucherVerificationStatus, setVoucherVerificationStatus] = useState('');
  const [voucherVerificationError, setVoucherVerificationError] = useState(false);

  useEffect(() => {
    if (discountInfo?.type === 'affiliate' && countryCode) {
      const getAffiliateVoucherCodeVerification = async () => {
        try {
          const verificationStatus = await createPublicClient.query({
            query: verifyAffiliateVoucherCode,
            variables: {
              affiliate_id: discountInfo?.data?.affiliateId,
              offer_id: discountInfo?.data?.offerId,
              voucher_code: discountInfo?.data?.voucherCode,
              country_code: countryCode,
            },
          });
          const verificationCode = verificationStatus?.data?.verifyAffiliateVoucherCode?.statusCode;
          const successfullyVerified =
            verificationCode === VoucherStatus.SUCCESS_REGULAR_AFFILIATE ||
            verificationCode === VoucherStatus.SUCCESS_VOUCHER_AFFILIATE;

          if (!successfullyVerified) {
            setVoucherVerificationError(true);
          }

          setVoucherVerificationStatus(verificationCode);
        } catch (error) {
          addErrorInDatadogRum(error);
        }
      };

      getAffiliateVoucherCodeVerification();
    }
  }, [discountInfo, countryCode]);

  return { voucherVerificationStatus, voucherVerificationError };
};

export default useAffiliateVouchercodeVerification;
