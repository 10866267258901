import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client';
import UpdateUser from '../../graphql/updateUser';
import {
  AxiiUserInput,
  MutationUpdateAxiiUserArgs,
} from '../../../../gatsby-theme-engagement/src/backend';

interface UpdateUserResponse {
  updateAxiiUser: {
    givenName: string;
    familyName: string;
    email: string;
    addressStreet: string;
    addressStreet2: string;
    addressCountry: string;
    addressPostalCode: string;
    addressRegion: string;
    addressLocality: string;
  };
}

const useUpdateUser = (
  options?: MutationHookOptions<UpdateUserResponse, MutationUpdateAxiiUserArgs>
): MutationTuple<UpdateUserResponse, MutationUpdateAxiiUserArgs> =>
  useMutation<UpdateUserResponse, { input: AxiiUserInput }>(UpdateUser, options);

export default useUpdateUser;
