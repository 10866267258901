import React, { useState, useEffect, useRef, useContext } from 'react';
import { Filter, ProgramCard } from '@lesmills-international/components';

import { Carousel } from '@components/atoms';
import {
  formatProgramCategories,
  formatPrograms,
  getActiveProgramCategories,
  getProgramCardSlideAmount,
  getSlideSpace,
} from '@services';
import { contentClickTracking } from '@src/utils';
import { useWindowSize } from '@hooks';
import { userContext } from '@context';
import { TIERS, PROGRAM_TEASER_TITLE } from '@constants';
import type { ProgramType } from '@types/program';

import {
  ProgramsSliceWrapper,
  TitleWrapper,
  Title,
  ViewAllLink,
  CarouselWrapper,
  FilterWrapper,
} from './style';
import ROUTES from '../../../../../../src/constants/routes';

interface Props {
  content: Queries.PrismicExploreDataBodyProgramsWithCategoryFilter;
  listPrograms: ProgramType[];
  analytics: {
    contentListTitle: string;
    contentListIndex: number;
    contentLocation: string;
  };
}

const ExploreHeroaprogramsWithCategoryFilterSlice: React.FC<Props> = ({
  content,
  listPrograms,
  analytics,
}) => {
  const [activeListPrograms, setActiveListPrograms] = useState(formatPrograms(listPrograms, 'All'));
  const [programCardItems, setProgramCardItems] = useState<JSX.Element[]>([]);
  const [carouselSlidesPerView, setCarouselSlidesPerView] = useState(10);
  const [carouselSlideSpaceBetween, setCarouselSlideSpaceBetween] = useState(16);
  const [programCategories, setProgramCategories] = useState(formatProgramCategories(listPrograms));

  const carouselRef = useRef<HTMLDivElement>(null);
  const windowInnerWidth = useWindowSize();
  const { userEntitlements, tier } = useContext(userContext);
  const vimeoTicket = userEntitlements?.LMOD?.vimeoUserTicket;

  const changeSubscriptionPage = ROUTES().SUBSCRIPTION;

  useEffect(() => {
    groupProgramItems(activeListPrograms);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowInnerWidth]);

  const formatProgramCardNode = (programsGroup: ProgramType[], preGroupSlideAmount: number) => {
    const programsGroupPlaceHolder = [];
    if (programsGroup?.length < preGroupSlideAmount) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < preGroupSlideAmount - programsGroup.length; i++) {
        programsGroupPlaceHolder.push(<div />);
      }
    }

    const programsWithTiers = programsGroup?.map((item) => ({
      ...item,
      isProgramLocked: tier === TIERS.BASE && !item.tiers?.includes(TIERS.BASE),
    }));

    const programsBase = [] as ProgramType[];
    const programsPremium = [] as ProgramType[];

    programsWithTiers?.forEach((item) =>
      item.isProgramLocked
        ? programsPremium.push(item as ProgramType)
        : programsBase.push(item as ProgramType)
    );

    const programsGroupSorted =
      programsBase.length > 0 ? programsBase.concat(programsPremium) : programsPremium;

    const programComponents = programsGroupSorted.map((item, index) => {
      // normal pattern: lowercase, replace punctuation and spaces with hypens
      const baseProgramName = item?.name?.replaceAll("'", '-').replaceAll(' ', '-').toLowerCase();
      return (
        <ProgramCard
          isTeaser={item.isProgramLocked}
          teaserHoverTitle={PROGRAM_TEASER_TITLE}
          name={item.name}
          logo={item.icon}
          backgroundImg={item.imageWide}
          alternateName={item.alternateName}
          link={item.isProgramLocked ? changeSubscriptionPage : `/program/${baseProgramName}`}
          onClick={() => {
            contentClickTracking({
              ...analytics,
              contentIndex: index + 1,
              contentTitle: item?.name,
              contentType: 'other',
              contentTopic: 'Program',
            });
          }}
        />
      );
    });

    // This placeholder is for carousel last slice style layout
    return programsGroupPlaceHolder.length > 0
      ? programComponents.concat(programsGroupPlaceHolder)
      : programComponents;
  };

  const groupProgramItems = (programs: ProgramType[], id?: string) => {
    if (carouselRef && carouselRef.current) {
      const programsGroup = formatPrograms(programs, id);
      const preGroupSlideAmount = getProgramCardSlideAmount(windowInnerWidth);
      const slideSpace = getSlideSpace(windowInnerWidth);
      setActiveListPrograms(programsGroup);
      setProgramCardItems(formatProgramCardNode(programsGroup, preGroupSlideAmount));
      setCarouselSlidesPerView(preGroupSlideAmount);
      setCarouselSlideSpaceBetween(slideSpace);
    }
  };

  const handleChangeProgramCategory = (_, id: string) => {
    const updatedCategories = getActiveProgramCategories(programCategories, id);

    groupProgramItems(listPrograms, updatedCategories.activeCategory.id);

    setProgramCategories(updatedCategories.categories);
  };

  return (
    <ProgramsSliceWrapper className="program--wrapper">
      <TitleWrapper>
        <Title>{content?.primary?.title}</Title>
        {content?.primary?.view_all_text && (
          <ViewAllLink href={`${content?.primary?.view_all_link}?ticket=${vimeoTicket}`}>
            {content?.primary?.view_all_text}
          </ViewAllLink>
        )}
      </TitleWrapper>
      <FilterWrapper>
        <Filter items={programCategories} handleClickItem={handleChangeProgramCategory}>
          <CarouselWrapper ref={carouselRef}>
            <Carousel
              items={programCardItems}
              slidesPerView={carouselSlidesPerView}
              slidesPerGroup={carouselSlidesPerView}
              spaceBetween={carouselSlideSpaceBetween}
            />
          </CarouselWrapper>
        </Filter>
      </FilterWrapper>
    </ProgramsSliceWrapper>
  );
};

export default ExploreHeroaprogramsWithCategoryFilterSlice;
