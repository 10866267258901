import { gql } from '@apollo/client';

const getLocation = gql`
  query getLocation($country: String!, $postalCode: String) {
    getLocation(country: $country, postalCode: $postalCode) {
      stateCode
    }
  }
`;

export default getLocation;
