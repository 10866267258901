import { useQuery } from '@apollo/client';
import getProduct from '../../../graphql/getProduct';
import { GENERIC_ERROR, PromoErrorType } from '../constants';
import { addErrorInDatadogRum } from '../../../utils/utilities';
import { ProductInformation } from '../type';

interface GetProduct {
  subdomain?: string;
  productHandle?: string;
  countryCode?: string;
}

const useGetProduct = ({ subdomain, productHandle, countryCode }: GetProduct) => {
  const productId = `${subdomain}:${productHandle}`;
  const { error, loading, data } = useQuery(getProduct, {
    variables: { productId, countryCode },
    skip: !productHandle || !countryCode || !subdomain,
  });

  const productInfo: ProductInformation = data?.getProduct;
  let productError: PromoErrorType | undefined;
  if (error) {
    addErrorInDatadogRum(error);
    productError = GENERIC_ERROR;
  }

  return { productInfo: { ...productInfo }, loading, productError };
};

export default useGetProduct;
