import { navigate } from 'gatsby';
import ROUTES from '../constants/routes';

export const isBrowser = typeof window !== 'undefined';

export const getFormattedParamInfoFromURL = (location) => {
  const urlParams = new URLSearchParams(location.search);
  // Get promotionCode from query string
  const promotionCode = urlParams.get('promo');
  // Get refererID
  const referrerId = urlParams.get('grsf');
  // Get affiliate info
  const affiliateId = urlParams.get('aff_id');
  const offerId = urlParams.get('offer_id');
  const transactionId = urlParams.get('transaction_id');
  // Get voucher code
  const voucherCode = urlParams.get('voucher_code');

  let discountInfoFormatted;
  // set discountInfo(refer, promoCode, affiliate) into local storage
  if (promotionCode) {
    discountInfoFormatted = {
      type: 'promotionCode',
      data: {
        promotionCode,
      },
    };
  } else if (referrerId) {
    discountInfoFormatted = {
      type: 'refer',
      data: {
        referrerId,
      },
    };
  } else if (affiliateId) {
    discountInfoFormatted = {
      type: 'affiliate',
      data: {
        affiliateId,
        offerId,
        transactionId,
        voucherCode,
      },
    };
  }

  return discountInfoFormatted || {};
};

export const parseJwt = (token) => {
  if (!token) return null;

  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');

  return JSON.parse(window.atob(base64));
};

// Acquisition local storage function calls haven't been moved to global utils folder yet
export const setLocalStorage = (key, data) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  isBrowser && window.localStorage && window.localStorage.setItem(key, data);
};

export const getLocalStorage = (key) =>
  isBrowser && window.localStorage ? window.localStorage.getItem(key) : undefined;

export const deleteLocalStorage = (key) =>
  isBrowser && window.localStorage && window.localStorage.removeItem(key);

// Get automation reCaptcha auth and nonce from localStorage
export const getAutomationRecaptcha = () => {
  const automationRecaptcha = getLocalStorage('automation-recaptcha');

  if (!automationRecaptcha) {
    return null;
  }
  const parsedCaptcha = JSON.parse(automationRecaptcha);
  return parsedCaptcha;
};

export const getPlanPeriod = (interval, intervalUnit) => {
  if (interval >= 12 && intervalUnit.toLowerCase() === 'month') {
    return '1 year';
  }

  const unit = interval > 1 ? intervalUnit : `${intervalUnit}s`;
  return `${interval} ${unit}`;
};

export const getConvertedPrice = (priceInCents, currency, toFixedNumber) => {
  let convertedPrice = priceInCents / 100;

  if (currency === 'JPY') {
    convertedPrice = priceInCents;
  } else if (toFixedNumber) {
    convertedPrice = (priceInCents / 100).toFixed(toFixedNumber);
  }

  return convertedPrice;
};

export const getPlanId = (discountInfo, plan) => {
  const { offerHandle, product_handle: productHandle } = plan || {};
  const hasOffer = Boolean(discountInfo?.promotionCode || discountInfo?.affiliate);
  return hasOffer ? offerHandle : productHandle;
};

export const redirectToMyAccount = () => {
  if (process.env.GATSBY_RT_19_02_2024_ACCOUNT_OVERVIEW === 'true') {
    navigate(ROUTES().ACCOUNT);
  } else if (isBrowser && process.env.GATSBY_MY_ACCOUNT_URL) {
    window.location.href = process.env.GATSBY_MY_ACCOUNT_URL;
  }
};
export const fixSlashes = (url) =>
  // This regex matches double-slashes that are in a path, and not part of an http:// or https://
  // It ensures we fix paths that have double slashes, examples:
  // "/login//de-de" replaces with "/login/de-de"
  // "/login" does not change
  // "https://try.lmplus-uat.com//test" changes to "https://try.lmplus-uat.com/test
  url.replace(new RegExp('(https?://)|(/{2,})', 'g'), (originalString, httpMatch) =>
    // If http:// or https:// is matched within the string, return it as-is (leave its double-slashes).
    // Otherwise, matched double-slashes should change to one slash.
    httpMatch ? originalString : '/'
  );

export const getLangFromURL = ({ withLeadingSlash = false }) => {
  // looks at the browser url, grabs the language locale from it
  // optional param withLeadingSlash returns a slash before the locale, when there is a locale
  if (isBrowser) {
    const localeRegex = new RegExp('/([a-z]{2}-[a-z]{2})');
    if (!window.location.pathname) {
      return '';
    }
    const path = window.location.pathname;
    const match = path.match(localeRegex);
    const leadingSlash = withLeadingSlash ? '/' : '';
    return match ? `${leadingSlash}${match[1]}` : '';
  }
};
