import React from 'react';
import { navigate, Link } from 'gatsby';
import { RouteComponentProps } from '@reach/router';
import { Button } from '@lesmills-international/components';
import { ReactComponent as Logo } from '@assets/lmi-plus-logo.svg';
import { GatsbyBackgroundImage, GatsbyBackgroudImageProps } from '@components/atoms';
import { PARAM_VALUE } from '@constants';
import { pushTracking } from '@src/utils';
import { Event, Params } from '@src/type';
import { SurveyWrapper, StartHeading, CenteredSurveyContainer, GradientOverlay } from './styles';

interface StartSurveyProps extends RouteComponentProps, GatsbyBackgroudImageProps {
  surveyTitle?: string;
}

/**
 * This component is used for the first step of our onboarding survey
 */
const StartSurvey = ({ surveyTitle, image, alt }: StartSurveyProps) => {
  const handleOnSkip = () => {
    pushTracking({
      event: Event.SURVEY_SKIP,
      [Params.CUSTOMER_LOGGED_IN_STATUS]: PARAM_VALUE.TRUE,
      [Params.STEP_LABEL]: PARAM_VALUE.SURVEY_START_STEP_LABEL,
    });
    navigate('/');
  };
  return (
    <GatsbyBackgroundImage image={image} alt={alt}>
      <GradientOverlay />
      <SurveyWrapper>
        <CenteredSurveyContainer>
          <div>
            {/* Fix logo in component library */}
            <Logo fill="#FFF" width={146} height={18} />
          </div>
          <StartHeading>{surveyTitle}</StartHeading>
          <Button label="Next" ctaButton inverted onClick={() => navigate('/survey/0/')} />
          <div style={{ marginTop: '50px', fontSize: '14px', zIndex: 200 }}>
            {/* There is a known bug with typescript and the gatsby link component, This can be resolved by updating @types/react to v18^ - which would also involve us updating to react 18, which means we need to upgrade to Gatsby */}
            {/* @ts-ignore */}
            <Link to="/" onClick={handleOnSkip}>
              SKIP
            </Link>
          </div>
        </CenteredSurveyContainer>
      </SurveyWrapper>
    </GatsbyBackgroundImage>
  );
};

export default StartSurvey;
