import { gql } from '@apollo/client';

const getPlaceAutocomplete = gql`
  query getPlaceAutocomplete($input: String!, $components: String!, $language: String) {
    getPlaceAutocomplete(input: $input, components: $components, language: $language) {
      place_id
      description
    }
  }
`;

export default getPlaceAutocomplete;
