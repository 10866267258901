import React from 'react';
import { Table } from '@mui/material';

import { GetInvoiceType } from '../../hooks/queries/useGetInvoices';
import { createTabelRow } from './utils';
import { HeadCell, Order, TableData } from './types';
import { TableContainer, TablePagination } from './styles';

import TableHead from './TableHead';
import TableBody from './TableBody';
import BillingHistoryMobileView from './BillingHistoryMobileView';

interface Props {
  pageData: Queries.PrismicBillingHistoryPageData;
  invoices: GetInvoiceType;
}

const BillingHistoryTable = ({ pageData, invoices }: Props) => {
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof TableData>('date');
  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(6);

  if (!invoices?.length) {
    return <p>{pageData.no_invoice_message}</p>;
  }

  const rows = createTabelRow(invoices);

  const headCells: HeadCell[] = [
    {
      id: 'date',
      label: pageData.date_label,
    },
    {
      id: 'subscription',
      label: pageData.subscription_label,
    },
    {
      id: 'servicePeriod',
      label: pageData.service_period_label,
    },
    {
      id: 'total',
      label: pageData.total_label,
    },
  ];

  const handleRequestSort = (_: React.MouseEvent<unknown>, property: keyof TableData) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 600 }} aria-labelledby="billing history table">
          <TableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={headCells}
          />
          <TableBody
            rows={rows}
            order={order}
            rowsPerPage={rowsPerPage}
            page={page}
            invoiceLinkLabel={pageData.view_invoice || ''}
          />
        </Table>
      </TableContainer>

      <BillingHistoryMobileView
        rows={rows}
        invoiceLinkLabel={pageData.view_invoice || ''}
        servicePerioidLable={pageData.service_period_label || ''}
        order={order}
      />

      <TablePagination
        rowsPerPageOptions={[6, 12]}
        component="div"
        count={rows?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default BillingHistoryTable;
