import React, { FC } from 'react';
import { Skeleton } from '@components/atoms';

interface HomeHeroLoadingProps {}

const HomeHeroLoading: FC<HomeHeroLoadingProps> = () => (
  <Skeleton className="w-full h-96 rounded-lg overflow-hidden bg-white" />
);

export default HomeHeroLoading;
