import React from 'react';

import { EquipmentIcon } from '@lesmills-international/components';
import { Container, Label, Input } from './style';
import { CheckboxProps } from './types';

const Checkbox: React.FC<CheckboxProps> = ({
  displayText,
  index,
  icon,
  onChange,
  checked = false,
  type,
}: CheckboxProps) => {
  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => onChange(index, event);
  const flexibleLayout = type === 'multi_select_flexible';
  return (
    <Container data-active={checked} data-type="checkbox" isFlexible={flexibleLayout}>
      <Input
        type="checkbox"
        aria-hidden="true"
        id={displayText}
        name={displayText}
        value={index}
        onChange={onChangeHandler}
        checked={checked}
      />
      <Label htmlFor={displayText}>
        {icon && <EquipmentIcon equipmentIconName={icon} />}
        {displayText}
      </Label>
    </Container>
  );
};

export default Checkbox;
