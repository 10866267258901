import captureException from '../utils/sentry';
import getChargifySecurityToken from '../graphql/getChargifySecurityToken';
import { createClient } from './client';

const getSecurityChargifyToken = async (setErrors, handleResponse) => {
  const response = await createClient.query({
    query: getChargifySecurityToken,
  });
  if (response.errors && response.errors.length > 0) {
    setErrors({
      payment: response.errors[0].message,
    });
    captureException({
      action: 'getSercurityChargifyToken',
      ...response.errors[0],
    });
  } else {
    handleResponse(response.data.getChargifySecurityToken);
  }
};

export default getSecurityChargifyToken;
