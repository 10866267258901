import { themes } from '@lesmills-international/components';
import { Dialog as MUIDialog, styled } from '@mui/material';

export const Dialog = styled(MUIDialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '16px',
    position: 'relative',
    margin: '8px',
    backgroundColor: themes[theme.themeColor].colors.surfaceSecondary,
    color: themes[theme.themeColor].colors.contentPrimary,

    fontFamily: 'Inter Medium',
    lineHeight: '24px',
    maxWidth: '600px',

    [theme.breakpoints.down('sm')]: {
      borderRadius: '16px',
      position: 'fixed',
      bottom: 0,
      margin: 0,
      width: '100%',
    },
  },
  '& .MuiDialogTitle-root': {
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
    padding: '24px 24px 16px 24px',
    fontWeight: 800,
    fontSize: '20px',
    lineHeight: '28px',
  },
  '& .MuiDialogContent-root': {
    padding: '0',
    fontSize: '16px',
    lineHeight: '24px',
  },
  '& .MuiDialogActions-root': {
    [theme.breakpoints.down('md')]: {
      padding: '16px 16px 48px 16px',
    },

    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    padding: '16px 16px 24px 16px',

    '& > :not(:first-of-type)': {
      marginLeft: 0,
    },
  },
  '& .MuiIconButton-root': {
    position: 'absolute',
    right: 24,
    top: 24,
    padding: 0,
  },
}));

export const ContentContainer = styled('div')`
  padding: 28px 16px 16px 16px;

  p:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const Label = styled('div')(({ theme }) => ({
  backgroundColor: themes[theme.themeColor].colors.surfaceAccent,
  color: themes[theme.themeColor].colors.contentPrimary,
  fontFamily: 'Inter SemiBold',
  fontSize: '14px',
  lineHeight: '19px',
  padding: '4px 8px',
  borderRadius: '4px',
  width: 'max-content',
  marginBottom: '16px',
}));

export const Title = styled('h2')`
  font-family: Inter SemiBold;
  font-size: 24px;
  line-height: 33px;
  margin-bottom: 16px;
`;

export const MobileImageWrapper = styled('div')(({ theme }) => ({
  display: 'block',
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

export const DesktopImageWrapper = styled('div')(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('md')]: {
    display: 'block',
  },
}));

export const CloseButton = styled('button')(({ theme }) => ({
  position: 'absolute',
  right: 28,
  top: 28,
  zIndex: 1,

  path: {
    fill: themes[theme.themeColor].colors.surfaceSecondary,
  },
}));
