import React from 'react';
import { firstPaymentDateFormat, priceFormat } from '../../../utils/utilities';
import { PaymentDetails, PaymentDetailsItem } from './style';

interface Props {
  dateLabel?: string;
  amountLabel?: string;
  trialInterval: number;
  lang: string;
  currency: string;
  priceInCents: number;
  taxLabel: string;
}

const PaymentDetail = ({
  dateLabel,
  amountLabel,
  trialInterval,
  lang,
  currency,
  priceInCents,
  taxLabel,
}: Props) => {
  const firstPaymentAmount = priceFormat({
    cents: priceInCents,
    currency,
    addTax: true,
    toFixedNumber: false,
    taxLabel,
  });
  const firstPaymentDate = firstPaymentDateFormat(trialInterval, lang);

  return (
    <PaymentDetails id="payment-details-wrapper">
      <PaymentDetailsItem id="first-payment-date-item">
        <span id="first-payment-date-label">{dateLabel}</span>
        <span id="first-payment-date">{firstPaymentDate}</span>
      </PaymentDetailsItem>
      <PaymentDetailsItem id="first-payment-amount-item">
        <span id="first-payment-amount-label">{amountLabel}</span>
        <span id="first-payment-amount">{`${firstPaymentAmount}`}</span>
      </PaymentDetailsItem>
    </PaymentDetails>
  );
};

export default PaymentDetail;
