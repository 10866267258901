import React, { useState, useEffect, useContext, FC, ReactNode } from 'react';
import { Header, Footer } from '@components/molecules';
import { graphql, useStaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';
import { GlobalBanner } from '@components/atoms';
import { useTrackUserAttribute } from '@hooks';
import { userContext } from '@context';
import { setLocalStorage, getLocalStorage } from '@src/utils';

import { ThemeProvider } from 'styled-components';
import { ThemeProvider as MUIThemeProvider, createTheme } from '@mui/material/styles';

import { COLORS } from '@themes';

import Metadata from '../metadata';

export enum Theme {
  Dark = 'dark',
  White = 'white',
}

interface Props {
  children?: ReactNode;
  scripts?: any[];
  metaData?: {
    title?: string | null;
    description?: string | null;
    imageSrc?: string | null;
    imageAlt?: string | null;
    type?: string | null;
  };
  theme?: Theme;
}

export enum BannerIdType {
  ADIDAS_BANNER_ID = 'adidas',
  DOWNLOAD_APP_BANNER_ID = 'download_app',
}

export interface BannerDataProps {
  available_affiliate_id_group?: string;
  banner_background_color?: string;
  banner_content?: string;
  banner_id?: BannerIdType;
  banner_logo?: {
    url?: string;
  };
  display_close_button?: boolean;
  download_app_button_link_apple?: string;
  download_app_button_link_google?: string;
  download_app_button_text?: string;
  learn_more_button_link?: string;
  learn_more_button_text?: string;
  show_banner?: boolean;
}

const NewLayout: FC<Props> = ({ children, scripts, metaData, theme = Theme.Dark }) => {
  useTrackUserAttribute();
  const { prismicEngagementGlobalBanner } = useStaticQuery<Queries.GlobalBannerQuery>(graphql`
    query GlobalBanner {
      prismicEngagementGlobalBanner {
        data {
          body {
            ... on PrismicEngagementGlobalBannerDataBodyGlobalBanner {
              items {
                available_affiliate_id_group
                banner_background_color
                banner_content
                banner_id
                banner_logo {
                  url
                }
                display_close_button
                download_app_button_link_apple
                download_app_button_link_google
                download_app_button_text
                learn_more_button_link
                learn_more_button_text
                show_banner
              }
            }
          }
        }
      }
    }
  `);
  const { tuneAffiliateId, lmodSubscription } = useContext(userContext);
  const [showAdidasBanner, setShowAdidasBanner] = useState<boolean>(false);
  const [showDownloadAppBanner, setShowDownloadAppBanner] = useState<boolean>(false);
  const [adidasBannerData, setAdidasBannerData] = useState<BannerDataProps | object>({});
  const [downloadAppBannerData, setDownloadAppBannerData] = useState<BannerDataProps | object>({});

  const AFFILIATE_BANNER_IS_DISPLAYED = 'affiliateBannerIsDisplayed';
  const DOWNLOAD_APP_BANNER_IS_DISPLAYED = 'downloadAppBannerIsDisplayed';

  useEffect(() => {
    const bannerDatas = prismicEngagementGlobalBanner?.data?.body[0]?.items;

    if (bannerDatas && bannerDatas.length) {
      bannerDatas.forEach((banner: BannerDataProps) => {
        if (banner?.banner_id === BannerIdType.ADIDAS_BANNER_ID) {
          setAdidasBannerData(banner);
          const availableAffiliateIds = banner?.available_affiliate_id_group?.split(',');
          if (
            tuneAffiliateId &&
            availableAffiliateIds?.includes(tuneAffiliateId) &&
            !getLocalStorage(AFFILIATE_BANNER_IS_DISPLAYED)
          ) {
            setShowAdidasBanner(true);
          }
        } else if (banner?.banner_id === BannerIdType.DOWNLOAD_APP_BANNER_ID) {
          const currentDate = new Date();
          const subscriptionStartDate = new Date(lmodSubscription?.current_period_started_at || '');
          const daysDiff = (+currentDate - +subscriptionStartDate) / (1000 * 60 * 60 * 24);
          if (daysDiff <= 30 && !getLocalStorage(DOWNLOAD_APP_BANNER_IS_DISPLAYED)) {
            setShowDownloadAppBanner(true);
          }
          setDownloadAppBannerData(banner);
        }
      });
    }
  }, [lmodSubscription?.current_period_started_at, prismicEngagementGlobalBanner, tuneAffiliateId]);

  const handleBannerHidden = (bannerId: string | undefined) => {
    if (bannerId === BannerIdType.ADIDAS_BANNER_ID) {
      setLocalStorage(AFFILIATE_BANNER_IS_DISPLAYED, true);
      setShowAdidasBanner(false);
    } else if (bannerId === BannerIdType.DOWNLOAD_APP_BANNER_ID) {
      setLocalStorage(DOWNLOAD_APP_BANNER_IS_DISPLAYED, true);
      setShowDownloadAppBanner(false);
    }
  };

  const MUICreateTheme = createTheme();

  return (
    <MUIThemeProvider theme={MUICreateTheme}>
      <ThemeProvider theme={{ colors: COLORS }}>
        {metaData && <Metadata {...metaData} />}
        <Helmet
          bodyAttributes={{
            class: 'overscroll-none bg-grey-new-lighterer',
          }}
          script={scripts}
        />
        {(adidasBannerData as BannerDataProps)?.show_banner && showAdidasBanner && (
          <GlobalBanner data={adidasBannerData} handleBannerHidden={handleBannerHidden} />
        )}
        {(downloadAppBannerData as BannerDataProps)?.show_banner && showDownloadAppBanner && (
          <GlobalBanner data={downloadAppBannerData} handleBannerHidden={handleBannerHidden} />
        )}
        <div className="flex flex-col min-h-screen">
          <a className="sr-only" href="#main">
            Skip to content
          </a>
          <Header />

          <main
            id="main"
            className="flex-grow relative"
            style={{ backgroundColor: theme === Theme.Dark ? COLORS.black : COLORS.white }}
          >
            {children}
          </main>

          <Footer />
        </div>
      </ThemeProvider>
    </MUIThemeProvider>
  );
};

export default NewLayout;
