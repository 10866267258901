import React from 'react';
import { ReactComponent as DiamondIcon } from '@assets/icons/diamond.svg';

import { UPGRADE_TEXT } from '@constants';
import {
  UpgradeTierWrapper,
  UpgradeTierContent,
  GoPremiumButton,
  UpgradeTitle,
  BenefitsItemsWrapper,
  BenefitRow,
  BenefitText,
  BenefitsWrapper,
  CheckIcon,
  ExclamationIcon,
} from './styles';

import ROUTES from '../../../../../../src/constants/routes';

const RenderBenefit = ({ text }: { text: string }) => (
  <BenefitRow>
    <CheckIcon />
    <BenefitText>{text}</BenefitText>
  </BenefitRow>
);

const Benefits = () => (
  <BenefitsWrapper>
    <UpgradeTitle>{UPGRADE_TEXT.TITLE}</UpgradeTitle>
    <BenefitsItemsWrapper>
      {UPGRADE_TEXT.TITLE_SUB}
      {UPGRADE_TEXT.BENEFITS_LIST.map((item: string, index: number) => (
        <RenderBenefit key={index} text={item} />
      ))}
    </BenefitsItemsWrapper>
  </BenefitsWrapper>
);

const UpgradeTier = () => (
  <UpgradeTierWrapper>
    <UpgradeTierContent>
      <ExclamationIcon />
      {UPGRADE_TEXT.BANNER_TEXT}
    </UpgradeTierContent>
    <Benefits />
    <GoPremiumButton href={ROUTES().SUBSCRIPTION}>
      <DiamondIcon />
      {UPGRADE_TEXT.CTA}
    </GoPremiumButton>
  </UpgradeTierWrapper>
);

export default UpgradeTier;
