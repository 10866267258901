/* eslint-disable no-template-curly-in-string */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useContext, useEffect } from 'react';

import Button from '../../common/button';
import SuccessImg from '../../../assets/icons/success-sun.svg';
import { partnerSignupStatus } from '../../../constants/partnerStatus';

import {
  Wrapper,
  SuccessLogo,
  SuccessTitle,
  DescriptionMessage,
  DescriptionMessageUpdate,
  ButtonWrapper,
  PartnerMessage,
} from '../../signup/success/styles';
import { getLocalStorage } from '../../../utils/utilities';
import { authContext } from '../../../context/authContext';
import { PARTNER_SIGNUP, PARTNER_SIGNUP_STATUS } from '../../../constants/localStorageKeys';

const PartnerSuccess = ({ data, handleStarted }) => {
  const {
    success_title,
    description_message,
    button_text,
    welcome_back_title,
    contact_partner_message,
    new_user_message,
  } = data;
  const signupStatus = getLocalStorage(PARTNER_SIGNUP_STATUS);
  const { isAuthenticated } = useContext(authContext);

  let title = '';
  let message = '';

  switch (signupStatus) {
    case partnerSignupStatus.EXISTING_UPDATED:
      title = success_title;
      message = description_message;
      break;
    case partnerSignupStatus.EXISTING_ACTIVATION_NOT_VALID:
      title = welcome_back_title;
      message = new_user_message;
      break;
    case partnerSignupStatus.NEW_ACTIVATION:
    default:
      title = success_title;
      message = new_user_message;
      break;
  }

  useEffect(() => {
    const signupSuccess = getLocalStorage(PARTNER_SIGNUP) === 'yes';
    // For unauthenticated user will redirect to login page
    if (!signupSuccess && !isAuthenticated) {
      window.location.href = '/login';
    }
  }, [isAuthenticated]);

  return (
    <Wrapper id="successWrapper">
      <SuccessLogo>
        <img src={SuccessImg} alt="partner signup success" />
      </SuccessLogo>

      <SuccessTitle>{title}</SuccessTitle>
      {signupStatus === partnerSignupStatus.EXISTING_UPDATED ? (
        <>
          <DescriptionMessageUpdate>{message}</DescriptionMessageUpdate>
          <PartnerMessage>{contact_partner_message}</PartnerMessage>
        </>
      ) : (
        <DescriptionMessage>{message}</DescriptionMessage>
      )}

      <ButtonWrapper id="success-button-wrapper">
        <Button variant="contained" className="submit-button uppercase" onClick={handleStarted}>
          <>{button_text}</>
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default PartnerSuccess;
