import React, { ReactNode, ReactElement } from 'react';
import { Button } from '@lesmills-international/components';
import { Description, Title, Wrapper } from './styles';
import { ButtonType } from '../type';

interface Props {
  icon?: ReactElement;
  title: string;
  description: ReactNode;
  primaryButton: ButtonType;
  secondaryButton?: ButtonType;
}

const SuccessPage = ({ icon, title, description, primaryButton, secondaryButton }: Props) => (
  <Wrapper>
    {icon && icon}
    <Title>{title}</Title>
    <Description>{description}</Description>
    <Button
      style={{ width: '270px' }}
      label={primaryButton.label}
      onClick={primaryButton.action}
      ctaButton
    />
    {secondaryButton && (
      <Button label={secondaryButton.label} onClick={secondaryButton.action} variant="text" />
    )}
  </Wrapper>
);

export default SuccessPage;
