import { gql } from '@apollo/client';

const verifyAffiliateVoucherCode = gql`
  query verifyAffiliateVoucherCode(
    $offer_id: String!
    $affiliate_id: String!
    $voucher_code: String
    $country_code: String
  ) {
    verifyAffiliateVoucherCode(
      offer_id: $offer_id
      affiliate_id: $affiliate_id
      voucher_code: $voucher_code
      country_code: $country_code
    ) {
      statusCode
      success
    }
  }
`;

export default verifyAffiliateVoucherCode;
