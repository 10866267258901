import React, { FC, useContext } from 'react';
import { Grid } from '@mui/material';
import { AlbumCard } from '@lesmills-international/components';

import { userContext } from '@context';
import { isVimeoUrl } from '@services';

import { AlbumSliceWrapper, TitleWrapper, Title, ViewAllLink } from './style';

interface Props {
  content: Queries.PrismicExploreDataBodyAlbum;
}

interface AlbumCardProps {
  background_image: { url: string };
  title: string;
  description: string;
  detail_link: string;
}

const ExploreAlbumSlice: FC<Props> = ({ content }) => {
  const { userEntitlements } = useContext(userContext);
  const vimeoTicket = userEntitlements?.LMOD?.vimeoUserTicket;

  return (
    <AlbumSliceWrapper>
      <TitleWrapper>
        <Title>{content?.primary?.title}</Title>
        {content?.primary?.view_all_text && (
          <ViewAllLink href={`${content?.primary?.view_all_link}?ticket=${vimeoTicket}`}>
            {content?.primary?.view_all_text}
          </ViewAllLink>
        )}
      </TitleWrapper>
      <Grid columns={{ xs: 4, sm: 8, md: 12 }} container spacing={2}>
        {content?.items.map((card: AlbumCardProps, index: number) => {
          const cardProps = {
            title: card.title,
            description: card.description,
            backgroundImg: card.background_image?.url,
            link: isVimeoUrl(card.detail_link)
              ? `${card.detail_link}?ticket=${vimeoTicket}`
              : card.detail_link,
          };
          return (
            <Grid item xs={4} sm={4} md={4} key={index}>
              <AlbumCard {...cardProps} />
            </Grid>
          );
        })}
      </Grid>
    </AlbumSliceWrapper>
  );
};

export default ExploreAlbumSlice;
