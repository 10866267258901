import { gql } from '@apollo/client';

const getHeaders = gql`
  query getHeaders {
    getHeaders {
      countryCode
    }
  }
`;

export default getHeaders;
