import { createBreakpoint, createMap } from 'styled-components-breakpoint';

export const breakpoints = {
  sm: 0,
  md: 768,
  lg: 1024,
  xl: 1280,
};

export const breakpoint = createBreakpoint(breakpoints);
export const map = createMap(breakpoints);
