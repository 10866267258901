import React, { FC, useContext } from 'react';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { graphql, PageProps, navigate } from 'gatsby';
import styled from 'styled-components';
import { useFlags } from 'gatsby-plugin-launchdarkly';
import { TIERS } from '@constants';
import { userContext } from '@context';

import { breakpoint } from '@themes';
import { BackButton } from '@lesmills-international/components';
import { withPrivateRoute, Layout } from '@components/molecules';
import SliceZone from '@components/sliceZone';

const MyWorkoutsWrapper = styled.div`
  margin-top: 128px;
  background-color: ${(props) => props.theme.colors.black};
  padding-bottom: 40px;
  margin-left: 16px;
  margin-right: 16px;

  ${breakpoint('sm')`
    margin-left: 32px;
    margin-right: 32px;
  `}

  ${breakpoint('lg')`
    margin-left: 80px;
    margin-right: 80px;
  `}
`;

const Title = styled.h4`
  font-size: 48px;
  line-height: 32px;
  color: ${(props) => props.theme.colors.lightishGrey};
  font-family: 'Inter SemiBold';
  margin: 56px 0 40px;
`;

const Description = styled.div`
  font-size: 14px;
  line-height: 32px;
  color: ${(props) => props.theme.colors.lightishGrey};
`;

const BackBtnWrapper = styled.div`
  position: absolute;
  top: 36px;
  z-index: 3;

  ${breakpoint('xs')`
  left: 16px;
  `}

  ${breakpoint('sm')`
    left: 32px;
  `}

  ${breakpoint('lg')`
  left: 80px;
  `}
`;

export const CarouselWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

interface PageContextProps {
  lang: string;
}

const workoutsData = [
  {
    id: 'favorite_content$1783ec13-abda-468e-a5fb-9cdc34de2522',
    slice_type: 'plan_cards',
    items: [
      {
        favorite_content_type: 'workout',
        favorite_content_type_label: 'Workout',
        empty_status_text:
          "You haven't saved any workouts, click the save button on the workout page to see it here.",
      },
      {
        favorite_content_type: 'program',
        favorite_content_type_label: 'Program',
        empty_status_text:
          "You haven't saved any programs, click the save button on the program page to see it here.",
      },
    ],
  },
];

const MyWorkoutsTemplate: FC<PageProps<Queries.GetMyWorkoutsDataQuery, PageContextProps>> = () => {
  const { rtPcb } = useFlags();
  const { tier } = useContext(userContext);

  if (!rtPcb || tier === TIERS.BASE) {
    navigate('/explore');
  }

  return (
    <Layout>
      <BackBtnWrapper>
        <BackButton
          onClick={() => {
            window.history.back();
          }}
          theme="dark"
        />
      </BackBtnWrapper>
      <MyWorkoutsWrapper>
        <Title>PLANS</Title>
        <Description>
          Feel fitter, stronger, healthier and happier. Choose from our collection of plans and
          challenges.
        </Description>
        <SliceZone slices={workoutsData} />
      </MyWorkoutsWrapper>
    </Layout>
  );
};

export default withPrismicPreview(withPrivateRoute(MyWorkoutsTemplate));

export const query = graphql`
  query GetMyWorkoutsData($lang: String!) {
    prismicMyWorkouts(lang: { eq: $lang }) {
      data {
        title
        body {
          ... on PrismicMyWorkoutsDataBodyFavoriteContent {
            id
            slice_type
            items {
              favorite_content_type
              favorite_content_type_label
              empty_status_text
            }
          }
        }
      }
    }
  }
`;
