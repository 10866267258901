const EUROPE = [
  'md',
  'no',
  'ad',
  'is',
  're',
  'fr',
  'pl',
  'es',
  'bg',
  'pf',
  'si',
  'ax',
  'ee',
  'at',
  'lv',
  'mt',
  'nl',
  'ie',
  'hr',
  'tn',
  'rs',
  'ma',
  'dk',
  'il',
  'lu',
  'nc',
  'be',
  'ro',
  'de',
  'mk',
  'sk',
  'cz',
  'lt',
  'mf',
  'gr',
  'me',
  'fi',
  'cy',
  'pt',
  'ba',
  'mu',
  'it',
  'hu',
  'al',
  'se',
];

const EUROPE_MINUS_GERMANY = [...EUROPE];

const indexGermany = EUROPE_MINUS_GERMANY.indexOf('de');
EUROPE_MINUS_GERMANY.splice(indexGermany, 1);

const CHARGIFY_WORLD_WIDE = [
  'kn',
  'gu',
  'tj',
  'kw',
  'mx',
  'bb',
  'pk',
  'bm',
  'vc',
  'kz',
  'pa',
  'gd',
  'ec',
  'bd',
  'co',
  'qa',
  'mp',
  'ms',
  'pe',
  'om',
  'bo',
  'pr',
  'az',
  'ag',
  'hn',
  'ph',
  'jm',
  'tt',
  'ni',
  'uz',
  'vg',
  'py',
  'jo',
  'ar',
  'uy',
  'sa',
  'vn',
  'bs',
  'cl',
  'cr',
  'do',
  'tw',
  'kg',
  'dm',
  'kr',
  'th',
  'ai',
  'ky',
  'as',
  'tc',
  'lc',
  'gt',
  'am',
  'tm',
  'bh',
];

export { CHARGIFY_WORLD_WIDE, EUROPE, EUROPE_MINUS_GERMANY };
