import React, { FC } from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import classNames from 'classnames';
import { Link } from '@components/atoms';
import { formatDate } from '@services';

interface Props {
  title?: string;
  lastPublicationDate: string;
  url?: string;
  image?: {
    gatsbyImageData?: IGatsbyImageData;
    alt?: string;
  };
  small?: boolean;
}

const ArticleItem: FC<Props> = ({ title, url, image, small, lastPublicationDate }) => (
  <Link to={url} className={classNames('bg-white hover:shadow-bottom', small ? 'h-72' : 'h-96')}>
    <div className={classNames(small ? 'h-2/5' : 'h-3/5')}>
      {image?.gatsbyImageData && (
        <GatsbyImage
          image={image.gatsbyImageData}
          alt={image.alt ?? ''}
          className="object-cover w-full h-full"
        />
      )}
    </div>
    <div className={classNames('flex flex-col justify-between p-6', small ? 'h-3/5' : 'h-2/5')}>
      {title && (
        <h2
          className={classNames(
            'text-grey font-inter-semibold leading-tight uppercase',
            small ? 'text-20 line-clamp-3' : 'text-24 line-clamp-2'
          )}
        >
          {title}
        </h2>
      )}
      <div className="font-inter-light text-14 text-grey opacity-75">
        {formatDate(lastPublicationDate)}
      </div>
    </div>
  </Link>
);

export default ArticleItem;
