import { subYears } from 'date-fns';
import { formatDate } from '../../utils/subscriptions';
import { Order, TableData } from './types';
import { GetInvoiceType } from '../../hooks/queries/useGetInvoices';
import { currencyFormat } from '../../utils/utilities';

const createData = ({ subscription, date, servicePeriod, total, invoice }: TableData) => ({
  subscription,
  date,
  servicePeriod,
  total,
  invoice,
});

export const getServicePeriod = (startDate: string, endDate: string) => {
  const pattern = 'MMM d, yyyy';
  const formatedStartDate = formatDate(startDate, pattern);
  const formatedEndDate = formatDate(endDate, pattern);
  return `${formatedStartDate} - ${formatedEndDate}`;
};

export const createTabelRow = (invoices: GetInvoiceType) =>
  invoices.map(
    ({
      product_name,
      issue_date,
      period_range_end,
      period_range_start,
      total_amount,
      public_url,
      currency,
    }) =>
      createData({
        subscription: product_name,
        date: formatDate(issue_date!),
        servicePeriod: getServicePeriod(period_range_start!, period_range_end!),
        total: currencyFormat({ currency, price: total_amount, addTax: false }),
        invoice: public_url,
      })
  );

const compareDates = (a: string, b: string): number => {
  const dateA = new Date(a);
  const dateB = new Date(b);

  if (dateA > dateB) return -1;
  if (dateA < dateB) return 1;
  return 0;
};

export const getDateComparator = (order: Order): ((a: string, b: string) => number) =>
  order === 'desc' ? (a, b) => compareDates(a, b) : (a, b) => -compareDates(a, b);

export const sortByDate = (rows: TableData[], dateComparator?: (a: string, b: string) => number) =>
  rows?.slice().sort((a, b) => dateComparator!(a.date!, b.date!));

export const getInvoiceStartDate = () => {
  const now = new Date(Date.now());

  // Current date minus 2 years time.
  const startDate = subYears(now, 2);

  return formatDate(startDate, 'yyyy-MM-dd');
};
