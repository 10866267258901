import React from 'react';

import TextField from '@mui/material/TextField';

import Wrapper from './style';

const TextFieldNew = ({ ...rest }) => (
  <Wrapper>
    <TextField InputProps={{ disableUnderline: true }} {...rest} />
  </Wrapper>
);

export default TextFieldNew;
