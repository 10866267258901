// Disabled eslint for name of prismic field
/* eslint-disable @typescript-eslint/naming-convention */
import React, { FC, useContext, useEffect, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { navigate } from 'gatsby';

import Button from '../../common/button';
import { Note, Title, ButtonWrapper } from '../style';
// eslint-disable-next-line import/no-relative-packages
import { authContext } from '../../../../../gatsby-theme-engagement/src/context/authContext';
import captureException from '../../../utils/sentry';
import { addErrorInDatadogRum } from '../../../utils/utilities';
import { signUpTescoUser, SignupVariables } from '../../../services/signUpTescoUser';
import getRecaptchaV3 from '../../../utils/tesco';
import Alert from '../../common/alert';
import { ReCaptchaRef } from '../../../types/Recaptcha';

interface Props {
  processing_title?: string;
  processing_note?: string;
  recaptchaRef?: ReCaptchaRef;
  location?: {
    state: {
      variables: SignupVariables;
    };
  };
}

interface ReCaptchaInputSignIn {
  Name: string;
  Value: string;
}

const Processing: FC<Props> = ({ processing_title, processing_note, recaptchaRef, location }) => {
  const [serverError, setServerError] = useState('');
  const { variables } = location?.state || {};
  const { userInput, auth } = variables || ({} as SignupVariables);
  const { handleSignIn, isAuthenticated } = useContext(authContext) as any;
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleNavigateSuccess = () => navigate(`/tesco/success/?voucher=${auth.voucherCode}`);

  const handleSignInNewUser = async () => {
    if (!executeRecaptcha) {
      // eslint-disable-next-line no-console
      console.error('Execute recaptcha not yet available');
    }
    const recaptcha = await getRecaptchaV3(executeRecaptcha);

    if (recaptcha.token) {
      const validationData: ReCaptchaInputSignIn = {
        Name: 'recaptchaTokenV3',
        Value: recaptcha.token as string,
      };
      await handleSignIn(
        userInput?.email,
        userInput?.password,
        validationData,
        handleNavigateSuccess,
        (error: string) => onSignfailure(error, validationData)
      );
    }
  };

  const onSignfailure = (errorMessage: string, reCaptcha: ReCaptchaInputSignIn) => {
    const email = userInput?.email.trim().toLowerCase();
    if (errorMessage === 'PreAuthentication failed with error Failed reCaptcha V3 verification.') {
      // trigger recaptcha v2
      recaptchaRef?.current?.executeAsync().then((token) => {
        const validationData = {
          Name: 'recaptchaToken',
          Value: token,
        };
        handleSignIn(
          email,
          userInput?.password,
          validationData,
          handleNavigateSuccess,
          onSignfailure as any
        );
      });
    } else {
      // setIsVoucherProcessing(false);
      setServerError(errorMessage);
      addErrorInDatadogRum({ errorMessage });
      // Send error to sentry
      captureException({
        action: 'SignIn',
        requestVariables: {
          email,
          ...reCaptcha,
        },
        email,
        error: errorMessage,
      });
    }
  };

  useEffect(() => {
    (async () => {
      await signUpTescoUser(
        variables,
        () => {
          if (isAuthenticated) {
            handleNavigateSuccess();
          } else {
            handleSignInNewUser();
          }
        },
        (error: { message: string }) => {
          setServerError(error.message);
          const email = userInput?.email?.trim().toLowerCase();
          addErrorInDatadogRum(error);
          // Send error to sentry
          captureException({
            action: 'startTescoSignup',
            requestVariables: {
              email,
              ...variables,
            },
            email,
            ...error,
          });
        }
      );
    })();

    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  return (
    <div>
      {serverError && <Alert content={serverError} />}
      <Title>{processing_title}</Title>
      <Note>{processing_note}</Note>
      <ButtonWrapper>
        <Button loading>{null}</Button>
      </ButtonWrapper>
    </div>
  );
};

export default Processing;
