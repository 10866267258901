import { CaretLeft, CaretRight } from 'phosphor-react';
import React, { FC, useRef, useEffect, ReactNode } from 'react';

import ReactSlick from 'react-slick';
import 'slick-carousel/slick/slick.css';

interface Props {
  value?: number;
  onChange?: (value: number) => void;
  slides?: number;
  children?: ReactNode;
}

const Carousel: FC<Props> & { Item: FC } = ({ children, value, onChange, slides }) => {
  const ref = useRef<ReactSlick>(null);

  useEffect(() => {
    if (ref.current && value) {
      ref.current.slickGoTo(value);
    }
  }, [value]);

  return (
    <ReactSlick
      ref={ref}
      initialSlide={value ?? 0}
      slidesToShow={slides}
      infinite
      swipeToSlide
      nextArrow={<CarouselNextArrow />}
      prevArrow={<CarouselPreviousArrow />}
      afterChange={(v) => onChange?.(v)}
      responsive={[
        {
          breakpoint: 640,
          settings: { slidesToShow: 1 },
        },
      ]}
    >
      {children}
    </ReactSlick>
  );
};

Carousel.defaultProps = {
  slides: 1,
};

Carousel.Item = ({ children }: Pick<Props, 'children'>) => <div className="px-1">{children}</div>;

const CarouselNextArrow: FC<any> = ({ onClick, className }) => {
  if (className.includes('slick-disabled')) return null;

  return (
    <button
      onClick={onClick}
      type="button"
      className="absolute top-1/2 transform -translate-y-1/2 -right-6"
      aria-label="Next"
    >
      <CaretRight color="currentColor" weight="bold" size={16} />
    </button>
  );
};

const CarouselPreviousArrow: FC<any> = ({ onClick, className }) => {
  if (className.includes('slick-disabled')) return null;

  return (
    <button
      onClick={onClick}
      type="button"
      className="absolute top-1/2 transform -translate-y-1/2 -left-6"
      aria-label="Previous"
    >
      <CaretLeft color="currentColor" weight="bold" size={16} />
    </button>
  );
};

export default Carousel;
