import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import useSiteMetadata from '../../../hooks/use-site-metadata';

const SEO = ({ lang, pathname, title, description, keywords, scripts }) => {
  const siteMetadata = useSiteMetadata();
  const canonical = pathname ? `https://${process.env.GATSBY_SITE_DOMAIN}${pathname}` : null;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title || siteMetadata.title}
      script={scripts}
      meta={[
        {
          name: `description`,
          content: description || siteMetadata.description,
        },
        {
          name: `keywords`,
          content: keywords || siteMetadata.keywords,
        },
        {
          property: `og:url`,
          content: canonical,
        },
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no',
        },
      ]}
    />
  );
};

SEO.defaultProps = {
  lang: 'en-nz',
  pathname: '',
  title: '',
  description: '',
  keywords: '',
  scripts: [],
};

SEO.propTypes = {
  lang: PropTypes.string,
  pathname: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  scripts: PropTypes.array,
};

export default SEO;
