import { Carousel, HomeSection, WorkoutItem } from '@components/molecules';
import React, { FC, useContext } from 'react';
import { userContext } from '@context';

interface Props {
  content: Queries.HomeWorkoutsFragment;
}

const HomeProgramsSlice: FC<Props> = ({ content }) => {
  const { isNewUser } = useContext(userContext);
  if (content.slice_label === 'new_user_only' && !isNewUser) {
    return null;
  }

  const title = content.primary?.workout_heading?.text;
  const workouts = content.items;

  if (!workouts) return null;

  return (
    <HomeSection title={title}>
      <Carousel slides={3}>
        {workouts.map((workout, i) =>
          workout && workout.workout_id && workout.video_url?.url ? (
            <Carousel.Item key={i}>
              <WorkoutItem workoutId={workout.workout_id} workoutVideoUrl={workout.video_url.url} />
            </Carousel.Item>
          ) : null
        )}
      </Carousel>
    </HomeSection>
  );
};

export default HomeProgramsSlice;
