/* eslint-disable @typescript-eslint/naming-convention */
import { Button } from '@lesmills-international/components';
import { DialogActions, DialogContent } from '@mui/material';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';

import { MobileImageWrapper, DesktopImageWrapper, ContentContainer, Label, Title } from './styles';

interface Props {
  offer:
    | Queries.PrismicMemberPromoPopupDataFirstOfferItem
    | Queries.PrismicMemberPromoPopupDataSecondOfferItem;
  handleRedemOffer: () => void;
  handleRejectOffer: () => void;
  loading: boolean;
}

interface ButtonProps {
  label: string;
  onClick: () => void;
  loading?: boolean;
  disabled?: boolean;
}

const OfferButton = ({ label, onClick, loading, disabled }: ButtonProps) => (
  <Button
    style={{ width: '100%', maxWidth: '600px' }}
    label={label}
    ctaButton
    inverted
    loading={!!loading}
    disabled={!!disabled}
    variant="outlined"
    onClick={onClick}
  />
);

const ModalContent = ({ offer, handleRedemOffer, handleRejectOffer, loading }: Props) => {
  const {
    banner_image,
    label,
    title,
    description,
    redeem_button_text,
    reject_button_text,
    terms_and_conditions,
  } = offer || {};

  const mobileImage = banner_image?.thumbnails.mobile?.gatsbyImageData;

  return (
    <>
      <DialogContent>
        <MobileImageWrapper>
          <GatsbyImage alt="" image={mobileImage as unknown as IGatsbyImageData} />
        </MobileImageWrapper>
        <DesktopImageWrapper>
          <GatsbyImage
            alt=""
            image={banner_image?.gatsbyImageData as unknown as IGatsbyImageData}
          />
        </DesktopImageWrapper>
        <ContentContainer>
          <Label>{label}</Label>
          <Title>{title}</Title>
          {description && <p>{description}</p>}
          {terms_and_conditions && <p>{terms_and_conditions}</p>}
        </ContentContainer>
      </DialogContent>
      <DialogActions>
        <OfferButton
          label={redeem_button_text || ''}
          onClick={handleRedemOffer}
          disabled={loading}
        />
        <OfferButton
          loading={loading}
          label={reject_button_text || ''}
          onClick={handleRejectOffer}
        />
      </DialogActions>
    </>
  );
};

export default ModalContent;
