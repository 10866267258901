import React, { FC, ReactNode } from 'react';

interface Props {
  className?: string;
  children?: ReactNode;
}

const Skeleton: FC<Props> = ({ children, className }) => (
  <div className={`animate-pulse ${className}`}>{children}</div>
);

export default Skeleton;
