export const DOLLAR_CURRENCIES = {
  SGD: 'SGD', // Singapore Dollar
  CAD: 'CAD', // Canadian Dollar
  AUD: 'AUD', // Australian Dollar
  NZD: 'NZD', // New Zealand Dollar
  AED: 'AED', // United Arab Emirates Dirham
  USD: 'USD', // United States Dollar
  MYR: 'RM', // Malaysian Ringgit
  HKD: 'HKD', // Hong Kong Dollar
  BRL: 'BRL', // Brazilian real
  ZAR: 'ZAR', // South African Rand
  CHF: 'CHF', // Swiss Franc
};

export const OTHER_CURRENCIES = {
  EUR: '€', // Euro
  GBP: '£', // Great British Pound
  INR: '₹', // Indian Rupee
  JPY: '¥', // Japanese yen
};

// map{object} to check the property without need of loop
export const MONTHLY_COUNTRIES = {
  US: true,
  CA: true,
  NZ: true,
  IN: true,
  GB: true,
};

export const currencyFormat = (currency, price, plain = false) => {
  let result = { amount: `$${price}`, currency: '' };
  if (plain) {
    result = { amount: Number(price), currency: '' };
  } else if (DOLLAR_CURRENCIES[currency]) {
    result = { amount: `$${price}`, currency: DOLLAR_CURRENCIES[currency] };
  } else if (OTHER_CURRENCIES[currency]) {
    result = { amount: `${OTHER_CURRENCIES[currency]}${price}`, currency: '' };
  }
  return result;
};

export const priceFormat = (_cents, currency, toFixedNumber, interval = 0, plain = false) => {
  let cents = _cents;
  // to count price per month
  if (interval) {
    cents = _cents / interval;
  }
  let displayPrice = currencyFormat(currency, cents / 100);
  if (plain) {
    displayPrice = currencyFormat(currency, cents / 100, plain);
  }

  if (currency === 'JPY') {
    if (cents.toString().length > 6) {
      cents = cents.toFixed(2);
    }
    if (plain) {
      displayPrice = currencyFormat(currency, cents, plain);
    } else {
      displayPrice = currencyFormat(currency, cents);
    }
  } else if (toFixedNumber) {
    if (plain) {
      displayPrice = currencyFormat(currency, (cents / 100).toFixed(toFixedNumber), plain);
    } else {
      displayPrice = currencyFormat(currency, (cents / 100).toFixed(toFixedNumber));
    }
  }
  return displayPrice;
};

export const getBillingPeriod = (period, periodNames) => {
  let result = periodNames.monthly;
  if (period === 3) {
    result = periodNames.quarterly;
  }
  if (period === 6) {
    result = periodNames.biyearly;
  }
  if (period === 12) {
    result = periodNames.yearly;
  }
  return result;
};

export const getPlanPeriod = (interval, intervalUnit) => {
  const plural = interval > 1 && interval < 12;
  let period = plural ? `${interval} ${intervalUnit}s` : `${interval} ${intervalUnit}`;
  if (interval >= 12 && intervalUnit.toLowerCase() === 'month') {
    period = '1 year';
  }
  return period;
};
