/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useState, useEffect, useRef } from 'react';
import isEqual from 'lodash/isEqual';

import captureException from '../../../utils/sentry';
import { enabled3DSBillingAddress, addErrorInDatadogRum } from '../../../utils/utilities';

import { getChargifyErrorPrismic, shouldShowOriginalBraintreeError } from '../../../utils/chargify';

import PROCESSING_VERIFY_CARD from '../../../constants/chargify-types';
import Alert from '../alert';

import {
  CCNumberField,
  ExpiryMonthField,
  ExpiryYearField,
  CCVField,
  FormFieldControl,
} from './style';
import COLORS from '../../../themes/colors';

const CreditCard = ({
  securityChargifyInfo,
  currentCountry,
  prismicData,
  onGetChargifySuccess,
  handleChargifyVerifyErrors,
}) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [isChagifyFailed, setIsChagifyFailed] = useState(false);

  const chargifyForm = useRef(null);
  const chargify = useRef(null);
  const { securityToken, subdomain, publicKey } = securityChargifyInfo;
  const {
    cc_number_field_label,
    expiry_month_field_label,
    expiry_year_field_label,
    ccv_field_label,
    cc_number_is_not_valid_error,
    month_is_not_valid_error,
    year_is_not_valid_error,
    ccv_is_not_valid_error,
    processing_verify_credit_card_text = {},
    chargify_error_message,
  } = prismicData;

  useEffect(
    () => () => {
      // Reset credit card when component unmounts
      if (chargify.current) {
        chargify.current.unload();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    []
  );

  useEffect(() => {
    setTimeout(() => {
      // Avoid new Chargify many time

      try {
        if (!chargify.current) {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          chargify.current = new window.Chargify();
        }

        if (chargify.current && securityToken) {
          chargify.current.load(
            {
              publicKey,
              serverHost: `https://${subdomain}.chargify.com`,
              securityToken,
              threeDSecure: enabled3DSBillingAddress(currentCountry),
              type: 'card',
              hideCardImage: true,
              fields: {
                number: {
                  selector: '#chargify-cc-number',
                  label: cc_number_field_label.text,
                  placeholder: '**** **** **** ****',
                  message: cc_number_is_not_valid_error.text,
                },
                month: {
                  selector: '#chargify-cc-expiry-month',
                  label: expiry_month_field_label.text,
                  placeholder: 'MM',
                  message: month_is_not_valid_error.text,
                },
                year: {
                  selector: '#chargify-cc-expiry-year',
                  label: expiry_year_field_label.text,
                  placeholder: 'YYYY',
                  message: year_is_not_valid_error.text,
                },
                cvv: {
                  selector: '#chargify-cc-cvv',
                  label: ccv_field_label.text,
                  placeholder: '***',
                  required: true,
                  message: ccv_is_not_valid_error.text,
                },
              },
              style: {
                input: {
                  height: '48px',
                  placeholder: { color: '#979797' },
                  borderRadius: '0',
                  fontSize: '14px',
                  lineHeight: '18px',
                  border: `1px solid ${COLORS.borderGrey}`,
                  letterSpacing: '0.02em',
                  fontWeight: '400',
                  backgroundColor: COLORS.white,
                },
                label: {
                  fontSize: '14px',
                  fontWeight: '400',
                  lineHeight: '18px',
                  letterSpacing: '0.02em',
                  color: '#333333',
                },
              },
            },
            {
              onThreeDsConfigError: (error) => {
                captureException({
                  action: '3DSecureConfig',
                  ...error,
                });
              },
            }
          );
        }
      } catch (error) {
        addErrorInDatadogRum(error);
        setIsChagifyFailed(true);
      }
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPrimsicError = (err) => {
    const errors = err?.errors || err?.message || err;

    if (typeof errors === 'string' && errors.includes('Processor declined')) {
      return {
        message: 'There is an error while saving your payment method, please try again.',
        type: '',
      };
    }

    if (shouldShowOriginalBraintreeError(errors)) {
      return {
        message: errors,
        type: '',
      };
    }

    let prismicError = errors
      ? prismicData?.[getChargifyErrorPrismic(errors)]
      : prismicData?.saving_payment_method_error;

    let errorType = '';
    if (errors && errors.length > 0) {
      if (
        Array.isArray(errors) &&
        errors
          ?.join()
          ?.toLowerCase()
          ?.includes('cannot call verifycard while existing authentication is in progress')
      ) {
        prismicError = processing_verify_credit_card_text;
        errorType = PROCESSING_VERIFY_CARD;
      } else {
        const errorMessageEN = Array.isArray(errors) ? errors?.[1] || errors?.[0] : errors;
        prismicError = prismicData[getChargifyErrorPrismic(errorMessageEN)];
      }
    }

    return {
      message: prismicError,
      type: errorType,
    };
  };

  const mapServerError = (serverError) => {
    let error = serverError;

    if (typeof error === 'object') {
      if (error?.message) {
        error = error?.message;
      } else if (error?.errors && Array.isArray(error?.errors)) {
        error = error?.errors?.join(' ');
      }
    }

    return error;
  };

  const handleSubmitCreditCardForm = (event) => {
    event.preventDefault();
    setIsProcessing(true);
    if (chargify.current) {
      chargify.current.token(
        chargifyForm.current,
        (token) => {
          onGetChargifySuccess({
            chargifyToken: token,
            paymentType: 'CreditCard',
          });
          setIsProcessing(false);
        },
        (err) => {
          setIsProcessing(false);

          const error = mapServerError(err);

          const prismicError = err && getPrimsicError(err);
          handleChargifyVerifyErrors(prismicError?.message || error);

          addErrorInDatadogRum(error);
          const is3DSError = error.indexOf('3DS') > -1 || error.indexOf('3D Secure') > -1;
          const sentryAction = is3DSError
            ? 'verify3DSChargifyCreditCard'
            : 'verifyChargifyCreditCard';

          captureException({
            sentryAction,
            ...err,
          });
        }
      );
    }
  };
  if (isChagifyFailed) {
    return <Alert content={chargify_error_message} testId="chargify-error-alert" />;
  }
  return (
    <form id="chargify-form" ref={chargifyForm} onSubmit={handleSubmitCreditCardForm}>
      <CCNumberField id="chargify-cc-number" className="chargify-input-field" />
      <FormFieldControl>
        <ExpiryMonthField id="chargify-cc-expiry-month" className="chargify-input-field" />
        <ExpiryYearField id="chargify-cc-expiry-year" className="chargify-input-field" />
        <CCVField id="chargify-cc-cvv" className="chargify-input-field" />
      </FormFieldControl>
      <div className="host-field">
        <input
          type="hidden"
          className="host-input"
          data-chargify="country"
          value={currentCountry?.toUpperCase()}
        />
      </div>
      <button
        type="submit"
        style={{ display: 'none' }}
        disabled={isProcessing}
        id="submit-credit-card"
      />
    </form>
  );
};

export default React.memo(CreditCard, (prevProps, nextProps) => {
  return (
    isEqual(prevProps.active, nextProps.active) &&
    isEqual(prevProps.securityChargifyInfo, nextProps.securityChargifyInfo)
  );
});
