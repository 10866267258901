import React, { useState, FC } from 'react';

import Checkbox from '../checkBox';
import Radio from '../radio';
import { CheckboxProps } from '../checkBox/types';
import { RadioProps } from '../radio/types';
import { MultiSelectContainer, SingleSelectContainer } from './style';

export interface ChoiceBoxProps {
  /**
   * Text to show as the label
   */
  type: 'single_select' | 'multi_select' | 'multi_select_flexible';
  /**
   * sets initial state of the component
   * defaults to false
   */
  choices: [RadioProps | CheckboxProps | any[]];
  /**
   * Returns an array of selected indexes
   */
  onChange: (index: number) => number[];
}

const RESET_SELECT_OPTION = 'none';

const getToggle = (arr: any[], item: any) =>
  arr.includes(item) ? arr.filter((i) => i !== item) : [...arr, item];

const handleSingleSelect = (selected: any[], selectedItem: any) =>
  selected.includes(selectedItem) ? [] : [selectedItem];

const findResetIndex = (choices: []) =>
  choices.findIndex((choice) => choice.value === RESET_SELECT_OPTION);

const ChoiceBox: FC<ChoiceBoxProps> = ({ type, choices = [], onChange }) => {
  const [selected, setSelected] = useState<number[]>([]);
  const isSingleSelect = type === 'single_select';
  const resetIndex = findResetIndex(choices);

  const onChangeHandler = (selectedItem: number) => {
    const removeResetOption = (item) => item !== resetIndex;

    const resetForm = () => {
      const onlySelectNoneOption = [resetIndex];
      onChange(onlySelectNoneOption);
      setSelected(onlySelectNoneOption);
    };

    if (selectedItem === resetIndex) {
      resetForm();
    } else {
      const selectedCurrent = isSingleSelect
        ? handleSingleSelect(selected, selectedItem)
        : getToggle(selected, selectedItem);

      const filteredSelectedItems = selectedCurrent.filter(removeResetOption);

      onChange(filteredSelectedItems);
      setSelected(filteredSelectedItems);
    }
  };

  if (isSingleSelect) {
    return (
      <SingleSelectContainer>
        {choices.map((props, index) => (
          <Radio
            {...props}
            key={index}
            index={index}
            onChange={props.value === RESET_SELECT_OPTION ? () => setSelected([]) : onChangeHandler}
            checked={Boolean(selected.includes(index))}
          />
        ))}
      </SingleSelectContainer>
    );
  }
  return (
    <MultiSelectContainer>
      {choices.map((props, index) => (
        <Checkbox
          {...props}
          key={index}
          index={index}
          onChange={props.displayText === 'None' ? () => onChangeHandler(index) : onChangeHandler}
          checked={Boolean(selected.includes(index))}
          type={type}
        />
      ))}
    </MultiSelectContainer>
  );
};

export default ChoiceBox;
