/* eslint-disable @typescript-eslint/naming-convention */
import { useState } from 'react';
import { Product } from '@src/type/Product';
import { getConvertedPrice, getPlanPeriod, getPlanId } from '@src/utils';
import { setLocalStorage } from '../utils/utilities';
import useScrollToElement from './useScrollToElement';
import { registrationFlowTracking } from '../utils/dataTracking';
import { StepLabel } from '../types/DataTracking';

type DiscountInfo =
  | { type: 'promotionCode'; data: { promotionCode: string } }
  | { type: 'affiliate'; data: { affilaite: string } };

const useHandleSelectPlan = (discountInfo: DiscountInfo) => {
  const [selectedPlan, setSelectedPlan] = useState<Product | null>(null);
  const { scrollRef, handleScroll } = useScrollToElement();

  const handleSelectPlan = (plan: Product) => {
    const { name, tier, currency, product_price_point } = plan;
    const { interval, interval_unit, price_in_cents } = product_price_point || {};

    registrationFlowTracking({
      stepLabel: StepLabel.CONFIRM_PLAN_NEXT_CTA,
      planName: name,
      planTier: tier?.internal_tier_id,
      planBillingFreq: getPlanPeriod(interval, interval_unit),
      currency,
      planPrice: getConvertedPrice(price_in_cents, currency).toString(),
      planId: getPlanId(discountInfo, plan),
    });

    setSelectedPlan(plan);
    setLocalStorage('selected-plan', JSON.stringify(plan));
    setLocalStorage('planId', plan?.product_handle);
    handleScroll();
  };

  return { selectedPlan, selectPlanScrollRef: scrollRef, handleSelectPlan };
};

export default useHandleSelectPlan;
