import { gql } from '@apollo/client';

const getPromoCodeInformation = gql`
  query ($promoCode: String!) {
    getPromoCodeInformation(promoCode: $promoCode) {
      type
      finalPriceInCentsTaxInclusive
      finalPriceInCentsTaxExclusive
      isRecurring
      productHandle
      discountPercentage
      discountAmount
    }
  }
`;

export default getPromoCodeInformation;
