import styled from 'styled-components';
import { breakpoint } from '@themes';

export const SpotlightRibbonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  gap: 24px;
  position: relative;
  z-index: 20;

  ${breakpoint('sm')`
    bottom: 80px;
  `}

  ${breakpoint('md')`
    bottom: 100px;
  `}

  ${breakpoint('lg')`
    bottom: 122px;
  `}

  ${breakpoint('xl')`
    bottom: 222px;
  `}

  ${breakpoint('xxl')`
    bottom: 172px;
  `}
`;

export const TabsWrapper = styled.div`
  width: 100%;
  margin-left: 16px;
  margin-right: 16px;

  ${breakpoint('sm')`
    margin-left: 32px;
    margin-right: 32px;
  `}

  ${breakpoint('lg')`
    margin-left: 80px;
    margin-right: 80px;
  `}
`;

export const CarouselWrapper = styled.div`
  width: 100%;
  min-height: 160px;
  display: flex;
  align-items: center;

  & .swiper--wrapper {
    height: 100%;
    padding-left: 16px;
    padding-right: 16px;

    ${breakpoint('sm')`
      padding-left: 32px;
      padding-right: 32px;
    `}

    ${breakpoint('lg')`
      padding-left: 80px;
      padding-right: 80px;
    `}
  }

  & .swiper-slide {
    height: auto;
  }

  & .videoCard {
    height: 100%;
  }
`;

export const SlicePlaceholderWrapper = styled.div`
  padding-top: 12px;
  margin-left: 16px;
  margin-right: 16px;

  ${breakpoint('sm')`
    margin-left: 32px;
    margin-right: 32px;
  `}

  ${breakpoint('lg')`
    margin-left: 80px;
    margin-right: 80px;
  `}
`;
