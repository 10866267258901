import React from 'react';
import { RichText } from '@components/atoms';

const LearnRichTextSlice = ({ content }) => (
  <div className="">
    <RichText text={content.primary.text} />
  </div>
);

export default LearnRichTextSlice;
