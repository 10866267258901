import styled from 'styled-components';
import { breakpoint } from '@themes';
import COLORS from '../../../themes/colors';

export const PlaylistGridWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: -20px;

  & .MuiGrid-root {
    z-index: 9;
  }

  & .videoCard {
    height: 100%;
  }

  ${breakpoint('sm')`
    padding-left: 32px;
    padding-right: 32px;
  `}

  ${breakpoint('lg')`
    padding-left: 80px;
    padding-right: 80px;
    margin-top: -42px;
  `}

  ${breakpoint('xl')`
    margin-top: -142px;
  `}

  ${breakpoint('xxl')`
    margin-top: -92px;
  `}
`;

export const Title = styled.h2`
  font-size: 24px;
  line-height: 32px;
  font-weight: 800;
  color: ${COLORS.lightGreyE5};
  z-index: 1;
`;
