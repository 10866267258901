/* eslint-disable @typescript-eslint/naming-convention */
import React, { useContext } from 'react';
import { ListItem } from '@lesmills-international/components';
import { navigate } from 'gatsby';
import { useTheme } from '@mui/styles';
import { SectionTitle } from './styles';
import { userContext } from '../../../../gatsby-theme-engagement/src/context';
import { TIERS } from '../../../../../src/constants/tiering';
import ROUTES from '../../../../../src/constants/routes';

interface Props {
  data: Pick<
    Queries.PrismicAccountOverviewPageData,
    | 'account_section_title'
    | 'account_details_title'
    | 'account_details_description'
    | 'personalize_your_workouts_title'
    | 'personalize_your_workouts_description'
  >;
}

const AccountSection = ({ data }: Props) => {
  const { themeColor } = useTheme();

  const { tier, hasActiveSubscription } = useContext(userContext);
  const isPremium = tier === TIERS.PREMIUM;

  const {
    account_section_title,
    account_details_title,
    account_details_description,
    personalize_your_workouts_title,
    personalize_your_workouts_description,
  } = data;

  const items = [
    {
      title: account_details_title,
      description: account_details_description,
      onClick: () => navigate(ROUTES().ACCOUNT_DETAILS),
    },
    ...(isPremium && hasActiveSubscription
      ? [
          {
            title: personalize_your_workouts_title,
            description: personalize_your_workouts_description,
            onClick: () => navigate('/update-fitness-profile'),
          },
        ]
      : []),
  ];

  return (
    <div>
      <SectionTitle>{account_section_title}</SectionTitle>
      <ListItem theme={themeColor} items={items} />
    </div>
  );
};

export default AccountSection;
