import { gql } from '@apollo/client';

const redeemPromoCode = gql`
  mutation redeemPromoCode($promoCode: String!) {
    redeemPromoCode(input: { promoCode: $promoCode }) {
      success
    }
  }
`;

export default redeemPromoCode;
