import { themes } from '@lesmills-international/components';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Title = styled.h2`
  color: ${({ theme }) => themes[theme.themeColor].colors.contentPrimary};
  font-family: Inter Regular;
  font-size: 16px;
  line-height: 24px;
`;

export const ErrorMessage = styled.p`
  margin-top: 10px;
  color: ${({ theme }) => themes[theme.themeColor].colors.contentNegative};
  font-family: Inter Regular;
  font-size: 14px;
  line-height: 20px;
`;
