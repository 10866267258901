/* eslint-disable @typescript-eslint/naming-convention */
import React, { useState } from 'react';

import AddressAutocomplete from './AddressAutocomplete';
import ManualAddressDialog from './ManualAddressDialog';

import { BillingAddressWrapper, AddManualAddress } from './style';

const BillingAddress = ({
  prismicData,
  countryCode,
  lang,
  updateFullAddress,
  error,
  defaultManualAddress,
  countriesData,
  statesData,
  currentUserAddress,
  disabled,
}) => {
  const [openManualAddressDialog, setOpenManualAddressDialog] = useState(false);
  const [manualAddressData, setManualAddressData] = useState(currentUserAddress || null);
  const { address_field_label, add_address_manually } = prismicData;

  const handleAddManualAddress = (values) => {
    setManualAddressData(values);
    updateFullAddress(values, true);
    setOpenManualAddressDialog(false);
  };

  return (
    <BillingAddressWrapper>
      <AddressAutocomplete
        label={address_field_label}
        countryCode={countryCode}
        lang={lang}
        updateFullAddress={updateFullAddress}
        error={error}
        manualAddressData={manualAddressData}
        prismicData={prismicData}
        disabled={disabled}
      />
      <AddManualAddress
        onClick={() => {
          if (disabled) {
            return;
          }
          setOpenManualAddressDialog(true);
        }}
      >
        {add_address_manually}
      </AddManualAddress>
      <ManualAddressDialog
        prismicData={prismicData}
        open={openManualAddressDialog}
        defaultValues={defaultManualAddress}
        countryCode={countryCode}
        handleClose={() => {
          setOpenManualAddressDialog(false);
        }}
        handleAddManualAddress={handleAddManualAddress}
        countriesData={countriesData}
        statesData={statesData}
      />
    </BillingAddressWrapper>
  );
};

export default BillingAddress;
