import React from 'react';

import Button from '../../common/button';

import {
  ButtonWrapper,
  DescriptionMessage,
  ErrorImageLogo,
  ErrorTypeTitle,
  Img,
  Wrapper,
} from './styles';

interface Props {
  image: string;
  title: string;
  description?: string;
  buttonText: string;
  onButtonClick: () => void;
}

const PartnerError = ({ image, title, description, buttonText, onButtonClick }: Props) => (
  <Wrapper id="partner-signup-error-Wrapper">
    <ErrorImageLogo>
      <Img src={image} alt="partner signup error image" />
    </ErrorImageLogo>

    <ErrorTypeTitle>{title}</ErrorTypeTitle>

    {description && <DescriptionMessage>{description}</DescriptionMessage>}

    <ButtonWrapper id="partner-signup-error-button-wrapper">
      <Button variant="contained" className="submit-button uppercase" onClick={onButtonClick}>
        <>{buttonText}</>
      </Button>
    </ButtonWrapper>
  </Wrapper>
);

export default PartnerError;
