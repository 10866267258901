import React from 'react';

const Video = ({ vimeoId }) => (
  <div className="relative" style={{ paddingTop: '56.25%' }}>
    <iframe
      title="Vimeo Video"
      className="absolute inset-0 w-full h-full"
      src={`https://player.vimeo.com/video/${vimeoId}?title=0&byline=0&portrait=0&api=1`}
      allow="autoplay; fullscreen; picture-in-picture; encrypted-media"
      allowFullScreen
    />
  </div>
);
export default Video;
