import styled from 'styled-components';
import BaseWrapper from '../textField/style';

const Wrapper = styled(BaseWrapper)`
  & .password-field.MuiTextField-root > .MuiFilledInput-root {
    padding-right: 0;
  }
  & .MuiFilledInput-root {
    border: none;
    box-shadow: none;
  }

  & .MuiFilledInput-root.Mui-focused .MuiInputBase-input {
    box-shadow: none;
  }

  & .MuiFilledInput-root .MuiInputBase-input {
    border: 1px solid ${(props) => props.theme.colors.lightGreyAE};
  }

  & .MuiFilledInput-root.Mui-error .MuiInputBase-input,
  & .MuiFilledInput-root.Mui-error.Mui-focused .MuiInputBase-input,
  & .MuiFilledInput-root.Mui-error .MuiInputBase-input[value]:not([value='']) {
    border: 1px solid ${(props) => props.theme.colors.lightGrey7B};
    border-bottom: 2px solid ${(props) => props.theme.colors.darkRed};
  }

  & .MuiFilledInput-root.Mui-focused .MuiInputBase-input,
  & .MuiInputBase-input[value]:not([value='']) {
    border: 1px solid ${(props) => props.theme.colors.lightGrey7B};
  }

  & .MuiInputLabel-root,
  & .MuiInputLabel-root.Mui-error {
    color: ${(props) => props.theme.colors.greyLetter};
  }
`;

export default Wrapper;
