import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Button } from '@lesmills-international/components';
import { ReactComponent as Logo } from '@assets/lmi-plus-logo.svg';
import { ReactComponent as BackIcon } from '@assets/icons/back.svg';
import { useCreateSurveyQuestionResponseMutation } from '@backend';
import { navigate } from 'gatsby';

import {
  SurveyContainer,
  SurveyLogoContainer,
  SurveyWrapper,
  QuestionTitleBar,
  BackBtn,
  ProgressDotsContainer,
  QuestionContainer,
  ErrorMessage,
  NextBtn,
  NextBtnDisabled,
  QuestionGradientOverlay,
} from './styles';
import { ChoiceBox } from '../../components/atoms/form';
import ProgressDots from '../../components/atoms/progressDots';

interface QuestionProps extends RouteComponentProps {
  /**
   * Active page index, associated with progress dots
   *
   */
  index: number;

  /**
   * Page path used to populate the "next" button.
   * Must be a link to a gatsby route.
   * @example
   * /explore/
   */
  nextLink: string;

  /**
   * Page path used to populate the "next" button.
   * Must be a link to a gatsby route.
   * @example
   * /survey/0/
   */
  previousLink: string | null;

  /**
   * Total number of questions
   */
  totalQuestions: number;

  /**
   * Generated from the gql query
   */
  question: {
    questionId: string;
    displayText: string;
    questionType: string;
    answers: [
      {
        displayText: string;
        attribute: {
          key: string;
          value: string;
        };
      }
    ];
  };
}

const Question = ({ index, question, nextLink, previousLink, totalQuestions }: QuestionProps) => {
  // Setup our state management for the question responses
  const [currentAnswers, setCurrentAnswers] = useState<number[]>([]);

  // Setup our state management for displaying the error message
  // If the user clicks the NEXT button before selecting an answer, show it
  const [showErrMsg, setShowErrMsg] = useState<boolean>(false);

  // Setup the mutation ready for execution
  const [createSurveyQuestionResponse] = useCreateSurveyQuestionResponseMutation();

  // Depending on if the user chooses any answer, use a different button style
  const hasSelection = !(currentAnswers.length === 0);
  const ButtonComponentToUse = hasSelection ? NextBtn : NextBtnDisabled;

  useEffect(() => {
    if (hasSelection && showErrMsg) {
      setShowErrMsg(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSelection]);

  const handleNextButton = () => {
    if (!hasSelection) {
      setShowErrMsg(true);
      return;
    }
    const selectedAnswers = currentAnswers
      .map((i: number) => answerOptions[i])
      .map(({ key, value }) => ({ key, value }));
    const { questionId } = question;

    const input = {
      selectedAnswers,
      questionId,
    };
    // // Execute our mutation
    createSurveyQuestionResponse({ variables: { input } });
    // Redirect to the next page
    navigate(nextLink);
  };

  const answerOptions = question?.answers?.map((answer) => {
    const {
      displayText,
      icon,
      attribute: { key, value },
    } = answer;
    return {
      displayText,
      key,
      value,
      icon,
    };
  });

  return (
    <SurveyWrapper height={`${window.innerHeight}px`}>
      {previousLink && (
        <BackBtn onClick={() => navigate(previousLink)} id="back-button">
          <BackIcon />
        </BackBtn>
      )}
      <SurveyContainer>
        <SurveyLogoContainer>
          <Logo fill="#FFF" height={20} width="100%" />
        </SurveyLogoContainer>
        <ProgressDotsContainer>
          <ProgressDots count={totalQuestions} active={index} />
        </ProgressDotsContainer>
        <QuestionTitleBar> {question?.displayText} </QuestionTitleBar>
        <QuestionContainer>
          <ChoiceBox
            type={question?.questionType.toLowerCase()}
            choices={answerOptions}
            onChange={setCurrentAnswers}
          />
        </QuestionContainer>
        {showErrMsg && <ErrorMessage>Please select answer</ErrorMessage>}
        <ButtonComponentToUse>
          <Button label="Next" ctaButton inverted onClick={() => handleNextButton()} />
        </ButtonComponentToUse>
        <QuestionGradientOverlay />
      </SurveyContainer>
    </SurveyWrapper>
  );
};

export default Question;
