import styled from 'styled-components';
// eslint-disable-next-line import/no-unresolved
import { breakpoint } from '@themes';

export const HeroSliceWrapper = styled.div`
  // width: 100%;
`;

export const CarouselWrapper = styled.div`
  .pagination--wrapper {
    ${breakpoint('md')`
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      z-index: 200;
      padding-bottom: max(24px, 5%);
      align-items: center;
      margin-left: 0px;
    `}
  }
`;
