import { format } from 'date-fns';
import { Subscription } from '../../../gatsby-theme-engagement/src/backend';
import {
  GENERIC_FAILED_STATE,
  ON_HOLD_STATES,
  SUBSCRIPTION_STATES,
  SUBSCRIPTION_TYPES,
} from '../constants/subscription';

export interface SignupChannel {
  name: string;
}

export type SubscriptionType = 'RC' | 'RT';

export const isIAP = (signupChannelList: SignupChannel[], userSignupChannel?: string | null) =>
  !!(
    userSignupChannel &&
    signupChannelList.some(({ name }) => name === userSignupChannel.toLowerCase())
  );

export const isAffiliate = (tuneAffiliateId?: string | null) => !!tuneAffiliateId;

export const isReseller = (type?: SubscriptionType | null) => type === 'RC';

export const isRetail = (type?: SubscriptionType | null, userSignupChannel?: string | null) =>
  type === 'RT' || (!type && !userSignupChannel);

export const isRecurring = (canceledAtEndOfPeriod?: boolean | null) => !!canceledAtEndOfPeriod;

export const getSubscriptionType = ({
  subscription,
  type,
  userSignupChannel,
  signupChannelList,
}: {
  subscription: Subscription;
  type?: SubscriptionType | null;
  userSignupChannel?: string | null;
  signupChannelList: SignupChannel[];
}): keyof typeof SUBSCRIPTION_TYPES => {
  const mapping: Record<keyof typeof SUBSCRIPTION_TYPES, boolean> = {
    [SUBSCRIPTION_TYPES.AFFILIATE]: isAffiliate(subscription?.tune_offer_id),
    [SUBSCRIPTION_TYPES.RETAIL]: isRetail(type, userSignupChannel),
    [SUBSCRIPTION_TYPES.RESELLER]: isReseller(type),
    [SUBSCRIPTION_TYPES.IAP]: isIAP(signupChannelList, userSignupChannel),
  };

  const subscriptionType = Object.keys(mapping).find(
    (key) => mapping[key as keyof typeof SUBSCRIPTION_TYPES]
  );

  return (subscriptionType || SUBSCRIPTION_TYPES.RETAIL) as keyof typeof SUBSCRIPTION_TYPES;
};

export const formatDate = (date: string | Date, pattern?: string) => {
  const parsedDate = new Date(date);

  return format(parsedDate, pattern ?? 'MMM d, yyyy');
};

export const isSubscriptionCanceled = (state: string) =>
  isFullyCanceled(state) || isPendingCancellation(state);

export const isFullyCanceled = (state: string) => state === SUBSCRIPTION_STATES.CANCELED;

export const isPendingCancellation = (state: string) =>
  state === SUBSCRIPTION_STATES.ON_DELAYED_CANCELLATION;

export const isSubscriptionFailed = (state: string) => GENERIC_FAILED_STATE.includes(state);

export const isSubscriptionOnHold = (state: string) => ON_HOLD_STATES.includes(state);
