import styled from 'styled-components';
import { ReactComponent as CheckIconLM } from '@assets/icons/check.svg';
import { ReactComponent as ExclamationIconLM } from '@assets/icons/exclamation-circled.svg';

export const UpgradeTierWrapper = styled.div`
  width: 350px;
  margin-left: auto;
  margin-right: auto;
`;

export const UpgradeTierContent = styled.div`
  display: flex;
  align-items: center;

  color: #dddddd;
  font-size: 13px;
  margin-bottom: 24px;
  position: relative;
  padding: 12px;

  background: #222222;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
`;

export const GoPremiumButton = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 20px;
  gap: 8px;
  font-size: 14px;
  font-weight: 900;

  width: 350px;
  height: 49px;

  background: #f7e7c8;
  border-radius: 100px;
  margin-top: 36px;

  path {
    fill: black;
  }
  color: black;
`;

export const UpgradeTitle = styled.div`
  font-size: 16px;
  line-height: 22px;
  font-weight: 900;
  margin-bottom: 8px;
`;

export const BenefitsItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 0px 0px;
  gap: 4px;
`;

export const BenefitRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`;

export const BenefitText = styled.div`
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
`;

export const BenefitsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-style: normal;
  color: #dddddd;
`;

export const CheckIcon = styled(CheckIconLM)`
  width: 15px;
  height: 15px;
  margin-right: 11px;
`;

export const ExclamationIcon = styled(ExclamationIconLM)`
  margin-right: 6px;
`;
