import MUIRadioGroup from '@mui/material/RadioGroup';
import { styled } from '@mui/material/styles';

const RadioGroupWrapper = styled(MUIRadioGroup)(({ theme }) => ({
  '&.MuiFormGroup-root': {
    margin: '10px 0',
  },

  '& .MuiRadio-root': {
    width: '17px',
    height: '17px',
    marginRight: '5px',
  },

  '& .MuiRadio-root.Mui-checked': {
    color: theme.colors.darkGrey,
  },

  '& .MuiFormControlLabel-label': {
    fontSize: '14px',
    lineHeight: '16px',
  },

  '&.MuiFormGroup-row > .MuiFormControlLabel-root': {
    marginLeft: 0,
    marginRight: '33px',

    [theme.breakpoints.up('lg')]: {
      marginRight: '20px',
    },
  },
}));

export default RadioGroupWrapper;
