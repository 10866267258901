import { gql } from '@apollo/client';

const getPlaceDetails = gql`
  query getPlaceDetails($placeId: String!, $language: String) {
    getPlaceDetails(placeId: $placeId, language: $language) {
      address
      postalCode
      city
      state
      country
      stateCode
      countryCode
    }
  }
`;

export default getPlaceDetails;
