import React from 'react';
import { PageProps, graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { withPrivateRoute } from '../../../gatsby-theme-engagement/src/components/molecules';
import BillingHistory from '../components/billingHistory';

const BillingHistoryTemplate = ({ data }: PageProps<Queries.GetBillingHistoryQuery>) => (
  <BillingHistory
    pageData={data?.prismicBillingHistoryPage?.data as Queries.PrismicBillingHistoryPageData}
  />
);

export default withPrismicPreview(withPrivateRoute(BillingHistoryTemplate, true));

export const query = graphql`
  query GetBillingHistory($lang: String!) {
    prismicBillingHistoryPage(lang: { eq: $lang }) {
      _previewable
      lang
      data {
        title
        date_label
        subscription_label
        service_period_label
        total_label
        view_invoice
        note {
          html
          richText
        }
        error_on_list_invoice
        no_invoice_message
      }
    }
  }
`;
