import React, { useMemo } from 'react';
import { TableRow, TableBody as MUITableBody } from '@mui/material';
import { Order, TableData } from './types';
import { getDateComparator, sortByDate } from './utils';
import { TableCell } from './styles';

interface Props {
  rows: TableData[];
  page: number;
  rowsPerPage: number;
  order: Order;
  invoiceLinkLabel: string;
}

const TableBody = ({ rows, page, rowsPerPage, order, invoiceLinkLabel }: Props) => {
  const sortedRow = useMemo(
    () =>
      sortByDate(rows, getDateComparator(order))?.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, page, rowsPerPage, rows]
  );

  return (
    <MUITableBody>
      {sortedRow?.map((row, index) => (
        <TableRow key={`tabel__row__${index}`}>
          <TableCell align="left">{row.date}</TableCell>
          <TableCell align="left">{row.subscription}</TableCell>
          <TableCell align="left">{row.servicePeriod}</TableCell>
          <TableCell align="left">{row.total}</TableCell>
          <TableCell align="right">
            <a target="_blank" rel="noreferrer" href={row.invoice!}>
              {invoiceLinkLabel}
            </a>
          </TableCell>
        </TableRow>
      ))}
    </MUITableBody>
  );
};

export default TableBody;
