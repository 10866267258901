export const TIERS = {
  BASE: 'base',
  PREMIUM: 'premium',
};

export const TIER_DEFAULT = 'premium';

export const UPGRADE_TEXT = {
  TITLE: 'Get full access with LES MILLS+ Premium',
  TITLE_SUB: 'The majority of exercisers use Premium.',
  BANNER_TEXT: 'This workout is exclusive to LES MILLS+ Premium plan.',
  CTA: 'GO PREMIUM',
  BENEFITS_LIST: [
    'Entire library of 15+ training styles',
    'Bonus Strength and Cycle workouts',
    'Flagship BODYPUMP™ workouts',
    'Workout plans from elite trainers',
    'Download workouts',
  ],
};

export const PROGRAM_TEASER_TITLE = 'Go Premium to gain access';
