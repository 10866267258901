import React from 'react';

import { ReactComponent as CheckLogo } from '@assets/icons/check.svg';

interface ProgressBlockProps {
  first: boolean;
  last: boolean;
  checked: boolean;
  current: boolean;
}

interface ProgressBarProps {
  weeks: number;
  currentWeek: number;
}

const ProgressBlock = ({ first, last, checked, current }: ProgressBlockProps) => (
  <div
    className={`flex items-center w-12 md:w-14 h-6 bg-green-progress ${
      !checked ? 'bg-opacity-30 justify-start' : 'justify-center'
    } ${first ? 'rounded-l-full' : ''} ${last ? 'rounded-r-full' : ''} `}
  >
    {checked ? (
      <CheckLogo />
    ) : (
      <div className={`${current ? '' : 'hidden'} w-7 h-full bg-green-progress`} />
    )}
  </div>
);

const ProgressBar = ({ weeks, currentWeek }: ProgressBarProps) => (
  <div className="flex" style={{ gap: '1px' }}>
    {Array.from({ length: weeks }, (_, i) => i + 1).map((item) => (
      <ProgressBlock
        first={item === 1}
        last={item === weeks}
        checked={currentWeek > item}
        current={currentWeek === item}
      />
    ))}
  </div>
);

export default ProgressBar;
