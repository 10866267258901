import VoucherStatus from '../constants/voucherVerificationStatus';
import { isBrowser } from './utilities';

interface VerificationMessage {
  success: string;
  invalid: string;
  redeemed: string;
  expired: string;
  required: string;
  notRequired: string;
  countryCodeMissing: string;
}

interface VoucherVerification {
  [key: string]: {
    message: string | undefined;
    type: string;
  };
}

const {
  OFFER_ID_NOT_FOUND,
  UNKNOWN_VOUCHER_STATUS,
  VOUCHER_NOT_FOUND,
  VOUCHER_PROCESSING,
  VOUCHER_REDEEMED,
  VOUCHER_EXPIRED,
  VOUCHER_NOT_REQUIRED,
  VOUCHER_OFFER_ID_NOT_MATCH,
  COUNTRY_CODE_MISSING,
} = VoucherStatus;

export const getVoucherVerification = (status: string, messageText: VerificationMessage) => {
  const ADIDAS_VOUCHER_VERIFICATION: VoucherVerification = {
    successVoucherAffiliate: {
      message: messageText?.success,
      type: 'success',
    },
    offerIdNotFound: {
      message: messageText?.invalid,
      type: 'error',
    },
    unknownVoucherStatus: {
      message: messageText?.invalid,
      type: 'error',
    },
    voucherNotFound: {
      message: messageText?.invalid,
      type: 'error',
    },
    voucherProcessing: {
      message: messageText?.redeemed,
      type: 'error',
    },
    voucherRedeemed: {
      message: messageText?.redeemed,
      type: 'error',
    },
    voucherExpired: {
      message: messageText?.expired,
      type: 'error',
    },
    voucherMissing: {
      message: messageText?.required,
      type: 'error',
    },
    voucherNotRequired: {
      message: messageText?.notRequired,
      type: 'info',
    },
    voucherOfferIdNotMatch: {
      message: messageText?.invalid,
      type: 'error',
    },
    countryCodeMissing: {
      message: messageText?.countryCodeMissing,
      type: 'error',
    },
  };

  return ADIDAS_VOUCHER_VERIFICATION[status];
};

export const getLinkUrl = (
  status: string,
  linkGroup: { homepage: string | null; landingPage: string | null; support: string | null }
) => {
  let linkObj = {
    linkText: 'here',
    linkUrl: '',
  };
  const defaultUrl = 'https://try.lmplus-uat.com';
  const urlParams = isBrowser && new URLSearchParams(window.location.search);
  const offerId = urlParams && urlParams.get('offer_id');
  const affiliateId = urlParams && urlParams.get('aff_id');
  switch (status) {
    case OFFER_ID_NOT_FOUND:
    case UNKNOWN_VOUCHER_STATUS:
    case VOUCHER_NOT_FOUND:
    case VOUCHER_OFFER_ID_NOT_MATCH:
    case VOUCHER_EXPIRED:
      linkObj = {
        linkText: 'here',
        linkUrl: linkGroup?.landingPage || defaultUrl,
      };
      break;
    case VOUCHER_NOT_REQUIRED:
      linkObj = {
        linkText: 'here',
        linkUrl:
          !linkGroup?.landingPage || !offerId || !affiliateId
            ? defaultUrl
            : `${linkGroup?.landingPage}/offers/?offer_id=${offerId}&aff_id=${affiliateId}`,
      };
      break;
    case VOUCHER_PROCESSING:
    case VOUCHER_REDEEMED:
      linkObj = {
        linkText: 'here',
        linkUrl: linkGroup?.homepage || defaultUrl,
      };
      break;
    case COUNTRY_CODE_MISSING:
      linkObj = {
        linkText: 'support',
        linkUrl: linkGroup?.support || defaultUrl,
      };
      break;
    default:
      break;
  }
  return linkObj;
};
