import styled from 'styled-components';
import { breakpoint } from '@themes';
import COLORS from '@themes/colors';

export const CardGridWrapper = styled.div`
  width: 100%;
  padding-top: 12px;
  background-color: ${(props) => (props.isDarkBackground ? COLORS.black : 'transparent')};
  & .videoCard {
    height: 100%;
  }
  padding-left: ${(props) => props.padding};
  padding-right: ${(props) => props.padding};
  padding-bottom: ${(props) => props.wrapperPaddingBottom};
  ${breakpoint('sm')`
    padding-left: ${(props) => props.padding};
    padding-right: ${(props) => props.padding};
  `}
  ${breakpoint('md')`
    padding-left: ${(props) => props.padding};
    padding-right: ${(props) => props.padding};
  `}
  ${breakpoint('lg')`
    padding-left: ${(props) => props.padding};
    padding-right: ${(props) => props.padding};
  `}
  ${breakpoint('xl')`
    padding-left: ${(props) => props.padding};
    padding-right: ${(props) => props.padding};
  `}
  z-index:1;
`;

export const WorkoutResultsAmount = styled.p`
  font-size: 16px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.lightGreyAE};
  z-index: 10;
  margin-bottom: 24px;
`;
