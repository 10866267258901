import { gql } from '@apollo/client';

const getPromotionProducts = gql`
  query getPromotionProducts($promotionCode: String!, $languageCode: String) {
    getHeaders {
      countryCode
    }
    promotionPricingPlans(promotionCode: $promotionCode, languageCode: $languageCode) {
      trialDays
      sortOrder
      price
      interval
      description
      currency
      name
      intervalUnit
      entity
      offerHandle
      productHandle
      trialPrice
      isRecurring
      finalPrice
      pricePointId
      tier {
        external_entity_id
        internal_tier_id
        tiered_entity_enabled
        tiered_entity_type
        tier_id
      }
    }
  }
`;

export default getPromotionProducts;
