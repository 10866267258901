import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

const PageTitle = styled.h2`
  text-align: center;
  font-size: 40px;
  line-height: 40px;
  margin-bottom: 8px;
  font-family: 'Inter SemiBold';
  color: ${(props) => props.theme.colors.darkGrey};
`;

interface Props {
  children: ReactNode;
  variant?: string;
}

const Title: FC<Props> = ({ children, variant, ...rest }) => (
  <PageTitle as={variant} {...rest}>
    {children}
  </PageTitle>
);

export default Title;
