import styled from 'styled-components';
import { Link } from '@components/atoms';
import { breakpoint, COLORS } from '@themes';

export const StyledParagraph = styled.p`
  font-family: 'Inter Light', sans-serif;
  font-size: 14px;
  line-height: 1.6;
  margin-bottom: 1rem;
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;

  ${breakpoint('md')`
    font-size: 15px;
  `}

  ${breakpoint('lg')`
    font-size: 16px;
    margin-bottom: 1.5rem;
  `}
`;
export const StyledHeading2 = styled.h2`
  font-family: 'Inter SemiBold', sans-serif;
  text-transform: uppercase;
  margin-bottom: 1rem;
  line-height: 1.2;
`;

export const StyledHeading3 = styled.h3`
  font-family: 'Inter SemiBold', sans-serif;
  text-transform: uppercase;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  line-height: 1.2;
`;

export const StyledLink = styled(Link)`
  color: ${COLORS.green};
  text-decoration: none;
  font-weight: bold;
  &:hover {
    text-decoration: underline;
  }
`;

export const StyledList = styled.ul`
  list-style-type: disc;
  padding-left: 1.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-family: 'Inter Light', sans-serif;
  font-size: 14px;
  line-height: 1.6;
  margin-left: 2rem;

  ${breakpoint('md')`
    font-size: 15px;
  `}

  ${breakpoint('lg')`
    font-size: 16px;
    margin-bottom: 1.5rem;
  `}
`;

export const StyledOrderedList = styled.ol`
  list-style-type: decimal;
  margin-left: 2rem;
  padding-left: 1.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-family: 'Inter Regular'
  font-size: 14px;
  line-height: 1.6;

  ${breakpoint('md')`
    font-size: 15px;
  `}

  ${breakpoint('lg')`
    font-size: 16px;
    margin-bottom: 1.5rem;
  `}
`;

export const StyledStrong = styled.strong`
  font-family: 'Inter SemiBold'
  font-weight: bold;
`;

export const StyledEm = styled.em`
  font-style: bold;
  font-family: 'Inter SemiBold';
`;
