import { floor } from 'lodash';

export const formatDuration = (durationInSeconds: number) => {
  if (durationInSeconds === undefined) return '';
  const minutes = floor(durationInSeconds / 60);
  const seconds = durationInSeconds - minutes * 60;

  return seconds < 10 ? `${minutes}:0${seconds}` : `${minutes}:${seconds}`;
};

export const formatTimeNumber = (time: number) => (time < 10 ? `0${time}` : time);

export const formatTimeDuration = (min: number | null, sec: number | null) => {
  if (!min || !sec) return '';
  return `${formatTimeNumber(min)}:${formatTimeNumber(sec)}`;
};
