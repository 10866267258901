import { gql } from '@apollo/client';

// Query a collection by a collection id
const GET_COLLECTION = gql`
  query GetCollection($id: Int!, $page: Int, $perPage: Int) {
    collection(id: $id, page: $page, perPage: $perPage) {
      backgroundImg
      description
      id
      items {
        ... on CollectionLinkItem {
          collectionId
          name
          slug
          type
          vimeoThumbnail
          description
        }
        ... on ExtrasCollectionItem {
          downloadUrl
          name
          slug
          vimeoThumbnail
          description
        }
        ... on WorkoutCollectionItem {
          duration
          name
          slug
          videoId
          vimeoThumbnail
          description
          workout {
            workoutName
            categories
            equipment
            imageWide
            tiers
            durationInSeconds
          }
        }
      }
      itemsCount
      itemsTotal
      name
      shortDescription
      slug
      type
    }
  }
`;

export default GET_COLLECTION;
