import React, { FC, memo } from 'react';
import { Link } from 'gatsby';

import { PaymentInfo } from '../../../types/Payment';
import PaymentIcon from '../../common/payment/PaymentIcon';

import { Wrapper, Title, PaymentWrapper, CcNumberWrapper } from './style';
import mapPaymentName, { formatPaymentDesc } from '../../../utils/normalizePaymentInfo';
import ROUTES from '../../../../../../src/constants/routes';

interface Props {
  paymentInfo: PaymentInfo;
  prismicData: Pick<
    Queries.PrismicChangeSubscriptionPageData,
    'current_payment_details_title' | 'current_payment_details_description' | 'payment_edit_text'
  >;
  selectedProductHandle: string;
  urlParam: string;
  isBorderTop: boolean;
}

const PaymentMethod: FC<Props> = ({
  paymentInfo,
  prismicData,
  selectedProductHandle,
  urlParam,
  isBorderTop,
}) => {
  const paymentType = mapPaymentName(paymentInfo);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { current_payment_details_title, current_payment_details_description, payment_edit_text } =
    prismicData;

  const isPaypal = paymentType === 'paypal';
  const description =
    !isPaypal && formatPaymentDesc(paymentInfo?.last4, current_payment_details_description);

  return (
    <Wrapper isBorderTop={isBorderTop}>
      <Title>{current_payment_details_title}</Title>
      <PaymentWrapper>
        <CcNumberWrapper>
          <PaymentIcon iconType={paymentType} />
          <span>{description}</span>
        </CcNumberWrapper>
        <Link
          to={ROUTES().UPDATE_PAYMENT_DETAILS}
          state={{
            from: 'change-subscription',
            preSelectedProduct: selectedProductHandle,
            urlSearchParam: urlParam,
          }}
          className="edit-link"
        >
          {payment_edit_text}
        </Link>
      </PaymentWrapper>
    </Wrapper>
  );
};

export default memo(PaymentMethod);
