/* eslint-disable no-case-declarations */
import React, { FC, useState, useContext } from 'react';
import { Grid } from '@mui/material';
import { Tabs, Tab, VideoCard, AlbumCard } from '@lesmills-international/components';
import { SlicePlaceholder } from '@components/atoms';
import { useCheckFavoriteProgram, useGridSize } from '@hooks';
import { UserContext, userContext } from '@context';
import { getWorkoutForVideoCard } from '@services';
import { useWorkoutByNameQuery } from '@backend';
import {
  FavoriteContentWrapper,
  TabsWrapper,
  SlicePlaceholderWrapper,
  FavoriteContentGridWrapper,
  EmptyContentText,
  ResultsAmount,
} from './style';

interface Props {
  content: Queries.PrismicMyWorkoutsDataBodyFavoriteContent;
}

interface TabProps {
  favorite_content_type: string | null;
  favorite_content_type_label: string | null;
  empty_status_text: string | null;
}

interface SimpleProgram {
  alternateName: string;
  categories: string[];
  imageWide: string;
  name: string;
  tiers: string[];
  __typename: string;
}

interface FavoriteProgramItemProps {
  content: SimpleProgram;
  contentId: string;
  contentType: string;
  __typename: string;
}

interface FavoriteWorkoutListProps {
  data: WorkoutItemProps[];
  emptyText: string;
  userInfo: UserContext;
}

interface FavoriteProgramListProps {
  data: FavoriteProgramItemProps[];
  emptyText: string;
  loading: boolean;
}

interface WorkoutItemProps {
  program: string;
  workoutName: string;
  __typename: string;
}

const WorkOutComponent: FC<{ videoId: string; userInfo: UserContext }> = ({
  videoId,
  userInfo,
}) => {
  const { data: workoutData, loading } = useWorkoutByNameQuery({
    variables: {
      product: 'LMOD',
      workoutName: videoId,
    },
  });

  if (loading) return null;

  if (workoutData?.workoutByName?.items?.length === 0) return null;

  const item = workoutData?.workoutByName?.items?.[0];

  return (
    <VideoCard
      {...getWorkoutForVideoCard({
        item,
        userInfo,
      })}
    />
  );
};

const FavoriteWorkoutList: FC<FavoriteWorkoutListProps> = ({ data, emptyText, userInfo }) => {
  const { gridSize } = useGridSize();

  if (!data || !data.length) {
    return <EmptyContentText>{emptyText}</EmptyContentText>;
  }

  return (
    <>
      {data &&
        data.map((workoutItem: WorkoutItemProps, index: number) => (
          <Grid item xs={gridSize} key={index} role="listitem">
            <WorkOutComponent videoId={workoutItem?.workoutName} userInfo={userInfo} />
          </Grid>
        ))}
    </>
  );
};

const FavoriteProgramList: FC<FavoriteProgramListProps> = ({ data, emptyText, loading }) => {
  const { gridSize } = useGridSize();

  if (loading) {
    return (
      <SlicePlaceholderWrapper>
        <SlicePlaceholder height="160px" />
      </SlicePlaceholderWrapper>
    );
  }

  if (!data || !data?.length) {
    return <EmptyContentText>{emptyText}</EmptyContentText>;
  }

  return (
    <>
      {data &&
        data.map((program: FavoriteProgramItemProps, index: number) => {
          const { content } = program || {};
          return (
            <Grid item xs={gridSize} key={index} role="listitem">
              <AlbumCard
                key={index}
                backgroundImg={content?.imageWide}
                title={content?.name}
                description={content?.alternateName}
                link={`/program/${content?.name
                  ?.replaceAll("'", '-')
                  .replaceAll(' ', '-')
                  .toLowerCase()}`}
              />
            </Grid>
          );
        })}
    </>
  );
};

const ResultCountComponent: FC<{
  type: string;
  count: number;
}> = ({ type, count }) => {
  if (!count) {
    return null;
  }
  return <ResultsAmount>{`${count} ${type}${count === 1 ? '' : 's'}`}</ResultsAmount>;
};

const FavoriteWorkoutGrid: FC<{ emptyText: string; userInfo: UserContext }> = ({
  emptyText,
  userInfo,
}) => {
  const workoutFavorites = userInfo?.workoutFavorites;

  return (
    <>
      <ResultCountComponent type="workout" count={workoutFavorites?.length} />
      <Grid container spacing={2} role="list">
        <FavoriteWorkoutList
          data={workoutFavorites as WorkoutItemProps[]}
          emptyText={emptyText}
          userInfo={userInfo}
        />
      </Grid>
    </>
  );
};

const FavoriteProgramGrid: FC<{ emptyText: string }> = ({ emptyText }) => {
  const { favoritePrograms, loading } = useCheckFavoriteProgram(undefined);

  return (
    <>
      <ResultCountComponent type="program" count={favoritePrograms?.length} />
      <Grid container spacing={2} role="list">
        <FavoriteProgramList
          data={favoritePrograms as unknown as FavoriteProgramItemProps[]}
          emptyText={emptyText}
          loading={loading}
        />
      </Grid>
    </>
  );
};

const FavoriteContent: FC<Props> = ({ content }) => {
  const firstTabValue = content?.items[0]?.favorite_content_type;
  const [value, setValue] = useState<string>(firstTabValue || '');
  const userInfo = useContext(userContext);

  const handleOnChangeTab = (_: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const workoutEmptyText = content?.items?.filter(
    (item) => item.favorite_content_type === 'workout'
  )[0].empty_status_text;
  const programEmptyText = content?.items?.filter(
    (item) => item.favorite_content_type === 'program'
  )[0].empty_status_text;

  return (
    <FavoriteContentWrapper>
      <TabsWrapper>
        <Tabs value={value} onChange={handleOnChangeTab}>
          {content?.items?.map((tab: TabProps, index: number) => {
            if (!tab || !tab?.favorite_content_type) {
              return null;
            }
            return (
              <Tab
                value={tab?.favorite_content_type}
                label={tab?.favorite_content_type_label}
                key={index}
              />
            );
          })}
        </Tabs>
      </TabsWrapper>
      <FavoriteContentGridWrapper>
        {value === 'workout' ? (
          <FavoriteWorkoutGrid emptyText={workoutEmptyText || ''} userInfo={userInfo} />
        ) : (
          <FavoriteProgramGrid emptyText={programEmptyText || ''} />
        )}
      </FavoriteContentGridWrapper>
    </FavoriteContentWrapper>
  );
};

export default FavoriteContent;
