import React, { FC, ButtonHTMLAttributes } from 'react';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon: any;
  title: string;
}

const HeaderButton: FC<Props> = ({ icon, title, ...props }) => (
  <button
    title={title}
    type="button"
    className="w-7 h-7 flex justify-center items-center hover:bg-background-medium bg-background-mlight text-grey-review hover:text-netural-100 rounded-full"
    {...props}
  >
    {icon}
  </button>
);

export default HeaderButton;
