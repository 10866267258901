/**
 * the normal program detail page url is combined with https://watch.lesmillsondemand.com/ and lowercase program name
 * but for some weird urls, we need to hardcode here
 * refs: https://lesmillsinternational.atlassian.net/browse/LME-735
 */
const getVimeoProgramPageUrl = (programName: string, vimeoTicket: string) => {
  const WEIRD_URLS = {
    'LES MILLS GRIT': `${process.env.GATSBY_WATCH_LES_MILLS_URL}/les-mills-grit-1`,
    'LES MILLS RUN': `${process.env.GATSBY_WATCH_LES_MILLS_URL}/les-mills-run-1`,
    'LES MILLS WELLNESS': `${process.env.GATSBY_WATCH_LES_MILLS_URL}/les-mills-wellness-1`,
    'LOW IMPACT': `${process.env.GATSBY_WATCH_LES_MILLS_URL}/low-impact-1`,
  };
  if (programName in WEIRD_URLS) {
    return `${WEIRD_URLS[programName]}?ticket=${vimeoTicket}`;
  }
  // normal pattern: lowercase, replace punctuation or spaces with hypens
  const suffix = programName.replaceAll("'", '-').replaceAll(' ', '-').toLowerCase();
  return `${process.env.GATSBY_WATCH_LES_MILLS_URL}/${suffix}?ticket=${vimeoTicket}`;
};

export default getVimeoProgramPageUrl;
