import { useEffect } from 'react';
import { waitForElementToLoad } from '../utils/utilities';

const useDisplayRecaptchaBadge = () => {
  const recaptchaV3Selector = '.grecaptcha-badge:not(#recaptchaV2 .grecaptcha-badge)';

  const handleRecaptchaVisibility = async (visible: boolean) => {
    await waitForElementToLoad(recaptchaV3Selector, 3000);
    const recaptcha = document.querySelector<HTMLElement>(recaptchaV3Selector);

    if (recaptcha) {
      recaptcha.style.visibility = visible ? 'visible' : 'hidden';
    }
  };

  useEffect(() => {
    handleRecaptchaVisibility(true);

    return () => {
      handleRecaptchaVisibility(false);
    };
  }, []);
};

export default useDisplayRecaptchaBadge;
