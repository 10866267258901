import React, { useState, useEffect } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import Paper from '@mui/material/Paper';

import COLORS from '../../../themes/colors';
import { createClient } from '../../../services/client';
import getPlaceAutocomplete from '../../../graphql/getPlaceAutocomplete';
import { addErrorInDatadogRum } from '../../../utils/utilities';

import TextField from '../textField';

import { OptionLi } from './style';

const AddressAutocomplete = ({
  label,
  countryCode,
  lang,
  updateFullAddress,
  error,
  manualAddressData,
  prismicData,
}) => {
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { address_default_text, address_no_results_text } = prismicData;

  useEffect(() => {
    const fullAddressArray = [];
    // eslint-disable-next-line array-callback-return
    if (manualAddressData) {
      Object.keys(manualAddressData).forEach((key) => {
        if (
          key === 'address' ||
          key === 'address2' ||
          key === 'city' ||
          key === 'state' ||
          key === 'country'
        ) {
          fullAddressArray.push(manualAddressData[key]);
        }
      });
    }

    if (fullAddressArray.length) {
      setValue(fullAddressArray.filter(Boolean).join(', '));
    }
  }, [manualAddressData]);

  useEffect(() => {
    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return;
    }

    createClient
      .query({
        query: getPlaceAutocomplete,
        variables: {
          input: inputValue,
          components: `country:${countryCode}`,
          language: lang,
        },
      })
      .then((res) => {
        if (res) {
          setOptions(res?.data?.getPlaceAutocomplete || []);
        }
      })
      .catch((err) => {
        addErrorInDatadogRum(err);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  return (
    <Autocomplete
      id="autocomplete-component"
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      isOptionEqualToValue={(option, selectedValue) => option.place_id === selectedValue.place_id}
      value={value}
      noOptionsText={
        <span style={{ fontSize: '14px' }}>
          {inputValue ? address_no_results_text : address_default_text}
        </span>
      }
      // eslint-disable-next-line react/no-unstable-nested-components
      PaperComponent={({ children }) => (
        <Paper style={{ border: `1px solid ${COLORS.borderGrey}`, borderRadius: 0 }}>
          {children}
        </Paper>
      )}
      onChange={(_, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        updateFullAddress(newValue);
      }}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          className="address-autocomplete-field"
          label={label}
          backgroundColor={COLORS.white}
          variant="filled"
          fullWidth
          error={Boolean(error?.length)}
          helperText={error}
        />
      )}
      renderOption={(props, option) => (
        <OptionLi {...props}>
          <span key={option.place_id}>{option.description}</span>
        </OptionLi>
      )}
    />
  );
};

export default AddressAutocomplete;
