import React from 'react';
import styled from 'styled-components';
import { breakpoint } from '../../../themes/breakpoints';

const Wrapper = styled.div`
  margin-bottom: 23px;
  ${breakpoint('md')`
    margin-bottom: 35px;
`}
`;

const Circle = styled.div`
  width: 41px;
  height: 41px;
  border-radius: 50%;
  background-color: #eeeeee;
  font-family: 'Inter SemiBold';
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  font-size: 22px;
  line-height: 28px;
`;

const StepTitle = styled.div`
  font-family: 'Inter SemiBold';
  font-size: 16px;
  line-height: 26px;
  width: 100%;
`;

const StepContent = styled.div`
  font-size: 16px;
  line-height: 26px;
  width: 100%;
  font-family: 'Inter Medium';
`;

const StepNumberBadge = ({ index }) => <Circle id="stepBadge">{index}</Circle>;

const Step = ({ index, content, title }) => (
  <Wrapper id="stepWrapper">
    <StepNumberBadge index={index + 1} />
    <StepTitle id="stepTitle">{title}</StepTitle>
    <StepContent id="stepContent ">{content}</StepContent>
  </Wrapper>
);

export default Step;
