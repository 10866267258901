// Map the prismic field name with the icon name
const equipmentMapping = {
  equipment_bench_step: 'Step or Bench',
  equipment_foam_roller: 'Foam roller',
  equipment_massage_ball: 'Massage ball',
  equipment_mat: 'Yoga mat',
  equipment_mini_trampolim: 'Mini trampolim',
  equipment_resistance_band: 'Resistance band',
  equipment_stationary_bike: 'Indoor bike',
  equipment_weights: 'Hand weight(s)',
};

const formatHeroEquipments = (
  item: Queries.Maybe<Queries.PrismicExploreDataBodyExploreHeroCarouselItem>,
  displayEquipment?: boolean
) => {
  if (!displayEquipment) {
    return null;
  }

  const regex = /^equipment_/;

  const equipmentsArray: string[] = [];
  Object.keys(item).forEach((key) => {
    if (regex.test(key) && item[key] === true) {
      equipmentsArray.push(equipmentMapping[key]);
    }
  });

  return equipmentsArray;
};

export default formatHeroEquipments;
