/* eslint-disable @typescript-eslint/naming-convention */
import React, { FC, useState, useEffect } from 'react';
import styled from 'styled-components';
import cloneDeep from 'lodash/cloneDeep';
import { gql } from '@apollo/client';
import { Button, Dialog, DialogContent, DialogActions, Alert } from '@lesmills-international/components';

import { breakpoint } from '@themes';
import { addErrorInDatadogRum } from '@utils/utilities';
import captureException from '../../../../../gatsby-theme-acquisition/src/utils/sentry';
import { createClient } from '../../../../../gatsby-theme-acquisition/src/services/client';

import FitnessProfileItem from './fitnessProfileItem';

export interface AnswerProps {
  attribute: {
    key: string;
    value: string;
  };
  displayText: string;
  selected: boolean;
}

export interface QuestionProps {
  questionId: string;
  questionIntent: string;
  displayText: string;
  questionType: string;
  answers: AnswerProps[];
  displayLabel: string;
}

interface FitnessProfileProps {
  data: QuestionProps[];
  prismicData?: Queries.PrismicUpdateFitnessProfileData;
}

const FitnessProfileContainer = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const ClearDataContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
  color: ${(props) => props.theme.colors.lightGreyAE};
`;

const ClearDataDialogContent = styled.div`
  height: 180px;
`;

const ClearDataButton = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  & button {
    width: 100%;
  }

  ${breakpoint('md')`
    justify-content: flex-end;
    & button {
      width: auto;
    }
  `}
`;

const FitnessProfile: FC<FitnessProfileProps> = ({ data, prismicData }: FitnessProfileProps) => {
  const [openClearDataDialog, setOpenClearDataDialog] = useState<boolean>(false);
  const [selectedAnswersCount, setSelectedAnswerCount] = useState<{ [key: string]: number }>({});
  const [selectedAnswersNumber, setSelectedAnswersNumber] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [serverErrors, setServerErrors] = useState<string[]>([]);
  const [questionData, setQuestionData] = useState<QuestionProps[]>([]);

  const { clear_data_button_label, clear_data_content, clear_data_title } = prismicData || {};

  useEffect(() => {
    const selectedAnswersObject: { [key: string]: number } = {};
    data.forEach((question) => {
      selectedAnswersObject[question?.questionId] = 0;
      question?.answers?.forEach((answer) => {
        if (answer?.selected) {
          selectedAnswersObject[question?.questionId] += 1;
        }
      });
    });
    setQuestionData(data);
    setSelectedAnswerCount(selectedAnswersObject);
  }, [data]);

  const updateSelectedAnswers = (questionId: string, number: number) => {
    const cloneData = cloneDeep(selectedAnswersCount);
    cloneData[questionId] = number;
    setSelectedAnswerCount(cloneData);
  };

  const handleOnCloseDialog = () => {
    setServerErrors([]);
    setOpenClearDataDialog(false);
  };

  const handleOnOpenDialog = () => {
    setOpenClearDataDialog(true);
  };

  const handleOnClearData = async () => {
    try {
      setSelectedAnswersNumber(0);
      setIsLoading(true);
      // add multiple fields into one mutation
      const variables = data.map((_, index) => `$questionId${index + 1}: String!`).join(', ');
      const fields = data
        .map(
          (question, index) => `
          ${question.questionId}: createSurveyQuestionResponse(input: { questionId: $questionId${
            index + 1
          }, selectedAnswers: [] }) {
              status
              message
            }
        `
        )
        .join('\n');

      const ClearSurveyResponsesquery = `
          mutation ClearSurveyQuestionResponses(${variables}) {
          ${fields}
        }
      `;
      const variableoObj: { [key: string]: string } = {};
      data.forEach((question, index) => {
        const key = `questionId${index + 1}`;
        variableoObj[key] = question.questionId;
      });

      const response = await createClient.mutate({
        mutation: gql`
          ${ClearSurveyResponsesquery}
        `,
        variables: variableoObj,
      });
      setIsLoading(false);
      const responseDataLength = Object.keys(response?.data).length;

      if (responseDataLength > 1) {
        const errorMessageArray: string[] = [];
        Object.keys(response?.data).forEach((res) => {
          // TODO: BE needs to add errors attribute
          if (response?.data[res].status && /^[45]/.test(response?.data[res].status)) {
            errorMessageArray.push(response?.data[res].message);
            addErrorInDatadogRum(response?.data[res].message);
            captureException({
              action: 'clearFitnessProfileData',
              error: response?.data[res].message,
            });
          }
        });
        setServerErrors(errorMessageArray);
        if (errorMessageArray.length < 1) {
          const cloneQuestionData = cloneDeep(questionData);
          cloneQuestionData?.forEach((question) => {
            question?.answers?.forEach((answer) => {
              // eslint-disable-next-line no-param-reassign
              answer.selected = false;
            });
          });
          setQuestionData(cloneQuestionData);
          setOpenClearDataDialog(false);
        }
      }
    } catch (error) {
      setIsLoading(false);
      addErrorInDatadogRum(error);
      captureException({
        action: 'clearAllFitnessProfileData',
        error,
      });
    }
  };

  const answersNumber = Object.entries(selectedAnswersCount)
    .map((count) => count[1])
    .reduce((accumulator: number, currentValue: number) => accumulator + currentValue, 0);

  useEffect(() => {
    setSelectedAnswersNumber(answersNumber);
  }, [answersNumber]);

  return (
    <FitnessProfileContainer>
      {questionData &&
        questionData.map((question, index) => (
          <FitnessProfileItem
            data={question}
            key={index}
            prismicData={prismicData}
            updateSelectedAnswers={updateSelectedAnswers}
          />
        ))}
      <ClearDataContainer>
        <Button
          variant="text"
          style={{
            textDecoration: 'underline',
            padding: 0,
            textTransform: 'capitalize',
            cursor: selectedAnswersNumber === 0 ? 'not-allowed' : 'pointer',
            letterSpacing: 'normal',
          }}
          onClick={handleOnOpenDialog}
          disabled={selectedAnswersNumber === 0}
        >
          {clear_data_button_label}
        </Button>
      </ClearDataContainer>
      <Dialog
        title={clear_data_title}
        open={openClearDataDialog}
        onClose={handleOnCloseDialog}
        fullWidth
        maxWidth="xs"
      >
        <DialogContent>
          {serverErrors.length > 0 && (
            <Alert severity="error" hasCloseButton={false} style={{ marginBottom: '24px' }}>
              {serverErrors[0]}
            </Alert>
          )}
          <ClearDataDialogContent>{clear_data_content}</ClearDataDialogContent>
        </DialogContent>
        <DialogActions>
          <ClearDataButton>
            <Button
              style={{
                borderRadius: '100px',
                textTransform: 'capitalize',
                letterSpacing: 'normal',
                minWidth: '110px',
              }}
              onClick={handleOnClearData}
              loading={isLoading}
            >
              {clear_data_button_label}
            </Button>
          </ClearDataButton>
        </DialogActions>
      </Dialog>
    </FitnessProfileContainer>
  );
};

export default FitnessProfile;
