import { useState } from 'react';
import { createPublicClient } from '../services/client';
import ValidateActivationCode from '../graphql/validateActivationCode';
import { setLocalStorage, deleteLocalStorage } from '../utils/utilities';
import { partnerActivationCodeStatus } from '../constants/partnerStatus';
import { ACTIVATION_CODE_FORMATTED } from '../constants/localStorageKeys';

interface IValidatedCode {
  activationCodeIsValid: Boolean;
  activationCodeStatus: String; // this is different from activationStatus and subscriptionStatus
  email?: String;
  givenName?: String;
  familyName?: String;
}

const useValidateActivationCode = () => {
  const [validatedCode, setValidatedCode] = useState<IValidatedCode | null>(null);

  const validateActivationCode = async (activationCode?: string) => {
    if (!activationCode) return;

    deleteLocalStorage(ACTIVATION_CODE_FORMATTED);

    const response = await createPublicClient.query({
      query: ValidateActivationCode,
      variables: {
        activationCode,
      },
    });

    const { status, data } = response?.data?.validateResellerActivationCode || {};
    const activationCodeIsValid = Boolean(status === partnerActivationCodeStatus.VALID && data);

    if (activationCodeIsValid) setLocalStorage(ACTIVATION_CODE_FORMATTED, JSON.stringify(data));

    setValidatedCode({
      activationCodeIsValid,
      activationCodeStatus: status,
      email: data?.email,
      givenName: data?.givenName,
      familyName: data?.familyName,
    });
  };

  return { validatedCode, validateActivationCode };
};

export default useValidateActivationCode;
