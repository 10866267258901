/* eslint-disable no-param-reassign */
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import cloneDeep from 'lodash/cloneDeep';
import { Chip } from '@lesmills-international/components';

import type { AnswerProps } from './fitnessProfile';

interface AnswersProps {
  AnswersData: AnswerProps[];
  onChange: (answeredValue: string[]) => void;
  questionType: string;
}

const ChipContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  padding: 24px 0;
  min-height: 216px;
  align-content: flex-start;
`;

const Answers: FC<AnswersProps> = ({ AnswersData, onChange, questionType }: AnswersProps) => {
  const [selectedAnswers, setSelectedAnswers] = useState<string[]>([]);

  const SINGLE_SELECT_TYPE = 'SINGLE_SELECT';
  const NO_EQUIPMENT_VALUE = 'none';
  const EQUIPMENT_KEY = 'equipment';

  useEffect(() => {
    const selectedAnswersArray: React.SetStateAction<string[]> = [];
    AnswersData.forEach((answer) => {
      if (answer.selected) {
        selectedAnswersArray.push(answer?.attribute?.value);
      }
    });
    setSelectedAnswers(selectedAnswersArray);
  }, [AnswersData]);

  const updateAnswers = (answerValue: string, answerKey: string) => {
    let cloneSelectedAnswers = cloneDeep(selectedAnswers);
    const isAlreadySelected = cloneSelectedAnswers.includes(answerValue);
    if (questionType === SINGLE_SELECT_TYPE) {
      cloneSelectedAnswers[0] = isAlreadySelected ? '' : answerValue;
    } else if (answerKey === EQUIPMENT_KEY) {
      // if select "no equipment", the other options will be unselected
      // if already selected "no equipment", then select one other option, the "no equipment" will be unselected
      const noEquipmentIndex = cloneSelectedAnswers.indexOf(NO_EQUIPMENT_VALUE);
      if (answerValue === NO_EQUIPMENT_VALUE) {
        if (!isAlreadySelected) {
          cloneSelectedAnswers = [];
          cloneSelectedAnswers[0] = answerValue;
        } else {
          cloneSelectedAnswers = [];
        }
      } else if (!isAlreadySelected) {
        if (noEquipmentIndex > -1) {
          cloneSelectedAnswers.splice(noEquipmentIndex, 1);
        }
        cloneSelectedAnswers.push(answerValue);
      } else {
        cloneSelectedAnswers.splice(cloneSelectedAnswers.indexOf(answerValue), 1);
      }
    } else if (!isAlreadySelected) {
      cloneSelectedAnswers.push(answerValue);
    } else {
      cloneSelectedAnswers.splice(cloneSelectedAnswers.indexOf(answerValue), 1);
    }
    onChange(cloneSelectedAnswers);
    setSelectedAnswers(cloneSelectedAnswers);
  };

  return (
    <ChipContainer>
      {AnswersData &&
        AnswersData.map((answer: AnswerProps, index: number) => (
          <Chip
            label={answer?.displayText}
            active={answer?.selected}
            key={index}
            onClick={() => {
              updateAnswers(answer?.attribute?.value, answer?.attribute?.key);
            }}
          />
        ))}
    </ChipContainer>
  );
};

export default Answers;
