/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-undef */
/* eslint max-len: ["error", { "code": 120 }] */

// 👇️ ts-nocheck ignores all ts errors in the file
// This is a JSX file that has been converted to TSX
// as we want to have type checking on new TS-based methods,
// without having to rewrite the whole page into TS first.
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { graphql, navigate } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';

import { getFormattedParamInfoFromURL } from '@src/utils';
import { authContext } from '../context/authContext';
import Signup from '../graphql/signUp';
import { createPublicClient } from '../services/client';
import { signUpTypes } from '../types/segment/types';
import { signUpAnalytics } from '../utils/segment/index';
import captureException from '../utils/sentry';
import handleSelectPlanRedirection from '../utils/selectPlanRedirection';
import ConsumeActivationCode from '../graphql/consumeActivationCode';
import {
  addErrorInDatadogRum,
  deleteLocalStorage,
  getAutomationRecaptcha,
  getLocalStorage,
  isBrowser,
  setLocalStorage,
  waitForElementToLoad,
} from '../utils/utilities';

import Layout from '../components/common/layout';
import ConfirmEmail from '../components/signup/confirmEmail';
import CreateAccount from '../components/signup/createAccount';
import SignUpUid from '../constants/d2c-partner-signup';
import { partnerSignupStatus } from '../constants/partnerStatus';
import { ACTIVATION_CODE_FORMATTED, PARTNER_SIGNUP } from '../constants/localStorageKeys';
import { sessionReceiverURL } from '../../../gatsby-theme-engagement/src/services/amplifyStorage';
import useDisplayRecaptchaBadge from '../hooks/useDisplayRecaptchaBadge';
import RecaptchaV2 from '../components/common/recaptchaV2';
import useGetCountryCode from '../hooks/useGetCountryCode';
import { fixSlashes, getLangFromURL } from '../../../../src/utils/utilities';

interface CreateAccountValues {
  firstName?: string;
  lastName?: string;
  email?: string;
  password?: string;
  receiveEmail?: string;
}

const CreateAccountTemplate = ({ data, pageContext, location }) => {
  const currentSignupUid = pageContext.uid;
  const { countryCode } = useGetCountryCode();

  const [currentStep, setCurrentStep] = useState('confirmEmail');
  const [createAccountValues, setCreateAccountValues] = useState<CreateAccountValues>({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    receiveEmail: '',
  });
  const [isProcessing, setIsProcessing] = useState(false);
  const [serverError, setServerError] = useState('');
  const [tiering, setTiering] = useState(null);
  // This is currently used exclusively for Abandoned Cart.
  // Usage of this state is eventually nullified by the feature flag.
  const [emailChangedFrom, setEmailChangedFrom] = useState('');

  const { prismicSignUpLayout, prismicCreateAccountPage } = data;

  const { lang } = pageContext;
  const recaptchaAutomation = getAutomationRecaptcha();
  const recaptchaRef = useRef();
  const { handleSignIn } = useContext(authContext);
  const [discountInfo, setDiscountInfo] = useState(null);

  // define the path
  const currentPagePath =
    currentSignupUid === SignUpUid.Partner ? '/partner/signup' : '/signup/create-account';

  const getDiscountInfoParamObject = () => {
    // Mapped into logical variable names for segment data
    // eslint-disable-next-line max-len
    // https://lesmillsinternational.atlassian.net/wiki/spaces/LMMD/pages/2723217607/Abandoned+Cart+Segment+Data+Specification
    const discountDict = {
      referrerId: 'signup_referrer_user_id',
      offerId: 'signup_offer_id',
      transactionId: 'signup_affiliate_transaction_id',
      promotionCode: 'signup_subscription_promo',
      affiliateId: 'signup_affiliate',
    };

    const discountObj = {};
    if (discountInfo?.type)
      Object.entries(discountInfo.data).forEach(([key, val]) => {
        discountObj[discountDict[key]] = val;
      });

    return discountObj;
  };

  useDisplayRecaptchaBadge();

  // wait for the google recaptcha html elements to load, check every 3 seconds
  const reCaptchaClickOutsideTheBox = () => {
    setIsProcessing(false);
  };
  waitForElementToLoad('#g-recaptcha-response', 3000)
    .then(() => {
      const el = document.querySelector(
        'iframe[src^="https://www.google.com/recaptcha"][src*="bframe"]'
      );
      el?.parentNode?.parentNode?.addEventListener('click', reCaptchaClickOutsideTheBox);
    })
    .catch(() => {});

  useEffect(() => {
    updateReceiveEmail(countryCode !== 'us' ? '' : 'yes');

    const urlParams = new URLSearchParams(location.search);
    const planId = urlParams.get('planId');
    const optedOut = urlParams.get('opted_out');
    const isTieringEnabled = urlParams.get('tiering');
    const isFromBAU = urlParams.has('bau');

    if (optedOut !== null) updateReceiveEmail(optedOut === 'true' ? 'no' : 'yes');
    const currentUserEmail = urlParams.get('email');
    // if you click the URL from abandoned cart email, it should take you to the step 2
    if (currentUserEmail) setCurrentStep('createAccount');

    if (planId) setLocalStorage('planId', planId);
    if (isFromBAU) setLocalStorage('isFromBAU', isFromBAU);

    if (isTieringEnabled !== null) {
      setLocalStorage('isTieringEnabled', isTieringEnabled);
      setTiering(isTieringEnabled);
    } else {
      deleteLocalStorage('isTieringEnabled');
    }

    const discountInfoFormatted = getFormattedParamInfoFromURL(location);

    if (discountInfoFormatted) {
      setLocalStorage('discountInfo', JSON.stringify(discountInfoFormatted));
      setDiscountInfo(discountInfoFormatted);
    } else {
      deleteLocalStorage('discountInfo');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryCode]);

  const handleConfirmEmail = async (onSuccessCallback?: () => void) => {
    const sendConfirmedEmailInfoToSegment = async (emailAddress) => {
      const { receiveEmail } = createAccountValues;
      const emailConfirmedInfo: signUpTypes.IdentityAtEmailConfirmationType = {
        email: emailAddress,
        signup_email_changed_from: null,
        unsubscribed: receiveEmail === 'no',
        country: countryCode,
        has_confirmed_subscription: countryCode !== 'de',
        ...getDiscountInfoParamObject(),
        signup_subscription_id: getLocalStorage('planId'),
        signup_is_tiering_subscription: getLocalStorage('isTieringEnabled') || false,
      };
      await signUpAnalytics.identifyEmailConfirmed(emailConfirmedInfo);
    };

    const { receiveEmail, email } = createAccountValues;

    setEmailChangedFrom(email);
    // abandon cart feature only works for D2C
    if (
      receiveEmail === 'yes' &&
      currentSignupUid === SignUpUid.D2 &&
      process.env.GATSBY_RT_05_10_2022_ABANDONED_CART === 'true'
    ) {
      sendConfirmedEmailInfoToSegment(email);
    }
    setLocalStorage('step', 'confirmEmail');
    setCurrentStep('createAccount');
    setIsProcessing(false);

    onSuccessCallback && onSuccessCallback();
  };
  const updateEmail = (email) => {
    setCreateAccountValues({ ...createAccountValues, email });
  };

  const updateReceiveEmail = (receiveEmail) => {
    setLocalStorage('opt_in', receiveEmail);
    setCreateAccountValues({ ...createAccountValues, receiveEmail });
  };

  const handleBack = () => {
    setLocalStorage('step', 'confirmEmail');
    setCurrentStep('confirmEmail');
    setIsProcessing(false);
    setServerError('');
  };

  const handleCreateAccount = async ({
    createAccountFormValues,
    onSuccessCallback,
  }: {
    createAccountFormValues: CreateAccountValues;
    onSuccessCallback?: () => void;
  }) => {
    // German users have to confirm subscription
    const sendCreateAccountInfoToSegment = async ({ email, firstName, lastName }) => {
      const accountInfo: signUpTypes.IdentityAtCreateAccountType = {
        email: createAccountFormValues.email,
        signup_email_changed_from: emailChangedFrom !== email ? emailChangedFrom : null,
        first_name: firstName,
        last_name: lastName,
        unsubscribed: getLocalStorage('opt_in') === 'no',
        country: countryCode,
        has_confirmed_subscription: countryCode !== 'de',
        ...getDiscountInfoParamObject(),
        signup_is_tiering_subscription: getLocalStorage('isTieringEnabled') || false,
      };
      await signUpAnalytics.identifyAccountCreated(accountInfo);
    };

    const { email, password, firstName, lastName, receiveEmail } = createAccountFormValues;

    try {
      setLocalStorage('email', email);
      const activationCode = JSON.parse(
        getLocalStorage(ACTIVATION_CODE_FORMATTED) || null
      )?.activationCode;

      // abandon cart feature only works for D2C
      if (
        currentSignupUid === SignUpUid.D2C &&
        process.env.GATSBY_RT_05_10_2022_ABANDONED_CART === 'true'
      ) {
        if (getLocalStorage('opt_in') === 'yes') {
          await sendCreateAccountInfoToSegment(createAccountFormValues);
        } else if (emailChangedFrom === email) {
          await signUpAnalytics.optOutUser(email);
        }
      }

      setServerError('');
      setIsProcessing(true);
      // this is the input for D2C signup journey
      const requestInput = {
        email: email.toLowerCase(),
        password,
        givenName: firstName,
        familyName: lastName,
        subComms: receiveEmail === 'yes',
      };
      // this is the input for partner signup journey
      const signupInput = {
        ...requestInput,
        country: countryCode,
      };

      if (discountInfo?.type === 'refer') {
        requestInput.referrerData = {
          referrerId: discountInfo?.data?.referrerId,
          campaignId: process.env.GATSBY_GROWSURF_CAMPAIGN_ID,
        };
      }

      if (recaptchaAutomation) {
        requestInput.validationData = [
          {
            Name: 'recaptchaToken',
            Value: '',
          },
        ];
        requestInput.captchaSignature = {
          auth: recaptchaAutomation.auth,
          nonce: recaptchaAutomation.nonce,
        };
      } else {
        const token = await recaptchaRef.current.executeAsync();
        recaptchaRef.current.reset();
        requestInput.validationData = [
          {
            Name: 'recaptchaToken',
            Value: token,
          },
        ];
      }
      // D2C signup
      if (currentSignupUid === SignUpUid.D2C) {
        createPublicClient
          .mutate({
            mutation: Signup,
            variables: {
              input: requestInput,
            },
          })
          .then((res) => {
            handleSignUp(res, requestInput);
            setCreateAccountValues(createAccountFormValues);
            onSuccessCallback && onSuccessCallback();
          })
          .catch((err) => {
            handleError(err, requestInput);
            addErrorInDatadogRum(err);
            setIsProcessing(false);
          });
      } else {
        // partner signup journey
        const failureCallback = (err) => {
          handleError(err, signupInput);
          addErrorInDatadogRum(err);
          setIsProcessing(false);
          setLocalStorage('partnerSignupStatus', partnerSignupStatus.ERROR);
          navigate(`/partner/signup/error`);
        };

        if (!countryCode) {
          failureCallback([{ message: 'missing country code' }]);
        }

        try {
          const res = await createPublicClient.mutate({
            mutation: ConsumeActivationCode,
            variables: {
              activationCode,
              signupInput,
            },
          });
          handleSignUp(res, signupInput);
          setCreateAccountValues(createAccountFormValues);
          onSuccessCallback && onSuccessCallback();
        } catch (err) {
          failureCallback(err);
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      setIsProcessing(false);
    }
  };

  const handleError = (err, requestInput) => {
    setServerError(
      err?.graphQLErrors?.[0]?.message ||
        err?.[0]?.message ||
        prismicCreateAccountPage?.data?.generic_error_message ||
        'Server error, please try again'
    );
    // Send error to sentry
    captureException({
      action: 'signUp',
      requestVariables: {
        email: requestInput.email,
        givenName: requestInput.givenName,
        familyName: requestInput.familyName,
        subComms: requestInput.subComms,
        validationData: requestInput.validationData,
      },
      email: requestInput.email.trim().toLowerCase(),
      ...err,
    });
  };

  const handleSignUp = async (response, requestInput) => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const { data, errors } = response;

    if (errors) {
      handleError(errors, requestInput);
    }
    if (currentSignupUid === SignUpUid.D2C) {
      if (data?.signupUser?.clientToken) {
        const validationData = {};
        if (recaptchaAutomation) {
          validationData.auth = recaptchaAutomation.auth;
          validationData.nonce = recaptchaAutomation.nonce;
        } else {
          const token = await recaptchaRef.current.executeAsync();
          validationData.Name = 'recaptchaToken';
          validationData.Value = token;
          recaptchaRef.current.reset();
        }
        handleSignIn(
          requestInput.email,
          requestInput.password,
          validationData,
          onSignInSuccess,
          onSignfailure
        );
      }
    } else if (currentSignupUid === SignUpUid.Partner && data?.consumeActivationCode?.success) {
      const validationData = {};
      if (recaptchaAutomation) {
        validationData.auth = recaptchaAutomation.auth;
        validationData.nonce = recaptchaAutomation.nonce;
      } else {
        const token = await recaptchaRef.current.executeAsync();
        validationData.Name = 'recaptchaToken';
        validationData.Value = token;
        recaptchaRef.current.reset();
      }
      setLocalStorage(PARTNER_SIGNUP, 'yes');
      handleSignIn(
        requestInput.email,
        requestInput.password,
        validationData,
        onSignInSuccess,
        onSignfailure
      );
    }
  };

  // eslint-disable-next-line consistent-return
  const onSignInSuccess = () => {
    // if it is D2c journey, will redirect to select plan page
    if (currentSignupUid === SignUpUid.D2C) {
      const selectplanURL = handleSelectPlanRedirection({
        discountInfoType: discountInfo?.type,
        promotionCode: discountInfo?.data?.promotionCode,
        lang,
        isDefaultNavigatable: true,
        isFromBAU: getLocalStorage('isFromBAU') === 'true',
      });

      if (selectplanURL) {
        window.location.href = selectplanURL;
      }

      return;
    }

    // For partner signup journey, will go to /partner/signup/success page
    setLocalStorage('partnerSignupStatus', partnerSignupStatus.NEW_ACTIVATION);

    if (isBrowser) {
      const currentLang = getLangFromURL({ withLeadingSlash: true });
      window.location.href = fixSlashes(`/partner/signup/success/${currentLang}`);
    }
  };

  const onSignfailure = async (errorMessage) => {
    if (errorMessage === 'PreAuthentication failed with error Failed captcha verification..') {
      // trigger recaptcha v2
      const token = await recaptchaRef.current.executeAsync();
      recaptchaRef.current.reset();
      const validationData = {
        Name: 'recaptchaToken',
        Value: token,
      };
      handleSignIn(
        createAccountValues.email,
        createAccountValues.password,
        validationData,
        onSignInSuccess,
        onSignfailure
      );
    } else {
      setServerError(errorMessage);
    }
  };

  const handleRecaptchaValidateData = async () => {
    let validationData;
    let captchaSignature;

    if (recaptchaAutomation) {
      validationData = {
        Name: 'recaptchaToken',
        Value: '',
      };
      captchaSignature = {
        auth: recaptchaAutomation.auth,
        nonce: recaptchaAutomation.nonce,
      };
    } else {
      const token = await recaptchaRef.current.executeAsync();
      recaptchaRef.current.reset();
      validationData = {
        Name: 'recaptchaToken',
        Value: token,
      };
    }
    return {
      validationData,
      captchaSignature,
    };
  };

  return (
    <Layout
      data={prismicSignUpLayout.data}
      currentStep={currentStep === 'confirmEmail' ? 1 : 2}
      omitClose
      lang={lang}
      pathName={currentPagePath}
    >
      <>
        {currentStep === 'confirmEmail' && (
          <ConfirmEmail
            mode={currentSignupUid}
            lang={lang}
            createAccountValues={createAccountValues}
            data={prismicCreateAccountPage.data}
            isProcessing={isProcessing}
            serverError={serverError}
            updateEmail={updateEmail}
            updateReceiveEmail={updateReceiveEmail}
            handleRecaptchaValidateData={handleRecaptchaValidateData}
            handleConfirmEmail={handleConfirmEmail}
            countryCode={countryCode}
            location={location}
            handleIsProcessing={(process) => {
              setIsProcessing(process);
            }}
            discountInfo={discountInfo}
            tiering={tiering}
          />
        )}
        {currentStep === 'createAccount' && (
          <CreateAccount
            mode={currentSignupUid}
            lang={lang}
            createAccountValues={createAccountValues}
            handleBack={handleBack}
            updateReceiveEmail={updateReceiveEmail}
            data={prismicCreateAccountPage.data}
            handleRecaptchaValidateData={handleRecaptchaValidateData}
            handleCreateAccount={handleCreateAccount}
            countryCode={countryCode}
            submitError={serverError}
            location={location}
            isProcessing={isProcessing}
            handleIsProcessing={(process) => {
              setIsProcessing(process);
            }}
            discountInfo={discountInfo}
          />
        )}
        <RecaptchaV2 ref={recaptchaRef} />
      </>

      {sessionReceiverURL && (
        <iframe
          title="session_receiver"
          id="session_receiver"
          src={sessionReceiverURL}
          width="0"
          height="0"
        />
      )}
    </Layout>
  );
};

export default withPrismicPreview(CreateAccountTemplate);

CreateAccountTemplate.defaultProps = {
  data: { prismicSignUpLayout: {}, prismicCreateAccountPage: {} },
};

CreateAccountTemplate.propTypes = {
  data: PropTypes.shape({
    /* eslint-disable react/forbid-prop-types */
    prismicSignUpLayout: PropTypes.object,
    prismicCreateAccountPage: PropTypes.object,
  }),
};

export const query = graphql`
  query ($lang: String!, $uid: String!) {
    prismicCreateAccountPage(lang: { eq: $lang }, uid: { eq: $uid }) {
      _previewable
      data {
        generic_error_message
        privacy_policy_disclaimer
        privacy_policy_link_label {
          text
        }
        privacy_policy_link {
          text
        }
        create_account_title {
          text
        }
        have_an_account_text {
          text
        }
        log_in_link_label {
          text
        }
        first_name_label {
          text
        }
        last_name_label {
          text
        }
        email_label {
          text
        }
        password_label {
          text
        }
        button_text {
          text
        }
        receive_email_label {
          text
        }
        receive_email_yes {
          text
        }
        receive_email_no {
          text
        }
        first_name_required {
          text
        }
        first_name_invalid {
          text
        }
        last_name_required {
          text
        }
        last_name_invalid {
          text
        }
        email_required {
          text
        }
        email_invalid {
          text
        }
        password_required {
          text
        }
        password_invalid {
          text
        }
        receive_email_required {
          text
        }
        password_dynamic_message {
          text
        }
        password_space_error_message
        existing_email_message {
          text
        }
        confirm_email_title {
          text
        }
        confirm_email_button_text {
          text
        }
        edit {
          text
        }
        voucher_success
        voucher_has_been_redeemed
        voucher_is_expired
        voucher_is_invalid
        voucher_is_not_required
        voucher_is_required
        country_code_missing
        bau_landing_page_link
        homepage_link
        customer_support_link
        agreement {
          richText
          text
        }
      }
    }
    prismicSignUpLayout(lang: { eq: $lang }) {
      _previewable
      data {
        logo_white {
          alt
          gatsbyImageData(width: 133, height: 33)
        }
        logo_black {
          alt
          gatsbyImageData(width: 133, height: 33)
        }
        background_image {
          alt
          gatsbyImageData
        }
        body {
          ... on PrismicSignUpLayoutDataBodyFooterNavigation {
            items {
              nav_label {
                text
              }
              nav_link {
                text
              }
            }
          }
        }
      }
    }
  }
`;
