import React from 'react';
import Dialog from '@mui/material/Dialog';

import { ContentWrapper, BtnText, Title, BtnsWrapper } from './style';

interface Props {
  width?: string;
  title: string;
  description: string;
  stayBtnText: string;
  leavePageBtnText: string;
  open: boolean;
  handleClose: () => void;
  leaveToUrl: string;
}

const LeavePageDialog = (props: Props) => {
  const {
    open,
    title,
    description,
    stayBtnText,
    leavePageBtnText,
    width = '490px',
    handleClose,
    leaveToUrl,
  } = props;

  const handleLeavePageNavigation = () => {
    window.location.href = leaveToUrl;
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiPaper-root': {
          width,
        },
      }}
    >
      <ContentWrapper>
        <Title>{title}</Title>
        <p>{description}</p>
        <BtnsWrapper>
          <BtnText id="leave-page-dialog-stay" onClick={handleClose}>
            {stayBtnText}
          </BtnText>
          <BtnText
            id="leave-page-dialog-leave"
            className="leave-page-btn"
            onClick={handleLeavePageNavigation}
          >
            {leavePageBtnText}
          </BtnText>
        </BtnsWrapper>
      </ContentWrapper>
    </Dialog>
  );
};

export default LeavePageDialog;
