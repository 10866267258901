import RachaelNewsham from '@assets/presenterAvatar/RachaelNewsham.png';
import DanCohen from '@assets/presenterAvatar/DanCohen.png';
import GlenOstergaard from '@assets/presenterAvatar/GlenOstergaard.png';
import ReaganKang from '@assets/presenterAvatar/ReaganKang.png';
import LisaOsbourne from '@assets/presenterAvatar/LisaOsbourne.png';
import ErinMaw from '@assets/presenterAvatar/ErinMaw.png';
import TauvagaSiolo from '@assets/presenterAvatar/TauvagaSiolo.png';
import KylieGates from '@assets/presenterAvatar/KylieGates.png';
import BenMain from '@assets/presenterAvatar/BenMain.png';
import DianaArcherMills from '@assets/presenterAvatar/DianaArcherMills.png';
import MarkSteele from '@assets/presenterAvatar/MarkSteele.png';
import CaleyJack from '@assets/presenterAvatar/CaleyJack.png';
import RomainPrevedello from '@assets/presenterAvatar/RomainPrevedello.png';
import CoreyBaird from '@assets/presenterAvatar/CoreyBaird.png';
import MarlonWoods from '@assets/presenterAvatar/MarlonWoods.png';
import JackieMills from '@assets/presenterAvatar/JackieMills.png';
import DaveKyle from '@assets/presenterAvatar/DaveKyle.png';

const PRESENTER_AVATAR = [
  {
    image: RachaelNewsham,
    name: 'Rachael Newsham',
  },
  {
    image: DanCohen,
    name: 'Dan Cohen',
  },
  {
    image: GlenOstergaard,
    name: 'Glen Ostergaard',
  },
  {
    image: ReaganKang,
    name: 'Reagan Kang',
  },
  {
    image: LisaOsbourne,
    name: 'Lisa Osbourne',
  },
  {
    image: ErinMaw,
    name: 'Erin Maw',
  },
  {
    image: TauvagaSiolo,
    name: 'Tauvaga Siolo',
  },
  {
    image: KylieGates,
    name: 'Kylie Gates',
  },
  {
    image: BenMain,
    name: 'Ben Main',
  },
  {
    image: DianaArcherMills,
    name: 'Diana Archer Mills',
  },
  {
    image: MarkSteele,
    name: "Mark Nu'u-Steele",
  },
  {
    image: CaleyJack,
    name: 'Caley Jack',
  },
  {
    image: RomainPrevedello,
    name: 'Romain Prevedello',
  },
  {
    image: CoreyBaird,
    name: 'Corey Baird',
  },
  {
    image: MarlonWoods,
    name: 'Marlon Woods',
  },
  {
    image: JackieMills,
    name: 'Jackie Mills',
  },
  {
    image: DaveKyle,
    name: 'Dave Kyle',
  },
];

export default PRESENTER_AVATAR;
