import React, { useRef, useEffect, useContext } from 'react';
import { useQuery } from '@apollo/client';
import { getWorkoutForVideoCard } from '@services';
import { userContext } from '@context';
import SearchBar from '@components/molecules/searchBar';
import VideoCardGrid from '@components/molecules/videoCardGrid';
import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch,
  Configure,
  useSearchBox,
  UseSearchBoxProps,
  useInfiniteHits,
  UseInfiniteHitsProps,
} from 'react-instantsearch-hooks-web';
import { debounce } from 'lodash';
import { pushTracking } from '@src/utils';
import { Event, Params } from '@src/type';
import {
  PageWrapper,
  SearchHeader,
  SearchInputWrapper,
  SearchBody,
  ResultStates,
  NoResultsWrapper,
} from './style';
import GET_ALGOLIA_CONFIGS from '../../../graphql/algolia';
import GET_COUNTRY_CODE from '../../../graphql/countryCode';

interface SearchInputProps {
  searchBoxProps?: UseSearchBoxProps;
  placeHolder: string;
  onClose: () => void;
}

interface SearchOutputProps {
  useHitsProps?: UseInfiniteHitsProps;
  fullScreen: boolean;
  onClick: () => void;
}

interface SearchProps {
  placeHolder?: string | undefined;
  onClose: () => void;
  fullScreen?: boolean;
}

const SearchInput = ({ placeHolder, searchBoxProps, onClose }: SearchInputProps) => {
  const { refine } = useSearchBox(searchBoxProps);
  const minRequiredChars = 2;
  const debouncingTime = 500;
  // Only allow query 1) after user input at least 3 characters 2) 0.5 seconds after typing
  const onChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    const userInputs = e.target.value;
    if (userInputs?.length < minRequiredChars) return;
    refine(userInputs);
  }, debouncingTime);
  return (
    <SearchInputWrapper>
      <SearchBar placeHolder={placeHolder} onChange={onChange} onClose={onClose} />
    </SearchInputWrapper>
  );
};

const SearchOutputs = ({ useHitsProps, fullScreen, onClick }: SearchOutputProps) => {
  const { hits, isLastPage, showMore, results } = useInfiniteHits(useHitsProps);
  const userInfo = useContext(userContext);
  const sentinelRef = useRef(null);
  const query = results?.query;
  const showOverlay = !fullScreen && !results?.query;

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (sentinelRef.current !== null) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !isLastPage) {
            showMore();
          }
        });
      });
      observer.observe(sentinelRef.current);
      return () => {
        observer.disconnect();
      };
    }
  }, [isLastPage, showMore]);

  const handleSearchBodyClick = () => {
    if (showOverlay && !fullScreen) onClick();
  };

  if (query && hits.length === 0) {
    return (
      <SearchBody>
        <NoResultsWrapper data-testid="no-results-found">{`No results found for "${query}"`}</NoResultsWrapper>
      </SearchBody>
    );
  }

  const items = hits.map((w, index) => {
    const {
      fullyQualifiedName: workoutName,
      category: categories,
      presenter: instructorTeam,
      // eslint-disable-next-line no-unused-vars
      name,
      ...rest
    } = w;

    const newItem = {
      workoutName,
      categories,
      instructorTeam,
      ...rest,
    };

    return getWorkoutForVideoCard({
      item: newItem,
      userInfo,
      index: index + 1,
      contentListTitle: 'Search',
      contentListIndex: 1,
      contentLocation: 'Search',
    });
  });
  return (
    <SearchBody showOverlay={showOverlay} onClick={handleSearchBodyClick}>
      {query && (
        <ResultStates>
          <p>{`Workouts for "${query}" (${results?.nbHits})`}</p>
        </ResultStates>
      )}
      <VideoCardGrid
        content={items}
        additionalChild={<li className="ais-InfiniteHits-sentinel" ref={sentinelRef} />}
        wrapperPaddingBottom="80px"
        isDarkBackground
      />
    </SearchBody>
  );
};

const Search = ({ placeHolder, onClose, fullScreen }: SearchProps) => {
  const { tier } = useContext(userContext);

  const {
    error: algoliaError,
    loading: algoliaLoading,
    data: algoliaData,
  } = useQuery(GET_ALGOLIA_CONFIGS);

  const {
    error: countryCodeError,
    loading: countryCodeLoading,
    data: countryCodeData,
  } = useQuery(GET_COUNTRY_CODE);

  if (algoliaLoading || countryCodeLoading) return null;

  // Use the active algolia configs or fallback to default ones through env vars
  const releaseIndex = algoliaError
    ? process.env.GATSBY_ALGOLIA_SEARCH_INDEX
    : algoliaData.getAlgoliaIndex.releaseIndex;

  const algoliaAppKey = algoliaError
    ? process.env.GATSBY_ALGOLIA_KEY
    : algoliaData.getAlgoliaConfig.key;

  const countryCode = countryCodeError ? null : countryCodeData.getHeaders.countryCode;
  const countryCodeUppercase = countryCode ? countryCode.toUpperCase() : '';

  const algoliaClient = algoliasearch(process.env.GATSBY_ALGOLIA_APP_ID || '', algoliaAppKey || '');

  const searchClient = {
    ...algoliaClient,
    search(requests) {
      // Prevent search for empty query for cost saving
      if (requests.every(({ params }) => !params.query)) {
        return Promise.resolve({
          results: requests.map(() => ({
            hits: [],
            nbHits: 0,
            nbPages: 0,
            page: 0,
            processingTimeMS: 0,
          })),
        });
      }
      // tracking the search_complete event
      algoliaClient.search(requests).then((result: { results: { hits: object[] }[] }) => {
        pushTracking({
          event: Event.SEARCH_COMPLETE,
          [Params.SEARCH_TYPE]: 'lmplus main nav',
          [Params.SEARCH_TERM]: requests[0]?.params?.query,
          [Params.SEARCH_STEP_LABEL]: '2',
          [Params.SEARCH_RESULTS]: result?.results[0]?.hits?.length,
        });
      });
      return algoliaClient.search(requests);
    },
  };

  const initialFilter = `tiers:${tier} AND (availableGlobally:true${
    countryCodeUppercase ? ` OR countries:"${countryCodeUppercase}"` : ''
  })`;

  return (
    <InstantSearch searchClient={searchClient} indexName={releaseIndex}>
      <Configure filters={initialFilter} hitsPerPage={30} />
      <PageWrapper>
        <SearchHeader fullScreen={fullScreen}>
          <SearchInput placeHolder={placeHolder || 'Search workouts'} onClose={onClose} />
        </SearchHeader>
        <SearchOutputs fullScreen={fullScreen} onClick={onClose} />
      </PageWrapper>
    </InstantSearch>
  );
};

export default Search;
