import React, { FC, useEffect, useState, useCallback, useMemo, ReactNode } from 'react';
import { Auth } from '@aws-amplify/auth';
import { navigate } from 'gatsby';
import { isBrowser } from '@services';
import Cookies from 'js-cookie';
import { authStorage } from '@services/amplifyStorage';

Auth.configure({
  ...{
    storage: authStorage,
  },
  region: process.env.GATSBY_AWS_COGNITO_REGION,
  userPoolId: process.env.GATSBY_AWS_COGNITO_USER_POOL_ID,
  userPoolWebClientId: process.env.GATSBY_AWS_COGNITO_USER_POOL_WEB_CLIENT_ID,
  authenticationFlowType: 'USER_PASSWORD_AUTH',
});

interface ChangePsswordProps {
  newPassword: string;
  oldPassword: string;
  successCallback?: () => void;
  errorCallback?: (code: string) => void;
}

type SignInFunc = (
  username: string,
  password: string,
  validationData: any,
  successCallback: () => void,
  errorCallback: (code: string) => void
) => void;

type SignOutFunc = (navigationCallBack?: () => void) => void;

type ChangePasswordFunc = ({
  newPassword,
  oldPassword,
  successCallback,
  errorCallback,
}: ChangePsswordProps) => void;

interface AuthState {
  isAuthenticated: boolean;
  isLoading: boolean;
}

interface AuthContext extends AuthState {
  handleSignIn?: SignInFunc;
  handleSignOut?: SignOutFunc;
  handleChangePassword?: ChangePasswordFunc;
}

const authContext = React.createContext<AuthContext>({
  isLoading: true,
  isAuthenticated: false,
});

const AuthProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  const [state, setState] = useState<AuthState>({
    isLoading: true,
    isAuthenticated: false,
  });

  useEffect(() => {
    if (isBrowser()) {
      Auth.currentAuthenticatedUser()
        .then(() =>
          setState({
            isLoading: false,
            isAuthenticated: true,
          })
        )
        .catch(() => setState({ ...state, isLoading: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Intentionally left empty so use effect only runs when the componet is first loaded
  const handleSignIn: SignInFunc = useCallback(
    (username, password, validationData, successCallback, errorCallback) => {
      // Synthetic Testing Recaptcha Bypass
      // DataDog will set two cookies, we can pass these as validationData to bypass recaptcha
      // This method will only work when using V3, if V2 is triggered then the UI will prevent the form from submitting

      if (Cookies.get('recaptchaBypassNonce')) {
        const nonce = Cookies.get('recaptchaBypassNonce');
        const auth = Cookies.get('recaptchaBypassAuth');

        // override the validation data array
        // eslint-disable-next-line no-param-reassign
        validationData = { auth, nonce };
      }

      Auth.signIn(username.toLowerCase(), password, validationData)
        .then(() => {
          setState({ ...state, isAuthenticated: true });
          successCallback();
        })
        .catch((error) => errorCallback(error.message));
    },
    [state]
  );

  const handleSignOut: SignOutFunc = useCallback(
    (navigationCallBack) => {
      Auth.signOut()
        .then(() => {
          setState({ ...state, isAuthenticated: false });
          if (navigationCallBack) {
            navigationCallBack();
          } else {
            navigate('/login/');
            // Temp fix for Recapcha blank page issue
            // eslint-disable-next-line no-restricted-globals
            location.reload();
          }
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
        });
    },
    [state]
  );

  const handleChangePassword: ChangePasswordFunc = useCallback(
    async ({ newPassword, oldPassword, successCallback, errorCallback }) => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        await Auth.changePassword(user, oldPassword, newPassword);

        successCallback && successCallback();
      } catch (err) {
        errorCallback && errorCallback(err?.code);
      }
    },
    []
  );

  const providerValue = useMemo(
    () => ({
      ...state,
      handleSignOut,
      handleSignIn,
      handleChangePassword,
    }),
    [state, handleSignOut, handleSignIn, handleChangePassword]
  );
  return <authContext.Provider value={providerValue}>{children}</authContext.Provider>;
};

export { AuthProvider, authContext };
