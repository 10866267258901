import { useState } from 'react';
import { addErrorInDatadogRum } from '../utils/utilities';
import useGetRolloverCountries from './queries/useGetRolloverCountries';

type RolloverCountries = {
  threeMonth: string[] | null;
  twelveMonth: string[] | null;
};

const lowerCaseCountryCodes = (rollOverCountries: RolloverCountries) => {
  const updatedCountries: RolloverCountries = { threeMonth: [], twelveMonth: [] };

  Object.entries(rollOverCountries).forEach(([key, values]) => {
    if (Array.isArray(values)) {
      updatedCountries[key as keyof RolloverCountries] = values.map((value: string) =>
        value.toLowerCase()
      );
    }
  });

  return updatedCountries;
};

const useFetchRolloverCountries = () => {
  const [rolloverCountries, setRolloverCountries] = useState<RolloverCountries | null>(null);

  useGetRolloverCountries({
    onError: (err) => {
      addErrorInDatadogRum(err);
    },
    onCompleted: (data) => {
      setRolloverCountries(lowerCaseCountryCodes(data?.getRolloverCountries as RolloverCountries));
    },
  });

  return rolloverCountries;
};

export default useFetchRolloverCountries;
