/* eslint-disable @typescript-eslint/naming-convention */
import React, { FC, useContext, useEffect, useState } from 'react';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { graphql, PageProps } from 'gatsby';

import { withPrivateRoute } from '@components/molecules';
import { userContext } from '@context';
import { TIERS } from '@constants';
import { useGetSurveyUserAnswerQuery } from '@backend';
import FitnessProfile from '@components/molecules/fitnessProfile/fitnessProfile';

import SubscriptionManagementLayout from '../../../../gatsby-theme-acquisition/src/components/common/subscriptionManagementLayout';
import Spinner from '../../../../gatsby-theme-acquisition/src/components/common/spinner';

import { Container, SpinnerWrapper } from './style';
import { redirectToMyAccount } from '../../../../../src/utils/utilities';

const UpdateFitnessProfile: FC<PageProps<Queries.GetUpdateFitnessProfileDataQuery>> = ({
  data,
}) => {
  const prismicData = data?.prismicUpdateFitnessProfile?.data;
  const { fitness_profile_title } = prismicData || {};
  const { tier } = useContext(userContext);

  useEffect(() => {
    if (tier === TIERS.BASE) {
      window.location.href = `${process.env.GATSBY_MAIN_LES_MILLS_URL}/customer/account`;
    }
  }, [tier]);

  const {
    loading,
    data: survey,
    refetch,
  } = useGetSurveyUserAnswerQuery({
    variables: { id: 'onboarding' },
  });
  const mySurveyAnswers = survey?.survey?.myAnswers;

  const [isRefetching, setIsRefetching] = useState(false);

  const isLoading = loading || isRefetching;

  const handleRefetch = async () => {
    setIsRefetching(true);
    try {
      await refetch();
    } finally {
      setIsRefetching(false);
    }
  };

  useEffect(() => {
    handleRefetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SubscriptionManagementLayout
      title={fitness_profile_title}
      size="medium"
      backLink={{ handleClick: () => redirectToMyAccount() }}
    >
      <Container>
        {isLoading && (
          <SpinnerWrapper>
            <Spinner />
          </SpinnerWrapper>
        )}
        {!isLoading && mySurveyAnswers && (
          <FitnessProfile data={mySurveyAnswers} prismicData={prismicData} />
        )}
      </Container>
    </SubscriptionManagementLayout>
  );
};

export default withPrismicPreview(withPrivateRoute(UpdateFitnessProfile));

export const query = graphql`
  query GetUpdateFitnessProfileData($lang: String!) {
    prismicUpdateFitnessProfile(lang: { eq: $lang }) {
      data {
        meta_title
        meta_description
        edit_button_label
        fitness_profile_title
        my_account_link_text
        save_button_label
        selected_text
        no_selected_answer_text
        clear_data_button_label
        clear_data_content
        clear_data_title
      }
    }
  }
`;
