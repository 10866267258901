// Disabled eslint for naming of prismic field
/* eslint-disable @typescript-eslint/naming-convention */
import React, { FC } from 'react';

import { Note, Title, ExternalLink, ExternalLinkWrapper } from '../style';

interface Props {
  invalid_title?: string;
  invalid_note?: string;
  contact_help_button?: string;
}

const Invalid: FC<Props> = ({ invalid_title, invalid_note, contact_help_button }) => (
  <div>
    <Title color="red">{invalid_title}</Title>
    <Note>{invalid_note}</Note>
    <ExternalLinkWrapper>
      <ExternalLink href="https://www.tesco.com/help/contact/">{contact_help_button}</ExternalLink>
    </ExternalLinkWrapper>
  </div>
);

export default Invalid;
