import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client';
import updateSubCommsV2 from '../../graphql/updateSubCommsV2';

interface UpdateSubCommsVariables {
  value: boolean;
}

const useUpdateSubCommsV2 = (
  options?: MutationHookOptions<boolean, UpdateSubCommsVariables>
): MutationTuple<boolean, UpdateSubCommsVariables> =>
  useMutation<boolean, UpdateSubCommsVariables>(updateSubCommsV2, options);

export default useUpdateSubCommsV2;
