export const TIERS = {
  BASE: 'base',
  PREMIUM: 'premium',
};

export const TIER_DEFAULT = 'premium';

export const NAV_TABS = {
  BASE: ['Community', 'Shop', 'Explore', 'Learn', 'Blog(FitPlanet)'],
  PREMIUM: [
    'Explore',
    'GettingStarted',
    'Blog(FitPlanet)',
    'Equipment',
    'Community',
    'Shop',
    'Learn',
  ],
};

export const PROGRAM_TEASER_TITLE = 'Go Premium to gain access';

// Plan is for this own file to become an npm package for consumption in Web/App/TV to help unify overall SEO tracking.
// Co-owned by non-technical teams (eg analytics/CX) so always consult before renaming
// May just be a plain JSON file? Does/will it need js?

// NAMING should be applicable to ALL platforms

export const ANALYTICS = {
  EVENT: {
    CLICK_HOMEPAGE: 'homepage_content_click',
    USER_SET: 'user_attribute_set',
    SURVEY_SUBMIT: 'survey_submitted',
    UPGRADE_SUBSCRIPTION: 'upgrade_subscription_button',
  },
  KEY: {
    USER_VIMEO_ID: 'vimeoUserId',
    USER_NEW: 'isNewUser',
    CONTENT_GROUP_ROW_TITLE: 'section',
    CONTENT_GROUP_ROW_POSITION: 'contentGroupRow',
    CONTENT_COLUM_POSITION: 'contentItemColum',
    CONTENT_CURATION_TYPE: 'contentCurationType',
    CONTENT_TITLE: 'destination_title',
    CONTENT_PATH: 'destination_path',
    SURVEY_NAME: 'surveyName_version',
    BUTTON_LOCATION: 'button_location',
  },
};

export const UPGRADE_TIER_TRACKING = {
  PROGRAM_CONTENT_TILE: 'program content tile',
  WORKOUT_CONTENT_TILE: 'workout content tile',
  ACCOUNT_DROPDOWN: 'account dropdown',
};

export const UPGRADE_TEXT = {
  TITLE: 'Get full access with LES MILLS+ Premium',
  TITLE_SUB: 'The majority of exercisers use Premium.',
  BANNER_TEXT: 'This workout is exclusive to LES MILLS+ Premium plan.',
  CTA: 'GO PREMIUM',
  BENEFITS_LIST: [
    'Entire library of 15+ training styles',
    'Bonus Strength and Cycle workouts',
    'Flagship BODYPUMP™ workouts',
    'Workout plans from elite trainers',
    'Download workouts',
  ],
};

export const DURATIONS = {
  SHORT: 'Short',
  MIN_30: '30 mins',
  MIN_45: '45 mins',
  MIN_55: '55 mins',
};

export const TYPES = {
  COOL_DOWN: 'Cool down',
  LEARN: 'Learn',
  REMIX: 'Remix',
};

export const SORTING = {
  DEF: 'Default',
  NEW: 'New to old',
  OLD: 'Old to new',
};
