import { breakpoints } from '@themes';

export const betweenBreakpoints = (left: number, width: number, right: number) =>
  width >= left && width <= right - 1;

export const getProgramCardSlideAmount = (wrapperWidth: number) => {
  let programCardAmount = 3;
  if (betweenBreakpoints(breakpoints.sm, wrapperWidth, breakpoints.md)) {
    programCardAmount = 4;
  } else if (betweenBreakpoints(breakpoints.md, wrapperWidth, breakpoints.lg)) {
    programCardAmount = 5;
  } else if (betweenBreakpoints(breakpoints.lg, wrapperWidth, breakpoints.xl)) {
    programCardAmount = 6;
  } else if (betweenBreakpoints(breakpoints.xl, wrapperWidth, breakpoints.xxl)) {
    programCardAmount = 7;
  } else if (wrapperWidth >= breakpoints.xxl) {
    programCardAmount = 8;
  }
  return programCardAmount;
};

export const getExploreAlbumCardSlideAmount = (wrapperWidth: number) => {
  let albumCardAmount = 1;
  if (betweenBreakpoints(breakpoints.sm, wrapperWidth, breakpoints.lg)) {
    albumCardAmount = 3;
  } else if (betweenBreakpoints(breakpoints.lg, wrapperWidth, breakpoints.xl)) {
    albumCardAmount = 4;
  } else if (betweenBreakpoints(breakpoints.xl, wrapperWidth, breakpoints.xxl)) {
    albumCardAmount = 5;
  } else if (wrapperWidth >= breakpoints.xxl) {
    albumCardAmount = 6;
  }
  return albumCardAmount;
};

export const getHomeAlbumCardSlideAmount = (wrapperWidth: number) => {
  let albumCardAmount = 1;
  if (betweenBreakpoints(breakpoints.sm, wrapperWidth, breakpoints.md)) {
    albumCardAmount = 2;
  } else if (betweenBreakpoints(breakpoints.md, wrapperWidth, breakpoints.lg)) {
    albumCardAmount = 3;
  } else if (betweenBreakpoints(breakpoints.lg, wrapperWidth, breakpoints.xl)) {
    albumCardAmount = 4;
  } else if (betweenBreakpoints(breakpoints.xl, wrapperWidth, breakpoints.xxl)) {
    albumCardAmount = 4;
  } else if (wrapperWidth >= breakpoints.xxl) {
    albumCardAmount = 4;
  }
  return albumCardAmount;
};

export const getCollectionPlaylistWorkoutSlideAmount = (wrapperWidth: number) => {
  let workoutCardAmount = 1;
  if (betweenBreakpoints(breakpoints.sm, wrapperWidth, breakpoints.md)) {
    workoutCardAmount = 2;
  } else if (betweenBreakpoints(breakpoints.md, wrapperWidth, breakpoints.lg)) {
    workoutCardAmount = 3;
  } else if (betweenBreakpoints(breakpoints.lg, wrapperWidth, breakpoints.xl)) {
    workoutCardAmount = 4;
  } else if (betweenBreakpoints(breakpoints.xl, wrapperWidth, breakpoints.xxl)) {
    workoutCardAmount = 5;
  } else if (wrapperWidth >= breakpoints.xxl) {
    workoutCardAmount = 6;
  }
  return workoutCardAmount;
};

export const getSlideSpace = (wrapperWidth: number) => {
  let slideSpace = 8;
  if (betweenBreakpoints(breakpoints.sm, wrapperWidth, breakpoints.lg)) {
    slideSpace = 12;
  } else if (wrapperWidth > breakpoints.lg) {
    slideSpace = 16;
  }
  return slideSpace;
};
