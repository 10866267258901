import { useState, useEffect, useCallback } from 'react';
import { createEngagementClient } from '../../../../src/services/client';
import { GET_FAVORITE_PROGRAM } from '../engagementGql/favoriteProgram';
import { addErrorInDatadogRum } from '../utils/utilities';

const useCheckFavoriteProgram = (programName: string | undefined) => {
  const [isProgramFavorited, setIsProgramFavorited] = useState(false);
  const [favoritePrograms, setFavoritePrograms] = useState();
  const [loading, setLoading] = useState(false);

  const handleGetFavoritePrograms = useCallback(async () => {
    try {
      setLoading(true);
      const result = await createEngagementClient.query({
        query: GET_FAVORITE_PROGRAM,
        variables: {
          contentType: 'program',
          pageSize: 50,
        },
      });
      const favoriteContentItems = result?.data?.favoriteContent?.items;
      if (favoriteContentItems && favoriteContentItems?.length && programName) {
        const programFavorited = favoriteContentItems.some(
          (item: { contentType: string; contentId: string }) => item.contentId === programName
        );
        setIsProgramFavorited(programFavorited);
      }
      setLoading(false);
      setFavoritePrograms(favoriteContentItems);
    } catch (error) {
      setLoading(false);
      addErrorInDatadogRum(error);
    }
  }, [programName]);

  useEffect(() => {
    handleGetFavoritePrograms();
  }, [handleGetFavoritePrograms]);
  return { favoritePrograms, isProgramFavorited, loading };
};

export default useCheckFavoriteProgram;
