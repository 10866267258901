import { Skeleton, Link } from '@components/atoms';
import { HomeSection } from '@components/molecules';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';

interface Props {
  content: Queries.HomeProgramsFragment;
}

const HomeProgramsSlice: FC<Props> = ({ content }) => {
  const title = content.primary?.categories_heading?.text;
  const programs = content.items;

  if (!programs) return null;

  return (
    <HomeSection title={title}>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
        {programs.map((program, i) =>
          program && program.category_image?.gatsbyImageData && program.category_link ? (
            <Link key={i} to={program.category_link?.url} title={program.category_name?.text}>
              <div className="relative flex justify-center items-center">
                <GatsbyImage
                  image={program.category_image.gatsbyImageData}
                  alt={program.category_image.alt ?? ''}
                  className="rounded-md overflow-hidden z-10 w-full object-cover"
                />
                <img
                  src={program.category_logo?.url}
                  alt={program.category_logo?.alt ?? ''}
                  className="absolute z-10 w-8/12"
                />
              </div>
            </Link>
          ) : (
            <Skeleton key={i} className="rounded-md w-full h-32 md:h-48 bg-white" />
          )
        )}
      </div>
    </HomeSection>
  );
};

export default HomeProgramsSlice;
