// Disabled eslint for name of prismic field
/* eslint-disable @typescript-eslint/naming-convention */
import React, { FC, useRef, useState } from 'react';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { Router } from '@reach/router';
import { graphql, PageProps, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import styled, { ThemeProvider } from 'styled-components';
import { ThemeProvider as MUIThemeProvider, createTheme } from '@mui/material/styles';

import { breakpoint } from '../themes/breakpoints';

import Footer from '../components/common/footer';

import COLORS from '../themes/colors';
import { getAutomationRecaptcha } from '../utils/utilities';
import SignUp from '../components/tesco/signup';
import ConfirmEmail from '../components/tesco/confirmEmail';
import Invalid from '../components/tesco/invalidVoucher';
import Success from '../components/tesco/success';
import ValidateVoucher from '../components/tesco/validateVoucher';
import Processing from '../components/tesco/processing';

import { ReCaptchaRef } from '../types/Recaptcha';
import { UserInfo } from '../types/UserInfo';
import SEO from '../components/common/seo';
import useDisplayRecaptchaBadge from '../hooks/useDisplayRecaptchaBadge';
import RecaptchaV2 from '../components/common/recaptchaV2';

const Wrapper = styled.div`
  width: 320px;
  min-height: 75vh;
  margin: 30px auto 0 auto;
  padding-bottom: 50px;

  & .logo {
    margin-bottom: 24px;
  }

  ${breakpoint('lg')`
    width: 1150px;
    min-height: 510px;
    margin-top: 0;
    padding-bottom: 0px;
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    justify-content: flex-end;

    & > div {
      width: 50%;
      box-shadow: 0px 4px 20px 0px #00000033;
      padding: 92px 133px 50px 80px;
      background-color: white;
    }
  `}
`;

const Section = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;

  & .background {
    display: none;
  }

  ${breakpoint('lg')`
    position: relative;
    height: 100vh;

    & .big-footer {
      display: flex;
    }

    & .background {
      display: block;
      height: 100vh;
      & img {
        object-fit: contain;
        height: 100vh;
        width: auto;
      }
    }
  `}
`;

const Logo = styled(Link)`
  cursor: pointer;
  margin: 18px;

  ${breakpoint('lg')`
    top: 33px;
    left: 32px;
    position: absolute;
    z-index: 1;
  `}
`;

interface PageContextProps {
  lang: string;
}

const MUICreateTheme = createTheme();

const TescoPage: FC<PageProps<Queries.GetTescoQuery, PageContextProps>> = ({
  data,
  location,
  pageContext,
}) => {
  const [tescoVoucherType, setTescoVoucherType] = useState('');
  const [userInfo, setUserInfo] = useState<UserInfo>({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    receiveEmail: false,
  });
  const urlParams = new URLSearchParams(location.search);
  const voucher = urlParams.get('voucher') || '';

  const prismicData = data?.prismicTescoPage?.data as Queries.PrismicTescoPageData;
  const signUpLayout = data?.prismicSignUpLayout?.data;

  useDisplayRecaptchaBadge();

  const recaptchaRef = useRef() as ReCaptchaRef;
  const recaptchaAutomation = JSON.parse(getAutomationRecaptcha() as string);

  const {
    confirm_email_note,
    confirm_email_title,
    email_label,
    next_btn,
    login_label,
    password_label,
    signup_note,
    signup_title,
    first_name_label,
    last_name_label,
    congratulations,
    next_billing_date_for_active_user,
    next_billing_date_for_non_active_user,
    lets_get_started,
    voucher_code_label,
    processing_title,
    processing_note,
    invalid_title,
    invalid_note,
    contact_help_button,
    three_month_label,
    twelve_month_label,
    subscribe_to_lm,
    first_name_required,
    first_name_invalid,
    last_name_required,
    last_name_invalid,
    email_required,
    email_invalid,
    existing_email_message,
    password_required,
    password_invalid,
    password_space_error_message,
    password_dynamic_message,
    title,
    description,
    getting_started_link,
  } = prismicData;

  const handleValidateRecaptcha = async () => {
    let captchaSignature;
    let validationData;

    if (recaptchaAutomation) {
      captchaSignature = {
        nonce: recaptchaAutomation.nonce,
        auth: recaptchaAutomation.auth,
      };

      validationData = {
        Name: 'recaptchaToken',
        Value: '',
      };
    } else {
      const token = await recaptchaRef?.current?.executeAsync();

      // call recaptchaRef?.current?.executeAsync(); again if token = null
      if (!token) {
        return handleValidateRecaptcha();
      }

      recaptchaRef?.current?.reset();
      validationData = {
        Name: 'recaptchaToken',
        Value: token,
      };
    }

    return {
      validationData,
      captchaSignature,
    };
  };

  return (
    <>
      <SEO pathname="/tesco/" title={title} description={description} lang={pageContext?.lang} />
      <MUIThemeProvider theme={MUICreateTheme}>
        <ThemeProvider theme={{ colors: COLORS }}>
          <Section>
            <Logo to={process.env.GATSBY_GETTING_STARTED_URL}>
              <GatsbyImage
                className="logo"
                image={prismicData?.lesmills_logo?.gatsbyImageData as IGatsbyImageData}
                alt={prismicData?.tesco_logo?.alt || 'lmplus-logo'}
              />
            </Logo>
            <GatsbyImage
              className="background"
              image={prismicData?.tesco_background?.gatsbyImageData}
              alt={prismicData?.tesco_background?.alt || 'tesco-background'}
            />
            <Wrapper>
              <div>
                <GatsbyImage
                  className="logo"
                  image={prismicData?.tesco_logo?.gatsbyImageData}
                  alt={prismicData?.tesco_logo?.alt || 'tesco-logo'}
                />
                <Router basepath="/tesco/">
                  <Processing
                    path="/processing/"
                    processing_title={processing_title}
                    processing_note={processing_note}
                    recaptchaRef={recaptchaRef}
                    location={location}
                    tescoVoucherType={tescoVoucherType}
                  />
                  <ValidateVoucher
                    voucher={voucher}
                    path="/"
                    recaptchaAutomation={recaptchaAutomation}
                    recaptchaRef={recaptchaRef}
                    setTescoVoucherType={setTescoVoucherType}
                  />
                  <ConfirmEmail
                    tescoVoucherType={tescoVoucherType}
                    path="/confirm/"
                    confirm_email_title={confirm_email_title}
                    confirm_email_note={confirm_email_note}
                    email_label={email_label}
                    next_btn={next_btn}
                    login_label={login_label}
                    password_label={password_label}
                    password_dynamic_message={password_dynamic_message}
                    userInfo={userInfo}
                    voucher={voucher}
                    prismicErrorData={{
                      email_required,
                      email_invalid,
                      password_required,
                      password_invalid,
                      password_space_error_message,
                      existing_email_message,
                    }}
                    handleValidateRecaptcha={handleValidateRecaptcha}
                  />
                  <Invalid
                    path="/invalid/"
                    invalid_title={invalid_title}
                    invalid_note={invalid_note}
                    contact_help_button={contact_help_button}
                  />
                  <Success
                    path="/success/"
                    congratulations={congratulations}
                    next_billing_date_for_active_user={next_billing_date_for_active_user}
                    next_billing_date_for_non_active_user={next_billing_date_for_non_active_user}
                    lets_get_started={lets_get_started}
                    voucher_code_label={voucher_code_label}
                    voucher={voucher}
                    tescoVoucherType={tescoVoucherType}
                    location={location}
                    getting_started_link={getting_started_link}
                  />
                  <SignUp
                    path="/signup/"
                    signup_note={signup_note}
                    voucher_code_label={voucher_code_label}
                    signup_title={signup_title}
                    first_name_label={first_name_label}
                    last_name_label={last_name_label}
                    email_label={email_label}
                    login_label={login_label}
                    next_btn={next_btn}
                    subscribe_to_lm={subscribe_to_lm}
                    voucher={voucher}
                    subscriptionTypes={{
                      three_month_label,
                      twelve_month_label,
                    }}
                    tescoVoucherType={tescoVoucherType}
                    handleValidateRecaptcha={handleValidateRecaptcha}
                    prismicErrorData={{
                      first_name_required,
                      first_name_invalid,
                      last_name_required,
                      last_name_invalid,
                      email_required,
                      email_invalid,
                      existing_email_message,
                    }}
                    setUserInfo={setUserInfo}
                    recaptchaRef={recaptchaRef}
                  />
                </Router>
              </div>{' '}
            </Wrapper>
            <div className="footer-wrapper">
              <Footer
                id="footer-navigation"
                navs={signUpLayout?.body && signUpLayout?.body[0]?.items}
                className="big-footer"
              />
            </div>
          </Section>
        </ThemeProvider>
      </MUIThemeProvider>

      <RecaptchaV2 ref={recaptchaRef} />
    </>
  );
};

export const query = graphql`
  query GetTesco($lang: String!) {
    prismicSignUpLayout(lang: { eq: $lang }) {
      _previewable
      data {
        body {
          ... on PrismicSignUpLayoutDataBodyFooterNavigation {
            items {
              nav_label {
                text
              }
              nav_link {
                text
              }
            }
          }
        }
      }
    }
    prismicTescoPage(lang: { eq: $lang }) {
      _previewable
      data {
        body {
          ... on PrismicTescoPageDataBodyFooterNavigation {
            items {
              nav_label {
                text
              }
              nav_link {
                text
              }
            }
          }
        }
        subscribe_to_lm
        signup_title
        signup_note
        processing_title
        processing_note
        tesco_background {
          alt
          gatsbyImageData
        }
        tesco_logo {
          alt
          gatsbyImageData
        }
        lesmills_logo {
          alt
          gatsbyImageData
        }
        voucher_code_label
        confirm_email_title
        confirm_email_note
        first_name_label
        last_name_label
        email_label
        password_label
        contact_help_button
        invalid_title
        invalid_note
        congratulations {
          richText
          text
        }
        title
        description
        next_billing_date_for_active_user
        next_billing_date_for_non_active_user
        next_btn
        login_label
        lets_get_started
        three_month_label
        twelve_month_label
        first_name_required
        first_name_invalid
        last_name_required
        last_name_invalid
        email_required
        email_invalid
        existing_email_message
        password_required
        password_invalid
        password_space_error_message
        password_not_match
        confirm_password_required
        password_dynamic_message
        getting_started_link {
          url
        }
      }
    }
  }
`;

export default withPrismicPreview(TescoPage);
