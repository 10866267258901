import * as React from 'react';
import { Elements } from 'prismic-richtext';

import { linkResolver } from '../../../../../../src/services/linkResolver';
import {
  StyledParagraph,
  StyledHeading2,
  StyledHeading3,
  StyledLink,
  StyledList,
  StyledOrderedList,
  StyledStrong,
  StyledEm,
} from './style';

interface ElementData {
  url?: string;
  alt?: string;
  linkTo?: {
    url?: string;
    target?: string;
    link_type?: string;
  };
  target?: string;
  data?: {
    url?: string;
  };
}

const TermsHtmlSerializer = (
  type: any,
  element: ElementData,
  // eslint-disable-next-line no-unused-vars
  content: any,
  children: React.ReactNode,
  key: React.Key | null | undefined
) => {
  switch (type) {
    case Elements.paragraph:
      return <StyledParagraph key={key}>{children}</StyledParagraph>;

    case Elements.heading2:
      return <StyledHeading2 key={key}>{children}</StyledHeading2>;

    case Elements.heading3:
      return <StyledHeading3 key={key}>{children}</StyledHeading3>;

    case Elements.hyperlink: {
      const { data } = element;
      return (
        <StyledLink to={data?.url || linkResolver(data)} key={key}>
          {children}
        </StyledLink>
      );
    }

    case Elements.list:
      return <StyledList key={key}>{children}</StyledList>;

    case Elements.oList:
      return <StyledOrderedList key={key}>{children}</StyledOrderedList>;

    case Elements.strong:
      return <StyledStrong key={key}>{children}</StyledStrong>;

    case Elements.em:
      return <StyledEm key={key}>{children}</StyledEm>;

    case Elements.span:
      return content;

    default:
      return null;
  }
};

export default TermsHtmlSerializer;
