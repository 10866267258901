import { ReactComponent as EmptyLogo } from '@assets/icons/empty.svg';
import { BackgroundImage, EquipmentList, Link } from '@components/atoms';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';

interface Workout {
  programName: string;
  workoutName: string;
  equipment: string[];
  imageWide: string | null;
  imageWideFile: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  instructorTeam: string[];
  vimeoWebPageUrl: string;
}
interface WorkoutCardProps {
  workout?: Workout;
  isInProgress: boolean;
  completed?: boolean;
}

const CardWrapper = ({
  active,
  children,
  workout,
}: {
  active: boolean;
  children: JSX.Element;
  workout: Workout;
}) => (active ? <Link to={workout?.vimeoWebPageUrl}>{children}</Link> : <div>{children}</div>);

const WorkoutCard = ({ workout, isInProgress, completed = false }: WorkoutCardProps) =>
  !workout ? (
    <div className="w-44 h-64 rounded-md flex flex-col-reverse p-5 bg-grey-light">
      <div className="h-full flex justify-center items-center text-grey-lighterer">
        <EmptyLogo />
      </div>
    </div>
  ) : (
    <CardWrapper active={isInProgress} workout={workout}>
      <BackgroundImage image={workout.imageWideFile.childImageSharp}>
        <div
          className={`w-44 h-64 rounded-md flex flex-col-reverse p-5 ${
            completed ? 'opacity-20' : ''
          }`}
        >
          <EquipmentList equipments={workout?.equipment} />
          <div className="font-inter-semibold text-20 leading-none font-bold text-white uppercase mb-4">
            {workout?.workoutName}
          </div>
        </div>
      </BackgroundImage>
    </CardWrapper>
  );

export default WorkoutCard;
