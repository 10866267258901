/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { TieringPricingCardHOC } from '@lesmills-international/components';

import { TieringPricingCardWrapper } from './selectPlanCommonStyle';

const PricingCardComponent = ({
  countryCode,
  plan,
  pricingData,
  selectedPlan,
  handleSelectPlan,
  voucher = undefined,
  isHidden,
  noTrialDisplay,
  showTotalPrice = false,
  currentFrequency,
  collapse = true,
  currentProductHandle,
  currentPlanText = '',
  customizePromoMessage,
  forceStrikeThroughDescription = false,
  customPromotionDescription = '',
  customDescription = '',
}) => {
  const isHiddenPricingCard =
    (isHidden && plan.id !== selectedPlan?.id) || currentFrequency !== plan.interval;
  if (!TieringPricingCardHOC || isHiddenPricingCard) return null;

  const {
    free_trial_text,
    monthly_text,
    three_monthly_text,
    no_trial_text,
    annually_text,
    plan_monthly_disclaimer,
    plan_trimonthly_disclaimer,
    plan_annually_disclaimer,
    oneoff_first_payment,
    oneoff_monthly_billed,
    oneoff_trial_text,
    base_tier_title,
    premium_tier_title,
    base_tier_description,
    premium_tier_description,
    items,
    promos,
    base_tier_monthly_promo,
    base_tier_trimonthly_promo,
    base_tier_annually_promo,
    premium_tier_monthly_promo,
    premium_tier_trimonthly_promo,
    premium_tier_annually_promo,
    billed_text,
  } = pricingData;

  const displayPromos = {
    baseMonthlyPromoDisplay:
      promos?.show_promo_on_base_tier_monthly_plan ||
      pricingData?.show_promo_on_base_tier_monthly_plan,
    baseAnnuallyPromoDisplay:
      promos?.show_promo_on_base_tier_annually_plan ||
      pricingData?.show_promo_on_base_tier_annually_plan,
    premiumMonthlyPromoDisplay:
      promos?.show_promo_on_premium_tier_monthly_plan ||
      pricingData?.show_promo_on_premium_tier_monthly_plan,
    premiumAnnuallyPromoDisplay:
      promos?.show_promo_on_premium_tier_annually_plan ||
      pricingData?.show_promo_on_premium_tier_annually_plan,
  };

  const customizePromoContent = {
    baseMonthlyPromoContent: promos?.base_tier_monthly_plan_promo_content,
    baseAnnuallyPromoContent: promos?.base_tier_annually_plan_promo_content,
    premiumMonthlyPromoContent: promos?.premium_tier_monthly_plan_promo_content,
    premiumAnnuallyPromoContent: promos?.premium_tier_annually_plan_promo_content,
  };

  const onCardClick = () => {
    handleSelectPlan(plan.id);
  };

  return (
    <TieringPricingCardWrapper>
      <TieringPricingCardHOC
        {...{
          onClick: onCardClick,
          selected: selectedPlan ? plan?.id === selectedPlan.id : false,
          countryCode: countryCode.toUpperCase(),
          plan,
          pricingCardText: {
            premiumTitleText: premium_tier_title,
            baseTitleText: base_tier_title,
            annuallyPeriodText: annually_text,
            monthlyPeriodText: monthly_text,
            threeMonthlyPeriodText: three_monthly_text,
            baseTierMonthlyPromoText: base_tier_monthly_promo,
            baseTierThreeMonthlyPromoText: base_tier_trimonthly_promo,
            baseTierAnnuallyPromoText: base_tier_annually_promo,
            premiumTierMonthlyPromoText: premium_tier_monthly_promo,
            premiumTierTrimonthlyPromoText: premium_tier_trimonthly_promo,
            PremiumTierAnnuallyPromoText: premium_tier_annually_promo,
            oneoffFirstPaymentText: oneoff_first_payment,
            oneoffMonthlyBilledText: oneoff_monthly_billed,
            oneoffTrialText: oneoff_trial_text,
            freeTrialText: free_trial_text,
            noTrialText: no_trial_text,
            billedText: billed_text,
            premiumTierDescriptionText: premium_tier_description,
            baseTierDescriptionText: base_tier_description,
            planAnnuallyDisclaimerText: plan_annually_disclaimer,
            planThreeMonthlyDisclaimerText: plan_trimonthly_disclaimer,
            planMonthlyDisclaimerText: plan_monthly_disclaimer,
            monthlyPaymentUnitText: '/month',
            currentPlanText,
          },
          showTotalPrice,
          voucher: {
            country: voucher?.country,
            addon_title: voucher?.addon_title,
            addon_price_point: voucher?.addon_price_point,
          },
          customizePromoMessage,
          showSmallDescInsideCard: false,
          benefitItems: items,
          collapse,
          displayPromos,
          customizePromoContent,
          noTrialDisplay,
          isCurrentPlan: currentProductHandle && currentProductHandle === plan.product_handle,
          forceStrikeThroughDescription,
          customDescription,
          customPromotionDescription,
        }}
      />
    </TieringPricingCardWrapper>
  );
};

export default PricingCardComponent;
