const fixSlashes = (str) => str.replace(new RegExp('/{2,}', 'g'), '/');
// fixSlashes removes double slashes, for example, "//login" becomes "/login"
// double slashes can occur when ${currentLang} is an empty string

exports.linkResolver = (doc) => {
  const { type, lang, uid } = doc;
  const currentLang = lang !== 'en-nz' ? lang : '';
  const partnerSignupPath = fixSlashes(`/partner/signup/${currentLang}`);
  const currentSignupPath =
    uid === 'd2c-signup' ? fixSlashes(`/signup/create-account/${currentLang}`) : partnerSignupPath;
  const subscriptionPagePath =
    process.env.GATSBY_RT_19_02_2024_ACCOUNT_OVERVIEW === 'true'
      ? '/subscription'
      : '/change-subscription';

  switch (type) {
    case '404page':
      return '/404';
    case 'login_page':
      return fixSlashes(`/login/${currentLang}`);
    case 'search_page':
      return fixSlashes(`/${currentLang}/search/`);
    case 'affiliate_landing_page':
      return fixSlashes(`/signup/select-plan/affiliate/${currentLang}`);
    case 'promotion_landing_page':
      return fixSlashes(`/signup/select-plan/${uid}/${currentLang}`);
    case 'onboarding_survey':
      return fixSlashes(`/survey/${currentLang}`);
    case 'create_account_page':
      return fixSlashes(currentSignupPath);
    case 'select_plan_page':
      return fixSlashes(`/signup/select-plan/${currentLang}`);
    case 'sign_up_success_page':
      return fixSlashes(`/signup/success/${currentLang}`);
    case 'partner_success_page':
      return fixSlashes(`/partner/signup/success/${currentLang}`);
    case 'partner_processing_page':
      return fixSlashes(`/partner/${currentLang}`);
    case 'partner_invalid_page':
      return fixSlashes(`/partner/error/${currentLang}`);
    case 'partner_generic_error_page':
      return fixSlashes(`partner/signup/error/${currentLang}`);
    case 'shop_page':
      return fixSlashes(`/shop/${currentLang}/`);
    case 'learn_page':
      return fixSlashes(`/fit-planet/${currentLang}/${uid}/`);
    case 'refer_a_friend_page':
      return `/refer/`;
    case 'tesco_page':
      return `/tesco/`;
    case 'new_web_homepage':
      return fixSlashes(`/${currentLang}`);
    case 'article_hub':
      return fixSlashes(`/${currentLang}/fit-planet/`);
    case 'change_subscription_page':
      return fixSlashes(`/${subscriptionPagePath}/${currentLang}`);
    case 'update_payment_details_page':
      return fixSlashes(`/update-payment-details/${currentLang}`);
    case 'explore':
      return fixSlashes(`/explore/${currentLang}`);
    case 'update_fitness_profile':
      return fixSlashes(`/update-fitness-profile/${currentLang}`);
    case 'offer_redemption_page':
      return fixSlashes(`/offer-redemption/${currentLang}`);
    case 'account_overview_page':
      return fixSlashes(`/account/${currentLang}`);
    case 'account_details_page':
      return fixSlashes(`/account-details/${currentLang}`);
    case 'billing_history_page':
      return fixSlashes(`/billing-history/${currentLang}`);
    case 'all_programs_view_page':
      return fixSlashes(`/all-programs/${currentLang}`);
    case 'my_workouts':
      return fixSlashes(`/my-workouts/${currentLang}`);
    case 'plans':
      return fixSlashes(`/plans/${currentLang}`);
    case 'terms_of_use_page':
      return fixSlashes(`/terms-of-use/${currentLang}`);
    case 'privacy_policy_page':
      return fixSlashes(`/privacy-policy/${currentLang}`);
    case 'cookie_policy_page':
      return fixSlashes(`/cookie-policy/${currentLang}`);
    // Fallback for all other documents
    default:
      return '/';
  }
};
