import React from 'react';
import styled from 'styled-components';
import { breakpoint } from '@themes';
import COLORS from '@themes/colors';
import { Button } from '@lesmills-international/components';
import { ReactComponent as HeartFilledIcon } from '../assets/icons/heart_filled.svg';
import { ReactComponent as HeartOutlinedIcon } from '../assets/icons/heart_outline.svg';

/**
 * Vimeo wrapper styles
 * the height of an iframe video must be specified and we calculate based on propotion
 */
export const VimeoWrapper = styled.div`
  height: calc(100vw / 1.7778);
  margin-top: 12px;

  ${breakpoint('md')`
    height: calc((100vw - 160px) / 1.7778);
    margin-top: 0px;
  `}

  ${breakpoint('xl')`
    height: 720px;
  `}

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Description = styled.div`
  color: ${COLORS.lightGreyAE};
  font-size: 16px;
  font-weight: 500;
  width: 100%;
`;

export const DescriptionText = styled.p`
  margin-bottom: 16px;
  height: ${(props) => (props.showFullDescription ? 'auto' : '72px')};
  overflow: hidden;
`;

export const VideoTitle = styled.div`
  color: ${COLORS.lightishGrey};
  font-weight: 800;
  font-size: 24px;
  line-height: 100%;
  margin-bottom: 24px;
`;

export const SectionWrapper = styled.div`
  margin-top: 56px;
`;

export const CommentsWrapper = styled.div`
  margin-top: 24px;
  margin-bottom: 56px;
`;

export const PostCommentWrapper = styled.div`
  margin-top: 24px;
`;

export const RepliedCommentWrapper = styled.div`
  margin-left: 64px;
`;

export const Spacer = styled.div`
  margin-bottom: 16px;
`;

export const SpacerLeft = styled.div`
  margin-left: 12px;
`;

export const DeleteIconWrapper = styled.div`
  margin-left: 4px;
  margin-right: 16px;
`;

export const TopButtonsWrapper = styled.div`
  margin-top: 24px;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const NoCommentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 16px 0px;
  margin-bottom: 56px;
  align-self: stretch;
`;

export const NoCommentsTitle = styled.p`
  color: ${COLORS.lightishGrey};
  font-family: Inter Light;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
`;

export const NoCommentsText = styled.p`
  color: ${COLORS.lightGreyAE};
  font-family: Inter Light;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
`;

export const Title = styled.p`
  font-family: 'Inter Medium';
  color: ${COLORS.lightishGrey};
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 0.08em;
`;

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

export const DropdownMenu = styled.div`
  width: 240px;
  background-color: ${COLORS.lightBlack};
  border-radius: 4px;
  padding: 8px 0;
  min-width: 120px;
  z-index: 1;
`;

export const MenuItem = styled.div`
  color: ${COLORS.white};
  padding: 8px 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;
`;

export const DialogWrapper = styled.div`
  .cCVNOZ .MuiPaper-root {
    background-color: ${COLORS.lightBlack};
  }
`;

export const ContentContainer = styled.div`
  margin-top: 24px;
  display: flex;
  gap: 32px;
  align-items: center;
  font-family: 'Inter Regular';
  color: ${COLORS.lightishGrey};
  font-size: 16px;
  font-weight: 500;
  flex-wrap: wrap;
`;

export const TrackContentContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  font-family: 'Inter Regular';
  color: ${COLORS.lightishGrey};
  font-size: 16px;
  font-weight: 500;
`;

export const EquipmentItem = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

export const NoEquipmentText = styled.p`
  color: ${COLORS.lightGreyAE};
`;

export const InstructorItem = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  & > img {
    border: 1px solid ${COLORS.greyLetter};
    border-radius: 50%;
  }
`;

export const ShowMoreContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 12px 16px;

  &:hover {
    background-color: ${COLORS.lightBlack};
    border-radius: 6px;
    cursor: pointer;
  }
`;

export const WorkoutWrapper = styled.div`
  width: 100%;
  font-family: 'Inter Regular';
  padding: 0;

  ${breakpoint('lg')`
    padding: 0px 80px;
  `}

  ${breakpoint('xl')`
    width: 1440px;
  `}
`;

export const ContentWrapper = styled.div`
  margin-top: 40px;
  padding: 0px;

  ${breakpoint('xs')`
    padding: 0px 16px;
  `}

  ${breakpoint('sm')`
    padding: 0px 32px;
  `}

  ${breakpoint('lg')`
    padding: 0;
  `}
`;

export const PageWrapper = styled.div`
  background: ${COLORS.black};
  display: flex;
  justify-content: center;
  min-height: 80vh;
`;

const TopButtonText = styled.div`
  font-family: 'Inter Medium';
  font-weight: 900;
  font-size: 14px;
  color: #ffffff;
`;

const IconWrapper = styled.div`
  margin-right: 8px;
`;

export const MyListButton = ({
  text,
  isProcessing,
  onClick,
  isFavourited,
}: {
  text: string;
  isProcessing?: boolean;
  onClick: () => void;
  isFavourited: boolean;
}) => (
  <Button
    style={{ borderRadius: '24px', height: '48px' }}
    loading={isProcessing}
    onClick={onClick}
    label={text}
  >
    {isFavourited ? (
      <IconWrapper>
        <HeartFilledIcon />
      </IconWrapper>
    ) : (
      <IconWrapper>
        <HeartOutlinedIcon />
      </IconWrapper>
    )}
    <TopButtonText>{text}</TopButtonText>
  </Button>
);
