import { gql } from '@apollo/client';

const updatePaymentChargifyV2 = gql`
  mutation updatePaymentChargifyV2(
    $captcha_signature: CaptchaSignatureInput
    $recaptcha_token: String!
    $recaptcha_token_V3: String
    $chargify_token: String!
    $billing_address: BillingAddressInput!
  ) {
    updatePaymentChargifyV2(
      captcha_signature: $captcha_signature
      recaptcha_token: $recaptcha_token
      recaptcha_token_V3: $recaptcha_token_V3
      chargify_token: $chargify_token
      billing_address: $billing_address
    )
  }
`;

export default updatePaymentChargifyV2;
