import React from 'react';
import PropTypes from 'prop-types';

import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

import { ThemeProvider, createTheme } from '@mui/material/styles';

import COLORS from '../../../themes/colors';
import RadioGroupWrapper from './style';

const MUICreateTheme = createTheme({
  colors: COLORS,
  breakpoints: {
    lg: 1024,
  },
});

const RadioGroup = ({
  currentValue,
  ariaLabel,
  name,
  handleOnChange,
  radioData,
  disabled = false,
  id,
}) => (
  <ThemeProvider theme={MUICreateTheme}>
    <RadioGroupWrapper
      id={id}
      row
      aria-label={ariaLabel}
      name={name}
      value={currentValue}
      onChange={handleOnChange}
    >
      {radioData &&
        radioData.map((data) => (
          <FormControlLabel
            key={data.value}
            value={data.value}
            control={<Radio />}
            label={data.label}
            disabled={disabled || data.disabled}
          />
        ))}
    </RadioGroupWrapper>
  </ThemeProvider>
);

RadioGroup.defaultProps = {
  currentValue: '',
  ariaLabel: '',
  name: '',
  handleOnChange: () => {},
  radioData: [
    {
      value: '',
      label: '',
    },
  ],
};

RadioGroup.propTypes = {
  currentValue: PropTypes.string,
  ariaLabel: PropTypes.string,
  name: PropTypes.string,
  handleOnChange: PropTypes.func,
  radioData: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
};

export default RadioGroup;
