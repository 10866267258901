import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';

interface Props {
  title?: string | null;
  description?: string | null;
  imageSrc?: string | null;
  imageAlt?: string | null;
  type?: string | null;
}

const Metadata: FC<Props> = ({ title, description, imageSrc, imageAlt, type }) => {
  const data = useStaticQuery<Queries.GetMetadataQuery>(graphql`
    query GetMetadata {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <Helmet
      titleTemplate={`%s | ${data.site?.siteMetadata?.title}`}
      title={title}
      defaultTitle={data.site?.siteMetadata?.title ?? ''}
    >
      <title>{title}</title>

      {/* html attributes */}
      <html lang="en" />

      {/* multiple meta elements */}
      <meta name="robots" content="noindex,nofollow" />
      {description && <meta name="description" content={description} />}
      <meta name="og:title" content={title} />
      {description && <meta name="og:description" content={description} />}
      {imageSrc && <meta name="og:image" content={imageSrc} />}
      {imageAlt && <meta name="og:image:alt" content={imageAlt} />}
      <meta name="og:type" content={type ?? 'website'} />
      <meta name="og:site_name" content={data.site?.siteMetadata?.title ?? ''} />
    </Helmet>
  );
};

Metadata.defaultProps = {
  description: undefined,
};

export default Metadata;
