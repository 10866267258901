import { graphql, useStaticQuery } from 'gatsby';

const useStaticLearnQuery = () =>
  useStaticQuery<Queries.LearnQuery>(graphql`
    query Learn {
      prismicArticleHub {
        _previewable
        id
        data {
          page_title {
            text
          }
          page_description {
            text
          }
          page_image {
            gatsbyImageData
            alt
          }
          page_heading {
            text
          }
          page_subheading {
            richText
          }
          page_header_image {
            gatsbyImageData(width: 1200)
            alt
          }
          body {
            ... on PrismicArticleHubDataBodyArticlesSection {
              ...ArticlesSection
            }
            ... on PrismicArticleHubDataBodyContrastArticlesSection {
              ...ContrastArticlesSection
            }
          }
        }
      }
    }

    fragment ArticlesSection on PrismicArticleHubDataBodyArticlesSection {
      id
      slice_type
      primary {
        heading {
          text
        }
      }
      items {
        article {
          document {
            ... on PrismicLearnPage {
              ...ArticleHubLearnPage
            }
          }
        }
      }
    }

    fragment ContrastArticlesSection on PrismicArticleHubDataBodyContrastArticlesSection {
      id
      slice_type
      primary {
        contrast_heading {
          text
        }
        contrast_subheading {
          richText
        }
      }
      items {
        contrast_article {
          document {
            ... on PrismicLearnPage {
              ...ArticleHubLearnPage
            }
          }
        }
      }
    }

    fragment ArticleHubLearnPage on PrismicLearnPage {
      id
      url
      data {
        display_image {
          alt
          gatsbyImageData
        }
        meta_description
        title {
          text
        }
      }
      first_publication_date
    }
  `);

export default useStaticLearnQuery;
