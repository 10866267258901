// TODO: refactor this component - too many logics in one component
import React, { useState, useEffect, useContext, useRef } from 'react';
import { Button } from '@lesmills-international/components';
import { Auth } from '@aws-amplify/auth';
import { navigate } from 'gatsby';
import { RichText } from 'prismic-reactjs';
/* eslint-disable import/no-relative-packages */
import { Product } from '@src/type/Product';
import { parseJwt } from '@src/utils';
import { TIERS } from '@constants';
import { userContext } from '../../../../../gatsby-theme-engagement/src/context';
import { addErrorInDatadogRum } from '../../../utils/utilities';
import { normalizeTieringPriceData } from '../../../utils/normalizePriceData';
import { createClient, createPublicClient } from '../../../services/client';
import getValidProducts from '../../../graphql/getValidProducts';
import { normalizePlans } from '../../signup/selectPlan/normalizePlansPromotions';
import changeSubscriptionChargifyJs from '../../../graphql/changeSubscriptionChargifyJs';
import normalizeServerErrorMsg from '../../../utils/normalizeServerErrorMsg';
import SubscriptionManagementLayout from '../../common/subscriptionManagementLayout';
import CurrentPaymentInfo from '../currentPaymentInfo';
import AvailableTieringSubscriptions from '../availableTieringSubscriptions';
import SuccessMsg from '../successMsg';
import Alert from '../../common/alert';
import WarningAlert from '../warningAlert';
import WaitingTime from '../../../constants/refreshTokenWaitingTime';
import {
  WrapperTiering,
  TermsCondition,
  Divider,
  PaymentWrapper,
  PricingCardButtonContainer,
} from './style';
import ProcessingMsg from '../../common/ProcessingMsg/ProcessingMsg';
import { redirectToMyAccount } from '../../../../../../src/utils/utilities';
import {
  SignupChannel,
  SubscriptionType,
  getSubscriptionType,
  isSubscriptionOnHold,
} from '../../../utils/subscriptions';
import { SUBSCRIPTION_STATES } from '../../../constants/subscription';
import RolloverPreference from '../rolloverPreference';

const ChangeSubscriptionTiering = ({ pageData, location }) => {
  const {
    chargifyPaymentProfile,
    paymentMethod,
    lmodSubscription,
    addressCountry,
    type,
    signupChannel,
  } = useContext(userContext);
  const currentPaymentInfo = chargifyPaymentProfile || paymentMethod;

  const [availablePlans, setAvailablePlans] = useState<Array<Product> | null>(null);
  const [countryCode, setCountryCode] = useState<string>('');
  const [selectedPlan, setSelectedPlan] = useState<Product>({} as Product);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [changeSubscriptionProcessing, setChangeSubscriptionProcessing] = useState<boolean>(false);
  const [changeSubscriptionSuccess, setChangeSubscriptionSuccess] = useState<boolean>(false);
  const [terms, setTerms] = useState<string>('');
  const [serverError, setServerError] = useState<string>('');
  const [diffRegionError, setDiffRegionError] = useState<boolean>(false);
  const [needNewToken, setNeedNewToken] = useState<boolean>(false);
  const [waitingTime, setWaitingTime] = useState<number>(WaitingTime.noWaiting);

  const newAccountPageEnabled = process.env.GATSBY_RT_19_02_2024_ACCOUNT_OVERVIEW === 'true';

  const availablePlansRef = useRef(null);
  const warningAlertRef = useRef(null);

  const urlParams = new URLSearchParams(location.search);
  const isFromApp = urlParams.get('from') === 'app';
  const preSelectedProduct = lmodSubscription?.product_handle;
  const disableChangeSubscriptionBtn =
    !selectedPlan?.id || preSelectedProduct === selectedPlan.product_handle;
  const subscriptionState = lmodSubscription?.state;

  const {
    prismicLesMillsPlusCommonLandingPage,
    prismicLesMillsPlusTieringLandingPage,
    prismicChangeSubscriptionPage,
    prismicSignupChannels,
  } = pageData;

  const signupChannelList = prismicSignupChannels?.data?.signup_channel;

  const subscriptionType = getSubscriptionType({
    subscription: lmodSubscription,
    type: type as SubscriptionType,
    userSignupChannel: signupChannel,
    signupChannelList: signupChannelList as unknown as SignupChannel[],
  });

  const isActive =
    subscriptionState === SUBSCRIPTION_STATES.ACTIVE ||
    subscriptionState === SUBSCRIPTION_STATES.TRIALING;

  const isOnHold = isSubscriptionOnHold(lmodSubscription?.state);

  const showRolloverPreference =
    !['IAP', 'RESELLER'].includes(subscriptionType) && isActive && newAccountPageEnabled;

  const changeSubscriptionPagePrismicData = prismicChangeSubscriptionPage?.data;
  const planCardPrismicData = normalizeTieringPriceData(
    prismicLesMillsPlusTieringLandingPage,
    prismicLesMillsPlusCommonLandingPage
  );

  /* eslint-disable @typescript-eslint/naming-convention */
  const {
    title,
    warning_text,
    success_message_title,
    change_subscription_success_message_description,
    change_subscription_button_text,
    term_and_condition_with_free_trial,
    term_and_condition_with_billed_immediately,
    term_and_condition_with_billed_next_cycle,
    loading_text,
    loading_process_text,
    get_started_button_text,
    error_text_diff_region,
    change_subscription_subtitle,
  } = changeSubscriptionPagePrismicData;

  useEffect(() => {
    try {
      const getDefaultSelectedPlan = (displayPlans: Array<Product>) => {
        let defaultSelectedPlan = {} as Product;
        if (preSelectedProduct) {
          const filteredPlans = displayPlans.filter(
            (plan) => plan.product_handle === preSelectedProduct
          );

          // eslint-disable-next-line prefer-destructuring
          if (filteredPlans?.length > 0) defaultSelectedPlan = filteredPlans[0];
        }
        return defaultSelectedPlan;
      };
      const getValidProductsQuery = async () => {
        const prices = await createPublicClient.query({
          query: getValidProducts,
          variables: {},
        });
        const normalizedPlans = normalizePlans(prices, 'tier');
        setCountryCode(prices.data.getHeaders.countryCode);
        setAvailablePlans(normalizedPlans);
        setSelectedPlan(getDefaultSelectedPlan(normalizedPlans));
      };
      getValidProductsQuery();
    } catch (error) {
      addErrorInDatadogRum(error);
    }
  }, [preSelectedProduct]);

  useEffect(() => {
    if (preSelectedProduct && preSelectedProduct.includes('3monthly') && warningAlertRef?.current) {
      warningAlertRef?.current?.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    } else if (availablePlansRef?.current) {
      availablePlansRef?.current?.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }
  }, [availablePlans, preSelectedProduct]);

  const currentTier = lmodSubscription?.product?.tier?.internal_tier_id?.toLowerCase();
  const showThreeMonthly = currentTier !== TIERS.BASE;

  useEffect(() => {
    const targetTier = selectedPlan?.tier?.internal_tier_id.toLowerCase();
    const isTrial = lmodSubscription?.state?.toLowerCase() === 'trialing';

    // 1 Check if need refresh JWT token
    // for trial users, refresh token when there is tier change, base -> premium or premium -> base
    // for paid users, refresh token only when base -> premium
    if (isTrial) {
      setNeedNewToken(currentTier !== targetTier);
    } else {
      setNeedNewToken(currentTier === TIERS.BASE && targetTier === TIERS.PREMIUM);
    }

    // 2 Display different copies under pricing card when the users select different plans
    // 2.1 Do not display any copy if users select their current plan or no plan is selected
    if (disableChangeSubscriptionBtn) {
      setTerms('');
      return;
    }
    // 2.2 Display term_and_condition_with_free_trial when the users still under trial period
    if (isTrial) {
      setTerms(term_and_condition_with_free_trial);
      return;
    }
    // 2.3 Display term_and_condition_with_billed_immediately when the users change plan from base to premium
    if (currentTier === TIERS.BASE && targetTier === TIERS.PREMIUM) {
      setTerms(term_and_condition_with_billed_immediately);
      return;
    }
    // 2.4 For other condition, display term_and_condition_with_billed_next_cycle
    setTerms(term_and_condition_with_billed_next_cycle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTier, selectedPlan]);

  useEffect(() => {
    if (waitingTime === WaitingTime.noWaiting) return;
    if (waitingTime > WaitingTime.maxWaitingTime) navigate('/404');

    const refreshToken = async () => {
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const currentSession = await Auth.currentSession();
        cognitoUser.refreshSession(currentSession.getRefreshToken(), (err, session) => {
          if (err) {
            addErrorInDatadogRum(err);
            navigate('/404');
          }
          const userInfoJwt = parseJwt(session?.idToken?.jwtToken);
          const tierIdInToken = userInfoJwt?.tierId;
          const tierIdInSelectedPlan = selectedPlan?.tier?.tier_id;
          if (tierIdInToken && tierIdInToken === tierIdInSelectedPlan) {
            localStorage.setItem('jwtToken', session?.idToken?.jwtToken);
            setChangeSubscriptionProcessing(false);
            setChangeSubscriptionSuccess(true);
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
          } else {
            setWaitingTime(waitingTime * 2);
          }
        });
      } catch (error) {
        addErrorInDatadogRum(error);
        navigate('/404');
      }
    };

    setTimeout(() => {
      refreshToken();
    }, waitingTime);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waitingTime]);

  const handleChangeSubscription = async () => {
    try {
      setIsProcessing(true);
      const res = await createClient.mutate({
        mutation: changeSubscriptionChargifyJs,
        variables: {
          product_handle: selectedPlan?.id,
        },
      });
      if (res?.data?.changeSubscriptionChargifyJs) {
        setIsProcessing(false);
        // Only display loading page when needNewToken is true
        if (needNewToken) {
          setChangeSubscriptionProcessing(true);
          setWaitingTime(WaitingTime.minWaitingTime);
        } else {
          setChangeSubscriptionSuccess(true);
        }
      }
    } catch (error) {
      const isDiffRegion = addressCountry?.toLowerCase() !== countryCode;
      if (isDiffRegion) {
        setDiffRegionError(true);
      } else {
        setServerError(normalizeServerErrorMsg(error));
      }
      addErrorInDatadogRum(error);
    } finally {
      setIsProcessing(false);
    }
  };

  const warningTexts = warning_text && warning_text.split('{planName}');

  return (
    <SubscriptionManagementLayout
      theme="white"
      title={changeSubscriptionSuccess || changeSubscriptionProcessing ? '' : title}
      backLink={
        !isFromApp && !changeSubscriptionProcessing && !changeSubscriptionSuccess
          ? { handleClick: () => redirectToMyAccount() }
          : null
      }
      subTitle={
        !newAccountPageEnabled
          ? changeSubscriptionSuccess || changeSubscriptionProcessing
            ? ''
            : change_subscription_subtitle
          : ''
      }
      size="medium"
    >
      {changeSubscriptionSuccess && (
        <SuccessMsg
          title={success_message_title}
          description={change_subscription_success_message_description}
          buttonText={get_started_button_text}
          onButtonClick={() => {
            window.location.href = '/';
          }}
        />
      )}
      {changeSubscriptionProcessing && needNewToken && (
        <ProcessingMsg loadingMsg={loading_text} loadingProcessMsg={loading_process_text} />
      )}
      {serverError && (
        <Alert content={serverError} style={{ width: '100%', marginBottom: '20px' }} />
      )}
      {diffRegionError && (
        <Alert style={{ width: '100%', marginBottom: '20px' }}>
          <RichText render={error_text_diff_region.richText} />
        </Alert>
      )}
      {preSelectedProduct && preSelectedProduct.includes('3monthly') && !showThreeMonthly && (
        <div ref={warningAlertRef}>
          <WarningAlert>
            <span>
              {warningTexts[0] || ''}
              <span className="warning-highlight">{lmodSubscription?.product?.name}</span>
              {warningTexts[1] || ''}
            </span>
          </WarningAlert>
        </div>
      )}
      {!changeSubscriptionSuccess && !changeSubscriptionProcessing && (
        <WrapperTiering ref={availablePlansRef}>
          {availablePlans && lmodSubscription?.product_handle && (
            <AvailableTieringSubscriptions
              displayOnlyCurrentPlan={isOnHold}
              changeSubscriptionPagePrismicData={changeSubscriptionPagePrismicData}
              planCardPrismicData={planCardPrismicData}
              countryCode={countryCode}
              availablePlans={availablePlans}
              currentProductHandle={lmodSubscription?.product_handle}
              getSelectedPlan={(plan) => {
                setSelectedPlan(plan);
              }}
              selectedPlan={selectedPlan}
              showThreeMonthly={showThreeMonthly}
            />
          )}

          {!isOnHold && (
            <>
              <TermsCondition>
                <p>{terms}</p>
              </TermsCondition>
              <PricingCardButtonContainer>
                <Button
                  style={{
                    marginBottom: '0px',
                  }}
                  ctaButton
                  loading={isProcessing}
                  onClick={handleChangeSubscription}
                  disabled={disableChangeSubscriptionBtn}
                >
                  <span>{change_subscription_button_text}</span>
                </Button>
              </PricingCardButtonContainer>
            </>
          )}

          <Divider />
          <PaymentWrapper>
            {currentPaymentInfo && (
              <CurrentPaymentInfo
                prismicData={changeSubscriptionPagePrismicData}
                paymentInfo={currentPaymentInfo}
                selectedProductHandle={selectedPlan?.product_handle}
                urlParam={urlParams.toString()}
                isBorderTop
              />
            )}

            {showRolloverPreference && (
              <RolloverPreference prismicData={prismicChangeSubscriptionPage?.data} />
            )}
          </PaymentWrapper>
        </WrapperTiering>
      )}
    </SubscriptionManagementLayout>
  );
};

export default ChangeSubscriptionTiering;
