import { ReactNode } from 'react';

export type ButtonType = {
  label: string;
  action: () => void;
};

export interface ProductInformation {
  currency?: string;
  description?: string;
  name?: string;
  product_handle?: string;
  country_code?: string;
  product_price_point?: {
    interval?: number;
    tax_included: boolean;
    interval_unit?: string;
    price_in_cents?: number;
    price_point_id?: number;
    product_handle?: string;
    trial_interval?: number;
    trial_interval_unit?: string;
    trial_price_in_cents?: number;
  };
  tier?: {
    external_entity_id?: string;
    internal_tier_id?: string;
    tier_id?: string;
    tiered_entity_enabled: boolean;
    tiered_entity_type?: string;
  };
}

export interface MapPricingData {
  productInformation: ProductInformation;
  promotionInformation?: PromoInformation;
  countryCode?: string;
  isTypeProductChange?: boolean;
}

export interface PrismicFrequencyData {
  monthly_text: string;
  three_monthly_text: string;
  annually_text: string;
}

export interface TextTranslation {
  description?: string | null;
  billingPeriod: string;
  amount: string;
}

export interface SuccessMessageProps {
  title: string;
  description: ReactNode;
  primaryButton: ButtonType;
  secondaryButton: ButtonType;
  hasIcon: boolean;
}

export interface MapSuccessMessageItem {
  defualtTitle?: string | null;
  defaultDescription?: string | null;
  cancellationTitle?: string | null;
  cancellationDescription?: ReactNode | null;
  primaryButtonText?: string | null;
  secondaryButtonText?: string | null;
}

export enum PromoErrorGroupType {
  INVALID_PROMO_ERRROR = 'INVALID_PROMO_ERRROR',
  PROMO_REDEMPTION_ERROR = 'PROMO_REDEMPTION_ERROR',
  ACCOUNT_INELIGIBLE_ERROR = 'ACCOUNT_INELIGIBLE_ERROR',
}

export enum IsFrom {
  MY_ACCOUNT_PAGE = 'my_account_page',
  CANCELLATION = 'cancellation',
  HOME_PAGE = 'homepage',
}

export enum SuccessType {
  OFFER_REDEMPTION_SUCCESS = 'offer_redemption_success',
  CANCELLATION_SUCCESS = 'cancellation_success',
}

export enum SubscriptionState {
  CANCELED = 'canceled',
  ON_DELAYED_CANCELLATION = 'on-delayed-cancellation',
}
