import Auth from '@aws-amplify/auth';
import * as Sentry from '@sentry/gatsby';
import { getLocalStorage } from './utilities';

const captureException = async (err) => {
  const userInfo = await JSON.parse(getLocalStorage('user-info'));

  const sentryCapture = (error, email) =>
    Sentry.captureException(
      {
        action: error.action,
        errorType: error.errorType || error.code || '',
        errorInfo:
          (error.errorInfo && error.errorInfo.message
            ? error.errorInfo.message
            : error.errorInfo) ||
          error.name ||
          '',
        message: error.message || '',
        email,
        data: error.data || JSON.stringify(error),
        requestVariables: error.requestVariables || {},
      },
      (scope) => {
        // Add more tags for filtering the error
        scope.clear();
        scope.setTag('action', error.action);
        scope.setTag('error.message', error.message);
        return scope;
      }
    );

  let userEmail = (userInfo && userInfo.email ? userInfo.email : err.email) || '';
  if (!userEmail) {
    // Get from currentAuthenticatedUser
    const user = await Auth.currentAuthenticatedUser();
    userEmail = user && user.attributes ? user.attributes.email : '';
  }
  if (err instanceof Error) {
    sentryCapture(err, userEmail);
  } else {
    const sentryError = new Error(err.message);
    Object.assign(sentryError, err);

    sentryCapture(sentryError, userEmail);
  }
};

export default captureException;
