import React from 'react';
import { PageProps, graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import { withPrivateRoute } from '../../../gatsby-theme-engagement/src/components/molecules';
import AccountDetails from '../components/accountDetails';

const AccountDetailsTemplate = ({ data }: PageProps<Queries.GetAccountDetailsQuery>) => (
  <AccountDetails
    pageData={data?.prismicAccountDetailsPage?.data as Queries.PrismicAccountDetailsPageData}
  />
);

export default withPrismicPreview(withPrivateRoute(AccountDetailsTemplate, true));

export const query = graphql`
  query GetAccountDetails($lang: String!) {
    prismicAccountDetailsPage(lang: { eq: $lang }) {
      _previewable
      lang
      data {
        edit_personal_details_title
        first_name_field_label
        last_name_field_label
        email_field_label
        edit_personal_details_submit_button_text
        change_password_title
        current_password_field_label
        new_password_field_label
        change_password_submit_button_text
        update_personal_information_success
        update_email_success
        update_name_success
        change_password_success
        existing_email_error
        email_required
        name_required
        password_required
        current_password_required
        email_invalid
        password_invalid
        password_space_error
        first_name_required
        last_name_required
        invalid_password_exception
        not_authorized_exception
        personal_profile_server_error
        change_password_server_error
      }
    }
  }
`;
