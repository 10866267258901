import { gql } from '@apollo/client';

const PROGRAM_DETAIL = gql`
  query ProgramDetail($programId: String!, $nextToken: ID, $pageSize: Int) {
    program(programId: $programId) {
      workoutGroups(pageSize: $pageSize, nextToken: $nextToken) {
        items {
          id
          tiers
          name
          content {
            items {
              name
              imageWide
              durationInSeconds
              completionCount
              tiers
              vimeoWebPageUrl
              categories
              releaseNumber
              equipment
              filterTags
              language
              publishDate
              tracks {
                presenters
              }
            }
            nextToken
          }
          isFavorite
          imageWide
          programName
          releaseNumber
          languages
        }
        nextToken
      }
    }
  }
`;

export default PROGRAM_DETAIL;
