export const PAYMENT_METHOD = {
  CREDITCARD: 'creditcard',
  PAYPAL: 'paypal',
};

export const CHARGIFY_SCRIPT = {
  type: 'text/javascript',
  src: 'https://js.chargify.com/latest/chargify.js',
};

export const countriesDisabledPaypal = ['ae', 'br', 'kr', 'in', 'my', 'za'];
