import React, { useState, useEffect } from 'react';
import { VideoCard } from '@lesmills-international/components';
import { Grid } from '@mui/material';
import { debounce } from 'lodash';
import { CardGridWrapper, WorkoutResultsAmount } from './styles';

interface VideoCardItem {
  name: string;
  backgroundImg: string;
  duration: string;
  equipmentIcons: string[];
  categories: string[];
  teaseText: string;
  onClick: () => void;
}

const GRID_BREAKPOINTS = [
  {
    width: 319,
    gridSize: 12,
    padding: '16px',
  },
  {
    width: 599,
    gridSize: 6,
    padding: '32px',
  },
  {
    width: 799,
    gridSize: 4,
    padding: '32px',
  },
  {
    width: 1023,
    gridSize: 3,
    padding: '80px',
  },
  {
    width: 1439,
    gridSize: 2.4,
    padding: '80px',
  },
  {
    width: 1799,
    gridSize: 2,
    padding: '80px',
  },
];

const getGridInfo = (width: number) => {
  let breakPointIndex = 0;
  GRID_BREAKPOINTS.forEach((breakPoint, index) => {
    if (breakPoint.width < width) breakPointIndex = index;
  });
  return GRID_BREAKPOINTS[breakPointIndex];
};

const VideoCardGrid = ({
  content,
  additionalChild,
  wrapperPaddingBottom = '0px',
  isFilterActive,
  displayResultsAmount = false,
  isDarkBackground = false,
}: {
  content: VideoCardItem[];
  additionalChild?: React.ReactNode;
  wrapperPaddingBottom?: string;
  isFilterActive?: boolean;
  displayResultsAmount?: boolean;
  isDarkBackground?: boolean;
}) => {
  const [currentWidth, setCurrentWidth] = useState(window.innerWidth);
  useEffect(() => {
    const resizeInterval = 100;
    const handleResize = debounce(() => setCurrentWidth(window.innerWidth), resizeInterval);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const { gridSize, padding } = getGridInfo(currentWidth);
  return (
    <CardGridWrapper
      padding={padding}
      wrapperPaddingBottom={wrapperPaddingBottom}
      isDarkBackground={isDarkBackground}
    >
      {displayResultsAmount && (
        <WorkoutResultsAmount>
          {`${content?.length} workout${content?.length === 1 ? '' : 's'} ${
            isFilterActive ? 'found' : ''
          }`}
        </WorkoutResultsAmount>
      )}
      <Grid container spacing={2} role="list">
        {content?.map((i) => (
          <Grid item xs={gridSize} key={i.name} role="listitem">
            <VideoCard {...i} />
          </Grid>
        ))}
      </Grid>
      {additionalChild && additionalChild}
    </CardGridWrapper>
  );
};

export default VideoCardGrid;
