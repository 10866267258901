/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { FC, useContext, useEffect, useState, useRef } from 'react';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { navigate, graphql, PageProps } from 'gatsby';
import { Auth } from '@aws-amplify/auth';
import {
  EquipmentIcon,
  AvatarInitial,
  CommentItem,
  BackButton,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from '@lesmills-international/components';

import {
  Layout,
  withPrivateRoute,
  UpgradeTier,
  WorkoutTrackItem,
  PostComment,
} from '@components/molecules';
import { ShowMore } from '@components/atoms';
import { useGetWorkoutByNameOnlyQuery } from '@backend';
import { TIERS } from '@constants';
import { userContext } from '@context';
import { contentViewTracking, pushTracking } from '@src/utils';
import { Event, Params } from '@src/type';
import type { WorkoutTrackItemProps } from '@components/molecules/workoutTrackItem';
import ShowMoreWrapper from '../../components/atoms/showMore/style';
import { convertToRelativeTime, addErrorInDatadogRum } from '../../utils/utilities';
import { createEngagementClient, createClient } from '../../../../../src/services/client';
import {
  GET_COMMENTS,
  CREATE_COMMENT,
  REPORT_COMMENT,
  DELETE_COMMENT,
} from '../../engagementGql/comments';
import UPDATE_WORKOUT_LIST from '../../engagementGql/mylist';
import { ReactComponent as ReplyIcon } from '../../assets/icons/reply.svg';
import { ReactComponent as ThreeDotsIcon } from '../../assets/icons/three_dots.svg';
import { ReactComponent as ReportIcon } from '../../assets/icons/report.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { PRESENTER_AVATAR } from '../../constants';

import {
  VimeoWrapper,
  VideoTitle,
  WorkoutWrapper,
  Description,
  DescriptionText,
  ContentWrapper,
  PageWrapper,
  SectionWrapper,
  Title,
  ContentContainer,
  EquipmentItem,
  InstructorItem,
  TrackContentContainer,
  ShowMoreContainer,
  NoEquipmentText,
  CommentsWrapper,
  PostCommentWrapper,
  NoCommentsWrapper,
  NoCommentsText,
  NoCommentsTitle,
  TopButtonsWrapper,
  MyListButton,
  RepliedCommentWrapper,
  OptionsWrapper,
  DropdownMenu,
  MenuItem,
  DialogWrapper,
  Spacer,
  SpacerLeft,
  DeleteIconWrapper,
} from '../../styles/workoutPageStyles';
import {
  vimeoUserIdentity,
  trackVideoStartedEvent,
  trackVideoCompletedEvent,
  trackVideoPausedEvent,
  trackVideoBufferStartedEvent,
  trackVideoBufferCompletedEvent,
  trackVideoSeekCompletedEvent,
  trackVideoSeekStartedEvent,
  trackVideoResumed,
  trackVideoContentPlaying,
} from '../../utils/segment/segment';

export interface WorkoutPageData {
  equipment_title: string;
  no_equipment_text: string;
  instructor_title: string;
  track_title: string;
  moves_title: string;
  presenter_title: string;
  minute_text: string;
  music_recording_text: string;
  show_more_text: string;
  show_less_text: string;
  cancel_text: string;
  input_placeholder_text: string;
  comments_text: string;
  post_comment_text: string;
  no_comments_title: string;
  no_comments_text: string;
  end_of_comments: string;
  add_to_list: string;
  remove_from_list: string;
}

interface TrackingPercentagesReached {
  [key: number]: boolean;
}

export const getInstructorsWithAvatar = (
  instructorTeam: string[],
  displayAllInstructors: boolean
): JSX.Element[] => {
  const instructorsWithAvatar: string[] = [];
  const instructorsWithoutAvatar: string[] = [];

  instructorTeam.forEach((item) => {
    const instructorAvatarUrl = PRESENTER_AVATAR.find(
      (avatar) => avatar?.name.toLowerCase() === item?.toLowerCase()
    )?.image;

    if (instructorAvatarUrl) {
      instructorsWithAvatar.push(item);
    } else {
      instructorsWithoutAvatar.push(item);
    }
  });

  instructorsWithAvatar.sort();
  instructorsWithoutAvatar.sort();

  const sortedInstructors: string[] = [...instructorsWithAvatar, ...instructorsWithoutAvatar];

  const firstFiveInstructors =
    sortedInstructors && sortedInstructors?.length > 5
      ? sortedInstructors.slice(0, 5)
      : sortedInstructors;

  const instructorsToShow = displayAllInstructors ? sortedInstructors : firstFiveInstructors;

  return instructorsToShow.map((item) => {
    const defaultAvatar = <AvatarInitial name={item} />;
    const instructorAvatarUrl = PRESENTER_AVATAR.find(
      (avatar) => avatar?.name.toLowerCase() === item?.toLowerCase()
    )?.image;

    return (
      <InstructorItem key={item} role="listitem">
        {instructorAvatarUrl ? (
          <img
            width="40px"
            height="40px"
            src={instructorAvatarUrl}
            alt="workout-presenter-avatar"
          />
        ) : (
          defaultAvatar
        )}
        {item}
      </InstructorItem>
    );
  });
};

const Component = ({
  workoutName,
  workoutPageData,
}: {
  workoutName: string;
  workoutPageData: WorkoutPageData;
}) => {
  const { loading, error, data } = useGetWorkoutByNameOnlyQuery({
    variables: { workoutName: workoutName ?? '' },
    skip: !workoutName,
  });

  const isError = !data || !!error;
  const { embedUrl, title, description } = data?.workoutByNameOnly?.vimeoWorkoutData || {};
  const workoutTiers = data?.workoutByNameOnly?.tiers;
  const equipments = data?.workoutByNameOnly?.equipment;
  const instructorTeam = data?.workoutByNameOnly?.instructorTeam;
  const tracksData = data?.workoutByNameOnly?.tracks;
  const programName = data?.workoutByNameOnly?.programName;
  const duration = data?.workoutByNameOnly?.assets.items[0].duration;

  const [isProcessing, setIsProcessing] = useState(false);
  const [videoId, setVideoId] = useState('');
  const [comments, setComments] = useState([]);
  const pageSize = 20;
  const [nextToken, setNextToken] = useState(null);
  const [loadingNextPage, setLoadingNextPage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleCommentsQuery = () => {
    createEngagementClient
      .query({
        query: GET_COMMENTS,
        variables: {
          videoId,
          pageSize,
        },
      })
      .then((res) => {
        if (res) {
          const { items, nextToken: firstToken } = res.data.comments;
          setComments(items);
          setNextToken(firstToken);
        }
      })
      .catch((err) => {
        addErrorInDatadogRum(err);
      });
  };

  useEffect(() => {
    const pattern = /\/videos\/(\d+)\?/;

    const match = embedUrl?.match(pattern);

    if (match) setVideoId(match[1]);

    if (videoId !== '') {
      handleCommentsQuery();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [embedUrl, videoId]);

  const handleLoadMore = async () => {
    setLoadingNextPage(true);
    if (!nextToken) return;

    try {
      const newData = await createEngagementClient.query({
        query: GET_COMMENTS,
        variables: {
          videoId,
          pageSize,
          nextToken,
        },
      });
      setNextToken(newData.data.comments.nextToken);
      const newItems = newData.data.comments.items;
      setComments((prevComments) => [...prevComments, ...newItems]);
    } catch (loadmoreError) {
      addErrorInDatadogRum(loadmoreError);
    } finally {
      setLoadingNextPage(false);
    }
  };

  const handleScroll = () => {
    if (window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 200) {
      if (!loadingNextPage) handleLoadMore();
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextToken, loadingNextPage]);

  useEffect(() => {
    if (workoutName && programName) {
      contentViewTracking(workoutName, 'video', programName, `Workout Page - ${workoutName}`);
    }
  }, [programName]);

  const {
    equipment_title,
    no_equipment_text,
    instructor_title,
    track_title,
    show_more_text,
    show_less_text,
    cancel_text,
    input_placeholder_text,
    post_comment_text,
    comments_text,
    no_comments_title,
    no_comments_text,
    end_of_comments,
    add_to_list,
    remove_from_list,
  } = workoutPageData;

  const { tier, givenName, familyName, workoutFavorites, vimeoUserId } = useContext(userContext);
  const [displayedAllTracks, setDisplayedAllTracks] = useState(false);
  const [displayAllInstructors, setDisplayAllInstructors] = useState(false);
  const [displayedTrackData, setDisplayedTrackData] = useState([]);
  const [showDescriptionShowMoreBtn, setShowDescriptionShowMoreBtn] = useState(false);
  const [showFullDescription, setShowFullDescription] = useState(true);
  const [showReplyInput, setShowReplyInput] = useState(false);
  const [clickedCommentId, setClickedCommentId] = useState('');
  const [vimeoSessionId, setVimeoSessionId] = useState('');
  const [isReportDialogOpen, setIsReportDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [showThreeDotsMenu, setShowThreeDotsMenu] = useState(false);

  const matchingWorkout = workoutFavorites?.find((item) => item.workoutName === workoutName);
  const [isFavourited, setIsFavourited] = useState(!!matchingWorkout);

  const workoutDescriptionRef = useRef<HTMLDivElement>(null);
  const workoutDescriptionHeight = workoutDescriptionRef?.current?.clientHeight;

  const isInvalidData = isError || !embedUrl || embedUrl === '';
  const showTeaseMessage =
    tier === TIERS.BASE && workoutTiers?.length === 1 && workoutTiers.includes(TIERS.PREMIUM);

  const VIMEO_SCRIPT = {
    id: 'vimeoScript',
    type: 'text/javascript',
    src: 'https://cdn.vhx.tv/assets/player.js',
  };

  useEffect(() => {
    if (workoutDescriptionHeight && workoutDescriptionHeight > 72) {
      setShowDescriptionShowMoreBtn(true);
      setShowFullDescription(false);
    }
  }, [workoutDescriptionHeight]);

  useEffect(() => {
    Auth.currentSession().then((authData) => {
      const decodeJwt = authData.getAccessToken().payload;
      const authId = decodeJwt.jti;
      setVimeoSessionId(authId);
    });
    setTimeout(() => {
      if (window.VHX && !!vimeoSessionId && !!videoId && duration) {
        const video = {
          platform: 'web',
          content_asset_id: videoId,
          total_length: duration,
          position: 0,
          session_id: vimeoSessionId,
          title: workoutName,
        };
        const p = new window.VHX.Player('vimeoVideo');

        p.on('startbuffering', async () => {
          // use play to trigger play event
          await trackVideoBufferStartedEvent({ ...video, position: p.currentTime() });
        });

        p.on('endbuffering', async () => {
          // use play to trigger play event
          await trackVideoBufferCompletedEvent({ ...video, position: p.currentTime() });
        });

        p.on('play', async () => {
          if (p.currentTime() === 0) {
            pushTracking({
              event: Event.VIDEO_PLAY,
              [Params.VIDEO_TITLE]: workoutName,
              [Params.VIDEO_DURATION]: duration,
              [Params.VIDEO_PLAY_PERCENTAGE]: 0,
              [Params.VIDEO_VIMEO_ID]: vimeoUserId,
            });
          } else {
            pushTracking({
              event: Event.VIDEO_UNPAUSE,
              [Params.VIDEO_TITLE]: workoutName,
              [Params.VIDEO_PLAY_PERCENTAGE]: parseInt((p.currentTime() / duration) * 100, 10),
              [Params.VIDEO_VIMEO_ID]: vimeoUserId,
              [Params.VIDEO_CURRENT_TIME]: p.currentTime(),
              [Params.VIDEO_DURATION]: duration,
            });
          }
          // use play to trigger play event
          await trackVideoStartedEvent({ ...video, position: p.currentTime() });
        });
        const trackingPercentagesReached: TrackingPercentagesReached = {};

        p.on('timeupdate', async () => {
          if (p.currentTime() > 0 && Math.round(p.currentTime()) % 60 === 0)
            await trackVideoContentPlaying({
              ...video,
              position: p.currentTime(),
              asset_id: videoId,
            });
          const trackingPercentage = [10, 25, 50, 75];
          const videoPlayPercentage = parseInt((p.currentTime() / duration) * 100, 10);

          if (
            trackingPercentage.includes(videoPlayPercentage) &&
            !trackingPercentagesReached[videoPlayPercentage]
          ) {
            trackingPercentagesReached[videoPlayPercentage] = true;
            pushTracking({
              event: Event.VIDEO_PROGRESS,
              [Params.VIDEO_TITLE]: workoutName,
              [Params.VIDEO_PLAY_PERCENTAGE]: videoPlayPercentage,
              [Params.VIDEO_VIMEO_ID]: vimeoUserId,
              [Params.VIDEO_CURRENT_TIME]: p.currentTime(),
              [Params.VIDEO_DURATION]: duration,
            });
          }
        });

        p.on('pause', async () => {
          // use play to trigger play event
          await trackVideoPausedEvent({ ...video, position: p.currentTime() });
        });

        p.on('seeking', async () => {
          // use play to trigger play event
          await trackVideoSeekStartedEvent({ ...video, position: p.currentTime() });
        });

        p.on('seeked', async () => {
          // use play to trigger play event
          await trackVideoSeekCompletedEvent({ ...video, position: p.currentTime() });
        });

        p.on('resume', async () => {
          // use play to trigger play event
          await trackVideoResumed({ ...video, position: p.currentTime() });
        });

        p.on('ended', async () => {
          // use play to trigger end event
          await trackVideoCompletedEvent({ ...video, position: p.currentTime() });
        });
      }
    }, 5000);
  }, [vimeoSessionId, videoId, duration]);

  useEffect(() => {
    if (tracksData?.items) {
      const sortedTracksData = tracksData?.items
        ?.slice()
        .sort((a, b) => a.trackNumber - b.trackNumber);
      const first3TrackData =
        tracksData && tracksData?.items?.length > 3
          ? sortedTracksData.slice(0, 3)
          : sortedTracksData;
      setDisplayedTrackData(displayedAllTracks ? sortedTracksData : first3TrackData);
    }
  }, [displayedAllTracks, tracksData, tracksData?.items]);

  const handleOnClickTrackItem = (startTime: number) => {
    if (window && window.VHX) {
      const player = new window.VHX.Player('vimeoVideo');
      player?.seekToTime(startTime);
      player?.play();
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  if (loading) return null;

  if (isInvalidData) {
    navigate('/404', { replace: true });
  }

  const handlePostComment = async (comment: string) => {
    const newComment = {
      id: comments.length + 1,
      user: { name: `${givenName} ${familyName}` },
      timestamp: new Date().toISOString(),
      content: comment,
    };
    setComments((prevComments) => [newComment, ...prevComments]);

    try {
      await createEngagementClient.mutate({
        mutation: CREATE_COMMENT,
        variables: {
          videoId,
          content: comment,
        },
      });
    } catch (err) {
      addErrorInDatadogRum(err);
    }
  };

  const handleDeleteComment = async () => {
    setIsLoading(true);

    try {
      await createEngagementClient.mutate({
        mutation: DELETE_COMMENT,
        variables: { id: clickedCommentId },
      });
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      addErrorInDatadogRum(err);
    }
    const updatedComments = comments.filter((item) => item.id !== clickedCommentId);
    setComments(updatedComments);
    handleCloseDeleteDialog();
    setShowThreeDotsMenu(false);
  };

  const handleReportComment = async () => {
    setIsLoading(true);

    try {
      await createEngagementClient.mutate({
        mutation: REPORT_COMMENT,
        variables: { id: clickedCommentId },
      });
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      addErrorInDatadogRum(err);
    }
    handleCloseReportDialog();
    setShowThreeDotsMenu(false);
  };

  const handleReply = async (comment: string, parentId: string) => {
    const updatedComments = comments.map((parentComment) => {
      if (parentComment.id === parentId) {
        const newReply = {
          id: parentId + 1,
          user: { name: `${givenName} ${familyName}` },
          timestamp: new Date().toISOString(),
          content: comment,
        };
        return {
          ...parentComment,
          repliedComments: parentComment.isReplied
            ? [...parentComment.repliedComments, newReply]
            : [newReply],
          isReplied: true,
        };
      }
      return parentComment;
    });
    setComments(updatedComments);
    setShowReplyInput(false);

    try {
      await createEngagementClient.mutate({
        mutation: CREATE_COMMENT,
        variables: {
          videoId,
          content: comment,
          replyTo: parentId,
        },
      });
    } catch (err) {
      addErrorInDatadogRum(err);
    }
  };

  const handleShowReply = (id: string) => {
    setClickedCommentId(id);
    setShowReplyInput(!showReplyInput);
  };

  const COMMENTS_MODERATION_FF = process.env.GATSBY_RT_16_04_2024_COMMENTS_MODERATION === 'true';

  const handleShowCommentMenu = (id: string) => {
    setClickedCommentId(id);
    setShowThreeDotsMenu(!showThreeDotsMenu);
  };

  const handleOpenReportDialog = () => {
    setIsReportDialogOpen(true);
  };

  const handleCloseReportDialog = () => {
    setIsReportDialogOpen(false);
    setShowThreeDotsMenu(false);
  };

  const handleOpenDeleteDialog = () => {
    setIsDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
    setShowThreeDotsMenu(false);
  };

  const handleUpdateMylist = async () => {
    setIsProcessing(true);
    try {
      await createClient.mutate({
        mutation: UPDATE_WORKOUT_LIST,
        variables: {
          input: {
            workoutName,
            program: programName,
            condition: !isFavourited,
          },
        },
      });
      setIsProcessing(false);
      setIsFavourited(!isFavourited);
    } catch (err) {
      setIsProcessing(false);
      addErrorInDatadogRum(err);
    }
  };

  const handleCancelReply = () => {
    setShowReplyInput(false);
  };

  const handleCancelComment = () => {};

  return (
    <Layout scripts={[VIMEO_SCRIPT]}>
      <PageWrapper>
        <WorkoutWrapper>
          <TopButtonsWrapper>
            <BackButton
              onClick={() => {
                window.history.back();
              }}
              theme="dark"
            />
            {tier === TIERS.PREMIUM && (
              <MyListButton
                isFavourited={isFavourited}
                isProcessing={isProcessing}
                onClick={() => handleUpdateMylist()}
                text={isFavourited ? remove_from_list : add_to_list}
              />
            )}
          </TopButtonsWrapper>
          {showTeaseMessage ? (
            <UpgradeTier />
          ) : (
            <>
              <VimeoWrapper>
                <iframe
                  id="vimeoVideo"
                  title="Vimeo Video"
                  className="inset-0 w-full h-full"
                  src={`${embedUrl}&sharing=0`}
                  allow="autoplay; picture-in-picture; encrypted-media"
                  allowFullScreen
                />
              </VimeoWrapper>
              <ContentWrapper>
                <VideoTitle role="heading" aria-level={1}>
                  {title}
                </VideoTitle>
                <>
                  {description && (
                    <Description data-testid="Description">
                      <DescriptionText
                        ref={workoutDescriptionRef}
                        showFullDescription={showFullDescription}
                      >
                        {description}
                      </DescriptionText>
                      {showDescriptionShowMoreBtn && (
                        <ShowMore
                          showMoreText={show_more_text}
                          showLessText={show_less_text}
                          onClick={(opened) => {
                            setShowFullDescription(!opened);
                          }}
                        />
                      )}
                    </Description>
                  )}
                  <SectionWrapper>
                    <Title role="heading" aria-level={2}>
                      {equipment_title}
                    </Title>
                    <ContentContainer role="list" aria-label="Equipment list">
                      {equipments && equipments.length > 0 ? (
                        equipments.map((item: string) => (
                          <EquipmentItem role="listitem">
                            <EquipmentIcon equipmentIconName={item} />
                            {item}
                          </EquipmentItem>
                        ))
                      ) : (
                        <NoEquipmentText>{no_equipment_text}</NoEquipmentText>
                      )}
                    </ContentContainer>
                  </SectionWrapper>
                  {instructorTeam && instructorTeam.length > 0 && (
                    <SectionWrapper>
                      <Title role="heading" aria-level={2}>
                        {instructor_title}
                      </Title>
                      <ContentContainer role="list" aria-label="Instructor list">
                        {getInstructorsWithAvatar(instructorTeam, displayAllInstructors)}
                        {instructorTeam?.length > 5 && (
                          <ShowMore
                            showMoreText={show_more_text}
                            showLessText={show_less_text}
                            onClick={(opened) => {
                              setDisplayAllInstructors(!opened);
                            }}
                          />
                        )}
                      </ContentContainer>
                    </SectionWrapper>
                  )}
                  {displayedTrackData && displayedTrackData?.length > 0 && (
                    <SectionWrapper>
                      <Title role="heading" aria-level={2}>
                        {track_title}
                      </Title>
                      <TrackContentContainer role="list" aria-label="Track list">
                        {displayedTrackData?.map((item: WorkoutTrackItemProps) => (
                          <WorkoutTrackItem
                            {...item}
                            role="listitem"
                            prismicData={workoutPageData}
                            handleOnClick={handleOnClickTrackItem}
                          />
                        ))}
                        {!displayedAllTracks && tracksData?.items?.length > 3 && (
                          <ShowMoreContainer>
                            <ShowMore
                              showMoreText={show_more_text}
                              onClick={() => {
                                setDisplayedAllTracks(true);
                              }}
                              hoverState={false}
                            />
                          </ShowMoreContainer>
                        )}
                      </TrackContentContainer>
                    </SectionWrapper>
                  )}
                </>
                <SectionWrapper>
                  <Title role="heading" aria-level={2}>
                    {comments_text}
                  </Title>
                  <PostCommentWrapper>
                    <PostComment
                      author={givenName}
                      onPost={handlePostComment}
                      onCancel={handleCancelComment}
                      cancelText={cancel_text}
                      postCommentText={post_comment_text}
                      inputPlaceholderText={input_placeholder_text}
                    />
                  </PostCommentWrapper>
                  <CommentsWrapper role="listitem" aria-label="Comment list item">
                    {comments.length !== 0 ? (
                      comments.map((comment) => (
                        <>
                          <CommentItem
                            key={comment.id}
                            author={comment.user.name ? comment.user.name : 'Anonymous'}
                            content={comment.content}
                            timestamp={convertToRelativeTime(comment.createdAt)}
                          />
                          <RepliedCommentWrapper>
                            <OptionsWrapper>
                              <ShowMoreWrapper
                                hoverState
                                onClick={() => handleShowReply(comment.id)}
                              >
                                <ReplyIcon />
                                Reply
                              </ShowMoreWrapper>
                              {COMMENTS_MODERATION_FF && (
                                <ShowMoreWrapper
                                  hoverState
                                  onClick={() => handleShowCommentMenu(comment?.id)}
                                >
                                  <ThreeDotsIcon />
                                </ShowMoreWrapper>
                              )}
                            </OptionsWrapper>
                            <Spacer />
                            {clickedCommentId === comment.id && showThreeDotsMenu && (
                              <DropdownMenu>
                                <MenuItem onClick={handleOpenDeleteDialog}>
                                  <DeleteIconWrapper>
                                    <DeleteIcon />
                                  </DeleteIconWrapper>
                                  Delete
                                </MenuItem>
                                <MenuItem onClick={handleOpenReportDialog}>
                                  <ReportIcon />
                                  <SpacerLeft />
                                  Report
                                </MenuItem>
                              </DropdownMenu>
                            )}
                            {comment.isReplied &&
                              comment.repliedComments.map((reply) => (
                                <CommentItem
                                  key={reply.id}
                                  author={reply.user.name ? reply.user.name : 'Anonymous'}
                                  content={reply.content}
                                  timestamp={convertToRelativeTime(reply.createdAt)}
                                />
                              ))}
                            {clickedCommentId === comment.id && showReplyInput && (
                              <PostComment
                                author={givenName}
                                onPost={(reply) => handleReply(reply, clickedCommentId)}
                                onCancel={handleCancelReply}
                                cancelText={cancel_text}
                                postCommentText="Reply"
                                inputPlaceholderText={input_placeholder_text}
                              />
                            )}
                          </RepliedCommentWrapper>
                          <DialogWrapper>
                            <Dialog
                              open={isReportDialogOpen}
                              onClose={handleCloseReportDialog}
                              fullWidth
                              maxWidth="sm"
                            >
                              <DialogContent>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                    height: '200px',
                                  }}
                                >
                                  <span>Report this comment to Les Mills?</span>
                                </div>
                              </DialogContent>
                              <DialogActions>
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                  }}
                                >
                                  <Button
                                    variant="text"
                                    style={{ padding: 0 }}
                                    onClick={handleCloseReportDialog}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    style={{ borderRadius: '100px' }}
                                    onClick={handleReportComment}
                                    loading={isLoading}
                                  >
                                    Report
                                  </Button>
                                </div>
                              </DialogActions>
                            </Dialog>
                          </DialogWrapper>
                          <Dialog
                            open={isDeleteDialogOpen}
                            onClose={handleCloseDeleteDialog}
                            fullWidth
                            maxWidth="sm"
                          >
                            <DialogContent>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'space-between',
                                  width: '100%',
                                  height: '200px',
                                }}
                              >
                                <span>Replies to this comment will also be deleted.</span>
                              </div>
                            </DialogContent>
                            <DialogActions>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  width: '100%',
                                }}
                              >
                                <Button
                                  variant="text"
                                  style={{ padding: 0 }}
                                  onClick={handleCloseDeleteDialog}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  style={{ borderRadius: '100px' }}
                                  onClick={handleDeleteComment}
                                  loading={isLoading}
                                >
                                  Delete
                                </Button>
                              </div>
                            </DialogActions>
                          </Dialog>
                        </>
                      ))
                    ) : (
                      <NoCommentsWrapper>
                        <NoCommentsTitle>{no_comments_title}</NoCommentsTitle>
                        <NoCommentsText>{no_comments_text}</NoCommentsText>
                      </NoCommentsWrapper>
                    )}
                  </CommentsWrapper>
                  {!nextToken && comments.length !== 0 && (
                    <NoCommentsWrapper>
                      <NoCommentsText>{end_of_comments}</NoCommentsText>
                    </NoCommentsWrapper>
                  )}
                </SectionWrapper>
              </ContentWrapper>
            </>
          )}
        </WorkoutWrapper>
      </PageWrapper>
    </Layout>
  );
};

const WorkoutGetWrapper: FC<PageProps<Queries.GetWorkoutDetailPageQuery>> = ({ data, params }) => {
  const workoutPageData = data?.prismicWorkoutDetailPage?.data;
  const workoutName = params.name;

  const { vimeoUserId } = useContext(userContext);

  useEffect(() => {
    vimeoUserIdentity(vimeoUserId as string);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!workoutName) return null;

  return <Component workoutName={workoutName} workoutPageData={workoutPageData} />;
};

export default withPrismicPreview(withPrivateRoute(WorkoutGetWrapper), [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY_NAME!,
  },
]);

export const query = graphql`
  query GetWorkoutDetailPage {
    prismicWorkoutDetailPage {
      _previewable
      data {
        equipment_title
        no_equipment_text
        instructor_title
        show_less_text
        show_more_text
        track_title
        moves_title
        presenter_title
        minute_text
        music_recording_text
        cancel_text
        input_placeholder_text
        comments_text
        post_comment_text
        no_comments_title
        no_comments_text
        end_of_comments
        add_to_list
        remove_from_list
      }
    }
  }
`;
