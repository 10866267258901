import { isEqual } from 'lodash';
import { useEffect, useRef, useState } from 'react';

const usePropsChanged = (currentProps: any) => {
  const ref = useRef(currentProps);
  const [isValueChanged, setIsValueChanged] = useState(false);

  useEffect(() => {
    if (ref.current) {
      const isTheSameAsPrevious = isEqual(ref.current, currentProps);
      setIsValueChanged(!isTheSameAsPrevious);
    }
  }, [currentProps]);

  return isValueChanged;
};

export default usePropsChanged;
