import React, { FC } from 'react';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { graphql, PageProps, navigate } from 'gatsby';
import styled from 'styled-components';
import { BackButton } from '@lesmills-international/components';

import { breakpoint } from '@themes';
import { withPrivateRoute, Layout, ProgramGridWithCategoryFilters } from '@components/molecules';
import SliceZone from '@components/sliceZone';
import PageName from '../types/pageName';

const AllProgramsWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.black};
  padding-bottom: 40px;

  .pagination--wrapper {
    display: none;
  }

  .hero--slice--wrapper {
    ${breakpoint('lg')`
      height: 650px;
    `}

    ${breakpoint('xl')`
      height: 850px !important;
    `}

    ${breakpoint('xxl')`
      height: 1000px !important;
    `}
  }

  .hero--card {
    ${breakpoint('lg')`
      height: 650px;
    `}

    ${breakpoint('xl')`
      height: 850px !important;
    `}

    ${breakpoint('xxl')`
      height: 1000px !important;
    `}
  }

  .hero--slice--wrapper .arrow--prev,
  .hero--slice--wrapper .arrow--next {
    ${breakpoint('lg')`
      top: calc(540px/2 - 18px);
    `}

    ${breakpoint('xl')`
      top: calc(640px/2 - 18px);
    `}

    ${breakpoint('xxl')`
      top: calc(840px/2 - 18px);
    `}
  }

  .content--left {
    ${breakpoint('lg')`
      height: 540px;
    `}

    ${breakpoint('xl')`
    height: 640px !important;
  `}

  ${breakpoint('xxl')`
    height: 840px !important;
  `}
  }

  & .slice--container > div:nth-child(3) {
    ${breakpoint('lg')`
      padding-top: 210px;
    `}

    ${breakpoint('xl')`
      padding-top: 108px !important;
    `}

    ${breakpoint('xxl')`
      padding-top: 160px !important;
    `}
  }
`;

const ProgramsWrapper = styled.div`
  margin-left: 16px;
  margin-right: 16px;
  position: relative;
  z-index: 99;

  ${breakpoint('sm')`
    margin-left: 32px;
    margin-right: 32px;
  `}

  ${breakpoint('lg')`
    margin-left: 80px;
    margin-right: 80px;
    bottom: 122px;
  `}

  ${breakpoint('xl')`
    bottom: 222px;
  `}

  ${breakpoint('xxl')`
    bottom: 172px;
  `}
`;

const BackBtnWrapper = styled.div`
  position: absolute;
  top: 36px;
  z-index: 30;

  ${breakpoint('xs')`
    left: 16px;
  `}

  ${breakpoint('sm')`
    left: 32px;
  `}

  ${breakpoint('lg')`
    left: 80px;
  `}
`;

interface PageContextProps {
  lang: string;
}

const AllProgramsTemplate: FC<PageProps<Queries.GetAllProgramsDataQuery, PageContextProps>> = ({
  data,
}) => {
  const { prismicAllProgramsViewPage, lesMillsPlusBackend } = data || {};

  return (
    <Layout
      metaData={{
        title: prismicAllProgramsViewPage?.data?.meta_title,
        description: prismicAllProgramsViewPage?.data?.meta_description,
      }}
    >
      <AllProgramsWrapper>
        <BackBtnWrapper>
          <BackButton
            onClick={() => {
              navigate('/explore');
            }}
            theme="dark"
          />
        </BackBtnWrapper>
        <SliceZone
          slices={prismicAllProgramsViewPage?.data?.body}
          pageName={PageName.ALL_PROGRAMS}
        />
        <ProgramsWrapper>
          <ProgramGridWithCategoryFilters listPrograms={lesMillsPlusBackend?.listPrograms?.items} />
        </ProgramsWrapper>
      </AllProgramsWrapper>
    </Layout>
  );
};

export default withPrismicPreview(withPrivateRoute(AllProgramsTemplate));

export const query = graphql`
  query GetAllProgramsData($lang: String!) {
    prismicAllProgramsViewPage(lang: { eq: $lang }) {
      data {
        meta_description
        meta_title
        body {
          ... on PrismicAllProgramsViewPageDataBodyExploreHeroCarousel {
            id
            slice_type
            items {
              image {
                url
              }
              promo_text
              promo_color
              show_promo
              main_text
              tag
              lead_text
              desc_text
              display_equipment_section
              equipment_bench_step
              equipment_foam_roller
              equipment_massage_ball
              equipment_mat
              equipment_mini_trampolim
              equipment_resistance_band
              equipment_stationary_bike
              equipment_weights
              button_secondary_text
              button_primary_text
              button_primary {
                url
              }
              button_secondary {
                url
              }
            }
          }
        }
      }
    }
    lesMillsPlusBackend {
      listPrograms {
        items {
          categories
          alternateName
          imageWide
          name
          icon
          tiers
        }
      }
    }
  }
`;
