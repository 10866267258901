import { ApolloError } from '@apollo/client';
import {
  PersonalProfileFieldName,
  PersonalProfileErrorMessages,
  PersonalProfileSuccessMessage,
  ChangePasswordFieldName,
  ChangePasswordErrorMessage,
} from './types';
import { CHANGE_PASSWORD_ERROR_CODES, existingEmailErrorCode } from './constants';
import { formValidator } from '../../utils/validator';

export const getPersonalInfoSuccessMessage = ({
  newInfo,
  currentInfo,
  successMessage,
}: {
  newInfo: PersonalProfileFieldName;
  currentInfo: PersonalProfileFieldName;
  successMessage: PersonalProfileSuccessMessage;
}) => {
  const isNameChanged =
    newInfo.firstName !== currentInfo.firstName || newInfo.lastName !== currentInfo.lastName;
  const isEmailChanged = newInfo.email !== currentInfo.email;

  if (isNameChanged && isEmailChanged) {
    return successMessage.update_personal_information_success;
  }

  if (isEmailChanged) {
    return successMessage.update_email_success;
  }

  return successMessage.update_name_success;
};

export const getErrorMessage = (err: ApolloError, errorMessage: PersonalProfileErrorMessages) => {
  const type = err.graphQLErrors[0]?.errorType;

  if (type === existingEmailErrorCode) {
    return errorMessage.existing_email_error;
  }

  return errorMessage.personal_profile_server_error;
};

export const getChangePasswordErrorMessage = (
  code: string,
  errorMessage: ChangePasswordErrorMessage
) => {
  const errorCode = code as keyof typeof CHANGE_PASSWORD_ERROR_CODES;

  return (
    errorMessage[CHANGE_PASSWORD_ERROR_CODES[errorCode]] ??
    errorMessage.change_password_server_error
  );
};

export const isFormValueChanged = <T extends string, U extends Record<T, string>>(
  fields: T[],
  newInfo: U,
  currentInfo: U
): boolean => fields.some((field) => newInfo[field] !== currentInfo[field]);

export const getPersonalInfoValidatorErrors = (
  personalInfo: PersonalProfileFieldName,
  errorMessage: PersonalProfileErrorMessages
) => ({
  firstName: formValidator(['REQUIRED'], personalInfo.firstName, {
    requiredError: errorMessage.first_name_required,
  }),
  lastName: formValidator(['REQUIRED'], personalInfo.lastName, {
    requiredError: errorMessage.last_name_required,
  }),
  email: formValidator(['REQUIRED', 'EMAIL'], personalInfo.email, {
    requiredError: errorMessage.email_required,
    invalidError: errorMessage.email_invalid,
  }),
});

export const getChangePasswordValidatorErrors = (
  personalInfo: ChangePasswordFieldName,
  errorMessage: ChangePasswordErrorMessage
) => ({
  oldPassword: formValidator(['REQUIRED', 'PASSWORD'], personalInfo.oldPassword, {
    requiredError: errorMessage.current_password_required,
    invalidError: errorMessage.password_invalid,
    passWordSpaceError: errorMessage.password_space_error,
  }),
  newPassword: formValidator(['REQUIRED', 'PASSWORD'], personalInfo.newPassword, {
    requiredError: errorMessage.password_required,
    invalidError: errorMessage.password_invalid,
    passWordSpaceError: errorMessage.password_space_error,
  }),
});
