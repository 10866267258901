import React from 'react';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { Layout, withPrivateRoute } from '@components/molecules';
import { Theme } from '@components/molecules/layout';
import SliceZone from '@components/sliceZone';

const Shop = ({ data }) => (
  <Layout
    theme={Theme.White}
    metaData={{
      title: data.prismicShopPage.data.meta_title,
      description: data.prismicShopPage.data.meta_description,
    }}
  >
    <SliceZone slices={data.prismicShopPage.data.body} />
    <div className="lg:pb-4" />
  </Layout>
);

export default withPrismicPreview(withPrivateRoute(Shop));

export const query = graphql`
  query GetShopPageData($lang: String!) {
    prismicShopPage(lang: { eq: $lang }) {
      _previewable
      data {
        meta_description
        meta_title
        body {
          ... on PrismicShopPageDataBodyHeroSlice {
            id
            slice_type
            primary {
              overlay_image {
                url
                alt
              }
              background_image {
                alt
                gatsbyImageData(layout: FULL_WIDTH)
              }
              link {
                url
              }
            }
          }
          ... on PrismicShopPageDataBodyReviewSlice {
            id
            slice_type
            items {
              reviewer_name
              reviewer_location
              header
              link {
                url
              }
              link_call_to_action
              blurb {
                richText
                html
              }
              icon {
                alt
                url
              }
              image {
                alt
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }

          ... on PrismicShopPageDataBodyProductSlice {
            id
            slice_type
            items {
              product_link {
                document {
                  ... on PrismicProductItem {
                    _previewable

                    data {
                      call_to_action
                      image {
                        alt
                        gatsbyImageData(layout: FULL_WIDTH)
                      }
                      name
                      url {
                        url
                      }
                    }
                  }
                }
              }
            }
            primary {
              background_image {
                gatsbyImageData(layout: FULL_WIDTH)
              }
              heading
              text_color
              theme_color
              lead {
                html
              }
              logo {
                alt
                url
              }
            }
          }
        }
      }
    }
  }
`;
