export enum FormName {
  LOGIN_FORM = 'login_form',
  CONFIRM_EMAIL_FORM = 'confirm_email_form',
  CREATE_ACCOUNT_FORM = 'create_account_form',
  PAYMENT_FORM = 'payment_form',
  EDIT_PERSONAL_DETAILS_FORM = 'edit_personal_detail_form',
}

export enum StepLabel {
  LOGIN = 'login',
  CONFIRM_PLAN = 'confirm_plan',
  CONFIRM_PLAN_NEXT_CTA = 'confirm_plan_next_cta',
  PAYMENT_SELECTED = 'payment_selected',
  ADD_PAYMENT_START_NOW_CTA = 'add_payment_start_now_cta',
  CONVERSION_SUCCESS = 'conversion_success',
}

export enum FormErrorName {
  SERVER_ERROR = 'Server Error',
  INCOMPELTE_FIELDS = 'Incomplete Fields',
}

export interface FormEventData {
  formName: string;
  stepLabel?: StepLabel;
  planName?: string;
  planType?: string;
  planTier?: string | null;
  planBillingFreq?: string;
  eventCallBack?: () => void;
}

export type FormStartEvent = FormEventData;
export type FormSubmitEvent = FormEventData;

export interface FormErrorEvent extends FormEventData {
  formErrorName: FormErrorName;
}

export interface RegistrationFlowEvent extends Omit<FormEventData, 'formName'> {
  formName?: string;
  currency?: string;
  planPrice?: string;
  planId?: string;
  customerEmail?: string;
  customerFirstName?: string;
  customerLastName?: string;
}
