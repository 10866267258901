import { gql } from '@apollo/client';

const getChargifySecurityToken = gql`
  query getChargifySecurityToken {
    getChargifySecurityToken {
      securityToken
      subdomain
      publicKey
    }
  }
`;

export default getChargifySecurityToken;
