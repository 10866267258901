import { gql } from '@apollo/client';

const restartSubscription = gql`
  mutation RestartSubscription {
    restartSubscription {
      user_id
      tune_offer_id
      offer_id
      state
      current_period_started_at
      current_period_ends_at
      balance_in_cents
      delayed_cancel_at
      next_product_id
      next_product_handle
      default_to_monthly
      product {
        product_handle
        name
        description
        currency
        rollover_product_handle
        product_price_point {
          price_in_cents
          interval
          interval_unit
          trial_interval
          trial_interval_unit
          final_price_in_cents
          tax_in_cents
          final_tax_in_cents
        }
      }
    }
  }
`;

export default restartSubscription;
