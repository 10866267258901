import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Lazy } from 'swiper';
import { CarouselArrow } from '@lesmills-international/components';

import 'swiper/css';
import 'swiper/css/bundle';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import CarouselWrapper from './style';

export interface CarouselProps {
  /**
   * Number of slides per view (slides visible at the same time on slider's container)
   */
  slidesPerView: number | 'auto';
  /**
   * distance between slides in px
   */
  spaceBetween: number;
  /**
   * Set numbers of slides to define and enable group sliding.
   */
  slidesPerGroup: number;
  /**
   * children array value
   */
  items: JSX.Element[];
  /**
   * if the pagination is clickable
   */
  paginationClickable?: boolean;
  /**
   * custom className value
   */
  className?: string;
  breakpoints?: object;
  /**
   * if the arrow button always show
   */
  alwaysShowArrowButton?: boolean;
}

const Carousel: React.FC<CarouselProps> = ({
  slidesPerView,
  spaceBetween,
  slidesPerGroup,
  items,
  paginationClickable = false,
  className = '',
  breakpoints,
  alwaysShowArrowButton = false,
}) => {
  if (!items || (items && items.length < 1)) {
    return null;
  }

  const swiperModules = paginationClickable ? [Pagination, Navigation, Lazy] : [Navigation, Lazy];

  return (
    <CarouselWrapper className={className}>
      <div className="swiper--wrapper">
        <Swiper
          breakpoints={breakpoints}
          slidesPerView={slidesPerView}
          spaceBetween={spaceBetween}
          slidesPerGroup={slidesPerGroup}
          loop={false}
          navigation={{
            prevEl: alwaysShowArrowButton ? '.home-arrow--prev' : '.arrow--prev',
            nextEl: alwaysShowArrowButton ? '.home-arrow--next' : '.arrow--next',
          }}
          pagination={{
            clickable: paginationClickable,
          }}
          lazy
          modules={swiperModules}
        >
          {items.map((item, index) => (
            <SwiperSlide key={index}>{item}</SwiperSlide>
          ))}
          <CarouselArrow
            direction="left"
            className={alwaysShowArrowButton ? 'home-arrow--prev' : 'arrow--prev'}
          />
          <CarouselArrow
            direction="right"
            className={alwaysShowArrowButton ? 'home-arrow--next' : 'arrow--next'}
          />
        </Swiper>
      </div>
    </CarouselWrapper>
  );
};

export default Carousel;
