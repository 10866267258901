import { usePersonalizedWorkoutQuery } from '@backend';
import { HomeHero, HomeHeroLoading } from '@components/molecules';

import React, { FC } from 'react';

interface Props {
  content: any;
}

const PersonalizedHeroSlice: FC<Props> = () => {
  const { data: personalizedWorkoutData } = usePersonalizedWorkoutQuery();

  const workout = personalizedWorkoutData?.personalizeWorkout?.[0];

  if (!workout) {
    return <HomeHeroLoading />;
  }

  return (
    <HomeHero
      url={workout.vimeoWebPageUrl}
      tagline="Recommended for you"
      imageSrc={workout.imageWide}
      workout={workout}
    />
  );
};

export default PersonalizedHeroSlice;
