const NAV_TABS = {
  BASE: ['Community', 'Shop', 'Explore', 'Learn', 'Blog(FitPlanet)'],
  PREMIUM: [
    'Explore',
    'GettingStarted',
    'Blog(FitPlanet)',
    'Equipment',
    'Community',
    'Shop',
    'Learn',
    'Plans',
    'MyWorkouts',
  ],
};

export default NAV_TABS;
