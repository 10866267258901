const partnerSignupStatus = {
  EXISTING_ACTIVATION_NOT_VALID: 'existing-activation',
  EXISTING_UPDATED: 'existing-updated',
  NEW_ACTIVATION: 'new-activation',
  ERROR: 'error',
  CANNOT_ACTIVATE: 'cannot-activate',
};

const partnerActivationCodeStatus = {
  VALID: 'valid',
  REDEEMED: 'redeemed',
  CANCELLED: 'cancelled',
  INVALID: 'invalid',
  EXPIRED: 'expired',
  ERROR: 'error',
};

export { partnerSignupStatus, partnerActivationCodeStatus };
