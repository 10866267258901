import React, { useMemo } from 'react';
import {
  MobileBillingHistoryTable,
  MobileBillingHistoryWrapper,
  MobileDate,
  MobileAmount,
  MobileSubscription,
  MobileServicePeriodLabel,
  ServicePeriodWrapper,
  MobileServicePeriod,
  DownloadLink,
  RowDivider,
} from './styles';
import { Order, TableData } from './types';
import { getDateComparator, sortByDate } from './utils';

interface Props {
  rows: TableData[];
  invoiceLinkLabel: string;
  servicePerioidLable: string;
  order: Order;
}

const BillingHistoryMobileView = ({
  rows,
  servicePerioidLable,
  invoiceLinkLabel,
  order,
}: Props) => {
  const sortedRow = useMemo(() => sortByDate(rows, getDateComparator(order)), [order, rows]);
  return (
    <MobileBillingHistoryTable>
      {sortedRow.map(({ date, subscription, servicePeriod, total, invoice }, index) => (
        <MobileBillingHistoryWrapper key={`mobile__billing__histroy__${index}`}>
          <MobileDate>{date}</MobileDate>
          <MobileAmount>{total}</MobileAmount>
          <MobileSubscription>{subscription}</MobileSubscription>
          <MobileServicePeriodLabel>{servicePerioidLable}</MobileServicePeriodLabel>
          <ServicePeriodWrapper>
            <MobileServicePeriod>{servicePeriod}</MobileServicePeriod>
            <DownloadLink target="_blank" rel="noreferrer" href={invoice as string}>
              {invoiceLinkLabel}
            </DownloadLink>
          </ServicePeriodWrapper>
          <RowDivider />
        </MobileBillingHistoryWrapper>
      ))}
    </MobileBillingHistoryTable>
  );
};

export default BillingHistoryMobileView;
