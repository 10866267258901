import styled from 'styled-components';

import { breakpoint } from '../../../themes/breakpoints';

export const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 24px;
`;

export const Title = styled.div`
  margin-bottom: 24px;
  font-family: Inter Light;
  font-size: 24px;
  font-weight: 900;
  line-height: 40px;
  letter-spacing: 0em;
`;

export const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 14px;
`;

export const PlansContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  .tieringPricingCard {
    height: 100%;
  }
  ${breakpoint('lg')`
    display: flex;
    flex-direction: row;
    align-items: stretch;
  `}
`;
