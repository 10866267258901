import { gql } from '@apollo/client';

const newSubscriptionByChargifyTokenV2 = gql`
  mutation newSubscriptionByChargifyTokenV2(
    $captcha_signature: CaptchaSignatureInput
    $recaptcha_token: String!
    $recaptcha_token_V3: String
    $product_handle: String!
    $default_to_monthly: Boolean
    $price_point_id: String
  ) {
    newSubscriptionByChargifyTokenV2(
      captcha_signature: $captcha_signature
      recaptcha_token: $recaptcha_token
      recaptcha_token_V3: $recaptcha_token_V3
      product_handle: $product_handle
      default_to_monthly: $default_to_monthly
      price_point_id: $price_point_id
    ) {
      user_id
      product {
        product_handle
        name
        description
      }
    }
  }
`;

export default newSubscriptionByChargifyTokenV2;
