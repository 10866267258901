import React from 'react';

import { TextField as MUITextField } from '@mui/material';

import Wrapper from './style';

const TextField = ({ backgroundColor, ...rest }) => (
  <Wrapper backgroundColor={backgroundColor}>
    <MUITextField InputProps={{ disableUnderline: true }} {...rest} />
  </Wrapper>
);

export default TextField;
