import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import GetInvoices from '../../graphql/getInvoices';
import { Invoice, QueryGetInvoicesArgs } from '../../../../gatsby-theme-engagement/src/backend';

export type GetInvoiceType = Pick<
  Invoice,
  | 'issue_date'
  | 'period_range_start'
  | 'period_range_end'
  | 'total_amount'
  | 'currency'
  | 'product_name'
  | 'product_family_name'
  | 'public_url'
  | '__typename'
>[];

export type GetInvoiceQuery = {
  getInvoices: GetInvoiceType;
};

const useGetInvoices = (
  options?: QueryHookOptions<GetInvoiceQuery, QueryGetInvoicesArgs>
): QueryResult<GetInvoiceQuery, QueryGetInvoicesArgs> =>
  useQuery<GetInvoiceQuery, QueryGetInvoicesArgs>(GetInvoices, options);

export default useGetInvoices;
