import { AnalyticsBrowser } from '@segment/analytics-next';
import hash from 'object-hash';
import { CONSTANTS, SignUpStage } from '../../constants/segment-signup';
import {
  IdentityAtCreateAccountType,
  IdentityAtEmailConfirmationType,
  IdentityAtSelectPlanType,
  IdentityAtStageType,
  TrackEventType,
} from '../../types/segment/signup.types';
import captureException from '../sentry';
import { addErrorInDatadogRum, getLocalStorage } from '../utilities';

const analytics = AnalyticsBrowser.load({ writeKey: CONSTANTS.SEGMENT_WRITE_KEY as string });

/**
 * getUserInfo returns an object with email and subComms from the locally stored user-info
 * and falls back to locally stored email, opt_in if no logged in user
 *
 * @returns Object with email and subComms
 *
 */
const getUserInfo = (): { email: string; subComms: boolean } => {
  let { email, subComms } = JSON.parse(getLocalStorage('user-info') || '{}');

  if (!email) email = getLocalStorage('email');
  if (subComms === null || subComms === undefined) subComms = getLocalStorage('opt_in') === 'yes';

  return {
    email,
    subComms,
  };
};

/**
 *
 * A new Segment userId is generated by hashing the lowercase email address
 * so that changing email addresses will create a new customerIO profile;
 *
 * @param email
 * @returns Hashed Email
 */
const createSegmentIdentity = (email: String | null): string => {
  const userEmail = email || getUserInfo().email;

  if (!userEmail)
    throw new Error(`SIGNUP: Unable to create segment user identity. Email doesn't exist.`);

  return hash(userEmail.toLowerCase());
};

async function identifyOnPayment(): Promise<void> {
  try {
    const { email } = getUserInfo();
    const segmentIdentity = createSegmentIdentity(email);
    await analytics.identify(segmentIdentity, {
      email,
    });
  } catch (error) {
    // suppress errors
    addErrorInDatadogRum(error);
    captureException({
      action: 'segmentIdentify',
      ...(error as object),
    });
  }
}

async function updateUserIdentity(traits: IdentityAtStageType): Promise<void> {
  try {
    const segmentIdentity = createSegmentIdentity(traits.email);
    await analytics.identify(segmentIdentity, {
      ...traits,
      signup_channel: 'Web',
    });
  } catch (error) {
    // suppress errors
    addErrorInDatadogRum(error);
    captureException({
      action: 'segmentIdentify',
      ...(error as object),
    });
  }
}

async function trackUserEvent(traits: TrackEventType): Promise<void> {
  try {
    await analytics.track(traits.event_name);
  } catch (error) {
    // suppress errors
    addErrorInDatadogRum(error);
    captureException({
      action: 'segmentTrack',
      ...(error as object),
    });
  }
}

async function identifyAccountCreated(traits: IdentityAtCreateAccountType): Promise<void> {
  await updateUserIdentity(traits);
  await trackUserEvent({ event_name: SignUpStage.CreateAccount });
}

async function optOutUser(email: string): Promise<void> {
  try {
    const segmentIdentity = createSegmentIdentity(email);
    await analytics.identify(segmentIdentity, {
      unsubscribed: true,
    });
  } catch (error) {
    // suppress errors
    addErrorInDatadogRum(error);
    captureException({
      action: 'segmentIdentify',
      ...(error as object),
    });
  }
}

async function identifyEmailConfirmed(traits: IdentityAtEmailConfirmationType): Promise<void> {
  try {
    await updateUserIdentity(traits);
    await trackUserEvent({ event_name: SignUpStage.ConfirmEmail });
  } catch (error) {
    addErrorInDatadogRum(error);
    captureException({
      action: 'segmentIdentify',
      ...(error as object),
    });
  }
}

async function identifySelectPlan(traits: IdentityAtSelectPlanType): Promise<void> {
  try {
    const { email } = getUserInfo();
    const segmentIdentity = createSegmentIdentity(email);
    await analytics.identify(segmentIdentity, {
      ...traits,
      email,
    });
    await trackUserEvent({ event_name: SignUpStage.SelectPlan });
  } catch (error) {
    // suppress errors
    addErrorInDatadogRum(error);
    captureException({
      action: 'segmentIdentify',
      ...(error as object),
    });
  }
}

async function trackPaymentCompleteEvent(): Promise<void> {
  await trackUserEvent({ event_name: SignUpStage.PaymentComplete });
}

export {
  identifyAccountCreated,
  identifyEmailConfirmed,
  identifySelectPlan,
  identifyOnPayment,
  trackPaymentCompleteEvent,
  optOutUser,
  getUserInfo,
};
