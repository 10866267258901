import React from 'react';
import styled from 'styled-components';

import CircularProgress from '@mui/material/CircularProgress';

import COLORS from '../../../themes/colors';

const SpinnerWrapper = styled.div`
  width: 100%;
  min-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  backgroud-color: transparent;
`;

const Spinner = () => (
  <SpinnerWrapper>
    <CircularProgress style={{ width: 21, height: 21, color: COLORS.darkGrey }} />
  </SpinnerWrapper>
);

export default Spinner;
