/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-filename-extension */

import './src/styles/global.css'; // Import global css files

import * as React from 'react';
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { AuthProvider } from './src/context/authContext';
import { linkResolver } from '../../src/services/linkResolver';

// Adds a shared React Context for Prismic preview sessions.

const repositoryConfigs = [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY_NAME,
    linkResolver,
  },
];

export const wrapRootElement = ({ element }) => (
  <AuthProvider>
    <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>
      <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_RECAPTCHA_V3_KEY}>
        {element}
      </GoogleReCaptchaProvider>
    </PrismicPreviewProvider>
  </AuthProvider>
);
