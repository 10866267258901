/* eslint-disable no-template-curly-in-string */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable arrow-body-style */
/* eslint arrow-body-style: ["error", "always"] */
/* eslint-disable no-unsafe-optional-chaining */

// 👇️ ts-nocheck ignores all ts errors in the file
// This is a JSX file that has been converted to TSX
// as we want to have type checking on new TS-based methods,
// without having to rewrite the whole page into TS first.
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect, useState } from 'react';

import { PricingCardToggle } from '@lesmills-international/components';
import LeavePageDialog from '../../common/leavePageDialog/LeavePageDialog';

import { normalizePlans } from './normalizePlansPromotions';
import { setLocalStorage, getLocalStorage, addErrorInDatadogRum } from '../../../utils/utilities';
import PlanCard from './PlanCardTiering';
import { createPublicClient } from '../../../services/client';
import getValidProducts from '../../../graphql/getValidProducts';
import getPromotionProducts from '../../../graphql/getPromotionProducts';
import { ReactComponent as BackIcon } from '../../../assets/icons/back.svg';

import {
  ChangePlan,
  Description,
  PlansContainer,
  PlansScrollContainer,
  Title,
  Wrapper,
  BackBtn,
  SwitchWrapper,
  SubTitleTiering,
} from './selectPlanCommonStyle';

const getSubtitleText = (selectedPlan, subtitle, notrialdescription) =>
  selectedPlan?.trial_interval
    ? subtitle.replace('{days}', `${selectedPlan.trial_interval}`)
    : notrialdescription;

const SelectPlan = ({
  pageData,
  pageContext,
  discountInfo,
  leavePageDialogContent,
  countryOverridesData,
  isWinback,
  onSelectPlan,
  selectedPlan,
  location,
}) => {
  const {
    monthly_text,
    annually_text,
    best_value,
    best_value_position,
    pricing_title,
    winback_pricing_title,
    pricing_subtitle,
    pricing_disclaimer,
    change_plan,
    no_trial_text,
    raf_promo_code,
    voucherContent,
    promos,
  } = pageData;

  const FREQUENCY_MAPPING = {
    [monthly_text]: 1,
    [annually_text]: 12,
  };

  let planId = getLocalStorage('planId');
  const urlParams = new URLSearchParams(location.search);
  if (process.env.GATSBY_RT_05_10_2022_ABANDONED_CART === 'true' && urlParams.has('plan_id')) {
    planId = urlParams.get('plan_id');
    setLocalStorage('planId', planId);
  }

  const [countryCode, setCountryCode] = useState('');
  const [subtitleText, setSubtitleText] = useState('');
  const [hidden, setHidden] = useState(false);
  const [displayChangePlanBtn, setDisplayChangePlanBtn] = useState(false);
  const [displaySwitch, setDisplaySwitch] = useState(false);
  const [openLeavePageDialog, setOpenLeavePageDialog] = useState(false);
  const [showTotalPrice, setShowTotalPrice] = useState(false);
  const [paymentFrequency, setPaymentFrequency] = useState(monthly_text);
  const [showWinBackTitle, setShowWinbackTitle] = useState(false);
  const [plans, setPlans] = useState([]);

  // Temeproray fix for showing winback title for tiered plan
  useEffect(() => {
    const noTrialInterval = plans
      .filter((plan) => plan.interval === 1 || plan.interval === 12)
      .every((plan) => !plan.trial_interval);

    setShowWinbackTitle(isWinback || noTrialInterval);
  }, [plans, isWinback]);

  const referPromo = discountInfo?.type === 'refer' ? 'refer' : null;
  const discountPromoCode =
    discountInfo?.type === 'promotionCode' ? discountInfo?.data?.promotionCode : null;
  const promotionCode = discountPromoCode || referPromo;
  const titleText = showWinBackTitle ? winback_pricing_title : pricing_title;
  const getProductsVariables = promotionCode ? { promotionCode } : {};
  const getProducts = promotionCode ? getPromotionProducts : getValidProducts;

  useEffect(() => {
    const GetValidProductsQuery = async () => {
      try {
        const prices = await createPublicClient.query({
          query: getProducts,
          variables: getProductsVariables,
        });
        const normalizedPlans = normalizePlans(
          prices,
          promotionCode ? 'tier_promotion' : 'tier', // Todo: can merge with promotion?
          isWinback
        );
        setPlans(normalizedPlans);
        setCountryCode(prices.data.getHeaders.countryCode);
        const preselectedPlan = normalizedPlans.find((plan) => plan.id === planId);
        if (preselectedPlan) {
          setHidden(true);
          onSelectPlan(preselectedPlan);
          setDisplayChangePlanBtn(true);
          let selectedFrequency = monthly_text;
          Object.entries(FREQUENCY_MAPPING).forEach(([frequencyText, interval]) => {
            if (interval === preselectedPlan.interval) selectedFrequency = frequencyText;
          });
          setPaymentFrequency(selectedFrequency);
        } else {
          setDisplaySwitch(true);
        }

        const newSubtitleText = getSubtitleText(preselectedPlan, pricing_disclaimer, no_trial_text);
        setSubtitleText(newSubtitleText);
      } catch (error) {
        addErrorInDatadogRum(error);
      }
    };
    GetValidProductsQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pageContext,
    discountInfo?.data?.promotionCode,
    no_trial_text,
    pricing_disclaimer,
    getProducts,
    raf_promo_code,
    isWinback,
  ]);

  useEffect(() => {
    if (countryOverridesData?.length && countryCode) {
      countryOverridesData.forEach((overrideCountry) => {
        if (overrideCountry.country === countryCode.toLowerCase()) {
          setShowTotalPrice(overrideCountry?.show_total_price);
        }
      });
    }

    // BAU landing page with promos
    if (!countryOverridesData?.length && promos && countryCode) {
      if (
        promos?.country === countryCode.toLowerCase() &&
        promos?.promotion &&
        promos?.promo_code
      ) {
        setShowTotalPrice(promos?.show_total_price);
      }
    }
  }, [countryCode, countryOverridesData, promos]);

  const handleSelectPlan = (newSelectedPlanId) => {
    const newSelectedPlan = plans.find((plan) => plan.id === newSelectedPlanId);
    onSelectPlan(newSelectedPlan);
    setHidden(true);
    setDisplayChangePlanBtn(true);
    setDisplaySwitch(false);

    const newSubtitleText = getSubtitleText(newSelectedPlan, pricing_disclaimer, no_trial_text);
    setSubtitleText(newSubtitleText);
  };

  const handleChangePlan = () => {
    setHidden(false);
    setDisplayChangePlanBtn(false);
    setDisplaySwitch(true);
  };

  const handleBack = () => {
    setOpenLeavePageDialog(true);
  };

  const voucher = voucherContent
    ? voucherContent.find(
        (voucherInstance) => voucherInstance?.country?.toUpperCase() === countryCode?.toUpperCase()
      )
    : null;

  if (!PricingCardToggle) return null;
  return (
    <Wrapper id="plansWrapper">
      <BackBtn onClick={handleBack} id="back-button">
        <BackIcon />
      </BackBtn>
      <Title>{titleText}</Title>
      <SubTitleTiering>{subtitleText}</SubTitleTiering>
      <Description>{pricing_subtitle}</Description>
      {displaySwitch && (
        <SwitchWrapper>
          <PricingCardToggle
            options={[monthly_text, annually_text]}
            getSelectedText={setPaymentFrequency}
            defaultText={paymentFrequency}
            badge={{
              label: best_value,
              positionText: best_value_position || annually_text,
            }}
          />
        </SwitchWrapper>
      )}
      <PlansScrollContainer id="PlansScrollContainer">
        <PlansContainer id="PlansContainer">
          {plans.length > 0 &&
            countryCode &&
            plans.map((plan) => {
              return (
                <PlanCard
                  plan={plan}
                  key={plan.name}
                  handleSelectPlan={handleSelectPlan}
                  selectedPlan={selectedPlan}
                  isHidden={hidden}
                  pricingData={pageData}
                  countryCode={countryCode}
                  voucher={voucher}
                  noTrialDisplay={isWinback}
                  showTotalPrice={showTotalPrice}
                  currentFrequency={FREQUENCY_MAPPING[paymentFrequency]}
                  promotionCode={promotionCode}
                />
              );
            })}
          {displayChangePlanBtn && (
            <ChangePlan onClick={handleChangePlan}>{change_plan}</ChangePlan>
          )}
        </PlansContainer>
      </PlansScrollContainer>
      <LeavePageDialog
        title={leavePageDialogContent?.title}
        description={leavePageDialogContent?.description}
        stayBtnText={leavePageDialogContent?.stay_btn_text}
        leavePageBtnText={leavePageDialogContent?.leave_btn_text}
        open={openLeavePageDialog}
        handleClose={() => {
          setOpenLeavePageDialog(false);
        }}
        leaveToUrl={process.env.GATSBY_LANDING_PAGES_HOME}
      />
    </Wrapper>
  );
};

export default SelectPlan;
