import React, { CSSProperties, ReactNode, useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close-white.svg';
import COLORS from '../../../themes/colors';

type Severity = 'error' | 'success';

interface Props {
  content?: string;
  onClose?: () => void;
  severity?: Severity;
  testId?: string;
  style?: CSSProperties;
  children?: ReactNode;
}

const Wrapper = styled.div<{ severity?: Severity; show: boolean }>`
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: relative;
  padding: 12px 34px 12px 12px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 18px;
  color: ${COLORS.white};
  background-color: ${(props) =>
    props.severity === 'success'
      ? COLORS.successGreen
      : COLORS.errorRed}; // TODO apply theme color when dark theme is supported

  & .close-icon {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
  }

  & a {
    text-decoration: underline;
  }
`;

const Alert: React.FC<Props> = ({
  content,
  severity = 'error',
  onClose,
  testId,
  children,
  style,
}) => {
  const [showAlert, setShowAlert] = useState(true);
  const handleClose = () => {
    setShowAlert(false);
    onClose && onClose();
  };
  return (
    <Wrapper show={showAlert} severity={severity} role="alert" data-testid={testId} style={style}>
      <CloseIcon className="close-icon" onClick={handleClose} />
      {content && <p>{content}</p>}
      {children}
    </Wrapper>
  );
};

export default Alert;
