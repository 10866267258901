import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

const Wrapper = styled.div`
  position: relative;
`;
const ContentWrapper = styled.div`
  z-index: 10;
  position: relative;
`;

const StyledGatsbyImage = styled(GatsbyImage)`
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

export { Wrapper, ContentWrapper, StyledGatsbyImage };
