/* eslint-disable @typescript-eslint/naming-convention */
import React, { ChangeEvent, useContext, useMemo, useState } from 'react';
import useFetchRolloverCountries from '../../../hooks/useFetchRolloverCountries';
import { Title, Container, ErrorMessage } from './styles';
import { userContext } from '../../../../../gatsby-theme-engagement/src/context';
import useGetCountryCode from '../../../hooks/useGetCountryCode';
import { addErrorInDatadogRum, allowRolloverPreferences } from '../../../utils/utilities';
import RadioGroup from '../../common/radioGroup';
import useUpdateSubscriptionPreference from '../../../hooks/mutation/useUpdateSubscriptionPreference';

type RolloverPageData = Pick<
  Queries.PrismicChangeSubscriptionPageData,
  'rollover_error_message' | 'rollover_title' | 'annual_text' | 'monthly_text'
>;

interface Props {
  prismicData: RolloverPageData;
}

const RolloverPreference = ({ prismicData }: Props) => {
  const { lmodSubscription, refetchUser } = useContext(userContext);
  const { rollover_title, annual_text, monthly_text, rollover_error_message } = prismicData;

  const getSubscriptionType = () => {
    let suscriptionType = monthly_text;

    if (interval === 12) {
      suscriptionType = annual_text;
    } else if (interval > 1) {
      suscriptionType = `${interval}-${monthly_text}`;
    }

    return suscriptionType;
  };
  const defaultInputValue = lmodSubscription?.default_to_monthly?.toString();

  const [inputValue, setInputValue] = useState(defaultInputValue);

  const { product } = lmodSubscription || {};

  const interval = product?.product_price_point?.interval;

  const { countryCode } = useGetCountryCode();
  const rolloverCountries = useFetchRolloverCountries();

  const [handleUpdateSubscriptionPreference, { loading, error }] = useUpdateSubscriptionPreference({
    onCompleted: () => {
      refetchUser && refetchUser();
    },
    onError: (err) => {
      addErrorInDatadogRum(err);
    },
  });

  const radioData = [
    { value: 'false', label: getSubscriptionType() },
    { value: 'true', label: monthly_text },
  ];

  const isRolloverAllowed = useMemo(
    () => allowRolloverPreferences(countryCode, product?.product_price_point, rolloverCountries),
    [countryCode, product?.product_price_point, rolloverCountries]
  );

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    handleUpdateSubscriptionPreference({
      variables: {
        rolloverOption: e.target.value === 'true',
      },
    });
  };

  if (!isRolloverAllowed) {
    return null;
  }

  return (
    <Container>
      <Title>{rollover_title}</Title>
      {error && <ErrorMessage>{rollover_error_message}</ErrorMessage>}
      <RadioGroup
        id="subscription-rollover-preference"
        ariaLabel="subscription rollover preference"
        name="subscription-rollover-preference"
        currentValue={inputValue}
        handleOnChange={handleOnChange}
        radioData={radioData}
        disabled={loading}
      />
    </Container>
  );
};

export default RolloverPreference;
