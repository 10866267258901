import React, { forwardRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const RecaptchaV2 = forwardRef((_, ref) => (
  <div id="recaptchaV2">
    <ReCAPTCHA ref={ref} size="invisible" sitekey={process.env.GATSBY_RECAPTCHA_V2_KEY} />
  </div>
));

export default RecaptchaV2;
