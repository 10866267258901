import React, { useState, useEffect, useRef } from 'react';

import captureException from '../../../utils/sentry';
import { addErrorInDatadogRum } from '../../../utils/utilities';

import { PayPalContainer, PaypalErrorMsg } from './style';

const PayPal = ({
  prismicData,
  securityChargifyInfo,
  onGetChargifySuccess,
  handleChargifyVerifyErrors,
  paypalFieldError,
  handlePaypalActivated,
}) => {
  const { securityToken, subdomain, publicKey } = securityChargifyInfo;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { paypal_verify_error, paypal_field_error } = prismicData;

  const [paypalVerifyError, setPaypalVerifyError] = useState(false);

  const chargifyPaypal = useRef(null);

  const initChargify = () => {
    chargifyPaypal.current = new window.Chargify();
  };

  useEffect(
    () => () => {
      // Reset credit card when component unmounts
      if (chargifyPaypal.current) {
        chargifyPaypal.current.unload();
      }
    },
    []
  );

  useEffect(() => {
    initChargify();
  }, []);

  const handleErrors = (error, chargifyError = false) => {
    if (chargifyError) handleChargifyVerifyErrors(error?.message);
    else setPaypalVerifyError(true);
    addErrorInDatadogRum(error);
    captureException({
      action: 'paypalError',
      ...error,
    });
  };
  useEffect(() => {
    try {
      if (chargifyPaypal.current && securityToken) {
        chargifyPaypal.current.load(
          {
            publicKey,
            serverHost: `https://${subdomain}.chargify.com`,
            type: 'pay_pal',
            securityToken,
            selector: '#paypal-chargify',
            selectorForPayPalButton: '#paypal-button',
          },
          {
            onPayPalAuthorized: () => {
              chargifyPaypal.current.token(
                document.querySelector('.paypal-host-form'),
                (token) => {
                  onGetChargifySuccess({
                    chargifyToken: token,
                    paymentType: 'PayPalAccount',
                  });
                  chargifyPaypal.current.unload();
                },
                (error) => {
                  handleErrors(error, true);
                }
              );
            },
            onPayPalError: (error) => {
              handleErrors(error);
            },
          }
        );

        handlePaypalActivated();
      }
    } catch (error) {
      handleErrors(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PayPalContainer id="paypal-chargify-section">
      <form id="paypal-chargify-form" className="paypal-host-form">
        {paypalVerifyError && (
          <PaypalErrorMsg id="paypal-error-msg">{paypal_verify_error.text}</PaypalErrorMsg>
        )}
        {paypalFieldError && (
          <PaypalErrorMsg id="paypal-error-msg">{paypal_field_error.text}</PaypalErrorMsg>
        )}
        <div id="paypal-chargify" />
      </form>
    </PayPalContainer>
  );
};

export default PayPal;
