import React, { useContext } from 'react';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

import Layout from '../components/common/layout';
import SelectPlan from '../components/signup/selectPlan/SelectPlanAffiliate';
import { normalizePriceData } from '../utils/normalizePriceData';
import { getDiscountInfo } from '../utils/utilities';
import { userContext } from '../../../gatsby-theme-engagement/src/context';
import { withPrivateRoute } from '../../../gatsby-theme-engagement/src/components/molecules';
import useSetUserInfo from '../hooks/useSetUserInfo';
import Payment from '../components/signup/payment/Payment';
import { CHARGIFY_SCRIPT } from '../components/signup/payment/constants';
import useHandleSelectPlan from '../hooks/useHandleSelectPlan';
import useDisplayRecaptchaBadge from '../hooks/useDisplayRecaptchaBadge';

const SelectPlanTemplate = ({ data, pageContext, location }) => {
  const {
    prismicSignUpLayout,
    prismicAffiliateLandingPage,
    prismicLesMillsPlusCommonLandingPage,
    prismicSignUpLeavePageDialog,
    prismicCreateAccountPage,
  } = data;
  const normalizedData = normalizePriceData(
    prismicAffiliateLandingPage,
    prismicLesMillsPlusCommonLandingPage
  );
  const { lang } = pageContext;
  const { isEntitledToFreeTrial } = useContext(userContext);

  useDisplayRecaptchaBadge();

  const { userValidated, userInfo } = useSetUserInfo();
  const discountInfo = getDiscountInfo();

  const { selectPlanScrollRef, selectedPlan, handleSelectPlan } = useHandleSelectPlan(discountInfo);

  if (!normalizedData) {
    return <div />;
  }

  const affiliateInfo = discountInfo?.type === 'affiliate' ? discountInfo?.data : null;

  const countriesData = prismicSignUpLayout.data?.body1[0];
  const statesData = prismicSignUpLayout.data?.body2;

  const currentAffiliateInfo =
    prismicAffiliateLandingPage?.data?.affiliate_info.find(
      (info) => info?.primary?.affiliate_id === affiliateInfo?.affiliateId
    )?.primary || {};

  return (
    <Layout
      data={prismicSignUpLayout?.data}
      currentStep={3}
      lang={lang}
      pathName="/signup/select-plan"
      omitClose
      scripts={[CHARGIFY_SCRIPT]}
      rightSectionStyle={{ paddingTop: '28px' }}
    >
      <SelectPlan
        pageData={normalizedData}
        location={location}
        affiliateInfo={affiliateInfo}
        currentAffiliateInfo={currentAffiliateInfo}
        pageContext={pageContext}
        leavePageDialogContent={prismicSignUpLeavePageDialog?.data}
        isWinback={!isEntitledToFreeTrial}
        selectedPlan={selectedPlan}
        onSelectPlan={handleSelectPlan}
      />
      {userValidated && (
        <div ref={selectPlanScrollRef} style={{ marginTop: '40px' }}>
          <Payment
            selectedPlan={selectedPlan}
            lang={pageContext?.lang}
            countriesData={countriesData}
            statesData={statesData}
            discountInfo={discountInfo}
            voucherVerificationMessageContent={prismicCreateAccountPage?.data}
            userInfo={userInfo}
          />
        </div>
      )}
    </Layout>
  );
};
export default withPrismicPreview(withPrivateRoute(SelectPlanTemplate, true));

export const query = graphql`
  query ($lang: String) {
    prismicSignUpLayout(lang: { eq: $lang }) {
      _previewable
      data {
        logo_white {
          alt
          gatsbyImageData(width: 133, height: 33)
        }
        logo_black {
          alt
          gatsbyImageData(width: 133, height: 33)
        }
        background_image {
          alt
          gatsbyImageData
        }
        body {
          ... on PrismicSignUpLayoutDataBodyFooterNavigation {
            items {
              nav_label {
                text
              }
              nav_link {
                text
              }
            }
          }
        }
        body1 {
          ... on PrismicSignUpLayoutDataBody1Countries {
            items {
              country_code {
                text
              }
              country_name {
                text
              }
            }
          }
        }
        body2 {
          ... on PrismicSignUpLayoutDataBody2States {
            primary {
              country_name {
                text
              }
              country_code {
                text
              }
            }
            items {
              state_name {
                text
              }
              state_code {
                text
              }
              state_short_name {
                text
              }
            }
          }
        }
      }
    }
    prismicLesMillsPlusCommonLandingPage(lang: { eq: $lang }) {
      data {
        body {
          ... on PrismicLesMillsPlusCommonLandingPageDataBodyPricing {
            slice_type
            primary {
              annually_text
              billed_text
              cancellation_text
              change_plan
              day
              days
              free_trial_text
              month
              monthly_text
              months
              no_trial_text
              pricing_cta_button
              pricing_cta_label
              pricing_disclaimer
              pricing_subtitle
              pricing_title
              winback_pricing_title
              no_free_trial_text
              three_monthly_text
              best_value
              year
              plan_monthly_disclaimer
              plan_trimonthly_disclaimer
              plan_annually_disclaimer
              billed_text
              first_payment
              next
              oneoff_first_payment
              oneoff_monthly_billed
              oneoff_trial_text
            }
          }
        }
      }
      _previewable
      lang
    }
    prismicAffiliateLandingPage(lang: { eq: $lang }) {
      data {
        body {
          ... on PrismicAffiliateLandingPageDataBodyPricing {
            slice_type
            primary {
              annually_text
              billed_text
              cancellation_text
              change_plan
              day
              days
              free_trial_text
              month
              monthly_text
              months
              no_trial_text
              pricing_cta_button
              pricing_cta_label
              pricing_disclaimer
              pricing_subtitle
              pricing_title
              winback_pricing_title
              no_free_trial_text
              three_monthly_text
              best_value
              year
              plan_monthly_disclaimer
              plan_trimonthly_disclaimer
              plan_annually_disclaimer
              billed_text
            }
          }
        }
        affiliate_info {
          ... on PrismicAffiliateLandingPageDataAffiliateInfoAffiliateInformation {
            id
            primary {
              affiliate_pricing_title
              affiliate_pricing_subtitle
              affiliate_signup_description
              affiliate_id
              affiliate_plan_monthly_disclaimer
              affiliate_plan_trimonthly_disclaimer
              affiliate_plan_annually_disclaimer
            }
          }
        }
        meta_title
        meta_description
      }
      _previewable
      lang
    }
    prismicSignUpLeavePageDialog(lang: { eq: $lang }) {
      data {
        title
        description
        stay_btn_text
        leave_btn_text
      }
    }
    prismicCreateAccountPage(lang: { eq: $lang }) {
      _previewable
      data {
        voucher_success
        voucher_has_been_redeemed
        voucher_is_expired
        voucher_is_invalid
        voucher_is_not_required
        voucher_is_required
        country_code_missing
        bau_landing_page_link
        homepage_link
        customer_support_link
      }
    }
  }
`;
