import styled from 'styled-components';
import COLORS from '@themes/colors';

const ShowMoreWrapper = styled.div<{ hoverState?: boolean }>`
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  color: ${COLORS.lightGreyAE};
  font-size: 14px;
  line-height: 20px;
  font-weight: 800;

  &:hover {
    color: ${(props) => (props.hoverState ? COLORS.lightishGrey : COLORS.lightGreyAE)};
    text-decoration: ${(props) => (props.hoverState ? 'underline' : 'none')};
  }
`;

export default ShowMoreWrapper;
