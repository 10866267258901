import React, { useState } from 'react';
import styled from 'styled-components';
import { AvatarInitial, Button } from '@lesmills-international/components';
import COLORS from '../../../themes/colors';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputContainer = styled.div`
  display: flex;
`;

const CommentInput = styled.input`
  flex: 1;
  margin-left: 24px;
  padding: 12px 16px;
  border-radius: 4px;
  font-size: 16px;
  background-color: ${COLORS.darkGrey};
  color: ${COLORS.white};
  border: 0;
  &:focus {
    outline: none;
    box-shadow: none;
  }
  &::placeholder {
    color: ${COLORS.lightGreyAE};
    font-family: Inter Light;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  margin-top: 24px;
  margin-left: 64px;
  gap: 24px;
`;

export interface PostCommentProps {
  onPost: (comment: string, parentId?: string) => void;
  onCancel: () => void;
  author: string;
  cancelText: string;
  postCommentText: string;
  inputPlaceholderText: string;
}

const PostComment: React.FC<PostCommentProps> = ({
  onPost,
  onCancel,
  author,
  cancelText,
  postCommentText,
  inputPlaceholderText,
}) => {
  const [comment, setComment] = useState('');
  const [isInputFocused, setIsInputFocused] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value);
  };

  const handlePost = () => {
    if (comment.trim()) {
      onPost(comment);
      setComment('');
    }
  };

  const handleCancel = () => {
    setComment('');
    onCancel();
  };

  const handleInputFocus = () => {
    setIsInputFocused(true);
  };

  const handleInputBlur = () => {
    // Delay setting isInputFocused to false
    setTimeout(() => {
      setIsInputFocused(false);
    }, 100);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      // Prevent the default behavior of the Enter key (form submission)
      event.preventDefault();
      // Call the onPost function here
      handlePost();
    }
  };

  return (
    <Container>
      <InputContainer>
        <AvatarInitial name={author} />
        <CommentInput
          type="text"
          placeholder={inputPlaceholderText}
          value={comment}
          onChange={handleChange}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          onKeyDown={handleKeyPress}
        />
      </InputContainer>
      {isInputFocused && (
        <ButtonsContainer>
          <Button style={{ borderRadius: '4px' }} onClick={handleCancel}>
            {cancelText}
          </Button>
          <Button disabled={!comment} inverted style={{ borderRadius: '4px' }} onClick={handlePost}>
            {postCommentText}
          </Button>
        </ButtonsContainer>
      )}
    </Container>
  );
};

export default PostComment;
