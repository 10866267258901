import React from 'react';
import styled from 'styled-components';
import { Terms_Of_Use_PageBody1Data_Table } from '../../../generated/prismicGraphql';

interface ExtendedTermsOfUseData extends Terms_Of_Use_PageBody1Data_Table {
  items: Array<{
    cell_column_1?: {
      raw: Array<{
        text: string;
        type: string;
        spans: Array<{
          start: number;
          end: number;
          type: string;
        }>;
      }>;
      text?: string;
    };
    cell_column_2?: {
      raw: Array<any>;
      text?: string | null;
    };
    cell_column_3?: {
      raw: Array<any>;
      text?: string | null;
    };
    cell_column_4?: {
      raw: Array<any>;
      text?: string | null;
    };
  }>;
}

interface TermTableProps {
  data: ExtendedTermsOfUseData;
  isArabic?: boolean;
}

const TableContainer = styled.div`
  margin-bottom: 2rem;
  overflow-x: auto;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHeader = styled.th`
  background-color: #f3f4f6;
  padding: 1rem;
  text-align: left;
  border-bottom: 2px solid #e5e7eb;
  font-weight: bold;
`;

const TableRow = styled.tr`
  border-bottom: 1px solid #e5e7eb;
`;

const TableCell = styled.td`
  padding: 1rem;
  border-bottom: 1px solid #e5e7eb;
`;

const TermTable: React.FC<TermTableProps> = ({ data, isArabic = false }) => {
  const { primary, items } = data;

  const headers = [
    primary?.header_column_1?.text || '',
    primary?.header_column_2?.text || '',
    primary?.header_column_3?.text || '',
    primary?.header_column_4?.text || '',
  ].filter((header) => header);

  return (
    <TableContainer>
      <StyledTable dir={isArabic ? 'rtl' : 'ltr'}>
        {/* Table Headers */}
        <thead>
          <TableRow>
            {headers.map((header, index) => (
              <TableHeader key={index}>{header}</TableHeader>
            ))}
          </TableRow>
        </thead>

        {/* Table Rows */}
        <tbody>
          {items.map((item, rowIndex) => {
            const cells = [
              item.cell_column_1?.text || '',
              item.cell_column_2?.text || '',
              item.cell_column_3?.text || '',
              item.cell_column_4?.text || '',
            ].filter((cell) => cell);

            return (
              <TableRow key={rowIndex}>
                {cells.map((cell, cellIndex) => (
                  <TableCell key={cellIndex}>{cell}</TableCell>
                ))}
              </TableRow>
            );
          })}
        </tbody>
      </StyledTable>
    </TableContainer>
  );
};

export default TermTable;
