import { map, isEmpty, filter } from 'ramda';
import checkPromoCountry from './checkPromoCountry';

const filterPrimaryData = (pageData) =>
  Object.fromEntries(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Object.entries(pageData?.primary || {}).filter(([_, v]) => v != null)
  );

const extractNonEmptyData = (pageData) => pageData.filter((body) => !isEmpty(body));

export const normalizePriceData = (customPage, defaultPage) => {
  if (!customPage || !defaultPage) {
    return null;
  }
  const filteredCustomPage = extractNonEmptyData(customPage.data.body);
  const filteredDefaultPage = extractNonEmptyData(defaultPage.data.body);

  const merged = map((slice) => {
    const defaultSlice = filteredDefaultPage.find(
      (defaulit) => slice.slice_type === defaulit.slice_type
    );
    if (!defaultSlice) {
      return filterPrimaryData(slice);
    }

    const hasTieringPromotion = slice.slice_type === 'tiering_pricing';
    const defaultData = filterPrimaryData(defaultSlice);
    const pricingData =
      hasTieringPromotion &&
      filteredDefaultPage.find((filtered) => filtered.slice_type === 'pricing');

    // Combine the pricing, default and slice data into a single object
    return {
      ...(pricingData && filterPrimaryData(pricingData)),
      ...defaultData,
      ...filterPrimaryData(slice),
    };
  }, filteredCustomPage);

  return merged[0];
};

const mergeItems = (items, defaultItems) => {
  if (!defaultItems) {
    return items;
  }

  const mergedItems = defaultItems.map((item, index) => {
    const currentCustomItem = items?.[index];

    if (!currentCustomItem) return item;
    const sanitizedItem = filter(Boolean, currentCustomItem);
    const itemsWithoutEmpty = map((value) => (value === 'false' ? false : value), sanitizedItem);

    return {
      ...item,
      ...itemsWithoutEmpty,
    };
  });

  return mergedItems;
};

// Tiering price data contains both primary and items
export const normalizeTieringPriceData = (customPage, defaultPage, countryCode) => {
  const mergedPrimaryPriceData = normalizePriceData(customPage, defaultPage);
  if (!mergedPrimaryPriceData) {
    return null;
  }
  const customItems = customPage?.data?.body?.find((slice) => slice.items) || [];
  const defaultItems = defaultPage?.data?.body?.find((slice) => slice.items) || [];

  const mergedItems = mergeItems(customItems.items, defaultItems.items);
  const bauPromoGroup = customPage?.data?.bau_promo_group;

  let pageDataPromos = {};
  if (bauPromoGroup && countryCode) {
    bauPromoGroup.forEach((promo) => {
      if (
        (promo?.country === countryCode || checkPromoCountry(promo?.country, countryCode)) &&
        promo?.promotion &&
        promo?.promo_code
      ) {
        pageDataPromos = promo;
      }
    });
  }
  const pageDataAddOn = customPage?.data?.addon_content || [];

  const merged = {
    ...mergedPrimaryPriceData,
    items: mergedItems,
    promos: pageDataPromos,
    voucherContent: pageDataAddOn,
  };

  return merged;
};
