import React, { FC } from 'react';

import { HomepageFooterWrapper, HomepageFooterContent, StyledLink } from './style';

interface Props {
  content: Queries.PrismicNewWebHomepageDataBodyHomepageFooter;
}

const HomepageFooter: FC<Props> = ({ content }) => (
  <HomepageFooterWrapper>
    <HomepageFooterContent>
      {`${content?.primary?.footer_content} `}
      <StyledLink to={content?.primary?.footer_link}>
        {content?.primary?.footer_link_text}
      </StyledLink>
    </HomepageFooterContent>
  </HomepageFooterWrapper>
);

export default HomepageFooter;
