/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Dialog, DialogContent, DialogActions } from '@lesmills-international/components';
import { userContext } from '../../../../../gatsby-theme-engagement/src/context';
import useCancelSubscription from '../../../hooks/mutation/useCancelSubscription';
import ProcessingMsg from '../../common/ProcessingMsg/ProcessingMsg';
import { CancellationSurvey, DialogButtonWrapper, DialogTextButton, SurveyWrapper } from './styles';
import { ReactComponent as OfferIcon } from '../../../assets/icons/offer.svg';
import { addErrorInDatadogRum, getLocalStorage } from '../../../utils/utilities';
import ALREADY_SEEN_MEMBER_PROMO_OFFER from '../constants';

interface Props {
  prismicData: Pick<
    Queries.PrismicAccountOverviewPageData,
    'keep_subscription' | 'redeem_offer_button_text' | 'loading_process_text'
  >;
  isOpened: boolean;
  handleClose: () => void;
  displayOfferButton: boolean;
  handleDisplayMemberPromoModal: () => void;
  handleReedemOffer: () => void;
  handleOpenCancellationSuccessModal: () => void;
  allowMemberPromoFullJourney: boolean;
  setCancelSubscriptionError: (isErr: boolean) => void;
}

const CancellationModal = ({
  isOpened,
  handleClose,
  prismicData,
  displayOfferButton,
  handleDisplayMemberPromoModal,
  handleReedemOffer,
  handleOpenCancellationSuccessModal,
  allowMemberPromoFullJourney,
  setCancelSubscriptionError,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const { lmodSubscription, chargifyUserId, refetchUser } = useContext(userContext);

  const { keep_subscription, loading_process_text, redeem_offer_button_text } = prismicData;

  const surveyIframeRef = useRef<HTMLIFrameElement>(null);
  const hasSeenMemberPromoOffer = getLocalStorage(ALREADY_SEEN_MEMBER_PROMO_OFFER) === 'true';

  const [onCancelSubscription] = useCancelSubscription({
    onCompleted: async () => {
      refetchUser && (await refetchUser());
      setIsLoading(false);
      handleClose();
      handleOpenCancellationSuccessModal();
    },
    onError: (err) => {
      setIsLoading(false);
      handleClose();
      setCancelSubscriptionError(true);
      addErrorInDatadogRum(err);
    },
  });

  const handleCancelSubscription = useCallback(() => {
    setIsLoading(true);
    onCancelSubscription();
  }, [onCancelSubscription]);

  const handleMessage = useCallback(
    (event: MessageEvent<any>) => {
      const isSurveyEndEvent =
        event.source === surveyIframeRef.current?.contentWindow || event.data === 'endOfSurvey';

      if (!isSurveyEndEvent) {
        return;
      }

      if (!hasSeenMemberPromoOffer && allowMemberPromoFullJourney && displayOfferButton) {
        handleDisplayMemberPromoModal();
      } else {
        handleCancelSubscription();
      }
    },
    [
      hasSeenMemberPromoOffer,
      allowMemberPromoFullJourney,
      displayOfferButton,
      handleDisplayMemberPromoModal,
      handleCancelSubscription,
    ]
  );

  useEffect(() => {
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [handleMessage]);

  const handleOnLoad = () => {
    const iframeWindow = surveyIframeRef.current && surveyIframeRef.current.contentWindow;
    setTimeout(() => {
      if (iframeWindow) {
        const chargifyIdMatch = chargifyUserId?.match(/:(\d+)/);
        const subscriptionIdMatch = lmodSubscription?.subscription_id?.match(/:(\d+)/);

        if (chargifyIdMatch) {
          iframeWindow.postMessage(`chargifyId-${chargifyIdMatch[1]}`, '*');
        }
        if (subscriptionIdMatch) {
          iframeWindow.postMessage(`subscriptionId-${subscriptionIdMatch[1]}`, '*');
        }
      }
    }, 500);
  };

  // block closing a modal while it's loading
  const onModalClose = () => {
    if (isLoading) {
      return false;
    }
    handleClose();
  };

  return (
    <Dialog open={isOpened} onClose={onModalClose} fullWidth maxWidth="md">
      {isLoading ? (
        <ProcessingMsg loadingMsg={loading_process_text || ''} />
      ) : (
        <>
          <DialogContent>
            <SurveyWrapper>
              <CancellationSurvey
                title="cancellation survey iframe"
                name="cancellation survey"
                ref={surveyIframeRef}
                src={process.env.GATSBY_EXIT_SURVEY_URL}
                onLoad={handleOnLoad}
              />
            </SurveyWrapper>
          </DialogContent>

          <DialogActions>
            <DialogButtonWrapper>
              <DialogTextButton onClick={handleClose}>{keep_subscription}</DialogTextButton>
              {displayOfferButton && !hasSeenMemberPromoOffer && !allowMemberPromoFullJourney && (
                <DialogTextButton
                  onClick={() => {
                    handleClose();
                    handleReedemOffer();
                  }}
                >
                  <OfferIcon style={{ marginRight: '6px' }} />
                  <span>{redeem_offer_button_text}</span>
                </DialogTextButton>
              )}
            </DialogButtonWrapper>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default CancellationModal;
