import React, { FC } from 'react';
import { Link } from '@components/atoms';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import classNames from 'classnames';
import { formatDate } from '@services';

interface Props {
  title?: string;
  blurb?: string;
  url?: string;
  lastPublicationDate: string;
  image?: {
    gatsbyImageData?: IGatsbyImageData;
    alt?: string;
  };
  small?: boolean;
  hasDarkBackground?: boolean;
}

const ArticleItemHorizontal: FC<Props> = ({
  title,
  blurb,
  url,
  image,
  small,
  lastPublicationDate,
  hasDarkBackground,
}) => (
  <Link
    to={url}
    className={classNames(
      'grid grid-cols-5',
      hasDarkBackground ? 'hover:shadow-bottom-white' : 'hover:shadow-bottom'
    )}
  >
    <div className="col-span-5 md:col-span-2 order-2 md:order-1">
      {image?.gatsbyImageData && (
        <GatsbyImage
          image={image.gatsbyImageData}
          alt={image.alt ?? ''}
          className="object-cover w-full h-64 md:h-full z-10"
        />
      )}
    </div>
    <div
      className={classNames(
        'col-span-5 md:col-span-3 bg-white space-y-4 md:order-1 flex flex-col',
        small ? 'py-5 px-6' : 'px-6 md:px-10 py-6 md:py-8'
      )}
    >
      {title && (
        <h2 className="text-grey font-inter-semibold leading-tight text-24 uppercase">{title}</h2>
      )}

      <div className="flex-grow">
        {blurb && (
          <p
            className={classNames(
              'font-inter-light text-14 text-grey opacity-75',
              small ? 'line-clamp-6' : 'line-clamp-10'
            )}
          >
            {blurb}
          </p>
        )}
      </div>
      <div className="font-inter-light text-14 text-grey opacity-75">
        {formatDate(lastPublicationDate)}
      </div>
    </div>
  </Link>
);

export default ArticleItemHorizontal;
