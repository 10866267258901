import { AnalyticsBrowser } from '@segment/analytics-next';
import { addErrorInDatadogRum } from '../utilities';

const analytics = AnalyticsBrowser.load({
  writeKey: process.env.GATSBY_SEGMENT_WEB_APP_WRITE_KEY as any,
});

interface TrackEventType {
  event_name: VideoPlayState | DownloadAPPEvent;
}

interface SegmentVideoType {
  platform: string;
  total_length: number;
  position: number;
  session_id: string;
  content_asset_id: string;
  title: string;
  asset_id?: string;
}

enum VideoPlayState {
  VIDEO_PLAYBACK_STARTED = 'Video Playback Started',
  VIDEO_PLAYBACK_PAUSED = 'Video Playback Paused',
  VIDEO_PLAYBACK_BUFFER_STARTED = 'Video Playback Buffer Started',
  VIDEO_PLAYBACK_BUFFER_COMPLETED = 'Video Playback Buffer Completed',
  VIDEO_PLAYBACK_SEEK_STARTED = 'Video Playback Seek Started',
  VIDEO_PLAYBACK_SEEK_COMPLETED = 'Video Playback Seek Completed',
  VIDEO_PLAYBACK_RESUMED = 'Video Playback Resumed',
  VIDEO_PLAYBACK_COMPLETED = 'Video Playback Completed',
  VIDEO_CONTENT_PLAYING = 'Video Content Playing',
}

enum DownloadAPPEvent {
  DOWNLOAD_APP_LINK_CLICKED = 'Download APP Link Clicked',
  DOWNLOAD_APP_LEARN_MORE_LINK_CLICKED = 'Download APP Learn More Link Clicked',
  DOWNLOAD_APP_CLOSE_BUTTON_CLICKED = 'Download APP Close Button Clicked',
}

async function vimeoUserIdentity(vimeoUserId: string): Promise<void> {
  try {
    await analytics.identify(vimeoUserId);
  } catch (error) {
    addErrorInDatadogRum(error);
  }
}

async function trackEvent(traits: TrackEventType, segmentVideo?: SegmentVideoType): Promise<void> {
  try {
    await analytics.track(traits.event_name, segmentVideo);
  } catch (error) {
    // suppress errors
    addErrorInDatadogRum(error);
  }
}

async function trackVideoStartedEvent(segmentVideo: SegmentVideoType): Promise<void> {
  await trackEvent({ event_name: VideoPlayState.VIDEO_PLAYBACK_STARTED }, segmentVideo);
}

async function trackVideoPausedEvent(segmentVideo: SegmentVideoType): Promise<void> {
  await trackEvent({ event_name: VideoPlayState.VIDEO_PLAYBACK_PAUSED }, segmentVideo);
}

async function trackVideoBufferStartedEvent(segmentVideo: SegmentVideoType): Promise<void> {
  await trackEvent({ event_name: VideoPlayState.VIDEO_PLAYBACK_BUFFER_STARTED }, segmentVideo);
}

async function trackVideoBufferCompletedEvent(segmentVideo: SegmentVideoType): Promise<void> {
  await trackEvent({ event_name: VideoPlayState.VIDEO_PLAYBACK_BUFFER_COMPLETED }, segmentVideo);
}
async function trackVideoSeekStartedEvent(segmentVideo: SegmentVideoType): Promise<void> {
  await trackEvent({ event_name: VideoPlayState.VIDEO_PLAYBACK_SEEK_STARTED }, segmentVideo);
}

async function trackVideoSeekCompletedEvent(segmentVideo: SegmentVideoType): Promise<void> {
  await trackEvent({ event_name: VideoPlayState.VIDEO_PLAYBACK_SEEK_COMPLETED }, segmentVideo);
}
async function trackVideoResumed(segmentVideo: SegmentVideoType): Promise<void> {
  await trackEvent({ event_name: VideoPlayState.VIDEO_PLAYBACK_RESUMED }, segmentVideo);
}

async function trackVideoCompletedEvent(segmentVideo: SegmentVideoType): Promise<void> {
  await trackEvent({ event_name: VideoPlayState.VIDEO_PLAYBACK_COMPLETED }, segmentVideo);
}

async function trackVideoContentPlaying(segmentVideo: SegmentVideoType): Promise<void> {
  await trackEvent({ event_name: VideoPlayState.VIDEO_CONTENT_PLAYING }, segmentVideo);
}

async function trackDownloadAppLinkClickedEvent(): Promise<void> {
  await trackEvent({ event_name: DownloadAPPEvent.DOWNLOAD_APP_LINK_CLICKED });
}

async function trackDownloadAppLearnMoreLinkClickedEvent(): Promise<void> {
  await trackEvent({ event_name: DownloadAPPEvent.DOWNLOAD_APP_LEARN_MORE_LINK_CLICKED });
}

async function trackDownloadAppCloseButtonClickedEvent(): Promise<void> {
  await trackEvent({ event_name: DownloadAPPEvent.DOWNLOAD_APP_CLOSE_BUTTON_CLICKED });
}

export {
  vimeoUserIdentity,
  trackVideoStartedEvent,
  trackVideoCompletedEvent,
  trackVideoPausedEvent,
  trackVideoBufferStartedEvent,
  trackVideoBufferCompletedEvent,
  trackVideoSeekCompletedEvent,
  trackVideoSeekStartedEvent,
  trackVideoResumed,
  trackVideoContentPlaying,
  trackDownloadAppLinkClickedEvent,
  trackDownloadAppLearnMoreLinkClickedEvent,
  trackDownloadAppCloseButtonClickedEvent,
};
