import styled from 'styled-components';
import { themes } from '@lesmills-international/components';
import { breakpoint } from '../../themes/breakpoints';

export const SectionTitle = styled.h2`
  font-size: 14px;
  font-family: 'Inter SemiBold';
  line-height: 20px;
  margin-bottom: 7px;

  color: ${({ theme }) => themes[theme.themeColor].colors.contentSecondary};

  ${breakpoint('lg')`
  font-size: 16px;
  margin-bottom: 12px;
  line-height: 24px;
  `}
`;

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
`;

export const PaymentDescriptionConatiner = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const RichTextWrapper = styled.div`
  a {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const RestartSubscriptionPopUpContent = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: 32px;
`;

export const RestartSubscriptionPopUpContentTitle = styled.p`
  font-size: 18px;
  font-family: 'Inter SemiBold';
  line-height: 20px;
  margin-bottom: 7px;
  margin-top: 12px;
  color: ${({ theme }) => themes[theme.themeColor].colors.contentPrimary};
`;

export const RestartSubscriptionPopUpContentDescription = styled.div`
  font-size: 16px;
  font-family: 'Inter Regular';
  line-height: 20px;
  margin-bottom: 28px;
  color: ${({ theme }) => themes[theme.themeColor].colors.contentPrimary};

  ul {
    list-style: inside;
    margin-left: 8px;

    li {
      list-style-type: '•';
    }

    span {
      position: relative;
      left: 8px;
    }
  }

  p {
    margin-bottom: 8px;
  }
`;

export const EmailPreferenceDescription = styled.div`
  width: 80%;
  > * {
    &:not(:last-child) {
      margin-bottom: 12px;
    }

    &.error {
      color: ${({ theme }) => themes[theme.themeColor].colors.IconNegative};
    }

    &.success {
      color: ${({ theme }) => themes[theme.themeColor].colors.IconPositive};
    }
  }
`;

export const EmailPreferenceInfo = styled.div`
  p {
    color: ${({ theme }) => themes[theme.themeColor].colors.IconPositive};
  }

  a {
    text-decoration: underline;
  }
`;

export const PendingCancellationMessage = styled.div`
  color: ${({ theme }) => themes[theme.themeColor].colors.contentNegative};
`;

export const Button = styled.button`
  text-decoration: underline;
`;
