import { useMemo, useState } from 'react';
import useGetPromoCodeInfo from '../../../hooks/queries/useGetPromoCodeInfo';
import { checkOfferCountryEligibility, getProductName, mapOfferByProductName } from '../utils';
import { Subscription } from '../../../../../gatsby-theme-engagement/src/backend';
import { Tier } from '../../../../../../src/type/subscriptions';
import { addErrorInDatadogRum } from '../../../utils/utilities';

interface Props {
  subscription: Subscription;
  tier: Tier;
  prismicData: Queries.PrismicMemberPromoPopupDataFirstOfferItem[];
  addressCountry?: string | null;
}

const useValidateMemberPromo = ({ subscription, tier, prismicData, addressCountry }: Props) => {
  const [hasValidOffer, setHasValidOffer] = useState(false);

  const productName = useMemo(() => getProductName(tier, subscription), [tier, subscription]);

  const offer = useMemo(
    () => mapOfferByProductName(prismicData, productName),
    [prismicData, productName]
  );

  const countryAvailable = useMemo(
    () =>
      checkOfferCountryEligibility(
        addressCountry,
        (offer as Queries.PrismicMemberPromoPopupDataFirstOfferItem)
          ?.full_member_promo_journey_country_list
      ),
    [addressCountry, offer]
  );

  const { loading } = useGetPromoCodeInfo({
    skip: !offer?.promo_code,
    variables: {
      promoCode: offer?.promo_code,
    },
    onCompleted: () => {
      setHasValidOffer(!!offer?.promo_code);
    },
    onError: (err) => {
      addErrorInDatadogRum(err);
    },
  });

  return { hasValidOffer, offer, countryAvailable, loading };
};

export default useValidateMemberPromo;
