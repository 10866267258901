import { gql } from '@apollo/client';

const doesUserExist = gql`
  query doesUserExist(
    $email: AWSEmail!
    $validationData: CognitoUserAttribute!
    $captchaSignature: CaptchaSignatureInput
  ) {
    doesUserExist(
      email: $email
      validationData: $validationData
      captchaSignature: $captchaSignature
    )
  }
`;

export default doesUserExist;
