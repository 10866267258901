import React, { FC, useContext } from 'react';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { graphql, PageProps } from 'gatsby';
import styled from 'styled-components';

import { breakpoint } from '@themes';
import { withPrivateRoute, Layout } from '@components/molecules';
import SliceZone from '@components/sliceZone';
import { userContext } from '@context';
import { TIERS } from '@constants';
import PageName from '../types/pageName';

const ExploreWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.black};
  padding-bottom: 40px;

  .pagination--wrapper {
    display: none;
  }

  .hero--slice--wrapper {
    ${breakpoint('lg')`
      height: 650px;
    `}

    ${breakpoint('xl')`
      height: 850px !important;
    `}

    ${breakpoint('xxl')`
      height: 1000px !important;
    `}
  }

  .hero--card {
    ${breakpoint('lg')`
      height: 650px;
    `}

    ${breakpoint('xl')`
      height: 850px !important;
    `}

    ${breakpoint('xxl')`
      height: 1000px !important;
    `}
  }

  .hero--slice--wrapper .arrow--prev,
  .hero--slice--wrapper .arrow--next {
    ${breakpoint('lg')`
      top: calc(540px/2 - 18px);
    `}

    ${breakpoint('xl')`
      top: calc(640px/2 - 18px);
    `}

    ${breakpoint('xxl')`
      top: calc(840px/2 - 18px);
    `}
  }

  .content--left {
    ${breakpoint('lg')`
      height: 540px;
    `}

    ${breakpoint('xl')`
    height: 640px !important;
  `}

  ${breakpoint('xxl')`
    height: 840px !important;
  `}
  }

  .program--wrapper {
    ${breakpoint('lg')`
      position: absolute;
      z-index: 1;
      top: 528px;
      background: transparent;
    `}

    ${breakpoint('xl')`
      top: 628px !important;
    `}

    ${breakpoint('xxl')`
      top: 828px !important;
    `}
  }

  & .slice--container > div:nth-child(3) {
    ${breakpoint('lg')`
      padding-top: 210px;
    `}

    ${breakpoint('xl')`
      padding-top: 108px !important;
    `}

    ${breakpoint('xxl')`
      padding-top: 160px !important;
    `}
  }
`;

interface PageContextProps {
  lang: string;
}

interface PrismicDataMapping {
  [key: string]: Queries.Maybe<Queries.exploreFieldsFragment>;
}

const ExploreTemplate: FC<PageProps<Queries.GetExploreDataQuery, PageContextProps>> = ({
  data,
}) => {
  const { tier } = useContext(userContext);
  const { prismicExploreBase, prismicExplorePremium, lesMillsPlusBackend } = data || {};

  const prismicDataMapping: PrismicDataMapping = {
    [TIERS.BASE]: prismicExploreBase,
    [TIERS.PREMIUM]: prismicExplorePremium,
  };

  const prismicData = prismicDataMapping[tier as keyof typeof prismicDataMapping];

  return (
    <Layout
      metaData={{
        title: prismicData?.data?.meta_title,
        description: prismicData?.data?.meta_description,
      }}
    >
      <ExploreWrapper>
        <SliceZone
          slices={prismicData?.data?.body}
          listPrograms={lesMillsPlusBackend?.listPrograms?.items}
          pageName={PageName.EXPLORE}
        />
      </ExploreWrapper>
    </Layout>
  );
};

export default withPrismicPreview(withPrivateRoute(ExploreTemplate));

export const query = graphql`
  query GetExploreData($lang: String!, $exploreBase: String, $explorePremium: String) {
    prismicExploreBase: prismicExplore(lang: { eq: $lang }, uid: { eq: $exploreBase }) {
      ...exploreFields
    }
    prismicExplorePremium: prismicExplore(lang: { eq: $lang }, uid: { eq: $explorePremium }) {
      ...exploreFields
    }
    lesMillsPlusBackend {
      listPrograms {
        items {
          categories
          alternateName
          imageWide
          name
          icon
          tiers
        }
      }
    }
  }

  fragment exploreFields on PrismicExplore {
    data {
      meta_description
      meta_title
      body {
        ... on PrismicExploreDataBodyExploreHeroCarousel {
          id
          slice_type
          items {
            image {
              url
            }
            promo_text
            promo_color
            show_promo
            main_text
            tag
            lead_text
            desc_text
            display_equipment_section
            equipment_bench_step
            equipment_foam_roller
            equipment_massage_ball
            equipment_mat
            equipment_mini_trampolim
            equipment_resistance_band
            equipment_stationary_bike
            equipment_weights
            button_secondary_text
            button_primary_text
            button_primary {
              url
            }
            button_secondary {
              url
            }
          }
        }
        ... on PrismicExploreDataBodyProgramsWithCategoryFilter {
          id
          slice_type
          primary {
            title
            view_all_text
            view_all_link
          }
        }
        ... on PrismicExploreDataBodyWorkoutVideoCarousel {
          id
          slice_type
          primary {
            title
            view_all_text
            view_all_link
          }
          items {
            video_id
          }
        }
        ... on PrismicExploreDataBodyAlbum {
          id
          slice_type
          primary {
            title
            view_all_text
            view_all_link
          }
          items {
            title
            description
            detail_link
            background_image {
              url
            }
          }
        }
        ... on PrismicExploreDataBodyAlbumsCarousel {
          id
          slice_type
          primary {
            title
            view_all_text
            view_all_link
          }
          items {
            label
            topic
            description
            detail_link
            title
            background_image {
              url
            }
          }
        }
      }
    }
  }
`;
