/* eslint max-len: ["error", { "code": 120 }] */
import React, { useCallback, useEffect } from 'react';
import { Auth } from '@aws-amplify/auth';
import { graphql, navigate } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { useFlags } from 'gatsby-plugin-launchdarkly';
// eslint-disable-next-line import/no-unresolved
import { parseJwt } from '@src/utils';

// eslint-disable-next-line import/no-unresolved
import {
  isBrowser,
  deleteLocalStorage,
  addErrorInDatadogRum,
  getLocalStorage,
} from '../utils/utilities';
import { getTieringLaunchFlag } from '../utils/tiering';

import Layout from '../components/common/layout';
import Success from '../components/signup/success';
import { TIERS } from '../../../../src/constants/tiering';
import useGetCountryCode from '../hooks/useGetCountryCode';

const SuccessTemplate = ({ data, location, pageContext }) => {
  const { prismicSignUpLayout, prismicSignUpSuccessPage } = data;
  const { lang } = pageContext;
  const { countryCode } = useGetCountryCode();
  const { rtTierProductCountries } = useFlags();

  const selectedPlan = JSON.parse(getLocalStorage('selected-plan') || 'null');
  const newSubscription = JSON.parse(getLocalStorage('new-subscription') || 'null');
  const userInfo = JSON.parse(getLocalStorage('user-info') || 'null');

  const clearLocalStroage = useCallback(() => {
    deleteLocalStorage('new-subscription');
    deleteLocalStorage('planId');
    deleteLocalStorage('selected-plan');
  }, []);

  useEffect(() => {
    // delete local storage after 500ms
    const timer = setTimeout(() => clearLocalStroage(), 500);
    return () => clearTimeout(timer);
  }, [clearLocalStroage]);

  const handleStarted = async () => {
    if (isBrowser) {
      // Refresh JWT token if tiering feature is enabled
      const isTieringEnable = getTieringLaunchFlag(countryCode, rtTierProductCountries, true);
      if (isTieringEnable) {
        try {
          const cognitoUser = await Auth.currentAuthenticatedUser();
          const currentSession = await Auth.currentSession();
          cognitoUser.refreshSession(currentSession.getRefreshToken(), (err, session) => {
            if (err) {
              addErrorInDatadogRum(err);
              navigate('/404');
            }
            localStorage.setItem('jwtToken', session?.idToken?.jwtToken);
            const userInfoJwt = parseJwt(session?.idToken?.jwtToken);
            const isBaseTier = userInfoJwt?.tierId?.includes(TIERS.BASE);
            const redirectionUrl = !isBaseTier ? '/survey/start' : '/explore';
            window.location.href = redirectionUrl;
          });
        } catch (error) {
          addErrorInDatadogRum(error);
          navigate('/404');
        }
      } else {
        const redirectionUrl = '/survey/start';
        window.location.href = redirectionUrl;
      }
    }
  };

  return (
    <Layout
      data={prismicSignUpLayout.data}
      contentWidth={380}
      omitClose
      lang={lang}
      pathName="/signup/success"
    >
      <Success
        data={prismicSignUpSuccessPage.data}
        handleStarted={handleStarted}
        prismicSignUpLayout={prismicSignUpLayout}
        pageContext={pageContext}
        location={location}
        selectedPlan={selectedPlan}
        newSubscription={newSubscription}
        userInfo={userInfo}
      />
    </Layout>
  );
};
export default withPrismicPreview(SuccessTemplate);

export const query = graphql`
  query ($lang: String!) {
    prismicSignUpSuccessPage(lang: { eq: $lang }) {
      _previewable
      data {
        page_title
        description
        subtitle
        get_started
        dont_refresh
        welcome_aboard
        all_set_image {
          alt
          gatsbyImageData(width: 80, height: 80)
        }
        title {
          text
        }
      }
    }
    prismicSignUpLayout(lang: { eq: $lang }) {
      _previewable
      data {
        logo_white {
          alt
          gatsbyImageData(width: 133, height: 33)
        }
        logo_black {
          alt
          gatsbyImageData(width: 133, height: 33)
        }
        background_image {
          alt
          gatsbyImageData
        }
        body {
          ... on PrismicSignUpLayoutDataBodyFooterNavigation {
            items {
              nav_label {
                text
              }
              nav_link {
                text
              }
            }
          }
        }
      }
    }
  }
`;
