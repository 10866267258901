import { CHARGIFY_ERRORS, BRAINTREE_ERRORS } from '../constants/chargify-errors';

export const getChargifyErrorPrismic = (errorMessage) => {
  let chargifyPrismicError = 'saving_payment_method_error';
  if (typeof errorMessage === 'string' || Array.isArray(errorMessage)) {
    const errorKey = Object.keys(CHARGIFY_ERRORS).find((item) => errorMessage.includes(item));
    chargifyPrismicError = errorKey ? CHARGIFY_ERRORS[errorKey] : 'saving_payment_method_error';
  }
  return chargifyPrismicError;
};

export const shouldShowOriginalBraintreeError = (errorMessage) => {
  // Error string example: "Processor declined: Do Not Honor (2000)"
  // When 3DS is enabled, 3DS errors will be returned and it's array, so no need check for 3DS array error
  if (typeof errorMessage === 'string') {
    return BRAINTREE_ERRORS.includes(errorMessage.match(/\d+/)?.[0]);
  }
  return false;
};
