/* eslint-disable @typescript-eslint/naming-convention */
import React, { useContext } from 'react';
import { Dialog, DialogContent, DialogActions } from '@lesmills-international/components';
import { formatPendingCancelDesc } from '../utils';
import { userContext } from '../../../../../gatsby-theme-engagement/src/context';
import {
  DialogButtonWrapper,
  DialogTextButton,
  CancellationSuccessTitle,
  CancellationSuccessDescription,
  RestartSubscriptionDescription,
  ResubscribeTextButton,
} from './styles';

interface Props {
  prismicData: Pick<
    Queries.PrismicAccountOverviewPageData,
    | 'cancellation_success_popup_title'
    | 'cancellation_success_popup_description'
    | 'restart_subscription_description'
    | 'close_button_label'
  >;
  isOpened: boolean;
  handleClose: () => void;
  handleOpenRestartSubscription: () => void;
}

const CancellationModal = ({
  isOpened,
  handleClose,
  prismicData,
  handleOpenRestartSubscription,
}: Props) => {
  const { lmodSubscription } = useContext(userContext);
  const {
    cancellation_success_popup_title,
    cancellation_success_popup_description,
    restart_subscription_description,
    close_button_label,
  } = prismicData;

  const closeModal = () => {
    handleClose();
  };

  const formatRestartDesc = (description: string | null) => {
    if (!description) {
      return null;
    }
    const parts = description.split('{resubscribe_button}');
    return (
      <RestartSubscriptionDescription>
        {parts[0]}
        <ResubscribeTextButton
          onClick={() => {
            closeModal();
            handleOpenRestartSubscription();
          }}
        >
          resubscribe here
        </ResubscribeTextButton>
        {parts[1]}
      </RestartSubscriptionDescription>
    );
  };

  return (
    <Dialog open={isOpened} onClose={closeModal} fullWidth maxWidth="sm">
      <DialogContent>
        <CancellationSuccessTitle>{cancellation_success_popup_title}</CancellationSuccessTitle>
        <CancellationSuccessDescription>
          {formatPendingCancelDesc(cancellation_success_popup_description, lmodSubscription)}
        </CancellationSuccessDescription>
        {formatRestartDesc(restart_subscription_description)}
      </DialogContent>
      <DialogActions>
        <DialogButtonWrapper>
          <DialogTextButton onClick={closeModal}>{close_button_label}</DialogTextButton>
        </DialogButtonWrapper>
      </DialogActions>
    </Dialog>
  );
};

export default CancellationModal;
