import React, { FC } from 'react';

import { FacebookShareButton, TwitterShareButton } from 'react-share';
import { FacebookLogo, TwitterLogo } from 'phosphor-react';

interface Props {
  url: string;
}

const SharingButtons: FC<Props> = ({ url }) => (
  <div className="flex items-center space-x-3">
    <div title="Share on Facebook">
      <FacebookShareButton url={url}>
        <FacebookLogo size={24} />
      </FacebookShareButton>
    </div>

    <div title="Share on Twitter">
      <TwitterShareButton url={url}>
        <TwitterLogo size={24} />
      </TwitterShareButton>
    </div>
  </div>
);

export default SharingButtons;
