import { gql } from '@apollo/client';

const pollSignupStatus = gql`
  query pollSignupStatus($requestId: String!) {
    pollSignupStatus(requestId: $requestId) {
      success
      error
    }
  }
`;

export default pollSignupStatus;
