import { createContext } from 'react';
import { FeatureFlags } from './types';

export interface FFContext {
  flags: FeatureFlags;
}

const context = createContext<FFContext>({ flags: {} });
const { Provider, Consumer } = context;

export { Provider, Consumer };
export default context;
