import { userContext } from '@context';
import { sample } from 'lodash';
import React, { FC, useContext, useMemo } from 'react';

interface Props {
  phrases: Queries.HomePhrasesFragment;
}

const HomeGreeting: FC<Props> = ({ phrases }) => {
  const { givenName } = useContext(userContext);

  const greeting = useMemo(() => sample(phrases.greeting_phrase)?.greeting_copy?.text, [phrases]);
  const sub = useMemo(() => sample(phrases.sub_phrase)?.sub_phrase_copy?.text, [phrases]);

  if (!greeting || !sub || !givenName) return null;

  const greetingWithData = greeting.replace('{first_name}', givenName);

  return (
    <div className="space-y-2">
      <h1 className="text-24 leading-7 tracking-wider font-bold font-inter-semibold text-grey uppercase">
        {greetingWithData}
      </h1>

      <p className="text-13 text-grey tracking-wide">{sub}</p>
    </div>
  );
};

export default HomeGreeting;
