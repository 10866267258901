export const CHANGE_PASSWORD_ERROR_CODES = {
  NotAuthorizedException: 'not_authorized_exception',
  InvalidPasswordException: 'invalid_password_exception',
  LimitExceededException: 'limit_exceeded_exception',
} as const;

export const PERSONAL_INFO_FIELD_NAME = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  EMAIL: 'email',
} as const;

export const existingEmailErrorCode = 'lmod_ucu_err';
