import React from 'react';
import { PageProps, graphql } from 'gatsby';
import { withPrivateRoute } from '../../../gatsby-theme-engagement/src/components/molecules';
import OfferRedemptionTiering from '../components/offerRedemption/templateComponent/OfferRedemptionTiering';

const OfferRedemption = ({ data, location }: PageProps<Queries.GetOfferRedemptionQuery>) => (
  <OfferRedemptionTiering pageData={data} location={location} />
);

export default withPrivateRoute(OfferRedemption);

export const query = graphql`
  query GetOfferRedemption($lang: String!) {
    prismicOfferRedemptionPage(lang: { eq: $lang }) {
      _previewable
      data {
        cancel_button_text
        back_to_home_button_text
        back_to_my_account_button_text
        loading_process_text
        loading_text
        term_and_condition_renewal
        term_and_condition_change_product
        title
        upgrade_button_text
        trimonthly_interval
        monthly_interval
        annual_interval
        logo {
          alt
          gatsbyImageData(width: 133, height: 33)
        }
        offer_banner_text
        offer_redemption_success_title
        offer_redemption_success_description
        cancellation_success_title
        cancellation_success_description
        account_page_link_text
        success_primary_button_text
        success_secondary_button_text
        cancel_subscription_button_text
        try_again_button_text
        pricing_card_description
        cancel_subscription_button_loading_text
        tax_label
        invalid_promo_error {
          richText
          text
        }
        promo_redemption_error {
          richText
          text
        }
        ineligible_account_error {
          richText
          text
        }
        server_error {
          richText
          text
        }
      }
    }
    prismicLesMillsPlusCommonLandingPage(lang: { eq: $lang }) {
      data {
        body {
          ...PricingSectionOfferRedemption
          ...TieringPricingSectionCommonOfferRedemption
        }
      }
      _previewable
      lang
    }
    prismicLesMillsPlusTieringLandingPage(lang: { eq: $lang }) {
      data {
        body {
          ...TieringPricingSectionTieringOfferRedemption
        }
      }
      _previewable
      lang
    }
  }
`;
