import React, { FC, useContext, useEffect } from 'react';
import { navigate } from 'gatsby';
import { Params, Event } from '@src/type/googleAnalytics';
import { UserProvider, authContext, userContext } from '@context';
import { getLocalStorage, pushTracking } from '@src/utils';
import { PARAM_VALUE } from '@constants';
import handleSelectPlanRedirection from '../../../../../gatsby-theme-acquisition/src/utils/selectPlanRedirection';
import { deleteLocalStorage } from '../../../utils/utilities';
import ROUTES from '../../../../../../src/constants/routes';

const withPrivateRoute = <Props extends Record<string, unknown>>(
  Component: React.ComponentType<Props>,
  shouldRenderComponent?: boolean
) => {
  const ComponentWithPrivateRoute: FC<Props> = (props) => (
    <Authenticated>
      <Subscribed shouldRenderComponent={shouldRenderComponent}>
        <Component {...props} />
      </Subscribed>
    </Authenticated>
  );

  return ComponentWithPrivateRoute;
};

const Authenticated: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { isLoading, isAuthenticated } = useContext(authContext);

  // SSR or we're still loading authentication state.
  if (isLoading) {
    return null;
  }
  if (!isAuthenticated) {
    return <RedirectToLogin />;
  }

  return <UserProvider>{children}</UserProvider>;
};

const Subscribed: FC<{
  children: React.ReactNode;
  shouldRenderComponent?: boolean;
}> = ({ children, shouldRenderComponent }) => {
  const { isLoading, hasActiveSubscription, isEntitledToFreeTrial, vimeoUserId } =
    useContext(userContext);

  if (isLoading) {
    return null;
  }

  // push signin event to datalayer only when isManualLogin = "true"
  if (getLocalStorage('isManualLogin') === 'true') {
    pushTracking({
      event: Event.CUSTOMER_LOGIN,
      [Params.CUSTOMER_LOGGED_IN_STATUS]: PARAM_VALUE.TRUE,
      [Params.VIMEO_ID]: vimeoUserId,
      [Params.LOGIN_TYPE]: 'email',
    });
    deleteLocalStorage('isManualLogin');
  }

  if (!hasActiveSubscription && !shouldRenderComponent) {
    return <RedirectToSubscribe isWinback={!isEntitledToFreeTrial} />;
  }

  return <>{children}</>;
};

const RedirectToLogin: FC = () => {
  useEffect(() => {
    navigate(
      `/login/?redirect_to=${encodeURIComponent(window.location.pathname + window.location.search)}`
    );
  }, []);

  return null;
};

const RedirectToSubscribe: FC<{ isWinback: boolean }> = ({ isWinback }) => {
  useEffect(() => {
    if (process.env.GATSBY_RT_19_02_2024_ACCOUNT_OVERVIEW === 'true') {
      navigate(ROUTES().ACCOUNT);
      return;
    }

    // Inactive user will be redirected to my account page
    // Abandoned cart user(new user) who is entitled to free trial will be redirected to select plan page
    if (isWinback) {
      window.location.href = `${process.env.GATSBY_MAIN_LES_MILLS_URL}/customer/account`;
      return;
    }

    const discountInfo = JSON.parse(getLocalStorage('discountInfo')!);

    const selectPlanURL = handleSelectPlanRedirection({
      discountInfoType: discountInfo?.type,
      promotionCode: discountInfo?.data?.promotionCode,
      isDefaultNavigatable: true,
      isFromBAU: getLocalStorage('isFromBAU') === 'true',
    });

    if (selectPlanURL) {
      navigate(selectPlanURL);
    }
  }, [isWinback]);

  return null;
};

export default withPrivateRoute;
