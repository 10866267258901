const ENABLED_3DS_COUNTRIES = [
  'at', // Austria
  'be', // Belgium
  'bg', // Bulgaria
  'hr', // Croatia
  'cy', // Cyprus
  'cz', // Czech Republic
  'dk', // Denmark
  'ee', // Estonia
  'fi', // Finland
  'fr', // France
  'de', // Germany
  'gr', // Greece
  'hu', // Hungary
  'gb', // United Kingdom
  'ie', // Ireland
  'it', // Italy
  'lv', // Latvia
  'lt', // Lithuania
  'lu', // Luxembourg
  'mt', // Malta
  'nl', // Netherlands
  'no', // Norway
  'pl', // Poland
  'pt', // Portugal
  'ro', // Romania
  'sk', // Slovakia
  'si', // Slovenia
  'es', // Spain (Espana)
  'se', // Sweden
];

export default ENABLED_3DS_COUNTRIES;
