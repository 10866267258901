import { HomeSection } from '@components/molecules';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React, { FC, useContext } from 'react';
import { Link } from '@components/atoms';
import { userContext } from '@context';

interface Props {
  content: {
    slice_label?: 'new_user_only' | null;
    primary?: {
      standalone_image?: {
        alt?: string;
        gatsbyImageData?: IGatsbyImageData;
      };
      image_title?: {
        text?: string;
      };
      link?: {
        url?: string;
      };
    };
  };
}

const ImageSlice: FC<Props> = ({ content }) => {
  const { isNewUser } = useContext(userContext);
  if (content.slice_label === 'new_user_only' && !isNewUser) {
    return null;
  }
  const title = content.primary?.image_title?.text;
  const image = content.primary?.standalone_image?.gatsbyImageData;
  const alt = content.primary?.standalone_image?.alt ?? '';
  const link = content.primary?.link?.url;

  if (!image) return null;

  return (
    <HomeSection title={title}>
      <Link rel="noreferrer" className="focus:outline-none" to={link}>
        <GatsbyImage image={image} alt={alt} className="rounded-md overflow-hidden z-10" />
      </Link>
    </HomeSection>
  );
};

export default ImageSlice;
