import React, { FC, useContext } from 'react';

import { useWorkoutByNameQuery } from '@backend';
import { VideoCard } from '@lesmills-international/components';
import { getWorkoutForVideoCard } from '@services';
import { userContext } from '@context';

import Carousel from '@components/atoms/carousel/Carousel';

import {
  VideoCarouselSliceWrapper,
  TitleWrapper,
  Title,
  ViewAllLink,
  CarouselWrapper,
} from './style';

interface AnalyticsProps {
  contentListTitle: string;
  contentListIndex: number;
  contentLocation: string;
}

interface Props {
  content: Queries.PrismicExploreDataBodyWorkoutVideoCarousel;
  analytics: AnalyticsProps;
}

interface WorkOutComponentProps {
  videoId: string;
  vimeoTicket: string;
  index: string;
  analytics: AnalyticsProps;
}

const CAROUSEL_BREAKPOINTS = {
  600: {
    slidesPerView: 3,
    slidesPerGroup: 3,
    spaceBetween: 12,
  },
  1024: {
    slidesPerView: 4,
    slidesPerGroup: 4,
    spaceBetween: 16,
  },
  1440: {
    slidesPerView: 5,
    slidesPerGroup: 5,
    spaceBetween: 16,
  },
  1800: {
    slidesPerView: 6,
    slidesPerGroup: 6,
    spaceBetween: 16,
  },
};

const WorkOutComponent: FC<WorkOutComponentProps> = ({ videoId, userInfo, index, analytics }) => {
  const { data: workoutData, loading } = useWorkoutByNameQuery({
    variables: {
      product: 'LMOD',
      workoutName: videoId,
    },
  });

  if (loading) return null;

  if (workoutData?.workoutByName?.items?.length === 0) return null;

  const item = workoutData?.workoutByName?.items?.[0];

  return (
    <VideoCard
      {...getWorkoutForVideoCard({
        item,
        userInfo,
        index,
        contentListTitle: analytics?.contentListTitle,
        contentListIndex: analytics?.contentListIndex,
        contentLocation: analytics?.contentLocation,
      })}
    />
  );
};

const VideoCarouselSlice: React.FC<Props> = ({ content, analytics }) => {
  const userInfo = useContext(userContext);
  const vimeoTicket = userInfo?.userEntitlements?.LMOD?.vimeoUserTicket;

  // generate the video array
  let videos: JSX.Element[] = [];
  if (content.items) {
    videos = content?.items.map((video, index) => (
      <WorkOutComponent
        videoId={video?.video_id}
        userInfo={userInfo}
        index={index + 1}
        analytics={analytics}
      />
    ));
  }
  if (content.slice_label === 'new_user_only' && !userInfo.isNewUser) {
    return null;
  }
  return (
    <VideoCarouselSliceWrapper className="video--carousel--wrapper">
      <TitleWrapper>
        <Title>{content?.primary?.title}</Title>
        {content?.primary?.view_all_text && (
          <ViewAllLink href={`${content?.primary?.view_all_link}?ticket=${vimeoTicket}`}>
            {content?.primary?.view_all_text}
          </ViewAllLink>
        )}
      </TitleWrapper>
      <CarouselWrapper>
        <Carousel
          slidesPerView={2}
          slidesPerGroup={2}
          spaceBetween={8}
          // breakpoints are mobile firss
          breakpoints={CAROUSEL_BREAKPOINTS}
          // tempoarary until old homepage removed- see Jira LME-1187
          items={videos}
        />
      </CarouselWrapper>
    </VideoCarouselSliceWrapper>
  );
};

export default VideoCarouselSlice;
