import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  background-color: ${(props) => props.theme.colors.white};

  & .payment-title {
    margin-bottom: 17px;
  }

  & .MuiFormControl-root.MuiTextField-root {
    width: 100%;
    margin: 0 0 12px 0;
  }
`;

export const Container = styled.div`
  width: 320px;
`;
