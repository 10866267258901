import styled from 'styled-components';
import { breakpoint } from '@themes';

const CarouselWrapper = styled.div`
  width: 100%;
  position: relative;

  & .swiper-wrapper {
    margin-bottom: 15px;

    ${breakpoint('sm')`
      margin-bottom: 0;
    `}
  }

  & .arrow--prev {
    display: none;
    ${breakpoint('lg')`
			display: flex;
			position: absolute;
			top: calc(50% - 18px);
			left: 40px;
			z-index: 2;
		`}
  }

  & .arrow--next {
    display: none;
    ${breakpoint('lg')`
			display: flex;
			position: absolute;
			top: calc(50% - 18px);
			right: 40px;
			z-index: 2;
		`}
  }

  & .home-arrow--prev {
    display: flex;
    position: absolute;
    z-index: 2;
    top: calc(50% - 18px);
    left: 16px;
    ${breakpoint('sm')`
      left: 40px;
    `}
  }

  & .home-arrow--next {
    display: flex;
    position: absolute;
    z-index: 2;
    top: calc(50% - 18px);
    right: 16px;
    ${breakpoint('sm')`
      right: 40px;
    `}
  }

  & .swiper-button-disabled {
    display: none;
  }

  & .swiper-pagination {
    bottom: 0;
    height: 8px;
    display: flex;
    justify-content: center;

    ${breakpoint('lg')`
      top: 520px;
    `}

    ${breakpoint('xl')`
      top: 620px;
    `}

    ${breakpoint('xxl')`
      top: 820px;
    `}

    & .swiper-pagination-bullet {
      background: rgba(255, 255, 255, 0.4) !important;
      opacity: 1 !important;
    }

    & .swiper-pagination-bullet-active {
      background: rgba(255, 255, 255, 0.8) !important;
    }
  }
`;

export default CarouselWrapper;
