import { navigate } from 'gatsby';
import { useEffect, useState } from 'react';
import { isBrowser } from '@services';

const SyncSessionPage = () => {
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    // Logic to insure if user lands on this page in browser, to redirect to home page
    const isInIframe = window.self !== window.top;
    setIsReady(isInIframe);

    if (!isInIframe) {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    if (!isReady) {
      return;
    }

    const listener = (event) => {
      // The replace will remove the trailing slash in string
      if (!event.origin.startsWith(`${process.env.GATSBY_MAIN_LES_MILLS_URL}`.replace(/\/$/, ''))) {
        return;
      }

      const { data } = event;
      if (typeof data !== 'object' || !data.action) {
        return;
      }

      const { action, key, value } = data;

      switch (action) {
        case 'removeItem':
          isBrowser && window.localStorage.removeItem(key);
          break;
        case 'setItem':
          isBrowser && window.localStorage.setItem(key, value);
          break;
        case 'clear':
          isBrowser && window.localStorage.clear();
          break;
        default:
          // eslint-disable-next-line no-console
          console.warn('action not supported: ', action);
          break;
      }
    };

    isBrowser && window.addEventListener('message', listener);
    return () => {
      isBrowser && window.removeEventListener('message', listener);
    };
  }, [isReady]);

  return null;
};

export default SyncSessionPage;
