/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import cloneDeep from 'lodash/cloneDeep';
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Alert,
} from '@lesmills-international/components';

import { useCreateSurveyQuestionResponseMutation } from '@backend';
import { addErrorInDatadogRum } from '@utils/utilities';
import { breakpoint } from '@themes';
import captureException from '../../../../../gatsby-theme-acquisition/src/utils/sentry';

import type { QuestionProps, AnswerProps } from './fitnessProfile';
import Answers from './answers';

interface FitnessProfileItemProps {
  data: QuestionProps;
  prismicData?: Pick<
    Queries.PrismicUpdateFitnessProfileData,
    'edit_button_label' | 'save_button_label' | 'selected_text' | 'no_selected_answer_text'
  >;

  updateSelectedAnswers: (questionId: string, selectedAnswersLength: number) => void;
}

interface SelectedAnswerProps {
  key: string;
  value: string;
}

const ItemWrapper = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.lightishGrey};
  padding: 16px 8px;
  font-family: 'Inter SemiBold';
`;

const ItemTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${(props) => props.theme.colors.darkGrey};
  margin-right: 8px;
  margin-bottom: 4px;
`;

const ItemTitle = styled.span`
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
`;

const ItemDescription = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.lightGrey7B};
  font-family: 'Inter Regular';
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SaveButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  & button {
    width: 100%;
  }

  ${breakpoint('md')`
    justify-content: flex-end;
    & button {
      width: auto;
    }
  `}
`;

const FitnessProfileItem: FC<FitnessProfileItemProps> = ({
  data,
  prismicData,
  updateSelectedAnswers,
  ...rest
}: FitnessProfileItemProps) => {
  const defaultAnswers = data?.answers;
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [answers, setAnswers] = useState(defaultAnswers);
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState('');

  const [createSurveyQuestionResponse] = useCreateSurveyQuestionResponseMutation();

  const { edit_button_label, save_button_label, selected_text, no_selected_answer_text } =
    prismicData || {};

  const selectedAnswers = answers?.filter((answer: AnswerProps) => answer.selected);

  const handleOnCloseDialog = () => {
    setAnswers(defaultAnswers);
    setServerError('');
    setOpenEditDialog(false);
  };

  const handleOnOpenDialog = () => {
    setOpenEditDialog(true);
  };

  const saveChangedAnswers = async () => {
    setIsLoading(true);
    const selectedAnswersArray: SelectedAnswerProps[] = [];
    selectedAnswers.forEach((answer) => {
      const answerObj: SelectedAnswerProps = {
        key: '',
        value: '',
      };
      answerObj.key = data?.questionIntent;
      answerObj.value = answer?.attribute?.value;
      selectedAnswersArray.push(answerObj);
    });
    const input = {
      selectedAnswers: selectedAnswersArray,
      questionId: data?.questionId,
    };
    try {
      const response = await createSurveyQuestionResponse({ variables: { input } });
      setIsLoading(false);
      const { status, message } = response?.data?.createSurveyQuestionResponse || {};
      // TODO: BE needs to add errors attribute
      if (status && /^[45]/.test(status) && message) {
        setServerError(message);
        addErrorInDatadogRum(message);
        captureException({
          action: 'updateFitnessProfile',
          error: message,
        });
      } else {
        updateSelectedAnswers(data?.questionId, selectedAnswers.length);
        setOpenEditDialog(false);
      }
    } catch (error) {
      setIsLoading(false);
      addErrorInDatadogRum(error);
      captureException({
        action: 'updateFitnessProfile',
        error,
      });
    }
  };

  const handleOnChangeAnswers = (answerValues: string[]) => {
    setServerError('');
    const cloneAnswers = cloneDeep(answers);
    cloneAnswers.forEach((answer) => {
      if (answerValues.includes(answer?.attribute?.value)) {
        answer.selected = true;
      } else {
        answer.selected = false;
      }
    });
    setAnswers(cloneAnswers);
  };

  useEffect(() => {
    setAnswers(data?.answers);
  }, [data]);

  return (
    <ItemWrapper {...rest}>
      <ItemTitleContainer>
        <ItemTitle>{data?.displayLabel || data?.displayText}</ItemTitle>
        <Button
          variant="text"
          style={{
            textDecoration: 'underline',
            padding: 0,
            textTransform: 'capitalize',
            letterSpacing: 'normal',
          }}
          onClick={handleOnOpenDialog}
        >
          {edit_button_label}
        </Button>
      </ItemTitleContainer>
      <ItemDescription>
        {selectedAnswers && selectedAnswers.length > 0 ? (
          selectedAnswers.map((answer: AnswerProps, index: number) => (
            <span key={index}>{`${answer?.displayText}${
              index !== selectedAnswers.length - 1 ? ', ' : ''
            }`}</span>
          ))
        ) : (
          <span>{no_selected_answer_text}</span>
        )}
      </ItemDescription>
      <Dialog
        title={data?.displayLabel || data?.displayText}
        open={openEditDialog}
        onClose={handleOnCloseDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <ContentWrapper>
            {serverError && (
              <Alert severity="error" hasCloseButton={false} style={{ marginBottom: '24px' }}>
                {serverError}
              </Alert>
            )}
            <span>{data?.displayText}</span>
            <Answers
              AnswersData={answers}
              onChange={handleOnChangeAnswers}
              questionType={data?.questionType}
            />
            <span>
              {selectedAnswers.length} {selected_text}
            </span>
          </ContentWrapper>
        </DialogContent>
        <DialogActions>
          <SaveButtonContainer>
            <Button
              style={{
                borderRadius: '100px',
                textTransform: 'capitalize',
                letterSpacing: 'normal',
                minWidth: '132px',
              }}
              onClick={saveChangedAnswers}
              loading={isLoading}
              disabled={selectedAnswers.length === 0}
            >
              {save_button_label}
            </Button>
          </SaveButtonContainer>
        </DialogActions>
      </Dialog>
    </ItemWrapper>
  );
};

export default FitnessProfileItem;
