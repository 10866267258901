// Disabled eslint for naming of prismic field
/* eslint-disable @typescript-eslint/naming-convention */
import React, { FC, useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import { navigate } from 'gatsby';

import { ChangeEventTarget, MouseEventTarget } from '../../../types/Event';

import TextFieldNew from '../../common/textFieldNew';
import Button from '../../common/button';
import Alert from '../../common/alert/Alert';

import { Note, Title, LoginLink, ButtonWrapper } from '../style';
import { formValidator } from '../../../utils/validator';
import { UserInfo } from '../../../types/UserInfo';
import { createPublicClient } from '../../../services/client';
import captureException from '../../../utils/sentry';
import { addErrorInDatadogRum } from '../../../utils/utilities';
import doesUserExist from '../../../graphql/doesUserExist';
import { ReactComponent as WrongIcon } from '../../../assets/icons/wrong.svg';
import { ReactComponent as CorrectIcon } from '../../../assets/icons/correct.svg';
import { ReactComponent as ShowPassword } from '../../../assets/icons/showPassword.svg';
import { ReactComponent as HidePassword } from '../../../assets/icons/hidePassword.svg';
import { PasswordIconWrapper } from '../../signup/createAccount/style';

interface Props {
  password_dynamic_message?: string;
  confirm_email_title?: string;
  confirm_email_note?: string;
  email_label?: string;
  next_btn?: string;
  login_label?: string;
  password_label?: string;
  prismicErrorData: {
    email_required?: string;
    email_invalid?: string;
    password_required?: string;
    password_invalid?: string;
    password_space_error_message?: string;
    existing_email_message?: string;
  };
  userInfo: UserInfo;
  handleValidateRecaptcha: () => Promise<any>;
  voucher: string;
  tescoVoucherType: string;
}

interface ValidationErrors {
  email: {
    valid: boolean;
    errorMsg: string;
  };
  password: {
    valid: boolean;
    errorMsg: string;
  };
}

const ConfirmEmail: FC<Props> = ({
  password_dynamic_message,
  confirm_email_title,
  confirm_email_note,
  email_label,
  next_btn,
  login_label,
  password_label,
  voucher,
  prismicErrorData,
  userInfo,
  handleValidateRecaptcha,
  tescoVoucherType,
}) => {
  const [values, setValues] = useState<UserInfo>({
    password: '',
    email: userInfo.email,
  });
  const [formErrors, setFormErrors] = useState<ValidationErrors>({
    email: {
      errorMsg: '',
      valid: false,
    },
    password: {
      errorMsg: '',
      valid: false,
    },
  });
  const [emailExisted, setEmailExisted] = useState(false);
  const [isSubmitProcessing, setIsSubmitProcessing] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordHelperText, setPasswordHelperText] = useState<any>('');
  const [serverError, setServerError] = useState('');

  useEffect(() => {
    if (formErrors.password.errorMsg) {
      setPasswordHelperText(formErrors.password.errorMsg);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formErrors]);

  const handleChange = (field: string, event: ChangeEventTarget) => {
    setValues({ ...values, [field]: event?.target?.value });
    if (field === 'email' && emailExisted) {
      setEmailExisted(false);
      setFormErrors({
        ...formErrors,
        email: {
          valid: false,
          errorMsg: '',
        },
      });
    }
  };

  const handleSingleValidate = (field: string, event: MouseEventTarget) => {
    const error = validateSingleField(field, event.target.value);
    if (!error.errorMsg && !emailExisted) {
      setIsSubmitProcessing(false);
    }
    setFormErrors({ ...formErrors, [field]: error });
  };

  const handlePasswordValidate = (event: MouseEventTarget) => {
    const error = validateSingleField('password', event.target.value);
    if (error.errorMsg) {
      setPasswordHelperText(<span>{formErrors.password.errorMsg}</span>);
    }
  };

  const handlePasswordChange = (event: ChangeEventTarget) => {
    handlePasswordHelperText(event.target.value);
    setFormErrors({ ...formErrors, password: { errorMsg: '', valid: formErrors.password.valid } });
  };

  const validateSingleField = (name: string, value?: string, isPasswordOnChange?: boolean) =>
    formValidator(
      ['REQUIRED', name.toUpperCase()],
      name === 'email' ? value.trim() : value,
      {
        requiredError: (prismicErrorData as any)[`${name}_required`],
        invalidError: (prismicErrorData as any)[`${name}_invalid`],
        passWordSpaceError: prismicErrorData.password_space_error_message,
      },
      isPasswordOnChange
    );

  const handleValidate = () => {
    const emailError = validateSingleField('email', values.email);
    const passwordError = validateSingleField('password', values.password, true);

    const errors = {
      email: emailError,
      password: passwordError,
    };

    setFormErrors(errors);
    return errors;
  };

  const handleUserExistedCheck = async (email: string) => {
    setIsSubmitProcessing(true);
    const { captchaSignature, validationData } = await handleValidateRecaptcha();
    const parameters = captchaSignature
      ? {
          email,
          validationData,
          captchaSignature,
        }
      : {
          email,
          validationData,
        };

    try {
      const res = await createPublicClient.query({
        query: doesUserExist,
        variables: parameters,
      });
      if (res.data?.doesUserExist) {
        setIsSubmitProcessing(false);
        setEmailExisted(true);

        setFormErrors({
          ...formErrors,
          email: {
            valid: false,
            errorMsg: prismicErrorData.existing_email_message,
          },
        } as ValidationErrors);
      } else {
        setIsSubmitProcessing(false);
        const variables = {
          userInput: {
            email: values.email?.trim()?.toLowerCase(),
            password: values.password,
            givenName: userInfo.firstName,
            familyName: userInfo.lastName,
            subComms: userInfo.receiveEmail,
          },
          auth: {
            issuer: 'tesco',
            voucherCode: voucher,
          },
        };
        navigate(`/tesco/processing/`, {
          state: {
            variables,
          },
        });
      }
    } catch (error) {
      setIsSubmitProcessing(false);
      setServerError(error.message);
      addErrorInDatadogRum(error);
      // Send error to sentry
      captureException({
        action: 'existingUserCheck',
        requestVariables: {
          email,
          validationData,
        },
        email: email.trim().toLowerCase(),
        ...error,
      });
    }
  };

  const handleSubmit = async () => {
    const errors = handleValidate();

    // If have at least 1 error, no call API
    if (Object.keys(errors).some((key) => !!(errors as any)[key].errorMsg)) {
      return;
    }

    await handleUserExistedCheck(values.email?.trim()?.toLowerCase());
  };

  const handlePasswordHelperText = (value: string) => {
    const passwordError = validateSingleField('password', value, true);
    const helperTextIcon = passwordError.valid ? (
      <CorrectIcon style={{ display: 'inline-block', marginLeft: '5px' }} />
    ) : (
      <WrongIcon style={{ display: 'inline-block', marginLeft: '5px' }} />
    );
    setPasswordHelperText(
      <span>
        {password_dynamic_message}
        {helperTextIcon}
      </span>
    );
  };

  const handleClickShowPassword = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div>
      <Title>{confirm_email_title}</Title>
      <Note>{confirm_email_note}</Note>
      <Box
        id="form-wrapper"
        component="form"
        sx={{
          '& .MuiTextField-root': { width: '320px' },
          maxWidth: '320px',
          textAlign: 'center',
        }}
        noValidate
        autoComplete="off"
      >
        {serverError && <Alert content={serverError} />}
        <TextFieldNew
          label={email_label}
          variant="filled"
          name="email"
          id="email-field"
          value={values.email}
          defaultValue={userInfo?.email}
          error={!!formErrors.email.errorMsg}
          helperText={formErrors.email.errorMsg}
          onBlur={(event: MouseEventTarget) => {
            handleSingleValidate('email', event);
          }}
          onChange={(event: ChangeEventTarget) => {
            handleChange('email', event);
          }}
        />
        <TextFieldNew
          label={password_label}
          type={passwordVisible ? 'text' : 'password'}
          variant="filled"
          name="password"
          id="password-field"
          className="password-field"
          value={values.password}
          defaultValue=""
          error={!!formErrors.password.errorMsg}
          helperText={passwordHelperText}
          onBlur={(event: MouseEventTarget) => {
            handlePasswordValidate(event);
            handleSingleValidate('password', event);
          }}
          onChange={(event: ChangeEventTarget) => {
            handlePasswordChange(event);
            handleChange('password', event);
          }}
          InputProps={{
            endAdornment: (
              <>
                {/*  eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                <PasswordIconWrapper
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onKeyDown={handleClickShowPassword}
                >
                  {passwordVisible ? <HidePassword /> : <ShowPassword />}
                </PasswordIconWrapper>
              </>
            ),
            disableUnderline: true,
          }}
        />
        <ButtonWrapper>
          <Button onClick={handleSubmit} loading={isSubmitProcessing}>
            {next_btn}
          </Button>
        </ButtonWrapper>
        <LoginLink
          to={`/login/?redirect_to=/tesco/?voucher=${voucher}`}
          state={{ tescoVoucherType }}
        >
          {login_label}
        </LoginLink>
      </Box>
    </div>
  );
};

export default ConfirmEmail;
