import styled from 'styled-components';

interface DotProps {
  active: boolean;
}

export const Container = styled.div`
  display: flex;
  gap: 6px;
`;

export const Dot = styled.div<DotProps>`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: ${(props) => (props.active ? '#00FF63' : '#4e4e4e')};
`;
