import styled from 'styled-components';

export const FavoriteContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  gap: 24px;

  .videoCard {
    height: 100%;
  }
`;

export const TabsWrapper = styled.div`
  width: 100%;
`;

export const SlicePlaceholderWrapper = styled.div`
  padding-top: 12px;
  width: 100%;
`;

export const FavoriteContentGridWrapper = styled.div`
  width: 100%;
`;

export const EmptyContentText = styled.div`
  color: ${(props) => props.theme.colors.lightishGrey};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 80px 0 80px 16px;
  font-size: 16px;
  text-align: center;
`;

export const ResultsAmount = styled.p`
  font-size: 16px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.lightGreyAE};
  margin-bottom: 24px;
`;
