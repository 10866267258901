declare global {
  interface Window {
    dataLayer: {
      push: (e: any) => void;
    };
  }
}

enum Event {
  PAGE_VIEW = 'page_view',
  CUSTOMER_LOGIN = 'customer_login',
  CUSTOMER_LOGOUT = 'customer_logout',
  FORM_START = 'form_start',
  FORM_SUBMIT = 'form_submit',
  FORM_ERROR = 'form_error',
  REGISTRATION_FLOW = 'registration_flow',
  CONTENT_LIST_VIEW = 'content_list_view',
  CONTENT_VIEW = 'content_view',
  CONTENT_SHARE = 'content_share',
  VIDEO_PLAY = 'video_play',
  VIDEO_START = 'video_start',
  VIDEO_UNPAUSE = 'video_unpause',
  VIDEO_PROGRESS = 'video_progress',
  NAV_SELECT = 'nav_select',
  SEARCH_START = 'search_start',
  SEARCH_COMPLETE = 'search_complete',
  CONTENT_DOWNLOAD = 'content_download',
  CHALLENGE_START = 'challenge_start',
  CHALLENGE_END = 'challenge_end',
  SURVEY_SKIP = 'survey_skip',
  CONTENT_CLICK = 'content_click',
}

enum Params {
  ACTIVATION_CODE = 'activation_code',
  ACTIVATION_CODE_ACCESS_ID = 'activation_code_access_id',
  AFFILIATE_ID = 'affiliate_id',
  AFFILIATION = 'affiliation',
  AXII_USER_ID = 'axii_user_id',
  CART_PAYMENT_TYPE = 'cart_payment_type',
  CART_SIZE = 'cart_size',
  CART_SUBTOTAL = 'cart_subtotal',
  CONTENT_LOCATION = 'content_location',
  CONTENT_SHARE_PLATFORM = 'content_share_platform',
  CONTENT_TITLE = 'content_title',
  CONTENT_TOPIC = 'content_topic',
  CONTENT_TYPE = 'content_type',
  CONTENT_INDEX = 'content_index',
  CONTENT_LIST_INDEX = 'content_list_index',
  CONTENT_LIST_ITEMS = 'content_list_items',
  CONTENT_LIST_TITLE = 'content_list_title',
  CONTENT_VIEW_COUNT = 'content_view_count',
  COUPON = 'coupon',
  CURRENCY = 'currency',
  CUSTOMER_CLASS = 'customer_class',
  CUSTOMER_ID = 'customer_id',
  CUSTOMER_LOGGED_IN_STATUS = 'customer_logged_in_status',
  CUSTOMER_REFERENCE_NUMBER = 'customer_reference_number',
  FORM_ERROR_NAME = 'form_error_name',
  FORM_NAME = 'form_name',
  FREE_TRIAL = 'free_trial',
  FREE_TRIAL_DAYS = 'free_trial_days',
  ITEM_BRAND = 'item_brand',
  ITEM_CATEGORY_1 = 'item_category_1',
  ITEM_CATEGORY_2 = 'item_category_2',
  ITEM_ID = 'item_id',
  ITEM_IMAGE_URL = 'item_image_url',
  ITEM_NAME = 'item_name',
  ITEM_PRICE = 'item_price',
  ITEM_PROMO_CATEGORY = 'item_promo_category',
  ITEM_PROMO_VALUE = 'item_promo_value',
  ITEM_QUANTITY = 'item_quantity',
  ITEM_SALE_PRICE = 'item_sale_price',
  ITEM_SKU = 'item_sku',
  ITEM_VARIANT = 'item_variant',
  ITEMS = 'items',
  MAGENTO_USER_ID = 'magento_user_id',
  MENU_CATEGORY_1 = 'menu_category_1',
  MENU_CATEGORY_2 = 'menu_category_2',
  MENU_CATEGORY_3 = 'menu_category_3',
  MENU_TYPE = 'menu_type',
  PAYMENT_TYPE = 'payment_type',
  PLAN_BILLING_FREQ = 'plan_billing_freq',
  PLAN_NAME = 'plan_name',
  PLAN_PRICE = 'plan_price',
  PLAN_TIER = 'plan_tier',
  PRICE = 'price',
  PROMO_ID = 'promo_id',
  PROMO_NAME = 'promo_name',
  QUANTITY = 'quantity',
  RESELLER_ID = 'reseller_id',
  RESELLER_NAME = 'reseller_name',
  SCREEN_CLASS = 'screen_class',
  SCREEN_NAME = 'screen_name',
  SEARCH_RESULT = 'search_result',
  SEARCH_RESULTS = 'search_results',
  SEARCH_STEP_LABEL = 'search_step_label',
  SEARCH_TERM = 'search_term',
  SEARCH_TYPE = 'search_type',
  SHIPPING = 'shipping',
  STEP_LABEL = 'step_label',
  SUBSCRIPTION_ID = 'subscription_id',
  SUBSCRIPTION_PERIOD = 'subscription_period',
  TAX = 'tax',
  TRANSACTION_ID = 'transaction_id',
  TUNE_AFFILIATE_ID = 'tune_affiliate_id',
  TUNE_GOAL_ID = 'tune_goal_id',
  TUNE_OFFER_ID = 'tune_offer_id',
  TUNE_TRANSACTION_ID = 'tune_transaction_id',
  TUNE_VOUCHER_CODE = 'tune_voucher_code',
  VALUE = 'value',
  VIDEO_CURRENT_TIME = 'video_current_time',
  VIDEO_DURATION = 'video_duration',
  VIDEO_LENGTH = 'video_length',
  VIDEO_PLAY_PERCENTAGE = 'video_play_percentage',
  VIDEO_TITLE = 'video_title',
  VIMEO_USER_ID = 'vimeo_user_id',
  VIDEO_VIEW_COUNT = 'video_view_count',
  VIDEO_VIMEO_ID = 'video_vimeo_id',
  VIMEO_ID = 'vimeo_id',
  LOGIN_TYPE = 'login_type',
  CUSTOMER_EMAIL = 'customer_email',
  CUSTOMER_FIRST_NAME = 'customer_first_name',
  CUSTOMER_LAST_NAME = 'customer_last_name',
  PLAN_ID = 'plan_id',
  CUSTOMER_DETAILS_UPDATE = 'customer_details_update',
}

export { Event, Params };
