import { PromoErrorGroupType } from './type';

export const GENERIC_ERROR = 'server_error';

export const INTERVAL_PLACE_HOLDER_TEXT = '{interval}';
export const AMOUNT_PLACE_HOLDER_TEXT = '{amount}';

export const PROMO_ERROR_TYPE = {
  INVALID_PROMO_NOT_FOUND: 'invalid_promo_not_found',
  INVALID_PROMO_NOT_ENABLED: 'invalid_promo_not_enabled',
  INVALID_PROMO_INACTIVE: 'invalid_promo_inactive',
  INVALID_PROMO_NOT_SUPPORTED: 'invalid_promo_not_supported',
  ACCOUNT_PROMO_REDEEMED: 'account_promo_redeemed',
  ACCOUNT_PROMO_GROUP_REDEEMED: 'account_promo_group_redeemed',
  ACCOUNT_SUB_STATE_INVALID: 'account_sub_state_invalid',
  ACCOUNT_SIGNUP_CHANNEL_INVALID: 'account_signup_channel_invalid',
  ACCOUNT_COUNTRY_INVALID: 'account_country_invalid',
  ACCOUNT_CUSTOMER_TYPE_INVALID: 'account_customer_type_invalid',
  ACCOUNT_SUB_TIER_INVALID: 'account_sub_tier_invalid',
  ACCOUNT_SUB_COUPONS_FOUND: 'account_sub_coupons_found',
  ACCOUNT_SUB_PRODUCT_FAMILY_INVALID: 'account_sub_product_family_invalid',
  ACCOUNT_SUB_TRIALIST_CONDITIONS_INVALID: 'account_sub_trialist_conditions_invalid',
  ACCOUNT_SUB_CUST_TYPE_INVALID: 'account_sub_cust_type_invalid',
  SERVER_ERROR: GENERIC_ERROR,
} as const;

export type PromoErrorType = typeof PROMO_ERROR_TYPE[keyof typeof PROMO_ERROR_TYPE];

export const PROMO_ERROR_GROUP: Record<PromoErrorGroupType, Readonly<PromoErrorType[]>> = {
  [PromoErrorGroupType.INVALID_PROMO_ERRROR]: [
    PROMO_ERROR_TYPE.INVALID_PROMO_INACTIVE,
    PROMO_ERROR_TYPE.INVALID_PROMO_NOT_ENABLED,
    PROMO_ERROR_TYPE.INVALID_PROMO_NOT_FOUND,
    PROMO_ERROR_TYPE.INVALID_PROMO_NOT_SUPPORTED,
  ],
  [PromoErrorGroupType.PROMO_REDEMPTION_ERROR]: [
    PROMO_ERROR_TYPE.ACCOUNT_PROMO_REDEEMED,
    PROMO_ERROR_TYPE.ACCOUNT_PROMO_GROUP_REDEEMED,
  ],
  [PromoErrorGroupType.ACCOUNT_INELIGIBLE_ERROR]: [
    PROMO_ERROR_TYPE.ACCOUNT_SUB_STATE_INVALID,
    PROMO_ERROR_TYPE.ACCOUNT_SIGNUP_CHANNEL_INVALID,
    PROMO_ERROR_TYPE.ACCOUNT_COUNTRY_INVALID,
    PROMO_ERROR_TYPE.ACCOUNT_CUSTOMER_TYPE_INVALID,
    PROMO_ERROR_TYPE.ACCOUNT_SUB_TIER_INVALID,
    PROMO_ERROR_TYPE.ACCOUNT_SUB_COUPONS_FOUND,
    PROMO_ERROR_TYPE.ACCOUNT_SUB_PRODUCT_FAMILY_INVALID,
    PROMO_ERROR_TYPE.ACCOUNT_SUB_TRIALIST_CONDITIONS_INVALID,
    PROMO_ERROR_TYPE.ACCOUNT_SUB_CUST_TYPE_INVALID,
  ],
} as const;
