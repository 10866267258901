/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { PricingCardHOC } from '@lesmills-international/components';

const PricingCardComponent = ({
  countryCode,
  hasHighlightedPlan,
  plan,
  pricingData,
  selectedPlan,
  handleSelectPlan,
  voucher,
  isHidden,
  isCurrentPlan,
  noTrialDisplay,
  showTotalPrice,
  currentAffiliateInfo,
  currentPlanText,
  forceStrikeThroughDescription,
}) => {
  const isHiddenPricingCard = isHidden && plan.id !== selectedPlan?.id;
  if (!PricingCardHOC || isHiddenPricingCard) return null;

  const onCardClick = () => {
    handleSelectPlan(plan?.id);
  };

  const {
    free_trial_text,
    monthly_text,
    no_trial_text,
    annually_text,
    three_monthly_text,
    best_value,
    plan_monthly_disclaimer,
    plan_trimonthly_disclaimer,
    plan_annually_disclaimer,
    oneoff_first_payment,
    oneoff_monthly_billed,
    oneoff_trial_text,
    billed_text,
  } = pricingData;

  return (
    <PricingCardHOC
      {...{
        countryCode: countryCode.toUpperCase(),
        plan,
        pricingCardText: {
          freeTrialText: free_trial_text,
          noTrialText: no_trial_text,
          monthlyPeriodText: monthly_text,
          threeMonthlyPeriodText: three_monthly_text,
          biyearlyPeriodText: 'Bi-yearly',
          annuallyPeriodText: annually_text,
          bestValueText: best_value,
          oneoffFirstPaymentText: oneoff_first_payment,
          oneoffMonthlyBilledText: oneoff_monthly_billed,
          oneoffTrialText: oneoff_trial_text,
          billedText: billed_text,
          monthlyPaymentUnitText: '/mo',
          currentPlanText,
        },
        onClick: onCardClick,
        selected: selectedPlan ? plan?.id === selectedPlan.id : false,
        showTotalPrice,
        hasHighlightedPlan,
        voucher: voucher
          ? {
              country: voucher?.country,
              addon_title: voucher?.addon_title,
              addon_price_point: voucher?.addon_price_point,
            }
          : null,
        planDisclaimerConstant: {
          Annual:
            currentAffiliateInfo?.affiliate_plan_annually_disclaimer || plan_annually_disclaimer,
          '3-Monthly':
            currentAffiliateInfo?.affiliate_plan_trimonthly_disclaimer ||
            plan_trimonthly_disclaimer,
          Monthly:
            currentAffiliateInfo?.affiliate_plan_monthly_disclaimer || plan_monthly_disclaimer,
        },
        isCurrentPlan,
        noTrialDisplay,
        forceStrikeThroughDescription,
      }}
    />
  );
};

export default PricingCardComponent;
