import { gql } from '@apollo/client';

const ValidateActivationCode = gql`
  query validateActivationCode($activationCode: String!) {
    validateResellerActivationCode(activationCode: $activationCode) {
      status
      data {
        partnerName
        email
        givenName
        familyName
        activationCode
      }
    }
  }
`;

export default ValidateActivationCode;
