import React, { useContext } from 'react';
import { CSSProperties } from 'styled-components';
import { userContext } from '../../../../gatsby-theme-engagement/src/context';
import mapPaymentName, { formatPaymentDesc } from '../../utils/normalizePaymentInfo';

import PaymentIcon from '../common/payment/PaymentIcon';
import { PaymentDescriptionConatiner } from './styles';

interface Props {
  cardDescription?: string | null;
  style?: CSSProperties;
}

const PaymentDetails = ({ cardDescription, style }: Props) => {
  const { chargifyPaymentProfile } = useContext(userContext);

  const paymentType = mapPaymentName(chargifyPaymentProfile);
  const isPaypal = paymentType === 'paypal';
  const description =
    !isPaypal && formatPaymentDesc(chargifyPaymentProfile?.last4, cardDescription);

  return (
    <PaymentDescriptionConatiner style={style}>
      <PaymentIcon iconType={paymentType} />
      {description}
    </PaymentDescriptionConatiner>
  );
};

export default PaymentDetails;
