import React, { FC } from 'react';
import styled from 'styled-components';

import { breakpoint } from '../../../themes/breakpoints';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 22px;
  background-color: ${(props) => props.theme.colors.darkGrey};
  width: 100%;
  min-height: 65px;
  z-index: 6;

  ${breakpoint('md')`
    min-height: 38px;
    position: fixed;
    bottom: 0;
  `}

  ${breakpoint('lg')`
    display: flex;
    position: relative;
    min-height: 38px;
    justify-content: flex-start;
  `}
`;

const Nav = styled.a`
  margin-right: 26px;
  cursor: pointer;
  font-size: 12px;
  line-height: 18px;
  color: ${(props) => props.theme.colors.white};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export interface NavItem {
  nav_link: {
    text: string;
  };
  nav_label: {
    text: string;
  };
}

interface Props {
  navs: NavItem[];
  className: string;
}

const Footer: FC<Props> = ({ navs, className, ...rest }) => (
  <Wrapper className={className} {...rest}>
    {navs &&
      navs.map((nav) => (
        <Nav
          target="_blank"
          key={nav?.nav_link?.text}
          href={nav?.nav_link?.text}
          alt={nav?.nav_label?.text}
        >
          {nav?.nav_label?.text}
        </Nav>
      ))}
  </Wrapper>
);

export default Footer;
