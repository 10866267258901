/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { createElement, forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import { ReactComponent as CalendarIcon } from '@assets/icons/calendar.svg';
import 'react-datepicker/dist/react-datepicker.css';

interface CustomInputProps {
  value: string;
  onClick: () => void;
  placeholder: string;
}

type CustomDatePickerProps = {
  placeholderText: string;
  selected: Date | null;
  minDate: Date;
  onChange: (date: Date) => void;
};

const CustomInput = (
  { value, onClick, placeholder }: CustomInputProps,
  ref: React.Ref<HTMLDivElement>
) => (
  <div
    className="bg-grey-new-lighterer cursor-pointer p-4 flex justify-between"
    onClick={onClick}
    ref={ref}
  >
    <div className={`${value ? '' : 'opacity-50'} font-inter-light tracking-wide`}>
      {value || placeholder}
    </div>
    <CalendarIcon />
  </div>
);

const CustomDatePicker = ({
  placeholderText,
  selected,
  minDate,
  onChange,
}: CustomDatePickerProps) => (
  <DatePicker
    placeholderText={placeholderText}
    selected={selected}
    minDate={minDate}
    onChange={onChange}
    customInput={createElement(forwardRef(CustomInput))}
  />
);

export default CustomDatePicker;
