const normalizeServerErrorMsg = (err) => {
  let errorMsg = '';
  if (err?.errors?.errorInfo) {
    errorMsg = err?.errors[0]?.errorInfo;
  } else if (err?.graphQLErrors?.length) {
    errorMsg = err.graphQLErrors[0].message;
  } else {
    errorMsg = err.toString() || 'Server error, please try again.';
  }
  return errorMsg;
};

export default normalizeServerErrorMsg;
