// This will process the slice data from prismic, and will group together
// based on the slice_type name(s) provided, while maintaining the order from
// prismic.

const getSlicesGrouped = (slices, sliceTypesToGroup = []) => {
  let hasGrouped = [];
  return slices.map((slice) => {
    const sliceType = slice.slice_type;
    if (hasGrouped.includes(sliceType)) {
      return null;
    }
    if (sliceTypesToGroup.includes(sliceType)) {
      hasGrouped = [...hasGrouped, sliceType];
      const slicesGrouped = slices.filter((i) => i.slice_type === sliceType);
      return {
        slice_type: sliceType,
        id: slicesGrouped[0]?.id,
        slices: slicesGrouped,
      };
    }
    return slice;
  });
};

export default getSlicesGrouped;
