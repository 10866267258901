import {
  identifyAccountCreated,
  identifyEmailConfirmed,
  identifySelectPlan,
  identifyOnPayment,
  trackPaymentCompleteEvent,
  optOutUser,
  getUserInfo,
} from './signup';

const signUpAnalytics = {
  identifyAccountCreated,
  identifyEmailConfirmed,
  identifySelectPlan,
  identifyOnPayment,
  trackPaymentCompleteEvent,
  optOutUser,
  getUserInfo,
};

// Currently only have one analytics object, so we need to disable the ESlint check
// eslint-disable-next-line import/prefer-default-export
export { signUpAnalytics };
