const formatFitnessLevels = (fitnessLevel: number | null | undefined) => {
  switch (fitnessLevel) {
    case 1:
    case 2:
      return 'Beginner';
    case 3:
      return 'Intermediate';
    case 4:
    case 5:
      return 'Advanced';
    default:
      return '';
  }
};
export default formatFitnessLevels;
