/* eslint-disable @typescript-eslint/naming-convention */
import React, { FC } from 'react';

import { ReactComponent as MusicIcon } from '@assets/icons/music.svg';
import type { WorkoutPageData } from '../../../pages/workout/[name]';

import {
  TrackItemWrapper,
  TrackNumber,
  TrackInfoContiner,
  TrackTitleContainer,
  TrackTitle,
  TrackDuration,
  TrackMusic,
  TrackMoves,
  TrackPresenter,
} from './styles';

export interface WorkoutTrackItemProps {
  trackNumber: string | null;
  name: string | null;
  presenterName: string[] | null;
  duration: number | null;
  keyMovements: string[] | null;
  music: MusicInfoType[];
  startTime: number | null;
  prismicData: WorkoutPageData;
  handleOnClick: (startTime: number) => void;
  role: string;
}

interface MusicInfoType {
  musicOriginalArtist: string | null;
  musicCoverArtist: string | null;
  musicTitle: string | null;
}

const WorkoutTrackItem: FC<WorkoutTrackItemProps> = ({
  trackNumber,
  name,
  presenterName,
  duration,
  keyMovements,
  music,
  startTime,
  prismicData,
  handleOnClick,
  role,
  ...rest
}) => {
  const durationMins =
    duration && !Number.isNaN(duration) ? (duration <= 60 ? 1 : Math.floor(duration / 60)) : 0;
  const { moves_title, presenter_title, minute_text, music_recording_text } = prismicData;

  return (
    <TrackItemWrapper
      onClick={() => {
        startTime && handleOnClick(startTime);
      }}
      role={role}
      {...rest}
    >
      <TrackNumber>{trackNumber}</TrackNumber>
      <TrackInfoContiner>
        <TrackTitleContainer>
          <TrackTitle>{name}</TrackTitle>
          {durationMins && (
            <TrackDuration>
              {durationMins}
              {durationMins > 1 ? `${minute_text}s` : minute_text}
            </TrackDuration>
          )}
        </TrackTitleContainer>
        {music &&
          music.length > 0 &&
          music.map((musicInfo) => (
            <>
              {musicInfo?.musicTitle && musicInfo?.musicOriginalArtist && (
                <TrackMusic>
                  <div className="track-music-icon">
                    <MusicIcon />
                  </div>
                  <span>
                    {musicInfo?.musicTitle} -{' '}
                    {music_recording_text && musicInfo?.musicOriginalArtist
                      ? music_recording_text.replace('{artist}', musicInfo?.musicOriginalArtist)
                      : ''}
                  </span>
                </TrackMusic>
              )}
            </>
          ))}
        {keyMovements && keyMovements.length > 0 && (
          <TrackMoves>
            {keyMovements.length} {moves_title}:{' '}
            {keyMovements?.map((move, index) => (
              <span>
                {move}
                {index !== keyMovements.length - 1 ? ', ' : ''}
              </span>
            ))}
          </TrackMoves>
        )}
        {presenterName && presenterName.length > 0 && (
          <TrackPresenter>
            {presenter_title}:{' '}
            {presenterName?.map((persenter, index) => (
              <span>
                {persenter}
                {index !== presenterName.length - 1 ? ', ' : ''}
              </span>
            ))}
          </TrackPresenter>
        )}
      </TrackInfoContiner>
    </TrackItemWrapper>
  );
};

export default WorkoutTrackItem;
