import styled, { keyframes } from 'styled-components';
import { breakpoint } from '@themes'; // We should import these from the component library and
import COLORS from '@themes/colors';

const animationErrorMessage = keyframes`
  from {
    position: absolute;
    opacity: 0;
    bottom: 86px;
  }
  to {
    position: absolute;
    opacity: 1;
    bottom: 100px;
  }
`;

const animationErrorMessageMd = keyframes`
  from {
    opacity: 0;
    margin-top: 40px;
    margin-bottom: -64px;
  }
  to {
    opacity: 1;
    margin-top: 24px;
    margin-bottom: -48px;
  }
`;

export const PageWrapper = styled.div`
  height: ${(props) => props.height};
  background: linear-gradient(180deg, ${COLORS.darkGrey} 0%, ${COLORS.black} 100%);
`;
export const SurveyWrapper = styled.div`
  height: ${(props) => (props.height ? props.height : '100vh')};
  display: flex;
  justify-content: center;
  padding: 20px 16px;
  ${breakpoint('lg')`
    padding-top: 75px;
    padding-bottom: 75px;
  `}
`;

export const SurveyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${COLORS.white};
  text-align: center;
  & .button {
    width: 100%;
    padding: 15px 0px;
    font-family: 'Inter SemiBold';
    font-weight: 900;
    ${breakpoint('sm')`
      margin-top: 62px;
      font-weight: 900;
  `}
  }
  & .button:hover {
    filter: drop-shadow(0px 0px 20px rgba(255, 255, 255, 0.4));
    font-family: 'Inter SemiBold';
  }
`;

export const CenteredSurveyContainer = styled(SurveyContainer)`
  justify-content: center;
  & .button {
    margin-top: 18px;
    z-index: 200;
    font-family: 'Inter Regular';
  }
`;

export const SurveyLogoContainer = styled.div`
  svg {
    display: none;
  }
  ${breakpoint('lg')`
    svg{
      display: inline;
    }
  `}
`;

export const ProgressDotsContainer = styled.div`
  padding: 10px 0px;
  ${breakpoint('md')`
    padding: 40px 0px;
  `}
`;

export const StartHeading = styled.h1`
  font-size: 24px;
  line-height: 33px;
  font-family: 'Inter Medium';
  margin-top: 20px;
  width: 305px;
  ${breakpoint('md')`
    font-size: 30px;
    width: 570px;
    line-height: 40px;
  `}
`;

export const EndHeading = styled.h1`
  font-size: 24px;
  line-height: 32px;
  font-family: 'Inter Medium';
  margin-top: 19px;
`;

export const GradientOverlay = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  height: 50%;
  width: 100%;
  background: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
  z-index: 100;
`;

export const QuestionGradientOverlay = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  height: 112px;
  width: 100%;
  background: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 30%);
  z-index: 100;
`;

export const QuestionTitleBar = styled.h2`
  margin: 18px 0;
  font-weight: 900;
  font-size: 20px;
  width: 400px;
  ${breakpoint('md')`
    font-size: 24px;
  `}
`;

export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  padding: 0px 2px 160px 2px;
  ${breakpoint('md')`
    padding: 0px 2px;
  `}
`;

export const LoadingIcon = styled.div`
  margin-bottom: 72px;
`;

export const BackBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  left: 16px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  color: white;
  transition: 0.15s;
  border-radius: 50%;
  &:hover {
    background: ${COLORS.greyLetter};
  }
  ${breakpoint('md')`
    top: 18px;
  `}
`;

export const ErrorMessage = styled.div`
  position: absolute;
  bottom: 100px;
  color: ${COLORS.darkRed};
  font-family: 'Inter SemiBold';
  font-weight: 900;
  font-size: 16px;
  -webkit-animation: ${animationErrorMessage} 0.22s ease-in-out;
  -moz-animation: ${animationErrorMessage} 0.22s ease-in-out;
  -o-animation: ${animationErrorMessage} 0.22s ease-in-out;
  -ms-animation: ${animationErrorMessage} 0.22s ease-in-out;
  animation: ${animationErrorMessage} 0.22s ease-in-out;
  ${breakpoint('md')`
    position: static;
    margin-top: 24px;
    margin-bottom: -48px;
    -webkit-animation: ${animationErrorMessageMd} 0.22s ease-in-out;
    -moz-animation: ${animationErrorMessageMd} 0.22s ease-in-out;
    -o-animation: ${animationErrorMessageMd} 0.22s ease-in-out;
    -ms-animation: ${animationErrorMessageMd} 0.22s ease-in-out;
    animation: ${animationErrorMessageMd} 0.22s ease-in-out;
  `}
`;

export const NextBtn = styled.div`
  position: absolute;
  bottom: 42px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  & .button {
    transition: ease-in-out 0.15s;
    z-index: 200;
  }
  & .button:hover {
    filter: drop-shadow(0px 0px 20px rgba(255, 255, 255, 0.4));
  }
  ${breakpoint('md')`
    position: static;
  `}
`;

export const NextBtnDisabled = styled(NextBtn)`
  & .button {
    cursor: auto;
    background-color: ${COLORS.lightGrey5C};
    z-index: 200;
  }
  & .button:hover {
    filter: none;
    background-color: ${COLORS.lightGrey5C} !important;
  }
`;
