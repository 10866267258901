import PATTERNS from '../constants/pattern';

export const formValidator = (types, value, externalValues, isPasswordOnChange) => {
  let errorMsg = '';
  const noOfTypes = types.length;

  // eslint-disable-next-line no-plusplus
  for (let index = 0; index < noOfTypes; index++) {
    const type = types[index];
    const validation = PATTERNS[type];
    const { requiredError, invalidError, passWordSpaceError } = externalValues;

    switch (type) {
      case 'REQUIRED':
        if (!value || value.length === 0) {
          errorMsg = requiredError;
        }
        break;

      case 'EMAIL':
        if (value && validation && !validation.test(value)) {
          errorMsg = invalidError;
        }
        break;
      case 'PASSWORD':
        if (value) {
          if (validation && !validation.test(value)) {
            errorMsg = invalidError;
          } else if ((value[0] === ' ' || value[value.length - 1] === ' ') && !isPasswordOnChange) {
            errorMsg = passWordSpaceError;
          }
        }
        break;

      default:
        break;
    }
    if (errorMsg) {
      return {
        errorMsg,
        valid: !errorMsg.length,
      };
    }
  }
  return {
    errorMsg,
    valid: !errorMsg.length,
  };
};

export const checkHaveErrors = (errors) => Object.keys(errors).some((key) => !errors[key].valid);
