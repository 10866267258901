// Disabled eslint for name of prismic field
/* eslint-disable @typescript-eslint/naming-convention */
import React, { FC } from 'react';

import { RichText, RichTextBlock } from 'prismic-reactjs';
import { addMonths } from 'date-fns';

import { Note, Title, ExternalLinkWrapper, ExternalLink } from '../style';
import { getLocalStorage } from '../../../utils/utilities';
import COLORS from '../../../themes/colors';
import formatDate from '../../../utils/formatDate';
import TESCO_VOUCHER_TYPE from '../../../constants/tesco';

interface Props {
  congratulations?: {
    text: string;
    raw: RichTextBlock[];
  };
  next_billing_date_for_active_user?: string;
  next_billing_date_for_non_active_user?: string;
  lets_get_started?: string;
  voucher_code_label?: string;
  voucher?: string;
  tescoVoucherType: string;
  getting_started_link: {
    url?: string;
  };
}

const Success: FC<Props> = ({
  congratulations,
  next_billing_date_for_active_user,
  next_billing_date_for_non_active_user,
  lets_get_started,
  voucher_code_label,
  voucher,
  tescoVoucherType,
  getting_started_link,
}) => {
  const { lmodSubscription } = JSON.parse(getLocalStorage('user-info') as string) || {};
  const state = lmodSubscription?.state;
  const isActiveUser =
    state === 'active' || state === 'trialing' || state === 'on-delayed-cancellation';

  const billingDateBefore = lmodSubscription?.current_period_ends_at;
  const billingDateAfter = isActiveUser
    ? addMonths(new Date(billingDateBefore), (TESCO_VOUCHER_TYPE as any)[tescoVoucherType])
    : addMonths(new Date(), (TESCO_VOUCHER_TYPE as any)[tescoVoucherType]);
  const formatedBillingDateBefore = billingDateBefore
    ? formatDate(billingDateBefore)
    : billingDateBefore;
  const formatedBillingDateAfter = billingDateAfter
    ? formatDate(billingDateAfter)
    : billingDateAfter;

  return (
    <div>
      <div
        style={{
          fontSize: '20px',
          lineHeight: '28px',
          color: COLORS.greyLetter,
          marginBottom: '15px',
        }}
      >
        <RichText key={congratulations?.text} render={congratulations?.richText} />
      </div>
      <Title>
        {voucher_code_label}: {voucher}
      </Title>
      {isActiveUser ? (
        <Note>
          {next_billing_date_for_active_user
            ?.replace('{next_billing_date_before}', formatedBillingDateBefore)
            ?.replace('{next_billing_date_after}', formatedBillingDateAfter)}
        </Note>
      ) : (
        <Note>
          {next_billing_date_for_non_active_user?.replace(
            '{next_billing_date}',
            formatedBillingDateAfter
          )}
        </Note>
      )}
      <ExternalLinkWrapper>
        <ExternalLink href={getting_started_link?.url}>{lets_get_started}</ExternalLink>
      </ExternalLinkWrapper>
    </div>
  );
};

export default Success;
