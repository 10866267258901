import React, { useEffect } from 'react';
import { Chip } from '@lesmills-international/components';
import Select from '@mui/material/Select';
import ListItem from '@mui/material/ListItem';
import Checkbox from '@mui/material/Checkbox';
import styled from 'styled-components';
import { ReactComponent as ArrowDownIcon } from '@assets/icons/arrow_down.svg';
import COLORS from '../../../themes/colors';

interface MultiSelectDropdownProps {
  options: string[];
  label: string;
  wide?: boolean;
  selectedValues: string[];
  open: boolean;
  handleOpenMultiSelectDropdown: (isOpen: boolean) => void;
  onChange: (selected: string[]) => void;
}

const DropdownWrapper = styled.div`
  padding: 8px 16px 8px 0;
  white-space: nowrap;
  .MuiInputBase-root {
    /* Hide the original switch icon */
    width: 0;
    height: 0;
  }
`;

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

const ItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const MultiSelectDropdown: React.FC<MultiSelectDropdownProps> = ({
  options,
  label,
  open,
  handleOpenMultiSelectDropdown,
  wide = false,
  selectedValues,
  onChange,
}) => {
  const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    onChange(event.target.value as string[]);
  };

  const toggleDropdown = () => {
    handleOpenMultiSelectDropdown(!open);
  };

  useEffect(() => {
    const handleScroll = () => {
      // Check if the dropdown is open and the user has scrolled
      if (open) {
        handleOpenMultiSelectDropdown(false);
      }
    };

    // Add the scroll event listener to the document
    document.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [handleOpenMultiSelectDropdown, open]);

  const styleObj = {
    position: 'relative',
    color: COLORS.lightishGrey,
    backgroundColor: COLORS.greyOpacity06,
    lineHeight: '24px',
    fontFamily: 'Inter Light',
    fontWeight: 400,
    fontSize: '16px',
    width: wide ? '260px' : '180px',
    backdropFilter: 'blur(80px)',
    borderRadius: '8px',
    border: `1px solid ${COLORS.darkGrey}`,
  };

  const selectedLabel = `${selectedValues[0]}${
    selectedValues.length > 1 ? ` + ${selectedValues.length - 1}` : ''
  }`;
  const onlyEnglishSelected = selectedValues.length === 1 && selectedValues[0] === 'English';

  return (
    <DropdownWrapper>
      <Chip
        theme="dark"
        active={selectedValues.length !== 0 && !onlyEnglishSelected}
        label={selectedValues.length === 0 || onlyEnglishSelected ? label : selectedLabel}
        rightIcon={<ArrowDownIcon />}
        onClick={toggleDropdown}
      />
      {open && (
        <Select
          multiple
          open={open}
          onClose={() => handleOpenMultiSelectDropdown(false)}
          onOpen={() => handleOpenMultiSelectDropdown(true)}
          value={selectedValues}
          onChange={handleSelectChange}
          renderValue={() => null} // Hide the selected values in the dropdown
          style={{ top: '-38px', left: '64px', zIndex: -2 }}
          MenuProps={{
            PaperProps: {
              style: styleObj,
            },
          }}
        >
          {options.map((option) => {
            // When the option has an icon or image, label is the second child
            const optionLabel = option.props ? option.props.children[1] : option;
            return (
              <ItemWrapper>
                <ListItem
                  key={optionLabel}
                  style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}
                  onClick={() =>
                    handleSelectChange({
                      target: {
                        value: selectedValues.includes(optionLabel)
                          ? selectedValues.filter((item) => item !== optionLabel)
                          : [...selectedValues, optionLabel],
                      },
                    })
                  }
                >
                  <OptionWrapper>{option}</OptionWrapper>
                  <Checkbox
                    checked={selectedValues.includes(optionLabel)}
                    sx={[
                      {
                        marginLeft: 'auto',
                        strokeWidth: selectedValues.includes(optionLabel) ? 0.5 : 0.8,
                        stroke: selectedValues.includes(optionLabel)
                          ? COLORS.accentGreen
                          : COLORS.darkGrey,
                        color: selectedValues.includes(optionLabel)
                          ? COLORS.accentGreen
                          : COLORS.lightGreyAE,
                      },
                      {
                        '&:hover': {
                          color: selectedValues.includes(optionLabel)
                            ? COLORS.accentGreen
                            : COLORS.lightishGrey,
                          backgroundColor: 'transparent',
                        },
                      },
                      {
                        '&.Mui-checked': {
                          color: COLORS.accentGreen,
                          stroke: COLORS.accentGreen,
                        },
                      },
                    ]}
                  />
                </ListItem>
              </ItemWrapper>
            );
          })}
        </Select>
      )}
    </DropdownWrapper>
  );
};

export default MultiSelectDropdown;
