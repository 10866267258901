/* eslint-disable no-template-curly-in-string */
/* eslint-disable @typescript-eslint/naming-convention */

import React, { useCallback, useEffect, useState } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { navigate } from 'gatsby';

import { Button } from '@lesmills-international/components';
// eslint-disable-next-line import/no-unresolved
import { getPlanPeriod, getConvertedPrice, getPlanId } from '@src/utils';
import SpinnerLogo from '../../common/spinnerLogo/SpinnerLogo';

import {
  Wrapper,
  Title,
  SubTitle,
  SuccessMessage,
  ProcessingMessage,
  WelcomeMessage,
  Logo,
  ButtonWrapper,
} from './styles';
import { registrationFlowTracking } from '../../../utils/dataTracking';
import { StepLabel } from '../../../types/DataTracking';

const Success = ({
  data,
  handleStarted,
  pageContext,
  location,
  selectedPlan,
  newSubscription,
  userInfo,
}) => {
  const { page_title, subtitle, description, get_started, all_set_image, dont_refresh } = data;
  const [userEmail, setUserEmail] = useState('');
  const [trackingReady, setTrackingReady] = useState(false);
  const { email, familyName, givenName } = userInfo || {};

  const handleTrackingReady = useCallback(() => {
    setTrackingReady(true);
  }, []);

  const trackRegistrationFlowEvent = useCallback(() => {
    const { name, tier, currency, product_price_point } = selectedPlan || {};
    const { interval, interval_unit, price_in_cents } = product_price_point || {};
    const { state } = location || {};
    const discountInfo = state?.discountInfo;

    registrationFlowTracking({
      planName: name,
      stepLabel: StepLabel.CONVERSION_SUCCESS,
      planTier: tier?.internal_tier_id,
      planBillingFreq: getPlanPeriod(interval, interval_unit),
      currency,
      planPrice: getConvertedPrice(price_in_cents, currency).toString(),
      customerEmail: email,
      customerFirstName: givenName,
      customerLastName: familyName,
      planId: getPlanId(discountInfo, selectedPlan),
      eventCallBack: handleTrackingReady(),
    });
  }, [selectedPlan, handleTrackingReady, givenName, familyName, email, location]);

  useEffect(() => {
    const initializePageTracking = async () => {
      if (!newSubscription || !userInfo) {
        navigate('/signup/create-account/');
      }

      setUserEmail(email);
      trackRegistrationFlowEvent();
      setTimeout(() => {
        handleTrackingReady();
      }, 5000);
    };
    initializePageTracking();
  }, [
    pageContext,
    trackRegistrationFlowEvent,
    email,
    userInfo,
    newSubscription,
    handleTrackingReady,
  ]);

  return (
    <Wrapper id="successWrapper">
      {trackingReady ? (
        <>
          <Logo id="logo-black" alt="logo">
            <GatsbyImage
              id="logo-image"
              image={all_set_image.gatsbyImageData}
              alt={all_set_image.alt || 'all_set_logo'}
            />
          </Logo>
          <SubTitle className="uppercase">{subtitle}</SubTitle>
          <Title>{page_title}</Title>
          <SuccessMessage>{description.replace('${userEmail}', userEmail)}</SuccessMessage>
          <WelcomeMessage>Welcome aboard</WelcomeMessage>
          <ButtonWrapper id="success-button-wrapper">
            <Button ctaButton style={{ width: '100%' }} onClick={handleStarted}>
              <>{get_started}</>
            </Button>
          </ButtonWrapper>
        </>
      ) : (
        <>
          <SpinnerLogo />
          <ProcessingMessage>{dont_refresh}</ProcessingMessage>
        </>
      )}
    </Wrapper>
  );
};

export default Success;
