import { ArticleSummary, HomeSection } from '@components/molecules';
import React, { FC } from 'react';

interface Props {
  content: Queries.PrismicHomepageDataBodyArticleSlice;
}

const HomeArticleSlice: FC<Props> = ({ content }) => {
  const title = content.primary?.article_heading?.text;
  const article = content.items?.[0]?.selected_article?.document;

  if (!article) return null;

  return (
    <HomeSection title={title}>
      <div className="rounded-lg overflow-hidden">
        <ArticleSummary
          title={article.data?.title?.text}
          blurb={article.data?.meta_description}
          url={article.url}
          image={article.data?.display_image}
        />
      </div>
    </HomeSection>
  );
};

export default HomeArticleSlice;
