/* eslint-disable no-restricted-syntax, guard-for-in */
import * as React from 'react';
import { FFProviderProps, FeatureFlags } from './types';
import fflags from '../feature-flags';
import { Provider, FFContext } from './context';

/**
 * This provider on `componentDidMount` will:
 *  1. load the feature flags defined in
 *    "../feature-flags.ts" into localstorage and then
 *    into the Context API.
 *  2. if in a nonprod environment
 *      - expose a global function to `resetFlags()`
 *      - expose a global function to `toggleFlag(string)`
 */
class FFProvider extends React.Component<FFProviderProps, FFContext> {
  constructor(props: FFProviderProps) {
    super(props);
    this.state = {
      flags: fflags,
    };
  }

  // eslint-disable-next-line react/state-in-constructor
  readonly state: FFContext;

  componentDidMount() {
    const fflagState: FeatureFlags = {};
    const { allowOverride } = this.props;

    // TODO: clear flags from localstorage if they've been removed in config
    //

    // If there's a localStorage value, use that
    // Otherwise, use the one from our file
    for (const flag in fflags) {
      const localStorageFlagValue = localStorage.getItem(flag);
      if (localStorageFlagValue === null) {
        fflagState[flag] = fflags[flag];
      } else {
        fflagState[flag] = localStorageFlagValue === 'true';
      }
    }

    this.setState({
      flags: fflagState,
    });

    if (allowOverride) {
      (window as any).resetFlags = () => {
        Object.keys(localStorage).forEach((key) => {
          if (key.startsWith('FF')) {
            localStorage.removeItem(key);
          }
        });
      };

      (window as any).toggleFlag = (flag: string) => {
        this.onUpdateFlag(flag);
      };
    }
  }

  onUpdateFlag(flagName: string): string | boolean {
    const { flags: stateFlags } = this.state;
    const oldFlagValue = stateFlags[flagName];
    if (oldFlagValue === null || oldFlagValue === undefined) {
      return 'Flag not found';
    }
    const newFlagValue = !oldFlagValue;
    localStorage.setItem(flagName, newFlagValue ? 'true' : 'false');

    this.setState({
      flags: {
        ...stateFlags,
        [flagName]: newFlagValue,
      },
    });

    return newFlagValue;
  }

  render() {
    const { children } = this.props;
    const { flags } = this.state;
    return <Provider value={{ flags }}>{children}</Provider>;
  }
}

export default FFProvider;
