import { useState, useCallback } from 'react';
import { createEngagementClient } from '../../../../src/services/client';
import { REMOVE_FAVORITE_PROGRAM } from '../engagementGql/favoriteProgram';
import { addErrorInDatadogRum } from '../utils/utilities';

const useRemoveFavoriteProgram = () => {
  const [isProcessing, setIsProcessing] = useState(false);

  const removeFavoriteProgram = useCallback(async (programName: string) => {
    setIsProcessing(true);
    try {
      await createEngagementClient.mutate({
        mutation: REMOVE_FAVORITE_PROGRAM,
        variables: {
          contentType: 'program',
          contentId: programName,
        },
      });
    } catch (err) {
      addErrorInDatadogRum(err);
    } finally {
      setIsProcessing(false);
    }
  }, []);

  return { removeFavoriteProgram, isProcessing };
};

export default useRemoveFavoriteProgram;
