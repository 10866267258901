import { Workout } from '@backend';
/**
 * Deduplicates and orders by frequency
 * @param {Array} names - The array name of workout
 * @param {Array} programs - The array name of program
 */
export const sortByWorkoutFrequency = (workouts: Workout[]) => {
  if (!workouts?.length) return [];
  const frequency = {};
  workouts?.forEach((value) => {
    frequency[value.workoutName] = 0;
  });

  // add value to each of workoutName field in frequency object.
  // And filter out duplicated one in uniques object.
  // eslint-disable-next-line no-plusplus
  const uniques = workouts?.filter((value) => ++frequency[value.workoutName] === 1);

  // Sort uniques object base on workout name exist in frequency's value
  return uniques?.sort((a, b) => frequency[b.workoutName] - frequency[a.workoutName]);
};

export default sortByWorkoutFrequency;
