import { gql } from '@apollo/client';

const getProduct = gql`
  query getProduct($productId: String!, $countryCode: String) {
    getProduct(product_handle: $productId, country_code: $countryCode) {
      product_handle
      name
      tier {
        external_entity_id
        internal_tier_id
        tiered_entity_enabled
        tiered_entity_type
        tier_id
      }
      description
      currency
      country_code
      product_price_point {
        tax_included
        price_in_cents
        interval
        interval_unit
        trial_price_in_cents
        trial_interval
        trial_interval_unit
      }
    }
  }
`;

export default getProduct;
