import { navigate } from 'gatsby';
import { useEffect, useState } from 'react';
import getUser from '../graphql/getUser';
import { createClient } from '../services/client';
import { setLocalStorage, addErrorInDatadogRum } from '../utils/utilities';

const useSetUserInfo = () => {
  const [userValidated, setUserValidated] = useState(false);
  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    createClient
      .query({ query: getUser })
      .then((res) => {
        if (!res?.data?.getUser?.userId) {
          navigate('/signup/create-account/');
        }
        const user = res?.data?.getUser;
        setUserInfo(user);

        setLocalStorage('user-info', JSON.stringify(user));
        setUserValidated(true);
      })
      .catch((err) => {
        addErrorInDatadogRum(err);
        navigate('/signup/create-account/');
      });
  }, []);

  return { userValidated, userInfo };
};

export default useSetUserInfo;
