interface LoginRedirectionType {
  redirectTo?: string | null;
  partnerActivationCode?: string;
  tier?: 'base' | 'premium';
}

// Define regular expressions for URLs that should not be redirected
const REDIRECTION_NOT_ALLOWED_URLS: RegExp[] = [
  /\/signup\/select-plan/, // URL for the signup page
  /^\/$/, // Root (home) page
  /^\/[a-z]{2}-[a-z]{2}\/$/, // Home page with full locale e.g en-us
  /^\/[a-z]{2}\/$/, // Home page with lang
];

const generateRedirectionUrl = ({
  redirectTo,
  partnerActivationCode,
  tier,
}: LoginRedirectionType) => {
  if (partnerActivationCode) {
    return `/partner/?code=${partnerActivationCode}`;
  }

  const shouldRedirect =
    redirectTo && !REDIRECTION_NOT_ALLOWED_URLS.some((url) => url.test(redirectTo));

  if (!shouldRedirect) {
    if (tier === 'base') {
      return '/explore';
    }

    return '/';
  }

  return window.location.origin + decodeURIComponent(redirectTo);
};

export default generateRedirectionUrl;
