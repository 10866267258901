/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-filename-extension */

import React from 'react';
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews';
// eslint-disable-next-line import/no-unresolved
import { AuthProvider, BackendProvider } from '@context';
import { linkResolver } from '../../src/services/linkResolver';

// Import or global styles
import './src/styles/global.css';

const repositoryConfigs = [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY_NAME,
    linkResolver,
  },
];

// Adds a shared React Context for Prismic preview sessions.
export const wrapRootElement = ({ element }) => (
  <AuthProvider>
    <BackendProvider>
      <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>
        {element}
      </PrismicPreviewProvider>
    </BackendProvider>
  </AuthProvider>
);
