import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from '@components/atoms';

const LearnImageSlice = ({ content }) => {
  const primaryContent = content?.primary;
  const linkUrl = primaryContent?.link?.url;
  const image = primaryContent?.image;

  return (
    <div className="pt-4 lg:pt-6 pb-8 lg:pb-12 -mx-4 lg:-mx-12">
      {linkUrl ? (
        <Link to={linkUrl}>
          <GatsbyImage image={image?.gatsbyImageData} alt={image?.alt ?? ''} />
        </Link>
      ) : (
        <GatsbyImage image={image?.gatsbyImageData} alt={image?.alt ?? ''} />
      )}
    </div>
  );
};

export default LearnImageSlice;
