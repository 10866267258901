import startTescoSignup from '../graphql/startTescoSignup';
import pollSignupStatus from '../graphql/pollSignupStatus';

import { createPublicClient } from './client';

export interface SignupVariables {
  userInput: {
    email: string;
    password: string;
  };
  auth: {
    voucherCode: string;
  };
}

export const pollSignupInterval = (cbSuccess: Function, cbError: Function, requestId: string) => {
  let count = 0;
  const interval = setInterval(async () => {
    count += 1;
    if (count >= 3) {
      clearInterval(interval);
      cbError({ message: 'Request timeout' });
    }
    try {
      const res = await createPublicClient.query({
        query: pollSignupStatus,
        fetchPolicy: 'network-only',
        variables: { requestId },
      });

      const success = res.data?.pollSignupStatus?.success;
      const error = res.data?.pollSignupStatus?.error;

      if (success || (!success && !!error)) {
        if (success) {
          cbSuccess();
        } else {
          cbError({ message: error });
        }
        clearInterval(interval);
      }
    } catch (error) {
      cbError(error.graphQLErrors && error.graphQLErrors[0]);
    }
  }, 10000);
};

export const handlePollSignupStatus = async (
  requestId: string,
  handleSignupSuccess: Function,
  handleErrors: Function
) => {
  try {
    const res = await createPublicClient.query({
      query: pollSignupStatus,
      variables: { requestId },
    });
    // fix logic by LME-526
    // eslint-disable-next-line no-unsafe-optional-chaining
    const { success, error } = res.data?.pollSignupStatus;

    if (success) {
      handleSignupSuccess();
    } else if (!error) {
      pollSignupInterval(handleSignupSuccess, handleErrors, requestId);
    } else {
      handleErrors({ message: error });
    }
  } catch (error) {
    handleErrors(error.graphQLErrors && error.graphQLErrors[0]);
  }
};

export const signUpTescoUser = async (
  variables?: SignupVariables,
  handleSignupSuccess: Function,
  handleErrors: Function
) => {
  try {
    const res = await createPublicClient.mutate({
      mutation: startTescoSignup,
      variables,
    });
    const requestId = res.data.startTescoUserSignup;

    if (requestId) {
      handlePollSignupStatus(requestId, handleSignupSuccess, handleErrors);
    }
  } catch (error) {
    handleErrors(error.graphQLErrors && error.graphQLErrors[0]);
  }
};
