import { gql } from '@apollo/client';

const GET_ALGOLIA_CONFIGS = gql`
  query {
    getAlgoliaIndex {
      workoutIndex
      releaseIndex
    }
    getAlgoliaConfig {
      index
      key
    }
  }
`;

export default GET_ALGOLIA_CONFIGS;
