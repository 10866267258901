import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import getPromoCodeInformation from '../../graphql/getPromoCodeInformation';

type RenewalType = 'RENEWAL' | 'IMMEDIATE_PRODUCT_CHANGE' | 'DELAYED_PRODUCT_CHANGE';

export interface PromoInformation {
  type?: RenewalType;
  finalPriceInCentsTaxInclusive?: number;
  finalPriceInCentsTaxExclusive?: number;
  isRecurring?: false;
  productHandle?: string;
  discountPercentage?: number | null;
  discountAmount?: number | null;
}

export type GetPromoCodeInfoQuery = {
  getPromoCodeInformation: PromoInformation;
};

export type QueryGetPromoCodeInfoArgs = {
  promoCode?: string | null;
};

const useGetPromoCodeInfo = (
  options?: QueryHookOptions<GetPromoCodeInfoQuery, QueryGetPromoCodeInfoArgs>
): QueryResult<GetPromoCodeInfoQuery, QueryGetPromoCodeInfoArgs> =>
  useQuery<GetPromoCodeInfoQuery, QueryGetPromoCodeInfoArgs>(getPromoCodeInformation, options);

export default useGetPromoCodeInfo;
