import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client';
import {
  MutationUpdateAxiiUserArgs,
  AxiiUserInput,
} from '../../../../gatsby-theme-engagement/src/backend';
import UpdateUser from '../../graphql/updateUser';

interface UpdateSubcommsResponse {
  updateAxiiUser: {
    subComms: boolean;
    subCommsConfirmed: boolean;
  };
}

const useUpdateSubcomms = (
  options?: MutationHookOptions<UpdateSubcommsResponse, MutationUpdateAxiiUserArgs>
): MutationTuple<UpdateSubcommsResponse, MutationUpdateAxiiUserArgs> =>
  useMutation<UpdateSubcommsResponse, { input: AxiiUserInput }>(UpdateUser, options);

export default useUpdateSubcomms;
