import React, { FC } from 'react';
import { Wrapper, Message } from './style';
import SpinnerLogo from '../spinnerLogo/SpinnerLogo';

interface Props {
  loadingMsg?: string;
  loadingProcessMsg?: string;
}

const ProcessingMsg: FC<Props> = ({ loadingMsg, loadingProcessMsg }) => (
  <Wrapper>
    <SpinnerLogo />
    {loadingMsg && <Message>{loadingMsg}</Message>}
    {loadingProcessMsg && <Message>{loadingProcessMsg}</Message>}
  </Wrapper>
);

export default ProcessingMsg;
