/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import planIntervalMap from '../../../utils/planIntervalMap';
import {
  allowRolloverPreferences,
  checkMultiMonthRolloverCountry,
  checkIsTwelveMonthRolloverCountry,
} from '../../../utils/utilities';

import RadioGroup from '../../common/radioGroup';

import { RolloverWrapper, RolloverLabel, RolloverDescription } from './style';

const Rollover = ({
  prismicData,
  value,
  countryCode,
  selectedProductData,
  handleOnChange,
  error,
  rolloverCountries,
  resetRolloverValue,
}) => {
  const { rollover_label, rollover_description } = prismicData;

  const description = rollover_description.replace('{period}', value);

  const [options, setOptions] = useState([]);

  const rolloverCountriesAllowed = useMemo(
    () => allowRolloverPreferences(countryCode, selectedProductData, rolloverCountries),
    [countryCode, selectedProductData, rolloverCountries]
  );

  const setRadioData = useCallback(() => {
    const planIntervalList = [planIntervalMap[selectedProductData.interval], planIntervalMap[1]];

    const handleSelectedProductChange = () => {
      resetRolloverValue();
      setOptions(planIntervalList);
    };

    if (checkMultiMonthRolloverCountry(rolloverCountries, countryCode)) {
      if (selectedProductData.interval <= 1) {
        return null;
      }
      handleSelectedProductChange();
    }

    if (checkIsTwelveMonthRolloverCountry(rolloverCountries, countryCode)) {
      if (selectedProductData.interval !== 12) {
        return null;
      }
      handleSelectedProductChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rolloverCountries, countryCode, selectedProductData.interval]);

  useEffect(() => {
    setRadioData();
  }, [setRadioData]);

  if (!rolloverCountriesAllowed) {
    return null;
  }

  if (options.length === 0) {
    return null;
  }

  return (
    <RolloverWrapper>
      <RolloverLabel>{rollover_label}</RolloverLabel>
      <RadioGroup
        row
        id="rollover-radio-group"
        ariaLabel="rollover"
        name="row-radio-buttons-group"
        currentValue={value}
        handleOnChange={handleOnChange}
        radioData={options}
      />
      {error && <div className="form-field-helper">{error}</div>}
      {value && <RolloverDescription>{description}</RolloverDescription>}
    </RolloverWrapper>
  );
};

Rollover.defaultProps = {
  prismicData: {},
  value: '',
  countryCode: '',
  selectedProductData: {},
  handleOnChange: () => {},
  error: '',
};

Rollover.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  prismicData: PropTypes.object,
  value: PropTypes.string,
  countryCode: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  selectedProductData: PropTypes.object,
  handleOnChange: PropTypes.func,
  error: PropTypes.string,
};

export default Rollover;
