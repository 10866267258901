interface Category {
  id: string;
  label: string;
  active: boolean;
}

interface ActiveCategories {
  categories: Category[];
  activeCategory: Category;
}

const getActiveProgramCategories = (
  categories: Category[],
  userSelectedId: string
): ActiveCategories => {
  const cloneProgramCategories = [...categories];

  let isDeselected = false;
  let activeCategory: Category;

  cloneProgramCategories.forEach((category) => {
    if (userSelectedId === category.id) {
      const toggleActiveState = category.active ? ((isDeselected = true), false) : true;
      // eslint-disable-next-line no-param-reassign
      category.active = toggleActiveState;
      activeCategory = category;
    } else {
      // eslint-disable-next-line no-param-reassign
      category.active = false;
    }
  });

  if (isDeselected) {
    const defaulltCategory = cloneProgramCategories.find((category) => category.id === 'All');
    if (defaulltCategory) {
      defaulltCategory.active = true;
      activeCategory = defaulltCategory;
    }
  }

  return { categories: cloneProgramCategories, activeCategory: activeCategory! };
};

export default getActiveProgramCategories;
