import { createBreakpoint, createMap } from 'styled-components-breakpoint';

export const breakpoints = {
  xs: 320,
  sm: 600,
  md: 800,
  lg: 1024,
  xl: 1440,
  xxl: 1800,
};

export const breakpoint = createBreakpoint(breakpoints);
export const map = createMap(breakpoints);
