import React from 'react';
import { ReactComponent as CloseIcon } from '@assets/icons/close-nocolor.svg';
import { ReactComponent as SearchIcon } from '@assets/icons/search.svg';
import { SearchBarWrapper, Input, SearchIconWrapper, CloseButton } from './styles';

interface SearchBarProps {
  placeHolder: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClose: () => void;
}

const SearchBar = ({ placeHolder, onChange, onClose }: SearchBarProps) => (
  <SearchBarWrapper>
    <SearchIconWrapper>
      <SearchIcon />
    </SearchIconWrapper>
    <Input placeholder={placeHolder} onChange={onChange} autoFocus />
    <CloseButton onClick={onClose}>
      <CloseIcon />
    </CloseButton>
  </SearchBarWrapper>
);

export default SearchBar;
