import { gql } from '@apollo/client';

const Signup = gql`
  mutation signupUser($input: UserSignupInput!) {
    signupUser(input: $input) {
      clientToken
    }
  }
`;

export default Signup;
