import React from 'react';
import { SkeletonLine } from '@lesmills-international/components';
import {
  MobileTableLoaderRow,
  ServicePeriodWrapper,
  SkeletonRowDivider,
  TableLoaderContainer,
  TableLoaderRow,
} from './styles';

interface Props {
  numOfRows: number;
}

const TableLoader = ({ numOfRows }: Props) => (
  <>
    {[...Array(numOfRows)].map((_, index: number) => (
      <TableLoaderContainer key={index}>
        {/* Render desktop loading skeleton */}
        <TableLoaderRow>
          <SkeletonLine width="15%" height="15px" />
          <SkeletonLine width="20%" height="15px" />
          <SkeletonLine width="25%" height="15px" />
          <SkeletonLine width="15%" height="15px" />
          <SkeletonLine width="15%" height="15px" />
        </TableLoaderRow>

        {/* Render mobile loading skeleton */}
        <MobileTableLoaderRow>
          <SkeletonLine width="15%" height="40px" marginBottom={8} />
          <SkeletonLine width="30%" height="30px" marginBottom={8} />
          <ServicePeriodWrapper>
            <SkeletonLine width="40%" height="15px" />
            <SkeletonLine width="15%" height="15px" />
          </ServicePeriodWrapper>
        </MobileTableLoaderRow>
        <SkeletonRowDivider />
      </TableLoaderContainer>
    ))}
  </>
);

export default TableLoader;
