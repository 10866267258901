const ROUTES = (lang: string = 'en-nz') => {
  const currentLang = lang === 'en-nz' ? '' : `/${lang}`;

  const subscriptionUrl =
    process.env.GATSBY_RT_19_02_2024_ACCOUNT_OVERVIEW === 'true'
      ? '/subscription'
      : '/change-subscription';

  return {
    SUBSCRIPTION: `${currentLang}${subscriptionUrl}`,
    ACCOUNT: `${currentLang}/account`,
    OFFER_REDEMPTION: `${currentLang}/offer-redemption`,
    ACCOUNT_DETAILS: `${currentLang}/account-details`,
    BILLING_HISTORY: `${currentLang}/billing-history`,
    UPDATE_PAYMENT_DETAILS: `${currentLang}/update-payment-details`,
  };
};

export default ROUTES;
