enum VoucherStatus {
  SUCCESS_VOUCHER_AFFILIATE = 'successVoucherAffiliate',
  SUCCESS_REGULAR_AFFILIATE = 'successRegularAffiliate',
  OFFER_ID_NOT_FOUND = 'offerIdNotFound',
  UNKNOWN_VOUCHER_STATUS = 'unknownVoucherStatus',
  VOUCHER_NOT_FOUND = 'voucherNotFound',
  VOUCHER_PROCESSING = 'voucherProcessing',
  VOUCHER_REDEEMED = 'voucherRedeemed',
  VOUCHER_EXPIRED = 'voucherExpired',
  VOUCHER_MISSING = 'voucherMissing',
  VOUCHER_NOT_REQUIRED = 'voucherNotRequired',
  VOUCHER_OFFER_ID_NOT_MATCH = 'voucherOfferIdNotMatch',
  COUNTRY_CODE_MISSING = 'countryCodeMissing',
}

export default VoucherStatus;
