/* eslint-disable import/prefer-default-export */
const normalizePlans = ({ data }, planType, isWinback = false) => {
  let normalizedPlans;
  switch (planType) {
    case 'normal':
      normalizedPlans = data.getValidProducts.products.map((plan) => ({
        country_code: plan?.country_code,
        currency: plan?.currency,
        id: plan?.product_handle,
        product_handle: plan?.product_handle,
        interval: plan?.product_price_point?.interval,
        interval_unit: plan?.product_price_point?.interval_unit,
        name: plan?.name,
        price: plan?.product_price_point?.price_in_cents,
        trial_interval: isWinback ? null : plan?.product_price_point?.trial_interval,
        trial_interval_unit: plan?.product_price_point?.trial_interval_unit,
        sortOrder: plan?.product_price_point?.interval,
        product_price_point: plan?.product_price_point,
        isPromotion: false,
        tier: plan?.tier,
      }));
      break;
    case 'promotion':
      normalizedPlans = data.promotionPricingPlans.map((plan) => ({
        country_code: plan?.countryCode || null,
        currency: plan?.currency,
        id: plan?.productHandle,
        product_handle: plan?.productHandle,
        interval: plan?.interval,
        interval_unit: plan?.interval_unit || 'month',
        name: plan?.name,
        price: plan?.finalPrice || plan?.price,
        trial_interval: isWinback ? null : plan?.trialDays,
        trial_interval_unit: 'day',
        sortOrder: plan?.sortOrder,
        discountAmount: plan?.discountAmount,
        description: plan?.description,
        isRecurring: plan?.isRecurring || plan?.entity === 'P',
        isPromotion: true,
        regularPrice: plan?.price,
        product_price_point: {
          trial_interval_unit: 'day',
          trial_interval: isWinback ? null : plan?.trialDays,
          interval: plan?.interval,
          interval_unit: plan?.interval_unit || 'month',
          trial_price_in_cents: plan?.trialPrice,
          pricePointId: plan?.pricePointId,
          price_in_cents: plan?.finalPrice || plan?.price,
          product_handle: plan?.productHandle,
        },
        offerHandle: plan?.offerHandle || null,
        pricePointId: plan?.pricePointId,
        tier: plan?.tier,
      }));
      break;
    case 'affiliate':
      normalizedPlans = data.getAffiliateOffers.map((plan) => ({
        country_code: plan?.country_code || null,
        currency: plan?.product_price_point?.currency,
        id: plan?.product_handle,
        product_handle: plan?.product_handle,
        interval: plan?.product_price_point?.interval,
        interval_unit: plan?.product_price_point?.interval_unit || 'month',
        name: plan?.name,
        price:
          plan?.product_price_point?.final_price_in_cents ||
          plan?.product_price_point?.price_in_cents,
        regularPrice: plan?.product_price_point?.price_in_cents,
        trial_interval: isWinback ? null : plan?.product_price_point?.trial_interval,
        trial_interval_unit: plan?.product_price_point?.trial_interval_unit,
        sortOrder: plan?.sort_order,
        description: '',
        isRecurring: plan?.recurring,
        isPromotion: true,
        offerHandle: plan?.offer_handle || null,
        product_price_point: {
          ...plan?.product_price_point,
          price_in_cents:
            plan?.product_price_point?.final_price_in_cents ||
            plan?.product_price_point?.price_in_cents,
        },
        tier: plan.tier,
      }));
      break;
    case 'tier':
      normalizedPlans = data.getValidProducts.products?.map((plan) => ({
        country_code: plan?.country_code,
        currency: plan?.currency,
        id: plan?.product_handle,
        product_handle: plan?.product_handle,
        interval: plan?.product_price_point?.interval,
        interval_unit: plan?.product_price_point?.interval_unit,
        name: plan?.name,
        price: plan?.product_price_point?.price_in_cents,
        trial_interval: isWinback ? null : plan?.product_price_point?.trial_interval,
        trial_interval_unit: plan?.product_price_point?.trial_interval_unit,
        sortOrder: plan?.product_price_point?.interval,
        product_price_point: plan?.product_price_point,
        isPromotion: false,
        tier: plan?.tier,
        description: '',
        title: plan?.tier?.internal_tier_id,
      }));
      break;
    case 'tier_promotion':
      normalizedPlans = data.promotionPricingPlans?.map((plan) => ({
        country_code: plan?.countryCode || null,
        currency: plan?.currency,
        id: plan?.productHandle,
        product_handle: plan?.productHandle,
        interval: plan?.interval,
        interval_unit: plan?.interval_unit || 'month',
        name: plan?.name,
        price: plan?.finalPrice || plan?.price,
        trial_interval: isWinback ? null : plan?.trialDays,
        trial_interval_unit: 'day',
        sortOrder: plan?.sortOrder,
        discountAmount: plan?.discountAmount,
        description: plan?.description,
        isRecurring: plan?.isRecurring || plan?.entity === 'P',
        isPromotion: true,
        regularPrice: plan?.price,
        product_price_point: {
          trial_interval_unit: 'day',
          trial_interval: isWinback ? null : plan?.trialDays,
          interval: plan?.interval,
          interval_unit: plan?.interval_unit || 'month',
          trial_price_in_cents: plan?.trialPrice,
          pricePointId: plan?.pricePointId,
          price_in_cents: plan?.finalPrice || plan?.price,
          product_handle: plan?.productHandle,
        },
        offerHandle: plan?.offerHandle || null,
        pricePointId: plan?.pricePointId,
        tier: plan?.tier,
        title: plan?.tier?.internal_tier_id,
      }));
      break;
    default:
      break;
  }

  const sortedPlans = normalizedPlans.sort((a, b) => a.sortOrder - b.sortOrder);
  return sortedPlans;
};

export { normalizePlans };
