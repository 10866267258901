import { useEffect, useState } from 'react';
import { formStartTracking } from '../utils/dataTracking';
import { FormEventData } from '../types/DataTracking';

const useFormStartPushTracking = ({
  formName,
  stepLabel,
  planName,
  planType,
  planTier,
  planBillingFreq,
}: FormEventData) => {
  const [formStarted, setFormStarted] = useState<boolean>(false);

  const handleFormStarted = () => {
    setFormStarted(true);
  };

  useEffect(() => {
    if (formStarted) {
      formStartTracking({
        formName,
        stepLabel,
        planName,
        planTier,
        planBillingFreq,
      });
    }
  }, [formName, stepLabel, planName, planType, planTier, planBillingFreq, formStarted]);

  return { handleFormStarted };
};

export default useFormStartPushTracking;
