import React, { useContext } from 'react';
import { ProgramCard } from '@lesmills-international/components';

import { getVimeoProgramPageUrl } from '@services';
import { userContext } from '@context';
import { useRecommendedProgramsQuery } from '@backend';
import {
  Title,
  TitleWrapperShort,
  TitleWrapperTall,
  ProgramsSliceWrapperShort,
  ProgramsSliceWrapperTall,
  ProgramsSliceGridTall,
  ProgramsSliceGridShort,
  ProgramWrapperTall,
  ProgramWrapperShort,
} from './style';

interface Props {
  content: Queries.PrismicNewWebHomepageDataBodyPrograms;
}

const NewHomeProgramsSlice: React.FC<Props> = ({ content }) => {
  const { isNewUser, locale, userEntitlements } = useContext(userContext);
  const vimeoTicket = userEntitlements?.LMOD?.vimeoUserTicket;
  const { data: recommendedPrograms, loading: recommendedProgramsLoading } =
    useRecommendedProgramsQuery({
      variables: {
        locale,
      },
    });
  const title = content.primary?.title;
  const isTall = content?.primary?.is_tall;

  if (recommendedProgramsLoading) return null;
  if (recommendedPrograms?.recommendedPrograms?.length === 0) return null;
  if (content.slice_label === 'new_user_only' && !isNewUser) {
    return null;
  }
  const ProgramsSliceWrapper = isTall ? ProgramsSliceWrapperTall : ProgramsSliceWrapperShort;
  const TitleWrapper = isTall ? TitleWrapperTall : TitleWrapperShort;
  const ProgramWrapper = isTall ? ProgramWrapperTall : ProgramWrapperShort;
  const ProgramsSliceGrid = isTall ? ProgramsSliceGridTall : ProgramsSliceGridShort;
  return (
    <ProgramsSliceWrapper>
      <TitleWrapper>
        <Title>{title}</Title>
      </TitleWrapper>
      <ProgramsSliceGrid>
        {recommendedPrograms?.recommendedPrograms?.slice(0, 4).map((program, index) => (
          <ProgramWrapper key={index}>
            <ProgramCard
              name={program?.name}
              logo={program?.icon}
              backgroundImg={program?.imageWide}
              alternateName={program?.alternateName}
              link={getVimeoProgramPageUrl(program?.name, vimeoTicket)}
            />
          </ProgramWrapper>
        ))}
      </ProgramsSliceGrid>
    </ProgramsSliceWrapper>
  );
};

export default NewHomeProgramsSlice;
