import React, { useContext, useEffect } from 'react';

import { graphql, navigate } from 'gatsby';
import { withPrismicUnpublishedPreview } from 'gatsby-plugin-prismic-previews';
// eslint-disable-next-line import/no-relative-packages
import { authContext } from '../../../gatsby-theme-engagement/src/context';

import SpinnerLogo from '../components/common/spinnerLogo/SpinnerLogo';

import { partnerActivationCodeStatus, partnerSignupStatus } from '../constants/partnerStatus';
import ConsumeActivationCode from '../graphql/consumeActivationCode';
import getUser from '../graphql/getUser';
import { createClient, createPublicClient } from '../services/client';

import { addErrorInDatadogRum, getLocalStorage, setLocalStorage } from '../utils/utilities';

import useValidateActivationCode from '../hooks/useValidateActivationCode';
import {
  PARTNER_CODE_ERROR,
  PARTNER_SIGNUP,
  PARTNER_SIGNUP_STATUS,
} from '../constants/localStorageKeys';
import useGetCountryCode from '../hooks/useGetCountryCode';

const PartnerLoadingPage = ({ data, location, pageContext }) => {
  const { lang } = pageContext;
  const currentLang = lang === 'en-nz' ? '' : lang;

  const { process_message: processMessage } = data?.prismicPartnerProcessingPage?.data || {};
  const { isAuthenticated, isLoading } = useContext(authContext);
  const { countryCode: country, loading: isFetchingCountry, errorMessage } = useGetCountryCode();
  const urlParams = new URLSearchParams(location.search);

  const activationCode = urlParams.get('code');
  const { validatedCode, validateActivationCode } = useValidateActivationCode();

  const currentActiveSubscriptionStatuses = ['active', 'trialing', 'on_hold'];

  const navigateBaseOnSubscriptionState = ({
    subscriptionStatus = null,
    errorSignup = false,
    activationCodeStatus = null,
  }) => {
    if (activationCodeStatus) {
      // error code redirect here
      setLocalStorage(PARTNER_CODE_ERROR, activationCodeStatus);
      navigate(`/partner/error/${currentLang}`);
      return;
    }

    if (errorSignup) {
      if (
        // Error signup redirect here
        !subscriptionStatus ||
        !currentActiveSubscriptionStatuses.includes(subscriptionStatus)
      ) {
        setLocalStorage(PARTNER_SIGNUP_STATUS, partnerSignupStatus.ERROR);
      } else {
        setLocalStorage(PARTNER_SIGNUP_STATUS, partnerSignupStatus.CANNOT_ACTIVATE);
      }
      navigate(`/partner/signup/error/${currentLang}`);
      return;
    }

    setLocalStorage(PARTNER_SIGNUP, 'yes');
    if (!subscriptionStatus) {
      setLocalStorage(PARTNER_SIGNUP_STATUS, partnerSignupStatus.NEW_ACTIVATION);
    } else if (currentActiveSubscriptionStatuses.includes(subscriptionStatus)) {
      setLocalStorage(PARTNER_SIGNUP_STATUS, partnerSignupStatus.EXISTING_UPDATED);
    } else {
      // Probably in 'on-delayed-cancelation', 'canceled' state
      setLocalStorage(PARTNER_SIGNUP_STATUS, partnerSignupStatus.EXISTING_ACTIVATION_NOT_VALID);
    }
    navigate(`/partner/signup/success/${currentLang}`);
  };

  const tryConsumeOnExistingAccount = async (
    partnerProvidedGivenName,
    partnerProvidedFamilyName
  ) => {
    const userInfo = await createClient.query({ query: getUser });
    const { givenName, familyName, subComms, email, lmodSubscription } =
      userInfo.data?.getUser || {};

    const res = await createPublicClient.mutate({
      mutation: ConsumeActivationCode,
      variables: {
        activationCode,
        signupInput: {
          email,
          subComms: !!subComms,
          country,
          // If the partner has provided a given and/or family name, they should be updated.
          givenName: partnerProvidedGivenName || givenName,
          familyName: partnerProvidedFamilyName || familyName,
        },
      },
    });

    const consumptionDidSucceed = res.data?.consumeActivationCode.success;
    const lmodSubscriptionStatus = lmodSubscription?.state;
    // User has had a previous subscription
    if (consumptionDidSucceed) {
      return navigateBaseOnSubscriptionState({
        subscriptionStatus: lmodSubscriptionStatus,
        activationCodeDidSucceed: consumptionDidSucceed,
      });
    }
    return navigateBaseOnSubscriptionState({
      subscriptionStatus: lmodSubscriptionStatus,
      errorSignup: true,
    });
  };

  const processActivationCode = async () => {
    try {
      const { activationCodeIsValid, activationCodeStatus, email, givenName, familyName } =
        validatedCode;

      if (!activationCodeIsValid) {
        return navigateBaseOnSubscriptionState({ activationCodeStatus });
      }

      if (!isAuthenticated) return await navigate(`/partner/signup/${currentLang}`);

      // For authenticate user, will skip login page
      // match email addresss if it is provided by the partner
      const emailFromAuthenticatedUser = getLocalStorage('email');
      const canCodeBeUsed = !email || email === emailFromAuthenticatedUser;
      if (canCodeBeUsed) return await tryConsumeOnExistingAccount(givenName, familyName);
    } catch (error) {
      addErrorInDatadogRum(error);
    }

    return navigateBaseOnSubscriptionState({
      errorSignup: true,
    });
  };

  useEffect(() => {
    // validate activation code
    if (isLoading && activationCode && !validatedCode) {
      validateActivationCode(activationCode);
    }
  }, [isLoading, activationCode, validatedCode, validateActivationCode]);

  useEffect(() => {
    // redirect to error page when either there is a server error or missing country code
    if (errorMessage || (!isFetchingCountry && !country)) {
      navigateBaseOnSubscriptionState({
        errorSignup: true,
      });
    }

    if (!isLoading && activationCode && validatedCode) {
      processActivationCode();
    } else if (!isLoading && !activationCode && !isAuthenticated) {
      // if no activationCode in URL will redirect to invalid activation code error page
      navigateBaseOnSubscriptionState({
        activationCodeStatus: partnerActivationCodeStatus.INVALID,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, activationCode, validatedCode, isAuthenticated, errorMessage, country]);

  return (
    <div className="flex flex-col min-h-screen">
      <main id="main" className="flex-grow">
        <div className="flex flex-col justify-center items-center align-middle h-screen">
          <div className="bottom-8 align-middle flex items-end ">
            <SpinnerLogo />
          </div>
          <p className="text-24 text-grey text-center max-w-md mb-12">{processMessage}</p>
        </div>
      </main>
    </div>
  );
};

export const query = graphql`
  query ($lang: String!) {
    prismicPartnerProcessingPage(lang: { eq: $lang }) {
      _previewable
      data {
        process_message
      }
    }
  }
`;

export default withPrismicUnpublishedPreview(PartnerLoadingPage);
