import styled from 'styled-components';

import { breakpoint } from '../../../themes/breakpoints';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  min-height: 100vh;

  & .big-footer {
    display: none;
  }
`;

export const Logo = styled.a`
  cursor: pointer;
  text-align: center;
`;

export const LeftSection = styled.div`
  display: none;
  flex: 1;

  & .layout-background-image {
    object-fit: contain;
    height: 100%;
  }

  & .big-footer {
    display: none;
  }

  ${breakpoint('lg')`
    display: flex;
    flex-direction: column;

    & .big-footer {
      display: flex;
    }
  `}
`;

export const RightSection = styled.div`
  flex: 1;
  display: flex;
  position: relative;
  flex-direction: column;
  align-content: center;
  height: 100vh;
  padding-top: 64px;

  & .small-footer {
    display: flex;
  }

  & .medium-footer {
    display: none;
  }

  ${breakpoint('md')`
    padding-top: 90px;

    & .medium-footer {
      display: flex;
    }
  `}

  ${breakpoint('lg')`
    padding-top: 100px;

    & .small-footer {
      display: none;
    }

    & .medium-footer {
      display: none;
    }
  `}
`;

export const ScrollWrap = styled.div`
  display: flex;
  min-height: 100%;
  width: 100%;
  flex-direction: column;

  ${breakpoint('md')`
    padding-bottom: 76px;
    min-height: 100%;
    width: 100%;
    flex-direction: column;
    -ms-overflow-style: none;
    overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  `}
`;

// TODO: Fix width issue when not set to default.
export const ContentWrapper = styled.div`
  width: ${(props) => props.width || 350}px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
`;

export const CloseButton = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  top: 18px;
  right: 16px;
  cursor: pointer;
  border: 1px solid ${(props) => props.theme.colors.darkGreyOpacity01};
  transition: border-color 0.15s;
  transition-timing-function: ease-out;

  &:hover {
    border-color: ${(props) => props.theme.colors.darkGrey};
  }

  ${breakpoint('md')`
    top: 16px;
    right: 22px;
  `}
`;
