import React, { FC, Fragment, ReactNode } from 'react';
import { Dialog as HeadlessDialog, Transition } from '@headlessui/react';

interface ComponentProps {
  children?: ReactNode;
}
interface Props extends ComponentProps {
  show: boolean;
  onClose: () => void;
}

interface ButtonProps extends ComponentProps {
  onClick: () => void;
}

interface Components {
  Icon: FC<{ icon: any }>;
  Title: FC<ComponentProps>;
  Description: FC<ComponentProps>;
  Footer: FC<ComponentProps>;
  PrimaryButton: FC<ButtonProps>;
  SecondaryButton: FC<ButtonProps>;
}

const Dialog: FC<Props> & Components = ({ show, onClose, children }) => (
  <Transition show={show} as={Fragment}>
    <HeadlessDialog
      as="div"
      className="fixed inset-0 z-40 overflow-y-auto min-h-screen w-full flex flex-col justify-center items-center"
      onClose={onClose}
    >
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <HeadlessDialog.Overlay className="fixed inset-0 bg-black bg-opacity-50" />
      </Transition.Child>

      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div className="text-center w-full max-w-xs px-4 py-6 space-y-6 transition-all transform bg-white">
          {children}
        </div>
      </Transition.Child>
    </HeadlessDialog>
  </Transition>
);

Dialog.Icon = ({ icon: I }) => (
  <I color="currentColor" weight="thin" className="w-16 h-16 mx-auto" />
);

Dialog.Title = ({ children }) => (
  <HeadlessDialog.Title
    as="h3"
    className="text-grey text-32 leading-none uppercase font-inter-semibold"
  >
    {children}
  </HeadlessDialog.Title>
);

Dialog.Description = ({ children }) => (
  <HeadlessDialog.Description as="p" className="text-grey">
    {children}
  </HeadlessDialog.Description>
);

Dialog.Footer = ({ children }) => <div className="space-y-4">{children}</div>;

Dialog.PrimaryButton = ({ onClick, children }) => (
  <button
    type="button"
    className="w-full p-4 bg-grey text-white hover:bg-grey-new font-inter-semibold uppercase text-12"
    onClick={() => onClick()}
  >
    {children}
  </button>
);

Dialog.SecondaryButton = ({ onClick, children }) => (
  <button
    type="button"
    className="w-full p-2 font-inter-semibold uppercase text-12 hover:underline"
    onClick={() => onClick()}
  >
    {children}
  </button>
);

export default Dialog;
