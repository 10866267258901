import { useAssetByExternalIdQuery, useWorkoutByNameQuery } from '@backend';
import { HomeHero, HomeHeroLoading } from '@components/molecules';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import React, { FC, useContext } from 'react';
import { userContext } from '@context';

interface Props {
  content: {
    slice_label: 'new_user_only' | null;
    primary: {
      hero_video_link?: {
        url?: string;
      };
      hero_video_id: string;
      hero_tag_text?: {
        text?: string;
      };
      override_hero_image?: {
        gatsbyImageData?: IGatsbyImageData;
        alt?: string;
      };
    };
  };
}

const HeroSlice: FC<Props> = ({ content }) => {
  // if the slice is for new users only & your are not a new user
  const { isNewUser } = useContext(userContext);
  if (content.slice_label === 'new_user_only' && !isNewUser) {
    return null;
  }

  const url = content.primary?.hero_video_link?.url;
  const tagline = content?.primary?.hero_tag_text?.text;

  // Use image from prismic
  if (content.primary?.override_hero_image?.gatsbyImageData) {
    return (
      <HomeHero
        url={url}
        tagline={tagline}
        imageGatsby={content.primary?.override_hero_image?.gatsbyImageData}
      />
    );
  }
  // Use image from the cmds
  return <HeroSliceCmdsImage url={url} tagline={tagline} videoId={content.primary.hero_video_id} />;
};

interface HeroSliceCmdsImageProps {
  videoId: string;
  url?: string | null;
  tagline?: string | null;
}

const HeroSliceCmdsImage: FC<HeroSliceCmdsImageProps> = ({ videoId, url, tagline }) => {
  const { data: assetData } = useAssetByExternalIdQuery({
    variables: {
      externalId: videoId,
    },
  });
  const { data: workoutData } = useWorkoutByNameQuery({
    variables: {
      product: assetData?.assetByExternalId?.product ?? '',
      workoutName: assetData?.assetByExternalId?.workoutName ?? '',
    },
    skip: !assetData?.assetByExternalId?.product || !assetData?.assetByExternalId?.workoutName,
  });

  const workout = workoutData?.workoutByName?.items?.[0];

  if (!workout) {
    return <HomeHeroLoading />;
  }

  return <HomeHero url={url} tagline={tagline} imageSrc={workout.imageWide} workout={workout} />;
};

export default HeroSlice;
