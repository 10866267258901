import { gql } from '@apollo/client';

const getRolloverCountries = gql`
  query getRolloverCountries {
    getRolloverCountries {
      threeMonth
      twelveMonth
    }
  }
`;

export default getRolloverCountries;
