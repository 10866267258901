/* eslint-disable no-template-curly-in-string */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { RichText } from 'prismic-reactjs';

import Box from '@mui/material/Box';
import { TextField, Button, Password } from '@lesmills-international/components';

import {
  setLocalStorage,
  addErrorInDatadogRum,
  getLocalStorage,
  getLoginUrl,
} from '../../../utils/utilities';
import { formValidator, checkHaveErrors } from '../../../utils/validator';
import { formErrorTracking, formSubmitTracking } from '../../../utils/dataTracking';

import SignUpUid from '../../../constants/d2c-partner-signup';

import Title from '../../common/title';
import Alert from '../../common/alert/Alert';
import RadioGroup from '../../common/radioGroup';

import { ReactComponent as WrongIcon } from '../../../assets/icons/wrong.svg';
import { ReactComponent as CorrectIcon } from '../../../assets/icons/correct.svg';
import { ACTIVATION_CODE_FORMATTED } from '../../../constants/localStorageKeys';

import { Wrapper, LoginLink, ReceiveEmailLabel, ButtonWrapper, Agreement } from './style';
import { sessionReceiverURL } from '../../../../../gatsby-theme-engagement/src/services/amplifyStorage';
import useFormStartPushTracking from '../../../hooks/useFormStartPushTracking';
import { FormErrorName, FormName, StepLabel } from '../../../types/DataTracking';
import useCheckDoesUserExists from '../../../hooks/useCheckDoesUserExists';

import usePropsChanged from '../../../hooks/usePropsChanged';

const Form = styled(Box)(() => ({
  '& .MuiTextField-root': { margin: '8px 0', width: '320px' },
  maxWidth: '320px',
  textAlign: 'center',
  '& .MuiInputBase-input:focus': { boxShadow: 'none' },
}));

const CreateAccount = ({
  mode,
  data,
  lang,
  submitError,
  handleCreateAccount,
  handleRecaptchaValidateData,
  updateReceiveEmail,
  createAccountValues,
  discountInfo,
  countryCode,
  location,
  isProcessing,
}) => {
  const {
    create_account_title,
    have_an_account_text,
    log_in_link_label,
    first_name_label,
    last_name_label,
    email_label,
    password_label,
    button_text,
    receive_email_label,
    receive_email_yes,
    receive_email_no,
    password_dynamic_message,
    existing_email_message,
    receive_email_required,
    password_space_error_message,
    agreement,
  } = data;

  const [values, setValues] = useState(createAccountValues);

  const [formErrors, setFormErrors] = useState({
    first_name: {
      errorMsg: '',
      valid: false,
    },
    last_name: {
      errorMsg: '',
      valid: false,
    },
    email: {
      errorMsg: '',
      valid: false,
    },
    password: {
      errorMsg: '',
      valid: false,
    },
  });

  const defaultTrackingData = useMemo(
    () => ({
      formName: FormName.CREATE_ACCOUNT_FORM,
      stepLabel: StepLabel.CONFIRM_PLAN,
    }),
    []
  );

  const { handleFormStarted } = useFormStartPushTracking(defaultTrackingData);

  const handleFormSubmitErrorTracking = useCallback(
    (formErrorName) => {
      formErrorTracking({
        ...defaultTrackingData,
        formErrorName,
      });
    },
    [defaultTrackingData]
  );

  const [omitReceiveEmailRadio, setOmitReceiveEmailRadio] = useState(true);
  const [emailHelperText, setEmailHelperText] = useState('');
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [passwordHelperText, setPasswordHelperText] = useState('');
  const [error, setError] = useState('');
  const [receiveEmailHelperText, setReceiveEmailHelperText] = useState('');

  const loginPage = useMemo(
    () => getLoginUrl(discountInfo, lang, isPartnerSignup),
    [discountInfo, lang, isPartnerSignup]
  );

  const isEmailChanged = usePropsChanged(values.email);

  // read query string
  const urlParams = new URLSearchParams(location.search);
  const currentUserEmail = urlParams.get('email');

  const isPartnerSignup = mode === SignUpUid.Partner;

  const { userExists, handleCheckUserExists, loading, emailCheckServerError, handleSetUserExists } =
    useCheckDoesUserExists();

  useEffect(() => {
    const allFormsAreFilled = Object.values(values).every((value) => value !== '');

    if (allFormsAreFilled) {
      setSubmitDisabled(false);
    }
  }, [values, formErrors]);

  useEffect(() => {
    if (currentUserEmail) {
      setValues({ ...values, email: currentUserEmail });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (submitError) {
      setError(submitError);
      handleFormSubmitErrorTracking(FormErrorName.SERVER_ERROR);
    }
  }, [submitError, handleFormSubmitErrorTracking]);

  useEffect(() => {
    if (formErrors.password.errorMsg) {
      setPasswordHelperText(formErrors.password.errorMsg);
    }
    if (formErrors.email.errorMsg) {
      setEmailHelperText(formErrors.email.errorMsg);
    } else if (!userExists) {
      setEmailHelperText('');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formErrors, userExists]);

  useEffect(() => {
    if (currentUserEmail) {
      setValues({ ...values, email: currentUserEmail });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateSingleField = (name, value, isPasswordOnChange) =>
    formValidator(
      ['REQUIRED', name.toUpperCase()],
      value,
      {
        requiredError: data[`${name}_required`].text,
        invalidError: data[`${name}_invalid`].text,
        passWordSpaceError: password_space_error_message,
      },
      isPasswordOnChange
    );

  const handleSubmit = (event) => {
    event.preventDefault();

    const hasErrors = handleValidate();

    if (hasErrors) {
      handleFormSubmitErrorTracking(FormErrorName.INCOMPELTE_FIELDS);
      return;
    }

    if (!values.receiveEmail) {
      setReceiveEmailHelperText(receive_email_required?.text);
      handleFormSubmitErrorTracking(FormErrorName.INCOMPELTE_FIELDS);
      return setSubmitDisabled(true);
    }

    const handleNewUserCreateAccount = () => {
      setError('');

      // Clean object values
      Object.keys(values).forEach((key) => {
        values[key] = values[key].trim();
      });

      const onSuccessCallback = formSubmitTracking(defaultTrackingData);

      handleCreateAccount({ createAccountFormValues: values, onSuccessCallback });
    };

    if (!isEmailChanged) {
      handleNewUserCreateAccount();
      return;
    }

    const checkUserExistsOnSuccess = (userAlreadyExists) => {
      if (userAlreadyExists) {
        setEmailHelperText(
          <span className="form-field-helper">
            {existing_email_message?.text}{' '}
            <a href={loginPage} className="existing-email-login-link">
              {log_in_link_label?.text}
            </a>
          </span>
        );
        handleFormSubmitErrorTracking(FormErrorName.SERVER_ERROR);
        setSubmitDisabled(true);
        return;
      }

      handleNewUserCreateAccount();
    };

    handleCheckUserExists({
      email: values.email,
      handleRecaptchaValidateData,
      successCallback: checkUserExistsOnSuccess,
    });
  };

  const handleReceiveEmailChange = (event) => {
    try {
      const optIn = event.target.value; // Is either "yes" or "no"
      setLocalStorage('opt_in', optIn);

      if (optIn) {
        setReceiveEmailHelperText('');
        setSubmitDisabled(false);
      } else setSubmitDisabled(true);

      setValues({ ...values, receiveEmail: optIn });
      updateReceiveEmail(optIn);
    } catch (err) {
      addErrorInDatadogRum(err);
    }
  };

  // get activationCode information from local storage, if partner pass through that info, then the input field will not be editable
  // this will only work for partner signup journey
  const activationCodeInfo = JSON.parse(getLocalStorage(ACTIVATION_CODE_FORMATTED) || null);
  const checkEmailFromPartnerSignup = () => {
    if (
      activationCodeInfo &&
      isPartnerSignup &&
      (activationCodeInfo.familyName || activationCodeInfo.givenName)
    ) {
      setValues({
        ...values,
        lastName: activationCodeInfo.familyName,
        firstName: activationCodeInfo.givenName,
      });
    }
  };
  const isEmailDisabled = Boolean(activationCodeInfo?.email);
  const isFirstNameDisabled = Boolean(activationCodeInfo?.givenName);
  const isLastNameDisabled = Boolean(activationCodeInfo?.familyName);

  useEffect(() => {
    checkEmailFromPartnerSignup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleValidate = () => {
    const firstNameError = validateSingleField('first_name', values.firstName);
    const lastNameError = validateSingleField('last_name', values.lastName);
    const emailError = validateSingleField('email', values.email);
    const passwordError = validateSingleField('password', values.password);

    const errors = {
      first_name: firstNameError,
      last_name: lastNameError,
      email: emailError,
      password: passwordError,
    };

    const hasErrors = checkHaveErrors(errors);

    if (hasErrors) setFormErrors(errors);

    return hasErrors;
  };

  const handleSingleValidate = (field, event) => {
    const validateError = validateSingleField(field, event.target.value);
    if (!validateError.errorMsg && !userExists) {
      setSubmitDisabled(false);
    }
    setFormErrors({ ...formErrors, [field]: validateError });
  };

  const handlePasswordValidate = (event) => {
    const validateError = validateSingleField('password', event.target.value);

    if (validateError.errorMsg) setPasswordHelperText(<span>{formErrors.password.errorMsg}</span>);
  };

  const handlePasswordHelperText = (value) => {
    const passwordError = validateSingleField('password', value, true);
    const helperTextIcon = passwordError.valid ? (
      <CorrectIcon style={{ display: 'inline-block', marginLeft: '5px' }} />
    ) : (
      <WrongIcon style={{ display: 'inline-block', marginLeft: '5px' }} />
    );
    setPasswordHelperText(
      <span>
        {password_dynamic_message.text}
        {helperTextIcon}
      </span>
    );
  };

  const handlePasswordChange = (event) => {
    handlePasswordHelperText(event.target.value);
    setFormErrors({ ...formErrors, password: { errorMsg: '', valid: formErrors.password.valid } });
  };

  const handleChange = (field, event) => {
    try {
      if (field === 'receiveEmail' && event.target.value) {
        setReceiveEmailHelperText('');
        setSubmitDisabled(false);
      }
      if (field === 'email') {
        handleSetUserExists(false);
        setEmailHelperText('');

        if (countryCode !== 'us') setOmitReceiveEmailRadio(false);
      }
      setValues({ ...values, [field]: event.target.value });
    } catch (err) {
      addErrorInDatadogRum(err);
    }
  };

  return (
    <Wrapper id="create-account-wrapper">
      {currentUserEmail && (
        <div className="text-30 font-inter-semibold uppercase py-4 text-center">WELCOME BACK</div>
      )}
      {mode === SignUpUid.D2C ? (
        <>
          <Title id="create-account-title">
            <>{create_account_title.text}</>
          </Title>
          <LoginLink id="have-an-account">
            {have_an_account_text.text}
            <a href={loginPage} id="log-in-link">
              {' '}
              {log_in_link_label.text}
            </a>
          </LoginLink>
        </>
      ) : (
        <Title id="create-account-title">
          <>{create_account_title.text}</>
        </Title>
      )}

      {(error || emailCheckServerError) && <Alert content={error || emailCheckServerError} />}
      <Form id="create-account-form" component="form" noValidate autoComplete="off">
        <TextField
          required
          label={first_name_label.text}
          autoComplete="given-name"
          variant="filled"
          name="first_name"
          id="first-name-field"
          disabled={isPartnerSignup && isFirstNameDisabled}
          value={values.firstName}
          error={!!formErrors.first_name.errorMsg}
          helperText={formErrors.first_name.errorMsg}
          onClick={() => handleFormStarted()}
          onBlur={(event) => {
            handleSingleValidate('first_name', event);
          }}
          onChange={(event) => {
            handleChange('firstName', event);
          }}
        />
        <TextField
          required
          label={last_name_label.text}
          autoComplete="family-name"
          variant="filled"
          name="last_name"
          id="last-name-field"
          disabled={isPartnerSignup && isLastNameDisabled}
          value={values.lastName}
          error={!!formErrors.last_name.errorMsg}
          helperText={formErrors.last_name.errorMsg}
          onBlur={(event) => {
            handleSingleValidate('last_name', event);
          }}
          onChange={(event) => {
            handleChange('lastName', event);
          }}
        />
        <TextField
          required
          className="email-text-field"
          label={email_label.text}
          autoComplete="email"
          variant="filled"
          name="email"
          id="email-field"
          value={values.email}
          disabled={isPartnerSignup && isEmailDisabled}
          error={!!formErrors.email.errorMsg}
          helperText={emailHelperText}
          onBlur={(event) => {
            handleSingleValidate('email', event);
          }}
          onChange={(event) => {
            handleChange('email', event);
          }}
        />
        {!omitReceiveEmailRadio && (
          <>
            <ReceiveEmailLabel id="receive-email-label">
              {receive_email_label.text}
            </ReceiveEmailLabel>
            <RadioGroup
              row
              id="receive-email-radio-group"
              ariaLabel="receive-email"
              name="row-radio-buttons-group"
              currentValue={createAccountValues.receiveEmail}
              handleOnChange={(event) => {
                handleReceiveEmailChange(event);
              }}
              radioData={[
                { value: 'yes', label: receive_email_yes.text },
                { value: 'no', label: receive_email_no.text },
              ]}
            />
            {receiveEmailHelperText && (
              <div className="form-field-helper">{receiveEmailHelperText}</div>
            )}
          </>
        )}
        <Password
          label={password_label.text}
          autoComplete="current-password"
          name="password"
          id="password-field"
          value={values.password}
          error={formErrors.password.errorMsg.length}
          helperText={passwordHelperText}
          onBlur={(event) => {
            handlePasswordValidate(event);
            handleSingleValidate('password', event);
          }}
          onChange={(event) => {
            handlePasswordChange(event);
            handleChange('password', event);
          }}
        />

        <Agreement>
          <RichText key={agreement?.text} render={agreement?.richText} />
        </Agreement>

        <ButtonWrapper id="submit-button-wrapper">
          <Button
            id="submit-button"
            disabled={submitDisabled}
            loading={isProcessing || loading}
            onClick={handleSubmit}
            ctaButton
            style={{ width: '100%' }}
            type="submit"
          >
            <>{button_text.text}</>
          </Button>
        </ButtonWrapper>
      </Form>

      {sessionReceiverURL && (
        <iframe
          title="session_receiver"
          id="session_receiver"
          src={sessionReceiverURL}
          width="0"
          height="0"
        />
      )}
    </Wrapper>
  );
};

CreateAccount.defaultProps = {
  data: {},
  lang: '',
  createAccountValues: {},
  handleRecaptchaValidateData: () => {},
  isProcessing: false,
};

CreateAccount.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  data: PropTypes.object,
  lang: PropTypes.string,
  createAccountValues: PropTypes.object,
  handleRecaptchaValidateData: PropTypes.func,
  isProcessing: PropTypes.bool,
};

export default CreateAccount;
