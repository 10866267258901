import { gql } from '@apollo/client';

// Query to list comments for a given videoId
export const GET_COMMENTS = gql`
  query GetComments($videoId: String!, $nextToken: String, $pageSize: Int) {
    comments(videoId: $videoId, nextToken: $nextToken, pageSize: $pageSize) {
      items {
        id
        content
        user {
          id
          name
        }
        createdAt
        isReplied
        repliedComments {
          content
          createdAt
          user {
            id
            name
          }
        }
      }
      nextToken
    }
  }
`;

// Mutation to create a new comment
export const CREATE_COMMENT = gql`
  mutation CreateComment($videoId: String!, $content: String!, $replyTo: String) {
    createComment(videoId: $videoId, content: $content, replyTo: $replyTo) {
      id
      content
      createdAt
    }
  }
`;

export const REPORT_COMMENT = gql`
  mutation ReportComment($id: String!) {
    reportComment(id: $id)
  }
`;

export const DELETE_COMMENT = gql`
  mutation DeleteComment($id: String!) {
    deleteComment(id: $id)
  }
`;
