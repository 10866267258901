/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { withPrismicUnpublishedPreview } from 'gatsby-plugin-prismic-previews';
import { Link, graphql } from 'gatsby';
import { ArrowRight } from 'phosphor-react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import '../styles/404.css';

import { breakpoint } from '../themes/breakpoints';

import { ReactComponent as Logo } from '../assets/images/new-logo.svg';

// eslint-disable-next-line import/no-relative-packages
import { Footer } from '../../../gatsby-theme-engagement/src/components/molecules';
import COLORS from '../themes/colors';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const LesmillsPlusLogo = styled(Logo)`
  height: 0.75rem;
  fill: currentColor;
`;

const LinkContainer = styled.div`
  margin-left: 12px;
  margin-top: 12px;

  ${breakpoint('md')`
    margin-left: 12px;
    margin-top: 12px;
  `}
`;

const Main = styled.main`
  display: flex;
  padding: 3rem;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
`;

const Heading = styled.h1`
  font-size: 160px;
  position: relative;
  font-weight: 700;
  color: ${COLORS.darkGrey};
  font-family: 'Inter SemiBold';
  ${breakpoint('lg')`
  font-size: 240px;
`};
`;

const ImageWrapper = styled.div`
  display: flex;
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translate(-50%, 0);
  align-items: flex-end;
`;

const Description = styled.p`
  font-size: 14px;
  margin-bottom: 3rem;
  text-align: center;
  max-width: 28rem;
  margin-bottom: 3rem;
  color: ${COLORS.darkGrey};
`;

const HomeLink = styled(Link)`
  font-family: 'Inter SemiBold';
  color: ${COLORS.darkGrey};
  display: flex;
  text-transform: uppercase;
  align-items: center;
  font-size: 14px;
  justify-content: center;
`;

const ArrowContainer = styled.div`
  margin-left: 0.5rem;
`;

const NotFoundPage = ({ data }) => {
  // fix logic by LME-526
  // eslint-disable-next-line no-unsafe-optional-chaining
  const { redirect_message, missing_page_message } = data?.prismic404Page?.data;

  return (
    <Container>
      <LinkContainer>
        <Link to="/" title="lesmills-logo">
          <LesmillsPlusLogo />
        </Link>
      </LinkContainer>
      <Main id="main">
        <Heading>
          <span>404</span>

          <ImageWrapper>
            <StaticImage
              src="../../../gatsby-theme-engagement/src/assets/404.png"
              className="static-image"
              alt=""
            />
          </ImageWrapper>
        </Heading>

        <Description>{missing_page_message}</Description>

        <HomeLink to="/explore">
          <span>{redirect_message}</span>
          <ArrowContainer>
            <ArrowRight weight="bold" />
          </ArrowContainer>
        </HomeLink>
      </Main>

      <Footer />
    </Container>
  );
};

export const query = graphql`
  query ($lang: String!) {
    prismic404Page(lang: { eq: $lang }) {
      _previewable
      data {
        missing_page_message
        redirect_message
      }
    }

    prismicSignUpLayout(lang: { eq: $lang }) {
      _previewable
      data {
        logo_white {
          alt
          gatsbyImageData(width: 133, height: 33)
        }
        logo_black {
          alt
          gatsbyImageData(width: 133, height: 33)
        }
        background_image {
          alt
          gatsbyImageData
        }
        body {
          ... on PrismicSignUpLayoutDataBodyFooterNavigation {
            items {
              nav_label {
                text
              }
              nav_link {
                text
              }
            }
          }
        }
      }
    }
  }
`;

export default withPrismicUnpublishedPreview(NotFoundPage);
