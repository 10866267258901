/* eslint-disable @typescript-eslint/naming-convention */
import React, { ReactElement, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import { RichText } from 'prismic-reactjs';
import { userContext } from '../../../gatsby-theme-engagement/src/context';
import {
  SignupChannel,
  SubscriptionType,
  getSubscriptionType,
  isFullyCanceled,
  isPendingCancellation,
  isSubscriptionCanceled,
  isSubscriptionFailed,
  isSubscriptionOnHold,
} from '../utils/subscriptions';
import { formatPendingCancelDesc } from '../components/accountOverview/utils';

const RestartButtonWrapper = styled.button`
  cursor: pointer;
  text-decoration: underline;
`;

const RichTextContainer = styled.div`
  a {
    text-decoration: underline;
  }
`;

export type NotificationSeverity = 'error' | 'success' | 'info';

type PrismicDataType = Queries.PrismicAccountOverviewPageData;

export interface NotificationState {
  message?: ReactElement | string | null;
  severity: NotificationSeverity;
}

interface Props {
  signupChannelList: SignupChannel[];
  prismicData:
    | Pick<
        PrismicDataType,
        | 'restart_subscription_cancellation'
        | 'restart_subscription_pending_cancellation'
        | 'restart_subscription'
        | 'subscription_on_hold'
        | 'subscription_on_hold_reseller'
        | 'subscription_failed'
        | 'no_active_subscription_winback'
        | 'no_active_subscription_trialist'
      >
    | undefined;
  handleRestartSubscription: () => void;
}

const CanceledMessage = ({
  canceledMessage,
  restartSubscriptionMessage,
  handleClick,
}: {
  canceledMessage: string;
  restartSubscriptionMessage: string;
  handleClick: () => void;
}) => (
  <div>
    {canceledMessage}{' '}
    <RestartButtonWrapper onClick={handleClick}>{restartSubscriptionMessage}</RestartButtonWrapper>
  </div>
);

const useAccountNotification = ({
  signupChannelList,
  prismicData,
  handleRestartSubscription,
}: Props) => {
  const {
    lmodSubscription,
    signupChannel,
    type,
    error,
    hasActiveSubscription,
    isEntitledToFreeTrial,
  } = useContext(userContext);
  const [notification, setNotification] = useState<NotificationState | null>(null);
  const state = lmodSubscription?.state;

  const setNotificationMessage = useCallback((notificationState: NotificationState) => {
    setNotification((prev) => ({ ...prev, ...notificationState }));
  }, []);

  const {
    restart_subscription_cancellation,
    restart_subscription_pending_cancellation,
    restart_subscription,
    subscription_failed,
    subscription_on_hold,
    subscription_on_hold_reseller,
    no_active_subscription_trialist,
    no_active_subscription_winback,
  } = prismicData;

  const subscriptionType = useMemo(
    () =>
      getSubscriptionType({
        subscription: lmodSubscription,
        type: type as SubscriptionType,
        userSignupChannel: signupChannel,
        signupChannelList: signupChannelList!,
      }),
    [lmodSubscription, type, signupChannel, signupChannelList]
  );

  const isOnHoldState = useMemo(() => isSubscriptionOnHold(state), [state]);

  const isFailedState = useMemo(() => isSubscriptionFailed(state), [state]);

  // Retail or Affiliate subscription
  const isD2C = useMemo(
    () => subscriptionType === 'RETAIL' || subscriptionType === 'AFFILIATE',
    [subscriptionType]
  );

  // Reseller subscription
  const isReseller = useMemo(() => subscriptionType === 'RESELLER', [subscriptionType]);

  const subscriptionCanceledMessage = useMemo(() => {
    if (!isSubscriptionCanceled(state) || (isPendingCancellation(state) && isReseller)) {
      return;
    }

    let message = '';

    // Handle fully canceled
    if (isFullyCanceled(state)) {
      message = restart_subscription_cancellation || '';
    }

    // Handle pending cancellation with d2c subscription (Retail or Affiliate)
    if (isPendingCancellation(state) && isD2C) {
      message = formatPendingCancelDesc(
        restart_subscription_pending_cancellation,
        lmodSubscription
      );
    }

    return (
      <CanceledMessage
        canceledMessage={message}
        restartSubscriptionMessage={restart_subscription || ''}
        handleClick={handleRestartSubscription}
      />
    );
  }, [
    state,
    isReseller,
    isD2C,
    restart_subscription,
    handleRestartSubscription,
    restart_subscription_cancellation,
    restart_subscription_pending_cancellation,
    lmodSubscription,
  ]);

  const noActiveSubscriptionMessage = useMemo(() => {
    let message = no_active_subscription_winback;

    if (hasActiveSubscription && !isEmpty(lmodSubscription)) {
      return;
    }

    if (isEntitledToFreeTrial) {
      message = no_active_subscription_trialist;
    }

    return (
      <RichTextContainer>
        <RichText render={message.richText} key={message.text} />
      </RichTextContainer>
    );
  }, [
    isEntitledToFreeTrial,
    no_active_subscription_winback,
    no_active_subscription_trialist,
    hasActiveSubscription,
    lmodSubscription,
  ]);

  const subscriptionOnHoldMessage = useMemo(() => {
    let message = subscription_on_hold;

    if (!isOnHoldState) {
      return;
    }

    if (subscriptionType === 'RESELLER') {
      message = subscription_on_hold_reseller;
    }

    return (
      <RichTextContainer>
        <RichText render={message.richText} key={message.text} />
      </RichTextContainer>
    );
  }, [isOnHoldState, subscription_on_hold_reseller, subscription_on_hold, subscriptionType]);

  const subscriptionFailedMessage = useMemo(() => {
    if (!isFailedState && !error) {
      return;
    }

    return (
      <RichTextContainer>
        <RichText render={subscription_failed.richText} key={subscription_failed.text} />
      </RichTextContainer>
    );
  }, [isFailedState, subscription_failed, error]);

  useEffect(() => {
    if (subscriptionType === 'IAP') {
      return;
    }

    const messages: Record<string, JSX.Element | undefined> = {
      subscriptionFailedMessage,
      subscriptionOnHoldMessage,
      subscriptionCanceledMessage,
      noActiveSubscriptionMessage,
    };

    Object.keys(messages).some((key) => {
      if (messages[key]) {
        setNotificationMessage({
          message: messages[key],
          severity: 'info',
        });
        return true;
      }
      return false;
    });
  }, [
    noActiveSubscriptionMessage,
    subscriptionType,
    subscriptionFailedMessage,
    subscriptionCanceledMessage,
    subscriptionOnHoldMessage,
    setNotificationMessage,
  ]);

  const clearNotificationMessage = () => {
    setNotification(null);
  };

  return { notification, setNotificationMessage, clearNotificationMessage };
};

export default useAccountNotification;
