/* eslint-disable @typescript-eslint/naming-convention */
import React, { FC } from 'react';
import { PageProps } from 'gatsby';
import styled from 'styled-components';
import { withPrivateRoute, Layout } from '@components/molecules';
import SliceZone from '@components/sliceZone';
import { breakpoint } from '@themes';
import { BackButton } from '@lesmills-international/components';

interface PageContextProps {
  data: {
    meta_title: string | null;
    meta_description: string | null;
    body: any;
  };
}

const CollectionWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.black};
  padding-bottom: 40px;

  .hero--card {
    background-position: top;

    ${breakpoint('sm')`
      height: 405px;
    `}

    ${breakpoint('md')`
      height: 480px;
    `}

    ${breakpoint('xl')`
      height: 675px;
    `}
  }

  .hero--card .content--left {
    height: 90%;

    ${breakpoint('xl')`
      height: 84%;
    `}
  }

  .hero--slice--wrapper .arrow--prev,
  .hero--slice--wrapper .arrow--next {
    ${breakpoint('sm')`
      top: calc(405px/2 - 18px);
    `}

    ${breakpoint('md')`
      top: calc(480px/2 - 18px);
    `}

    ${breakpoint('xl')`
      top: calc(675px/2 - 18px);
    `}
  }
`;

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.black};
`;

const BackBtnWrapper = styled.div`
  position: absolute;
  top: 36px;
  z-index: 22;

  ${breakpoint('xs')`
    left: 16px;
  `}

  ${breakpoint('sm')`
    left: 32px;
  `}

  ${breakpoint('lg')`
    left: 80px;
  `}
`;

const CollectionPageTemplate: FC<PageProps<any, PageContextProps>> = ({ pageContext }) => {
  const { data } = pageContext;
  const { meta_title, meta_description, body } = data;

  return (
    <Layout metaData={{ title: meta_title, description: meta_description }}>
      <Wrapper>
        <CollectionWrapper>
          <BackBtnWrapper>
            <BackButton
              onClick={() => {
                window.history.back();
              }}
              theme="dark"
            />
          </BackBtnWrapper>
          <SliceZone slices={body} pageName="Collection" />
        </CollectionWrapper>
      </Wrapper>
    </Layout>
  );
};

export default withPrivateRoute(CollectionPageTemplate);
