import React, { FC, useMemo, useContext } from 'react';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { isBrowser } from '@services';
import fetch from 'isomorphic-fetch';
import { authContext } from '@context';
import Auth from '@aws-amplify/auth';

interface ProviderProps {
  children?: React.ReactNode;
}

const httpLink = createHttpLink({
  fetch,
  uri: process.env.GATSBY_APP_SYNC_URL,
});

const createAuthLink = () =>
  setContext(async (_, { headers }) => {
    const user = await Auth.currentAuthenticatedUser();
    localStorage.setItem('jwtToken', user.signInUserSession.idToken.jwtToken);

    return {
      headers: {
        ...headers,
        Authorization: user.signInUserSession.idToken.jwtToken,
      },
    };
  });

const createClient = () =>
  new ApolloClient({
    ssrMode: !isBrowser(),
    link: from([createAuthLink(), httpLink]),
    cache: new InMemoryCache(),
  });

const BackendProvider: FC<ProviderProps> = ({ children }) => {
  const { isAuthenticated } = useContext(authContext);

  // Reinitialize client when user signs in/out to clear cached private data.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const client = useMemo(() => createClient(), [isAuthenticated]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default BackendProvider;
