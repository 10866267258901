const planIntervalMap = {
  1: {
    value: 'monthly',
    label: 'Monthly',
  },
  3: {
    value: '3-monthly',
    label: '3-Monthly',
  },
  6: {
    value: '6-monthly',
    label: '6-Monthly',
  },
  12: {
    value: 'annually',
    label: 'Annually',
  },
};

export default planIntervalMap;
