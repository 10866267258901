import React from 'react';
import { TableHead as MUITableHead, TableRow } from '@mui/material';
import { HeadCell, Order, TableData } from './types';
import { SortArrow, TableCellHeader, TableSortLabel } from './styles';

interface Props {
  headCells: HeadCell[];
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof TableData) => void;
  order: Order;
  orderBy: keyof TableData;
}

const CustomArrow = ({ disabled, direction }: { disabled: boolean; direction: Order }) => (
  <SortArrow disabled={disabled} direction={direction} />
);

const TableHead = ({ order, orderBy, onRequestSort, headCells }: Props) => {
  const createSortHandler = (property: keyof TableData) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <MUITableHead>
      <TableRow>
        {headCells?.map((headCell) => {
          const disabled = orderBy !== headCell.id;
          const direction = orderBy === headCell.id ? order : 'asc';
          return (
            <TableCellHeader
              key={headCell.id}
              align="left"
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                disabled={disabled}
                direction={direction}
                onClick={createSortHandler(headCell.id)}
                IconComponent={() =>
                  CustomArrow({
                    disabled,
                    direction,
                  })
                }
              >
                {headCell.label}
              </TableSortLabel>
            </TableCellHeader>
          );
        })}
      </TableRow>
    </MUITableHead>
  );
};

export default TableHead;
