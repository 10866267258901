import React, { FC, useState, useContext, useEffect } from 'react';
import { Hero } from '@lesmills-international/components';
import { TIERS } from '@constants';
import { userContext } from '@context';
import { useCheckFavoriteProgram, useAddFavoriteProgram, useRemoveFavoriteProgram } from '@hooks';
import { ProgramHeroWrapper } from './style';

interface HeroImg {
  url: string;
}
interface HeroProps {
  content: {
    primary: {
      hero_description: string;
      hero_title: string;
      hero_image: HeroImg;
      onSave: () => void;
      buttonSecondaryText: string;
    };
  };
  programName: string;
}

const HeroComponent: FC<HeroProps> = ({ content, programName }) => {
  const { tier } = useContext(userContext);
  const { isProgramFavorited } = useCheckFavoriteProgram(programName);
  const { addFavoriteProgram, isProcessing: isAdding } = useAddFavoriteProgram();
  const { removeFavoriteProgram, isProcessing: isRemoving } = useRemoveFavoriteProgram();
  const [isFavourited, setIsFavourited] = useState(false);

  useEffect(() => {
    setIsFavourited(isProgramFavorited);
  }, [isProgramFavorited]);

  const handleUpdateMylist = async () => {
    if (isFavourited) {
      await removeFavoriteProgram(programName);
      setIsFavourited(false);
    } else {
      await addFavoriteProgram(programName);
      setIsFavourited(true);
    }
  };

  const isPremium = tier === TIERS.PREMIUM;
  const isProcessing = isAdding || isRemoving;

  return (
    <ProgramHeroWrapper>
      <Hero
        backgroundImg={content.primary.hero_image?.url}
        mainText={content.primary.hero_title}
        descText={content.primary.hero_description}
        buttonSecondaryText={isFavourited ? 'saved' : 'save'}
        saveLoading={isProcessing}
        isSaved={isFavourited}
        onSave={
          isPremium
            ? () => {
                handleUpdateMylist();
              }
            : null
        }
      />
    </ProgramHeroWrapper>
  );
};

export default HeroComponent;
