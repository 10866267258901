import { useState } from 'react';
import { ApolloError, useLazyQuery } from '@apollo/client';
import doesUserExist from '../graphql/doesUserExist';
import { createPublicClient } from '../services/client';
import { addErrorInDatadogRum } from '../utils/utilities';
import captureException from '../utils/sentry';

interface CheckUserExistsType {
  email: string;
  handleRecaptchaValidateData: () => Promise<any>;
  successCallback: (userExists: string) => void;
}

const useCheckUserExists = () => {
  const [userExists, setUserExists] = useState(false);
  const [emailCheckServerError, SetEmailCheckServerError] =
    useState<ApolloError['message'] | null>(null);

  const handleSetUserExists = (val: boolean) => {
    setUserExists(val);
  };

  const handleError = (
    error: ApolloError,
    variables: {
      email: string;
      validationData: any;
    }
  ) => {
    addErrorInDatadogRum(error);
    SetEmailCheckServerError(error.message);
    // Send error to sentry
    captureException({
      action: 'existingUserCheck',
      requestVariables: {
        emai: variables.email,
        validationData: variables.validationData,
      },
      email: variables.email.trim().toLowerCase(),
      ...error,
    });
  };

  const [checkDoesUserExists, { loading }] = useLazyQuery(doesUserExist, {
    client: createPublicClient,
  });

  const handleCheckUserExists = async ({
    email,
    handleRecaptchaValidateData,
    successCallback,
  }: CheckUserExistsType) => {
    const { validationData, captchaSignature } = await handleRecaptchaValidateData();
    checkDoesUserExists({
      variables: {
        email,
        validationData,
        captchaSignature,
      },
      onCompleted: (data) => {
        const { doesUserExist: alreadyInUse } = data;
        setUserExists(alreadyInUse);
        successCallback(alreadyInUse);
      },
      onError: (error) => {
        handleError(error, {
          email,
          validationData,
        });
      },
    });
  };

  return { handleCheckUserExists, userExists, emailCheckServerError, loading, handleSetUserExists };
};
export default useCheckUserExists;
