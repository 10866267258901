import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

const BackgroundImage = ({ children, image }) => (
  <div className="relative">
    <div className="z-10 relative">{children}</div>
    <div className="z-0 absolute left-0 top-0 w-full h-full">
      <GatsbyImage
        className="object-cover object-left w-full h-full"
        image={image.gatsbyImageData}
        alt={image.alt ? image.alt : ''}
      />
    </div>
  </div>
);

export default BackgroundImage;
