import { gql } from '@apollo/client';

const getAffiliateOffers = gql`
  query getAffiliateOffers($offerId: String!, $affiliateId: String, $voucherCode: String) {
    getHeaders {
      countryCode
    }
    getAffiliateOffers(
      tune_offer_id: $offerId
      tune_affiliate_id: $affiliateId
      voucher_code: $voucherCode
    ) {
      country_code
      product_handle
      offer_handle
      name
      description
      recurring
      sort_order
      product_price_point {
        currency
        final_price_in_cents
        product_handle
        price_point_id
        price_in_cents
        interval
        interval_unit
        trial_price_in_cents
        trial_interval
        trial_interval_unit
        initial_charge_in_cents
        initial_charge_after_trial
      }
    }
  }
`;

export default getAffiliateOffers;
