import { css } from 'styled-components';

export const WrapperStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  background-color: ${(props) => props.theme.colors.white};
  margin-top: 24px;
  margin-bottom: 8px;
`;

export const StepStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  background: ${(props) =>
    props.status === 'active' || props.status === 'finished'
      ? props.theme.colors.black
      : props.theme.colors.lightGreyB1};
  border-radius: 50%;
  color: ${(props) => props.theme.colors.white};
  font-size: 10px;
  font-weight: 800;
`;

export const HorizontalLineStyles = css`
  width: 30px;
  border: 1px solid
    ${(props) =>
      props.status === 'finished' ? props.theme.colors.black : props.theme.colors.lightGreyB1};
  margin: 0 5px;
`;
