import { Link } from '@components/atoms';
import React, { FC } from 'react';
import styled from 'styled-components';
import { ReactComponent as Arrow } from '@assets/icons/arrow-drop-down.svg';
import { pushTracking } from '@src/utils';
import { Event, Params } from '@src/type';
import { PARAM_VALUE } from '@constants';
import { localeInNavLink } from '../../../utils/utilities';

const MenuText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -2px;
`;

const MenuIcon = styled.div`
  margin-left: 5px;
`;

const DropdownParent = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

const DropdownActive = styled.div`
  display: ${(props) => (props.show ? 'initial' : 'none')};
`;

const DropdownWrapper = styled.div`
  position: absolute;
  top: 48px;
  right: -25px;
  background: #111111;
  border-radius: 0.5em;
  width: 130px;
  height: 3.5em;
  padding: 0.5em;
  height: ${(props) => 1 + 2.5 * props.childrenCount}em;
`;

const Dropdown = styled.div`
  position: static;
  text-transform: none;
  padding: 5px;
  background: transparent;
  &:hover {
    color: #dddddd;
  }
`;

const DropdownTriangle = styled.div`
  position: absolute;
  bottom: 0.5em;
  right: 1.1em;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0.6em 0.6em 0.6em;
  border-color: transparent transparent #111111 transparent;
`;

const HeaderLink: FC<Props> = ({
  linkText,
  linkItem,
  childrenItem,
  activeMenuButton,
  setActiveMenuButton,
}) => {
  const classes =
    'hover:text-grey-review cursor-pointer py-4 block font-inter-semibold uppercase text-netural-400 text-12 leading-5 tracking-wider border-b-2 border-transparent transition-colors duration-300 ease-out';
  if (!Array.isArray(childrenItem) || childrenItem?.length === 0) {
    // regular navigation button (not a dropdown)
    return (
      <Link
        to={localeInNavLink(linkItem?.url)}
        className={classes}
        activeClassName="border-green-accent text-white"
        onMouseEnter={() => setActiveMenuButton(null)}
        onMouseLeave={() => setActiveMenuButton(null)}
        onClick={() => {
          pushTracking({
            event: Event.NAV_SELECT,
            [Params.MENU_CATEGORY_1]: linkText,
            [Params.MENU_TYPE]: PARAM_VALUE.MENU_TYPE_HEADER,
          });
        }}
      >
        <div>{linkText}</div>
      </Link>
    );
  }
  // display a dropdown
  const childrenCount = childrenItem?.length; // determins the height of the dropdown container
  return (
    <DropdownParent
      className="cursor-pointer py-4 font-inter-semibold uppercase text-netural-400 text-12 leading-5 tracking-wider transition-colors duration-300 ease-out"
      onMouseEnter={() => setActiveMenuButton(linkText)}
      onMouseLeave={() => setActiveMenuButton(null)}
      onClick={(e) => e.target === e.currentTarget && setActiveMenuButton(linkText)}
    >
      <MenuText
        className={
          activeMenuButton === linkText
            ? 'transition-colors duration-300 text-grey-review'
            : 'transition-colors duration-300 text-netural-400'
        }
      >
        {linkText}
        <MenuIcon>
          <Arrow />
        </MenuIcon>
      </MenuText>
      <DropdownActive show={activeMenuButton === linkText} className="cursor-auto">
        <DropdownTriangle />
        <DropdownWrapper childrenCount={childrenCount}>
          {childrenItem.map((child) => (
            <Dropdown key={`${child.parent}-${child.link_text}`} className="font-inter-semibold">
              <Link
                to={child.link_item?.url}
                activeClassName="border-grey"
                className="cursor-pointer"
                onClick={() => {
                  pushTracking({
                    event: Event.NAV_SELECT,
                    [Params.MENU_CATEGORY_1]: linkText,
                    [Params.MENU_CATEGORY_2]: child.link_text,
                    [Params.MENU_TYPE]: PARAM_VALUE.MENU_TYPE_HEADER,
                  });
                }}
              >
                {child.link_text}
              </Link>
            </Dropdown>
          ))}
        </DropdownWrapper>
      </DropdownActive>
    </DropdownParent>
  );
};

export default HeaderLink;
