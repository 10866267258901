import { EUROPE, EUROPE_MINUS_GERMANY, CHARGIFY_WORLD_WIDE } from '../constants/promoCountries';

enum Country {
  EUROPE_GROUP = 'europe',
  EUROPE_MINUS_GERMANY_GROUP = 'europe minus germany',
  CHARGIFY_WORLD_WIDE_GROUP = 'chargify world wide',
  ALL_GROUP = 'all country',
}

const checkPromoCountry = (country: Country | string, countryCode: string) => {
  switch (country) {
    case Country.EUROPE_GROUP:
      return EUROPE.includes(countryCode);
    case Country.EUROPE_MINUS_GERMANY_GROUP:
      return EUROPE_MINUS_GERMANY.includes(countryCode);
    case Country.CHARGIFY_WORLD_WIDE_GROUP:
      return CHARGIFY_WORLD_WIDE.includes(countryCode);
    case Country.ALL_GROUP:
      return true;
    default:
      return false;
  }
};

export default checkPromoCountry;
