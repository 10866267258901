import React, { FC } from 'react';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { graphql, PageProps } from 'gatsby';

import { withPrivateRoute, Metadata, Search } from '@components/molecules';

interface PageContextProps {
  lang: string;
}

const SearchPageTemplate: FC<PageProps<Queries.GetSearchPageDataQuery, PageContextProps>> = ({
  data,
}) => {
  const page = data.prismicSearchPage?.data;

  const handleClose = () => {
    // // Users will reach search from the Vimeo hosted page. Therfore we need a way to go "back" to it
    if (window.history.length > 1) {
      window.history.back();
    } else {
      // This handles when a referring website opens it in a new tab
      window.close();
    }
  };

  if (page) {
    return (
      <>
        <Metadata title={page.meta_title} description={page.meta_description} />
        <Search placeHolder={page.search_place_holder_message} onClose={handleClose} fullScreen />
      </>
    );
  }
  return <div>prismicSearchPage page document does not exist</div>;
};

export default withPrismicPreview(withPrivateRoute(SearchPageTemplate));

export const query = graphql`
  query GetSearchPageData($lang: String!) {
    prismicSearchPage(lang: { eq: $lang }) {
      data {
        meta_title
        meta_description
        search_place_holder_message
      }
    }
  }
`;
