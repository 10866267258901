/* eslint-disable @typescript-eslint/naming-convention */
import { navigate } from 'gatsby';
import { format } from 'date-fns';
import { isBrowser } from '../../utils/utilities';
import {
  AMOUNT_PLACE_HOLDER_TEXT,
  INTERVAL_PLACE_HOLDER_TEXT,
  PROMO_ERROR_GROUP,
  PromoErrorType,
} from './constants';
import {
  MapPricingData,
  MapSuccessMessageItem,
  PrismicFrequencyData,
  PromoErrorGroupType,
  SuccessMessageProps,
  SuccessType,
  TextTranslation,
} from './type';

export const mapPricingData = ({
  productInformation,
  promotionInformation,
  countryCode,
}: MapPricingData) => {
  const pricingCardData = {
    country_code: countryCode,
    currency: productInformation?.currency,
    id: productInformation?.product_handle,
    product_handle: productInformation?.product_handle,
    interval: productInformation?.product_price_point?.interval,
    interval_unit: productInformation?.product_price_point?.interval_unit,
    name: productInformation.name,
    price: promotionInformation?.finalPriceInCentsTaxInclusive,
    tier: productInformation?.tier?.internal_tier_id,
    title: productInformation?.tier?.internal_tier_id,
    isRecurring: promotionInformation?.isRecurring,
    isPromotion: true,
    regularPrice: productInformation?.product_price_point?.price_in_cents,
    product_price_point: {
      interval: productInformation?.product_price_point?.interval,
      interval_unit: productInformation?.product_price_point?.interval_unit || 'month',
    },
  };

  return pricingCardData;
};

export const getCurrentPaymentFrequency = (
  frequencyData: PrismicFrequencyData,
  priceInterval?: number
) => {
  const { monthly_text, three_monthly_text, annually_text } = frequencyData;

  const FREQUENCY_MAPPING = {
    [monthly_text]: 1,
    [three_monthly_text]: 3,
    [annually_text]: 12,
  };

  const currentFrequencyKey =
    Object.keys(FREQUENCY_MAPPING).find((key) => FREQUENCY_MAPPING[key] === priceInterval) ||
    monthly_text;

  return FREQUENCY_MAPPING[currentFrequencyKey];
};

const findAll = (placeHolder: string) => new RegExp(placeHolder, 'g');

export const generateTermsAndConditionText = ({
  description,
  billingPeriod,
  amount,
}: TextTranslation) => {
  const findInterval = findAll(INTERVAL_PLACE_HOLDER_TEXT);
  const findAmount = findAll(AMOUNT_PLACE_HOLDER_TEXT);

  const termsAndCondition = description
    ?.replace(findInterval, billingPeriod)
    ?.replace(findAmount, amount);

  return termsAndCondition;
};

export const mapPromoError = (errorType?: PromoErrorType) =>
  errorType
    ? (Object.keys(PROMO_ERROR_GROUP) as (keyof typeof PROMO_ERROR_GROUP)[]).find((key) =>
        PROMO_ERROR_GROUP[key].includes(errorType)
      )
    : errorType;

export const mapPrismicErrorDescription = (
  groupErrorCode?: PromoErrorGroupType,
  prismicData?: any
) => {
  const { invalid_promo_error, promo_redemption_error, ineligible_account_error, server_error } =
    prismicData?.data || {};

  let prismicErrorData = server_error;

  switch (groupErrorCode) {
    case PromoErrorGroupType.INVALID_PROMO_ERRROR:
      prismicErrorData = invalid_promo_error;
      break;
    case PromoErrorGroupType.PROMO_REDEMPTION_ERROR:
      prismicErrorData = promo_redemption_error;
      break;
    case PromoErrorGroupType.ACCOUNT_INELIGIBLE_ERROR:
      prismicErrorData = ineligible_account_error;
      break;
    default:
      prismicErrorData = server_error;
  }

  return prismicErrorData;
};

export const mapSuccessMessageItem = (
  successType: SuccessType | null,
  items: MapSuccessMessageItem
) => {
  const {
    defualtTitle,
    defaultDescription,
    cancellationTitle,
    cancellationDescription,
    primaryButtonText,
    secondaryButtonText,
  } = items;

  if (!successType) {
    return null;
  }

  const primaryButton = {
    label: primaryButtonText || '',
    action: () => navigate('/'),
  };

  const secondaryButton = {
    label: secondaryButtonText || 'View my account',
    action: () => {
      if (isBrowser) {
        if (process.env.GATSBY_RT_19_02_2024_ACCOUNT_OVERVIEW === 'true') {
          // When back to the account page, need to refresh the page to get the account status updated
          window.location.href = `${window.location.origin}/account`;
          return;
        }
        window.location.href = `${process.env.GATSBY_MAIN_LES_MILLS_URL}/customer/account`;
      }
    },
  };

  let successProps: SuccessMessageProps = {
    title: defualtTitle || '',
    description: defaultDescription,
    primaryButton,
    secondaryButton,
    hasIcon: true,
  };

  if (successType === SuccessType.CANCELLATION_SUCCESS) {
    successProps = {
      ...successProps,
      title: cancellationTitle || '',
      description: cancellationDescription,
      hasIcon: false,
    };
  }

  return successProps;
};

export const handleMyAccountPageNavigation = (toMyAccount: boolean) => {
  if (toMyAccount && isBrowser) {
    if (process.env.GATSBY_RT_19_02_2024_ACCOUNT_OVERVIEW === 'true') {
      // When back to the account page, need to refresh the page to get the account status updated
      window.location.href = `${window.location.origin}/account`;
      return;
    }
    window.location.href = `${process.env.GATSBY_MY_ACCOUNT_URL}`;
  } else {
    navigate('/');
  }
};

export const handleRefresh = () => {
  if (isBrowser) {
    window.location.reload();
  }
};

export const formatDate = (dt: string | Date) => format(new Date(dt), 'dd MMM y');

export const generatePricingCardDescription = ({
  description,
  amount,
}: Omit<TextTranslation, 'billingPeriod'>) => {
  const findAmount = findAll(AMOUNT_PLACE_HOLDER_TEXT);
  return description?.replace(findAmount, amount);
};
