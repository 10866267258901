import { Disclosure, Transition } from '@headlessui/react';
import React, { FC, ReactNode } from 'react';

interface Props {
  show: boolean;
  children?: ReactNode;
}

const NavMenu: FC<Props> = ({ children, show }) => (
  <Transition
    show={show}
    enter="transition duration-100 ease-out"
    enterFrom="transform scale-95 opacity-0"
    enterTo="transform scale-100 opacity-100"
    leave="transition duration-75 ease-out"
    leaveFrom="transform scale-100 opacity-100"
    leaveTo="transform scale-95 opacity-0"
    className="fixed lg:hidden flex flex-col w-full h-screen bg-grey-new-lighterer z-40 overflow-y-auto"
    style={{
      top: '3.25em',
      paddingBottom: '3.25em',
    }}
  >
    <Disclosure.Panel static className="h-full">
      {children}
    </Disclosure.Panel>
  </Transition>
);

export default NavMenu;
