import React from 'react';
import { ReactComponent as QuoteStart } from '@assets/quote-start.svg';
import { ReactComponent as QuoteEnd } from '@assets/quote-end.svg';
import { RichText } from 'prismic-reactjs';

const QuoteSlice = ({ content }) => (
  <figure className="pt-4 lg:pt-6 pb-8 lg:pb-12">
    {content.primary.quote_text && (
      <blockquote className="flex space-x-4">
        <div className="self-start">
          <QuoteStart />
        </div>
        <div className="text-24 leading-relaxed">
          <RichText render={content.primary.quote_text.richText} />
        </div>
        <div className="self-end">
          <QuoteEnd />
        </div>
      </blockquote>
    )}

    {content.primary.quote_author && (
      <figcaption
        className="text-16 leading-relaxed mt-2"
        style={{
          marginLeft: '53px',
          marginRight: '53px',
        }}
      >
        - {content.primary.quote_author.text}
      </figcaption>
    )}
  </figure>
);

export default QuoteSlice;
