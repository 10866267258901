import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client';
import { Subscription } from '../../../../gatsby-theme-engagement/src/backend';
import restartSubscription from '../../graphql/restartSubscription';

const useRestartSubscription = (
  options?: MutationHookOptions<Subscription, unknown>
): MutationTuple<Subscription, unknown> =>
  useMutation<Subscription, unknown>(restartSubscription, options);

export default useRestartSubscription;
