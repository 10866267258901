import styled, { keyframes } from 'styled-components';

const animationEllipsis = keyframes`
  0% {
    content: ".";
  }
  25% {
    content: "..";
  }
  50% {
    content: "...";
  }
  75% {
    content: "..";
  }
`;

const SpinnerDots = styled.span`
  &::after {
    display: inline-block;
    animation: ${animationEllipsis} 2s infinite;
    content: '.';
    width: 1em;
    text-align: left;
  }
`;

export default SpinnerDots;
