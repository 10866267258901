import styled from 'styled-components';

import { styled as MUIStyled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import { Link } from 'gatsby';

import { breakpoint } from '../../themes/breakpoints';

const Title = styled.p`
  text-align: left;
  font-size: 20px;
  line-height: 28px;
  color: ${(props) => props.color || props.theme.colors.greyLetter};
  margin-bottom: 15px;
`;

const Note = styled.p`
  text-align: left;
  line-height: 26px;
  color: ${(props) => props.theme.colors.greyLetter};
  margin-bottom: 10px;
`;

const ToggleLabel = styled.p`
  text-align: left;
  line-height: 16px;
  color: ${(props) => props.theme.colors.greyLetter};
  font-size: 14px;
`;

const LoginLink = styled(Link)`
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 32px;
  text-decoration: underline;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.darkGrey};

  ${breakpoint('md')`
    font-size: 12px;
  `}
`;

const BlackSwitch = MUIStyled(Switch)(() => ({
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-thumb': {
    color: '#fff',
  },
  '& .MuiSwitch-thumb': {
    border: '1px solid #333',
    boxShadow: 'none',
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#4e4e4e',
    opacity: 1,
  },
}));

const ButtonWrapper = styled.div`
  margin-top: 8px;
  margin-bottom: 12px;
  & > button:hover {
    background-color: ${(props) => props.theme.colors.black} !important;
  }
`;

const ExternalLink = styled.a`
  text-decoration: none;
  font-size: 14px;
  width: 320px;
  padding-top: 14px;
  padding-bottom: 13px;
  text-align: center;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.darkGrey};

  &:hover {
    background-color: ${(props) => props.theme.colors.black};
  }
`;

const ExternalLinkWrapper = styled.a`
  margin-top: 24px;
  display: flex;
`;

export {
  BlackSwitch,
  LoginLink,
  Note,
  Title,
  ToggleLabel,
  ExternalLink,
  ButtonWrapper,
  ExternalLinkWrapper,
};
