/* eslint arrow-body-style: ["error", "as-needed"] */
/* eslint-env es6 */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ReactComponent as CheckIcon } from '../../../assets/icons/check.svg';

import { WrapperStyles, StepStyles, HorizontalLineStyles } from './style';

const Wrapper = styled.div`
  ${WrapperStyles}
`;
const Step = styled.div`
  ${StepStyles}
`;

const HorizontalLine = styled.div`
  ${HorizontalLineStyles}
`;

const Stepper = ({ totalSteps, currentStep }) => {
  const getStatus = (data) => {
    let status = '';

    if (data === currentStep) {
      status = 'active';
    } else if (data < currentStep) {
      status = 'finished';
    } else if (data === totalSteps) {
      status = 'last';
    }

    return status;
  };

  const renderStep = () => {
    const defaultStepData = Array(totalSteps)
      .fill(null)
      .map((_, i) => i + 1);

    return (
      defaultStepData.length &&
      defaultStepData.map((data, index) => {
        const status = getStatus(data);
        const displayHorizontalLine = index + 1 !== totalSteps;
        return (
          <React.Fragment key={`step_${data}`}>
            <Step status={status}>
              {status === 'finished' ? <CheckIcon alt="check-icon" /> : data}
            </Step>
            {displayHorizontalLine && <HorizontalLine status={status} />}
          </React.Fragment>
        );
      })
    );
  };
  return <Wrapper>{renderStep()}</Wrapper>;
};

Stepper.defaultProps = {
  currentStep: 1,
  totalSteps: 4,
};

Stepper.propTypes = {
  currentStep: PropTypes.number,
  totalSteps: PropTypes.number,
};

export default Stepper;
