import classNames from 'classnames';
import React, { FC } from 'react';
import Placeholder from '@assets/new-placeholder.jpg';

interface Props {
  order: number;
  name: string;
  description: string;
  active?: boolean;
}

const VideoItem: FC<Props> = ({ order, name, description, active }) => (
  <div
    className={classNames('rounded-md overflow-hidden h-72', active ? 'shadow-lg' : 'opacity-75')}
  >
    <img src={Placeholder} alt="" className="h-1/2 w-full object-cover" />

    <div className="relative bg-grey p-4 h-1/2 w-full">
      <div className="flex space-x-2">
        <div
          className={classNames(
            'text-white text-48 leading-none font-inter-semibold',
            !active && 'opacity-75'
          )}
        >
          {order}
        </div>
        <div className="space-y-1">
          <h3
            className={classNames(
              'text-white text-16 uppercase font-inter-semibold',
              !active && 'opacity-75'
            )}
          >
            {name}
          </h3>
          <p className="text-white opacity-75 text-12 font-inter-light line-clamp-3">
            {description}
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default VideoItem;
