import { fixSlashes } from '../../../../src/utils/utilities';

interface SelectPlanParams {
  discountInfoType: string;
  promotionCode?: string;
  lang?: string;
  isDefaultNavigatable?: boolean;
  isFromBAU: boolean;
}

const handleSelectPlanRedirection = ({
  discountInfoType,
  promotionCode,
  lang,
  isDefaultNavigatable,
  isFromBAU,
}: SelectPlanParams) => {
  let path = '';
  const currentLang = lang && lang !== 'en-nz' ? `/${lang}` : '';

  if ((!discountInfoType || isFromBAU) && !isDefaultNavigatable) {
    return;
  }

  // Non winback users will always need to sign up hence logic in CreateAccount.jsx will handle that part
  if (discountInfoType === 'affiliate') {
    path = `/affiliate`;
  }

  if (discountInfoType === 'promotionCode' && !isFromBAU) {
    path = `/${promotionCode}`;
  }

  return fixSlashes(`/signup/select-plan/${path}/${currentLang}`);
};

export default handleSelectPlanRedirection;
