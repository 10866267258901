import { gql } from '@apollo/client';

// Query favorite program from favorite content
const GET_FAVORITE_PROGRAM = gql`
  query GetFavoriteProgram($nextToken: String, $pageSize: Int, $contentType: String) {
    favoriteContent(nextToken: $nextToken, pageSize: $pageSize, contentType: $contentType) {
      items {
        content {
          alternateName
          categories
          imageWide
          name
          tiers
        }
        contentId
        contentType
      }
    }
  }
`;

// Mutation to add favorite program to favorite content
const ADD_FAVORITE_PROGRAM = gql`
  mutation AddFavoriteProgram($contentId: String!, $contentType: String!) {
    addFavorite(contentId: $contentId, contentType: $contentType)
  }
`;

// Mutation to remove favorite program from favorite content
const REMOVE_FAVORITE_PROGRAM = gql`
  mutation RemoveFavoriteProgram($contentId: String!, $contentType: String!) {
    removeFavorite(contentId: $contentId, contentType: $contentType)
  }
`;

export { GET_FAVORITE_PROGRAM, ADD_FAVORITE_PROGRAM, REMOVE_FAVORITE_PROGRAM };
