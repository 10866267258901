export const SUBSCRIPTION_TYPES = {
  RETAIL: 'RETAIL',
  AFFILIATE: 'AFFILIATE',
  RESELLER: 'RESELLER',
  IAP: 'IAP',
} as const;

export const SUBSCRIPTION_STATES = {
  ACTIVE: 'active',
  TRIALING: 'trialing',
  ON_DELAYED_CANCELLATION: 'on-delayed-cancellation',
  CANCELED: 'canceled',
  PROCESSING: 'processing',
  EXPIRED: 'expired',
  ON_HOLD: 'on_hold',
  PAST_DUE: 'past_due',
  SOFT_FAILUE: 'soft_failure',
  TRIAL_ENDED: 'trial_ended',
  ASSESSING: 'assessing',
  FAILED_TO_CREATE: 'failed_to_create',
  PAUSED: 'paused',
  PENDING: 'pending',
  SUSPENDED: 'suspended',
  UNPAID: 'unpaid',
  FAILED: 'failed',
} as const;

export const GENERIC_FAILED_STATE: string[] = [
  SUBSCRIPTION_STATES.PENDING,
  SUBSCRIPTION_STATES.PAUSED,
  SUBSCRIPTION_STATES.ASSESSING,
  SUBSCRIPTION_STATES.TRIAL_ENDED,
  SUBSCRIPTION_STATES.SOFT_FAILUE,
  SUBSCRIPTION_STATES.FAILED_TO_CREATE,
] as const;

export const ON_HOLD_STATES: string[] = [
  SUBSCRIPTION_STATES.ON_HOLD,
  SUBSCRIPTION_STATES.PAST_DUE,
  SUBSCRIPTION_STATES.EXPIRED,
  SUBSCRIPTION_STATES.UNPAID,
  SUBSCRIPTION_STATES.FAILED,
] as const;
