import { gql } from '@apollo/client';

const GET_COUNTRY_CODE = gql`
  query GetCountryCode {
    getHeaders {
      countryCode
    }
  }
`;

export default GET_COUNTRY_CODE;
