import React from 'react';
import { Link, BackgroundImage } from '@components/atoms';

const ShopHeroSlice = ({ content }) => (
  <div className="flex justify-center w-full">
    <div className="lg:pt-4 lg:px-4 w-full max-w-screen-3xl">
      <Link
        target="_blank"
        rel="noreferrer"
        className="focus:outline-none"
        to={content.primary.link.url}
      >
        <BackgroundImage image={content.primary.background_image}>
          <div className="h-96 lg:h-56 px-16 lg:px-32 p-4">
            <div className="flex h-full items-center pt-0 md:w-1/2 lg:w-1/3 w-full">
              <div className="h-full w-full">
                <img
                  className="h-full"
                  src={content.primary.overlay_image.url}
                  alt={content.primary.overlay_image.alt}
                />
              </div>
            </div>
          </div>
        </BackgroundImage>
      </Link>
    </div>
  </div>
);

export default ShopHeroSlice;
