// Disabled eslint for name of prismic field
/* eslint-disable @typescript-eslint/naming-convention */

import React, { FC, useEffect, useState, useContext } from 'react';
import { navigate } from 'gatsby';
import { CircularProgress } from '@mui/material';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { ReCaptchaInput, ReCaptchaRef } from '../../../types/Recaptcha';

import { createPublicClient, createClient } from '../../../services/client';
import ValidateTescoVoucher from '../../../graphql/validateTescoVoucher';
// eslint-disable-next-line import/no-relative-packages
import { authContext } from '../../../../../gatsby-theme-engagement/src/context/authContext';
import captureException from '../../../utils/sentry';
import getRecaptchaV3 from '../../../utils/tesco';
import { addErrorInDatadogRum, setLocalStorage } from '../../../utils/utilities';
import Alert from '../../common/alert';
import getUser from '../../../graphql/getUser';
import { ResponseError } from '../../../types/Error';

interface Props {
  voucher?: string;
  recaptchaRef?: ReCaptchaRef;
  setTescoVoucherType: (value: string) => void;
}

type Response = {
  data: {
    validateTescoVoucher: string;
  };
};

const ValidateVoucher: FC<Props> = ({ voucher, recaptchaRef, setTescoVoucherType }) => {
  const { isAuthenticated } = useContext(authContext);
  const [isProcessing, setIsProcessing] = useState(true);
  const [serverError, setServerError] = useState('');
  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    (async () => {
      const reCaptcha = await getRecaptchaV3(executeRecaptcha);
      if (reCaptcha) {
        handleValidateVoucher(reCaptcha, onValidateFailure);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [executeRecaptcha]);

  const onValidateFailure = (error: ResponseError) => {
    const errorMessage = error?.graphQLErrors && error?.graphQLErrors[0]?.message;
    if (errorMessage === 'reCaptcha validation failed') {
      // trigger recaptcha v2
      recaptchaRef?.current?.executeAsync()?.then((token: string) => {
        const reCaptcha: ReCaptchaInput = {
          version: 2,
          token,
        };
        handleValidateVoucher(reCaptcha, onValidateFailure);
      });
    } else {
      setServerError(errorMessage);
    }
  };

  const handleValidateVoucher = async (reCaptcha: ReCaptchaInput, handleError: Function) => {
    setIsProcessing(true);

    createPublicClient
      .query({
        query: ValidateTescoVoucher,
        variables: {
          voucherCode: voucher,
          reCaptcha,
        },
      })
      .then(async (res) => {
        await handleRedirect(res);
      })
      .catch((err) => {
        setIsProcessing(false);
        handleError(err, reCaptcha);
      });
  };

  const handleRedirect = async (res: Response) => {
    const tescoVoucherType = res?.data?.validateTescoVoucher;
    if (tescoVoucherType === 'INVALID') {
      navigate(`/tesco/invalid/`);

      return;
    }

    setTescoVoucherType(tescoVoucherType);

    if (isAuthenticated) {
      try {
        const response = await createClient.query({ query: getUser });
        const userData = response.data?.getUser;
        setLocalStorage('user-info', JSON.stringify(userData));
        const { email } = userData;

        const variables = {
          userInput: {
            email,
          },
          auth: {
            issuer: 'tesco',
            voucherCode: voucher,
          },
        };

        navigate('/tesco/processing/', {
          state: {
            variables,
          },
        });
        return;
      } catch (error) {
        setIsProcessing(false);
        setServerError(error?.graphQLErrors && error?.graphQLErrors[0]?.message);
        addErrorInDatadogRum(error);
        // Send error to sentry
        captureException({
          action: 'getUser',
          ...error,
        });
      }
    }
    setIsProcessing(false);
    navigate(`/tesco/signup/?voucher=${voucher}`);
  };

  if (serverError) {
    return <Alert content={serverError} />;
  }

  return isProcessing ? <CircularProgress color="inherit" /> : null;
};

export default ValidateVoucher;
