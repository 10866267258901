import React, { useContext, useRef, useState } from 'react';
import { Button, Password } from '@lesmills-international/components';
import { ButtonWrapper, Form } from './styles';
import { Title } from '../common/subscriptionManagementLayout/style';
import { ChangePasswordErrorMessage, ChangePasswordFormErrors, NotificationHandle } from './types';
import { checkHaveErrors } from '../../utils/validator';
import { authContext } from '../../../../gatsby-theme-engagement/src/context';
import { getChangePasswordErrorMessage, getChangePasswordValidatorErrors } from './utils';
import { FormErrorName } from '../../types/DataTracking';

interface Props {
  title?: string | null;
  oldPasswordLabel: string | null;
  newPasswordLabel: string | null;
  buttonText: string | null;
  errorMessage: ChangePasswordErrorMessage;
  successMessage: string | null;
  handleNotification: NotificationHandle;
  successCallback: () => void;
  handleOnFormStart: () => void;
  handleFormTrackingError: (formErrorName: FormErrorName) => void;
}

const ChangePassword = ({
  title,
  oldPasswordLabel,
  newPasswordLabel,
  buttonText,
  errorMessage,
  handleNotification,
  successMessage,
  successCallback,
  handleOnFormStart,
  handleFormTrackingError,
}: Props) => {
  const { handleChangePassword } = useContext(authContext);
  const [isLoading, setIsLoading] = useState(false);

  const oldPasswordRef = useRef<HTMLInputElement>(null);
  const newPaswordRef = useRef<HTMLInputElement>(null);

  const [formErrors, setFormErrors] = useState<ChangePasswordFormErrors | null>(null);

  const getOldPassword = () => oldPasswordRef?.current?.value || '';
  const getNewPassword = () => newPaswordRef?.current?.value || '';

  const submitOnSuccess = () => {
    successCallback && successCallback();
    handleNotification(successMessage, 'success');
    setIsLoading(false);
  };

  const submitOnError = (code: string) => {
    const message = getChangePasswordErrorMessage(code, errorMessage);

    handleFormTrackingError(FormErrorName.SERVER_ERROR);
    handleNotification(message, 'error');
    setIsLoading(false);
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setIsLoading(true);

    const validatorErrors = getChangePasswordValidatorErrors(
      {
        oldPassword: getOldPassword(),
        newPassword: getNewPassword(),
      },
      errorMessage
    );

    setFormErrors({ ...formErrors, ...validatorErrors });

    const passedValidation = !checkHaveErrors(validatorErrors);

    if (!passedValidation) {
      handleFormTrackingError(FormErrorName.INCOMPELTE_FIELDS);
      setIsLoading(false);
      return;
    }

    handleChangePassword &&
      handleChangePassword({
        newPassword: getNewPassword(),
        oldPassword: getOldPassword(),
        successCallback: submitOnSuccess,
        errorCallback: submitOnError,
      });
  };

  return (
    <Form id="change-password-form" component="form">
      <Title>{title}</Title>
      <Password
        label={oldPasswordLabel}
        autoComplete="old_password"
        name="old_password"
        id="old_password_field"
        inputRef={oldPasswordRef}
        error={formErrors?.oldPassword.errorMsg.length}
        helperText={formErrors?.oldPassword.errorMsg}
        onFocus={handleOnFormStart}
      />
      <Password
        label={newPasswordLabel}
        name="new_password"
        id="new_password_field"
        inputRef={newPaswordRef}
        error={formErrors?.newPassword.errorMsg.length}
        helperText={formErrors?.newPassword.errorMsg}
      />
      <ButtonWrapper>
        <Button
          id="change-password-submit-button"
          onClick={handleSubmit}
          ctaButton
          style={{ width: '208px' }}
          type="submit"
          disable={isLoading}
          loading={isLoading}
        >
          <>{buttonText}</>
        </Button>
      </ButtonWrapper>
    </Form>
  );
};

export default ChangePassword;
