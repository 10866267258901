import React from 'react';
import { Button as LesmillsButton } from '@lesmills-international/components';
import { PAYMENT_METHOD } from './constants';
import { PaymentType, PaymentOptionType, ChargifyPaymentType } from './types';

interface Props {
  paymentOptions: Partial<PaymentOptionType>[];
  currentPayment: PaymentType;
  chargifyInfoData?: { chargifyToken: string; paymentType: ChargifyPaymentType };
  isDisabled: boolean;
  isProcessing: boolean;
  handleSubmit: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const Button = ({
  paymentOptions,
  currentPayment,
  chargifyInfoData,
  isDisabled,
  isProcessing,
  handleSubmit,
}: Props) => {
  const findButtonLabel = (type: string) =>
    paymentOptions.find(({ payment_type }) => payment_type === type)?.button_label;

  let label = findButtonLabel(PAYMENT_METHOD.CREDITCARD);
  const displayPaypal = currentPayment === PAYMENT_METHOD.PAYPAL;

  if (displayPaypal) {
    label = findButtonLabel(PAYMENT_METHOD.PAYPAL);
  }

  const showActivatePaypalButton = displayPaypal && !chargifyInfoData?.chargifyToken;

  if (showActivatePaypalButton) {
    return <div style={{ marginTop: '16px' }} id="paypal-button" className="flex justify-center" />;
  }

  return (
    <LesmillsButton
      disabled={isDisabled}
      type="submit"
      style={{ width: '100%', marginTop: '16px' }}
      ctaButton
      loading={isProcessing}
      id="payment-submit-button"
      className="submit-button uppercase"
      onClick={handleSubmit}
    >
      {label}
    </LesmillsButton>
  );
};

export default Button;
