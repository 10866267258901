import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client';
import { Subscription } from '../../../../gatsby-theme-engagement/src/backend';
import cancelSubscription from '../../graphql/cancelSubscription';

const useCancelSubscription = (
  options?: MutationHookOptions<Subscription, unknown>
): MutationTuple<Subscription, unknown> =>
  useMutation<Subscription, unknown>(cancelSubscription, options);

export default useCancelSubscription;
