import React, { useContext, useState, useEffect, FC } from 'react';
import { graphql, PageProps } from 'gatsby';
import styled from 'styled-components';
import { COLORS, breakpoint } from '@themes';
import { BackButton } from '@lesmills-international/components';
import SliceZone from '@components/sliceZone';
import { withPrivateRoute, Layout, UpgradeTier } from '@components/molecules';
import { getWorkoutForVideoCard, getSlicesGrouped } from '@services';
import { userContext } from '@context';
import { TIERS } from '@constants';
import { PageContextProps } from '../../types/program';
import PageName from '../../types/pageName';
import { addErrorInDatadogRum } from '../../utils/utilities';
import { createEngagementClient } from '../../../../../src/services/client';
import PROGRAM_DETAIL from '../../engagementGql/programDetails';

const CAROUSEL_SLICE_NAME = 'workout_video_carousel_tab';

const ProgramWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.black};
  color: white;
  .MuiGrid-container {
    // this places the workout cards above the hero 'left' title section
    position: relative;
    z-index: 2;
  }
`;

const Wrapper = styled.div`
  background-color: ${COLORS.black};
`;

const UpgradeTierWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BackBtnWrapper = styled.div`
  position: absolute;
  top: 36px;
  z-index: 3;

  ${breakpoint('xs')`
  left: 16px;
  `}

  ${breakpoint('sm')`
    left: 32px;
  `}

  ${breakpoint('lg')`
  left: 80px;
  `}
`;

const ProgramPageTemplate: FC<PageProps<Queries.GetProgramPageDataQuery, PageContextProps>> = ({
  pageContext,
}) => {
  const userInfo = useContext(userContext);
  const [premiumWorkouts, setPremiumWorkouts] = useState([]);

  const {
    body,
    meta_description: metaDescription,
    meta_title: metaTitle,
    name_external: programName,
  } = pageContext;
  const handleProgramDetailQuery = () => {
    createEngagementClient
      .query({
        query: PROGRAM_DETAIL,
        variables: {
          programId: programName,
        },
      })
      .then((res) => {
        if (res) {
          const { workoutGroups } = res?.data?.program || {};
          const items = workoutGroups?.items.flatMap((obj) => obj?.content?.items);
          setPremiumWorkouts(items);
        }
      })
      .catch((err) => {
        addErrorInDatadogRum(err);
      });
  };

  useEffect(() => {
    handleProgramDetailQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // begin: if user is basic, look at the group of workouts coming from prismic, ensure at least one group is for the basic tier
  const { tier, isLoading } = userInfo;

  const bodyWithWorkouts = body?.map((d) => {
    const workoutsToMap =
      tier === TIERS.BASE
        ? d.items
            ?.map(({ workout_name }) =>
              premiumWorkouts?.find(
                (w) => w?.name === workout_name && w?.tiers?.includes(TIERS.BASE)
              )
            )
            .filter(Boolean)
        : premiumWorkouts;
    if (!body[1]) body[1] = { slice_type: CAROUSEL_SLICE_NAME, id: d.id + 1 };
    return d.slice_type === CAROUSEL_SLICE_NAME
      ? {
          ...d,
          items: workoutsToMap?.map((i, index) =>
            getWorkoutForVideoCard({
              isEngApi: true,
              item: i,
              userInfo,
              index,
              contentListTitle: i?.programName,
              contentListIndex: 1,
              contentLocation: `Program - ${i?.programName}`,
            })
          ),
        }
      : d;
  });
  const slicesGrouped = getSlicesGrouped(bodyWithWorkouts, [CAROUSEL_SLICE_NAME]);
  const hasBaseTierContent = body?.find(
    (i) => i.slice_type === CAROUSEL_SLICE_NAME && i.primary?.tier === TIERS.BASE
  );
  const showTeaseMessage = !isLoading && tier === TIERS.BASE && !hasBaseTierContent;

  return (
    <Layout metaData={{ title: metaTitle, description: metaDescription }}>
      <Wrapper>
        <BackBtnWrapper>
          <BackButton
            onClick={() => {
              window.history.back();
            }}
            theme="dark"
          />
        </BackBtnWrapper>
        <ProgramWrapper>
          {showTeaseMessage ? (
            <UpgradeTierWrapper>
              <UpgradeTier />
            </UpgradeTierWrapper>
          ) : (
            <SliceZone
              programName={programName}
              slices={slicesGrouped}
              pageName={PageName.PROGRAM}
            />
          )}
        </ProgramWrapper>
      </Wrapper>
    </Layout>
  );
};

export default withPrivateRoute(ProgramPageTemplate);

export const query = graphql`
  query GetProgramPageData($programs: [String]!, $names: [String]!) {
    lesMillsPlusBackend {
      workouts(programs: $programs, names: $names) {
        programName
        workoutName
        imageWide
        tiers
        vimeoWebPageUrl
        categories
        instructorTeam
        subCategory
        publishDate
        equipment
        assets {
          items {
            ... on lesMillsPlusBackend_Video {
              duration
            }
          }
        }
      }
    }
  }
`;
