/* eslint-disable @typescript-eslint/naming-convention */
import React, { useContext, useMemo, useState } from 'react';
import { navigate } from 'gatsby';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { CloseButton, Dialog } from './styles';
import { userContext } from '../../../../../gatsby-theme-engagement/src/context';
import { Tier } from '../../../../../../src/type/subscriptions';
import useCancelSubscription from '../../../hooks/mutation/useCancelSubscription';
import ModalContent from './ModalContent';
import useGetPromoCodeInfo from '../../../hooks/queries/useGetPromoCodeInfo';
import { getProductName, mapOfferByProductName } from '../utils';
import { PrismicCommonMemberPromoOfferData } from '../types';
import ROUTES from '../../../../../../src/constants/routes';
import { addErrorInDatadogRum, setLocalStorage } from '../../../utils/utilities';
import ALREADY_SEEN_MEMBER_PROMO_OFFER from '../constants';

type OfferType = 'first' | 'second';

interface Props {
  isOpened: boolean;
  prismicData: Queries.PrismicMemberPromoPopupData;
  handleClose: () => void;
  handleOpenCancellationSuccessModal: () => void;
  setCancelSubscriptionError: (isErr: boolean) => void;
}

const MemberPromoModal = ({
  prismicData,
  isOpened,
  handleClose,
  handleOpenCancellationSuccessModal,
  setCancelSubscriptionError,
}: Props) => {
  const { lmodSubscription, tier, refetchUser } = useContext(userContext);
  const [isLoading, setIsloading] = useState(false);
  const [offerType, setOfferType] = useState<OfferType>('first');
  const [validateSecondOffer, setValidateSecondOffer] = useState(false);

  const productName = useMemo(
    () => getProductName(tier as Tier, lmodSubscription),
    [tier, lmodSubscription]
  );

  const firstOffer = useMemo(
    () =>
      mapOfferByProductName(
        prismicData.first_offer as PrismicCommonMemberPromoOfferData[],
        productName
      ),
    [prismicData, productName]
  );

  const secondOffer = useMemo(
    () =>
      mapOfferByProductName(
        prismicData.second_offer as PrismicCommonMemberPromoOfferData[],
        productName
      ),
    [prismicData, productName]
  );

  const { loading: validatingSeccondOffer } = useGetPromoCodeInfo({
    skip: !validateSecondOffer,
    variables: {
      promoCode: secondOffer?.promo_code,
    },
    onCompleted: () => {
      setOfferType('second');
    },
    onError: (err) => {
      handleCancelSubscription();
      addErrorInDatadogRum(err);
    },
  });

  const handleCancelSubscription = () => {
    setIsloading(true);
    cancelSubscriptionMutation();
  };

  const [cancelSubscriptionMutation] = useCancelSubscription({
    onCompleted: async () => {
      refetchUser && (await refetchUser());
      setIsloading(false);
      handleClose();
      handleOpenCancellationSuccessModal();
    },
    onError: (err) => {
      setIsloading(false);
      handleClose();
      setCancelSubscriptionError(true);
      addErrorInDatadogRum(err);
    },
  });

  const handleNavigate = (promoCode: string) => {
    navigate(`${ROUTES().OFFER_REDEMPTION}?isFrom=cancellation&promoCode=${promoCode}`);
  };

  const handleRedeemOffer = () => {
    // Close the modal
    handleClose();

    // Mark offer as seen in local storage
    setLocalStorage(ALREADY_SEEN_MEMBER_PROMO_OFFER, 'true');

    const offers = {
      first: firstOffer,
      second: secondOffer,
    };

    const currentOffer = offers[offerType];

    if (currentOffer?.promo_code) {
      handleNavigate(currentOffer.promo_code);
    }
  };

  const handleRejectFirstOffer = () => {
    if (firstOffer?.show_second_offer) {
      setValidateSecondOffer(true);
    } else {
      handleCancelSubscription();
    }
  };

  const renderFirstOffer = firstOffer && offerType === 'first';

  const renderSecondOffer = validateSecondOffer && secondOffer && offerType === 'second';

  const onModalClose = () => {
    // Should not close the modal when it's either cancelling or validinting offer
    if (isLoading || validatingSeccondOffer) {
      return;
    }
    handleClose();
  };

  return (
    <Dialog
      onClose={onModalClose}
      aria-labelledby="Member Promo Modal"
      open={isOpened}
      maxWidth="lg"
    >
      <CloseButton
        disabled={isLoading || validatingSeccondOffer}
        aria-label="close"
        onClick={onModalClose}
      >
        <CloseIcon />
      </CloseButton>
      {renderFirstOffer && (
        <ModalContent
          offer={firstOffer}
          handleRedemOffer={handleRedeemOffer}
          handleRejectOffer={handleRejectFirstOffer}
          loading={validatingSeccondOffer || isLoading}
        />
      )}
      {renderSecondOffer && (
        <ModalContent
          offer={secondOffer}
          handleRedemOffer={handleRedeemOffer}
          handleRejectOffer={handleCancelSubscription}
          loading={isLoading}
        />
      )}
    </Dialog>
  );
};

export default MemberPromoModal;
