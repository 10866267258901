import { gql } from '@apollo/client';

const getValidProducts = gql`
  query getValidProducts {
    getHeaders {
      countryCode
    }
    getValidProducts {
      products {
        country_code
        product_price_point {
          product_handle
          price_point_id
          price_in_cents
          interval
          interval_unit
          trial_price_in_cents
          trial_interval
          trial_interval_unit
          initial_charge_in_cents
          initial_charge_after_trial
        }
        product_handle
        currency
        customer_type
        name
        tier {
          external_entity_id
          internal_tier_id
          tiered_entity_enabled
          tiered_entity_type
          tier_id
        }
      }
    }
  }
`;

export default getValidProducts;
