import isBrowser from './isBrowser';

const { GATSBY_MAIN_LES_MILLS_URL: RECEIVER_URL } = process.env;

type Message = {
  action: 'removeItem' | 'clear' | 'setItem';
  key?: string;
  value?: any;
};

const getKeyWithoutRandomString = (key: string) => {
  const keySplit = key.split('.');
  return `LMPlusAuthProvider.${keySplit[keySplit.length - 1]}`;
};

const isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement =>
  input !== null && input.tagName === 'IFRAME';

const postMessageToReceiver = (message: Message) => {
  const sessionReceiver = isBrowser() && document.getElementById('session_receiver');

  if (!sessionReceiver) {
    // eslint-disable-next-line no-console
    console.warn('unable to find sessionReceiver');
    return;
  }

  if (isIFrame(sessionReceiver) && sessionReceiver.contentWindow) {
    sessionReceiver?.contentWindow?.postMessage(message, `${RECEIVER_URL}`);
  }
};

export const sessionReceiverURL = RECEIVER_URL ? `${RECEIVER_URL}/syncSession` : undefined;

export const authStorage = {
  setItem: (key: string, value: string) => {
    const formattedKey = getKeyWithoutRandomString(key);

    isBrowser() && window.localStorage.setItem(formattedKey, value);

    postMessageToReceiver({ action: 'setItem', key: formattedKey, value });

    return value;
  },
  getItem: (key: string) => {
    const formattedKey = getKeyWithoutRandomString(key);

    return isBrowser() && window.localStorage.getItem(formattedKey);
  },
  removeItem: (key: string) => {
    const formattedKey = getKeyWithoutRandomString(key);

    isBrowser() && window.localStorage.removeItem(formattedKey);

    postMessageToReceiver({ action: 'removeItem', key: formattedKey });
  },
  clear: () => {
    isBrowser() && window.localStorage.clear();

    postMessageToReceiver({ action: 'clear' });
  },
};
