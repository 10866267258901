/* eslint-disable @typescript-eslint/naming-convention */
import React, { FC, useState, useEffect, useContext, useRef } from 'react';
import { AlbumCard } from '@lesmills-international/components';

import { userContext } from '@context';
import {
  isVimeoUrl,
  getHomeAlbumCardSlideAmount,
  getExploreAlbumCardSlideAmount,
  getSlideSpace,
  formatHeroEquipments,
  formatTimeDuration,
} from '@services';
import { contentClickTracking } from '@src/utils';
import { useWindowSize } from '@hooks';
import Carousel from '@components/atoms/carousel/Carousel';
import PageName from '../../../types/pageName';

import {
  AlbumsCarouselSliceWrapper,
  TitleWrapper,
  Title,
  ViewAllLink,
  CarouselWrapper,
} from './style';

interface Props {
  content: Queries.PrismicNewWebHomepageDataBodyAlbumsCarousel;
  analytics: {
    contentListTitle: string;
    contentListIndex: number;
    contentLocation: string;
  };
  pageName: PageName;
}

interface AlbumCardProps {
  background_image: { url: string };
  title: string;
  description: string;
  detail_link: string;
  label: string;
  duration_min: number;
  duration_sec: number;
  topic: string;
}

enum AspectRatio {
  landscape_16_9 = 'landscape',
  portrait_3_4 = 'portrait',
  square_1_1 = 'square',
}

const { DOMAIN, DOMAIN_PROD } = process.env;
const isDomainEnvVarDefined = DOMAIN && DOMAIN_PROD;

const AlbumsCarouselSlice: FC<Props> = ({ content, analytics, pageName }) => {
  const [carouselSlidesPerView, setCarouselSlidesPerView] = useState(3);
  const [carouselSlidesSpace, setCarouselSlidesSpace] = useState(8);
  const [albumCards, setAlbumCards] = useState<JSX.Element[]>([]);

  const { isNewUser, userEntitlements } = useContext(userContext);
  const vimeoTicket = userEntitlements?.LMOD?.vimeoUserTicket;
  const carouselRef = useRef<HTMLDivElement>(null);
  const windowInnerWidth = useWindowSize();
  const isFromHomePage = pageName === PageName.HOME;

  useEffect(() => {
    getAlbumsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowInnerWidth]);

  const getAlbumsData = () => {
    if (carouselRef && carouselRef.current) {
      const carouselWrapperWidth = carouselRef.current.clientWidth;
      const slidesPerView = isFromHomePage
        ? getHomeAlbumCardSlideAmount(carouselWrapperWidth)
        : getExploreAlbumCardSlideAmount(carouselWrapperWidth);

      const albumCardArray: JSX.Element[] = [];
      content?.items.forEach((album: AlbumCardProps, index: number) => {
        const {
          label,
          title,
          topic,
          description,
          background_image: { url: backgroundImg },
          duration_min,
          duration_sec,
          detail_link,
        } = album;
        const path = isVimeoUrl(detail_link)
          ? `${detail_link}?ticket=${vimeoTicket}`
          : isDomainEnvVarDefined
          ? detail_link.replace(DOMAIN_PROD, DOMAIN) // convert the link to dev/test/uat environments
          : detail_link;
        const albumCardProps = {
          label,
          title,
          description,
          backgroundImg,
          duration: formatTimeDuration(duration_min, duration_sec),
          equipmentIcons: formatHeroEquipments(album) || [],
          smallCard: content?.primary?.small_card,
          aspectRatio: AspectRatio[content?.primary?.aspect_ratio as keyof typeof AspectRatio],
          link: path,
          onClick: () => {
            contentClickTracking({
              ...analytics,
              contentIndex: index + 1,
              contentTitle: title,
              contentType: label,
              contentTopic: topic,
            });
          },
        };
        albumCardArray.push(<AlbumCard {...albumCardProps} />);
      });
      if (albumCardArray.length < slidesPerView) {
        // eslint-disable-next-line no-unsafe-optional-chaining
        for (let i = 0; i < slidesPerView - content?.items.length; i++) {
          albumCardArray.push(<div />);
        }
      }
      setCarouselSlidesPerView(slidesPerView);
      setCarouselSlidesSpace(getSlideSpace(carouselWrapperWidth));
      setAlbumCards(albumCardArray);
    }
  };

  if (content.slice_label === 'new_user_only' && !isNewUser) {
    return null;
  }

  return (
    <AlbumsCarouselSliceWrapper>
      <TitleWrapper>
        <Title>{content?.primary?.title}</Title>
        {content?.primary?.view_all_text && (
          <ViewAllLink href={`${content?.primary?.view_all_link}?ticket=${vimeoTicket}`}>
            {content?.primary?.view_all_text}
          </ViewAllLink>
        )}
      </TitleWrapper>
      <CarouselWrapper ref={carouselRef} noRightPadding={isFromHomePage}>
        <Carousel
          items={albumCards}
          slidesPerView={carouselSlidesPerView}
          slidesPerGroup={carouselSlidesPerView}
          spaceBetween={carouselSlidesSpace}
          className={`${isFromHomePage ? 'home--carousel--wrapper' : ''}`}
          alwaysShowArrowButton={isFromHomePage}
        />
      </CarouselWrapper>
    </AlbumsCarouselSliceWrapper>
  );
};

export default AlbumsCarouselSlice;
