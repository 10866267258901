import type { ProgramType } from '@types/program';

const formatPrograms = (
  listPrograms: ProgramType[],
  categoryId = 'All',
  tier?: 'premium' | 'base' | null
) => {
  // don't display programs that are under Hide or Other categories
  const programsWithoutOtherHide = listPrograms?.filter(
    (program) => !program?.categories?.includes('Hide') && !program?.categories?.includes('Other')
  );
  // filter the specific tiering programs
  const tieredPrograms = tier
    ? programsWithoutOtherHide.filter((program) => program?.tiers?.includes(tier))
    : programsWithoutOtherHide;
  // filter the specific category programs
  const programsFilterByCategory =
    categoryId === 'All'
      ? tieredPrograms
      : tieredPrograms?.filter((program) => program?.categories?.includes(categoryId));
  return programsFilterByCategory;
};

export default formatPrograms;
