import React, { useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import { ReactComponent as Logo } from '@assets/lmi-plus-logo.svg';
import { navigate } from 'gatsby';
import { SurveyWrapper, EndHeading, CenteredSurveyContainer, LoadingIcon } from './styles';
import SpinnerPlus from '../../components/atoms/spinnerPlus';
import SpinnerDots from '../../components/atoms/spinnerDots';

interface EndSurveyProps extends RouteComponentProps {
  /**
   * Survey title to display under logo
   * @example
   * Creating your personlized fitness experience...
   */
  surveyTitle?: string;
  /**
   * Page path used to navigate after 1.2 seconds.
   * Must be a link to a gatsby route.
   * @example
   * /explore/
   */
  nextLink: string;
}

/**
 * This component is used for the last step of our onboarding survey
 */
const EndSurvey = ({ surveyTitle, nextLink }: EndSurveyProps) => {
  // Only display this loading page for 1.2 seconds, then navigate to the next page
  useEffect(() => {
    const DISPLAY_DELAY = 1200;
    setTimeout(() => {
      navigate(nextLink);
    }, DISPLAY_DELAY);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SurveyWrapper>
      <CenteredSurveyContainer>
        <LoadingIcon>
          <SpinnerPlus />
        </LoadingIcon>
        <div>
          {/* Fix logo in component library */}
          <Logo fill="#FFF" width={219} height={27} />
        </div>
        <EndHeading>
          {surveyTitle}
          <SpinnerDots />
        </EndHeading>
      </CenteredSurveyContainer>
    </SurveyWrapper>
  );
};

export default EndSurvey;
