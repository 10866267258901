/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-template-curly-in-string */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable arrow-body-style */
/* eslint arrow-body-style: ["error", "always"] */
/* eslint-disable no-unsafe-optional-chaining */

// 👇️ ts-nocheck ignores all ts errors in the file
// This is a JSX file that has been converted to TSX
// as we want to have type checking on new TS-based methods,
// without having to rewrite the whole page into TS first.
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect, useState } from 'react';

import LeavePageDialog from '../../common/leavePageDialog/LeavePageDialog';

import { normalizePlans } from './normalizePlansPromotions';
import { setLocalStorage, getLocalStorage, addErrorInDatadogRum } from '../../../utils/utilities';
import PlanCard from './PlanCardPromotions';
import { createPublicClient } from '../../../services/client';

import getAffiliateOffers from '../../../graphql/getAffiliateOffers';

import { ReactComponent as BackIcon } from '../../../assets/icons/back.svg';

import {
  ChangePlan,
  Description,
  PlansContainer,
  PlansScrollContainer,
  SubTitle,
  Title,
  Wrapper,
  BackBtn,
  AffiliateLegalDescription,
} from './selectPlanCommonStyle';
import useGetCountryCode from '../../../hooks/useGetCountryCode';

const getSubtitleText = (selectedPlan, subtitle, notrialdescription) =>
  selectedPlan?.trial_interval
    ? subtitle.replace('{days}', `${selectedPlan.trial_interval}`)
    : notrialdescription;

const SelectPlan = ({
  pageData,
  pageContext,
  affiliateInfo,
  currentAffiliateInfo,
  leavePageDialogContent,
  isWinback,
  selectedPlan,
  onSelectPlan,
}) => {
  const planId = getLocalStorage('planId');
  const [subtitleText, setSubtitleText] = useState('');
  const [displayChangePlanBtn, setDisplayChangePlanBtn] = useState(false);
  const [hidden, setHidden] = useState(false);
  const [openLeavePageDialog, setOpenLeavePageDialog] = useState(false);
  const [plans, setPlans] = useState([]);
  const {
    pricing_title,
    winback_pricing_title,
    pricing_subtitle,
    pricing_disclaimer,
    change_plan,
    no_trial_text,
  } = pageData;

  setLocalStorage('winback', isWinback);

  const titleText = isWinback ? winback_pricing_title : pricing_title;

  useEffect(() => {
    const GetAffiliateProductsQuery = async () => {
      try {
        const prices = await createPublicClient.query({
          query: getAffiliateOffers,
          variables: {
            affiliateId: affiliateInfo?.affiliateId,
            offerId: affiliateInfo?.offerId,
            voucherCode: affiliateInfo?.voucherCode,
          },
        });
        const normalizedPlans = normalizePlans(prices, 'affiliate', isWinback);
        setPlans(normalizedPlans);
        const preselectedPlan = normalizedPlans.find((plan) => plan.id === planId);
        if (preselectedPlan) {
          setDisplayChangePlanBtn(true);
          setHidden(true);
          onSelectPlan(preselectedPlan);
        }

        const newSubtitleText = getSubtitleText(preselectedPlan, pricing_disclaimer, no_trial_text);
        setSubtitleText(newSubtitleText);
      } catch (error) {
        addErrorInDatadogRum(error);
      }
    };
    GetAffiliateProductsQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageContext, no_trial_text, pricing_disclaimer, getAffiliateOffers, isWinback]);

  const handleSelectPlan = (newSelectedPlanId) => {
    const newSelectedPlan = plans.find((plan) => plan.id === newSelectedPlanId);
    onSelectPlan(newSelectedPlan);
    setHidden(true);
    setDisplayChangePlanBtn(true);
    const newSubtitleText = getSubtitleText(newSelectedPlan, pricing_disclaimer, no_trial_text);
    setSubtitleText(newSubtitleText);
  };
  const handleChangePlan = () => {
    setHidden(false);
    setDisplayChangePlanBtn(false);
  };

  const handleBack = () => {
    setOpenLeavePageDialog(true);
  };

  const { countryCode } = useGetCountryCode();
  const hasHighlightedPlan = plans ? plans[0]?.sortOrder === 0 : null;

  return (
    <Wrapper id="plansWrapper">
      <BackBtn onClick={handleBack} id="back-button">
        <BackIcon />
      </BackBtn>
      <Title>{currentAffiliateInfo?.affiliate_pricing_title || titleText}</Title>
      <SubTitle>{subtitleText}</SubTitle>
      <Description>
        {currentAffiliateInfo?.affiliate_pricing_subtitle || pricing_subtitle}
      </Description>
      {currentAffiliateInfo?.affiliate_signup_description && (
        <AffiliateLegalDescription>
          {currentAffiliateInfo?.affiliate_signup_description}
        </AffiliateLegalDescription>
      )}
      <PlansScrollContainer id="PlansScrollContainer">
        <PlansContainer id="PlansContainer">
          {plans.length > 0 &&
            plans.map((plan) => (
              <PlanCard
                plan={plan}
                key={plan.name}
                handleSelectPlan={handleSelectPlan}
                selectedPlan={selectedPlan}
                isHidden={hidden}
                pricingData={pageData}
                countryCode={countryCode}
                voucher={null}
                hasHighlightedPlan={hasHighlightedPlan}
                noTrialDisplay={isWinback}
                currentAffiliateInfo={currentAffiliateInfo}
                forceStrikeThroughDescription
              />
            ))}
          {displayChangePlanBtn && plans?.length > 1 && (
            <ChangePlan onClick={handleChangePlan}>{change_plan}</ChangePlan>
          )}
        </PlansContainer>
      </PlansScrollContainer>

      <LeavePageDialog
        title={leavePageDialogContent?.title}
        description={leavePageDialogContent?.description}
        stayBtnText={leavePageDialogContent?.stay_btn_text}
        leavePageBtnText={leavePageDialogContent?.leave_btn_text}
        open={openLeavePageDialog}
        handleClose={() => {
          setOpenLeavePageDialog(false);
        }}
        leaveToUrl={process.env.GATSBY_LANDING_PAGES_HOME}
      />
    </Wrapper>
  );
};

export default SelectPlan;
