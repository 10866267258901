// Disabled eslint for name of prismic field
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useState, FC } from 'react';

import Box from '@mui/material/Box';
import { navigate } from 'gatsby';

import TextFieldNew from '../../common/textFieldNew';
import Button from '../../common/button';
import Alert from '../../common/alert/Alert';

import { Note, BlackSwitch, Title, LoginLink, ToggleLabel, ButtonWrapper } from '../style';
import { formValidator } from '../../../utils/validator';
import { createPublicClient } from '../../../services/client';
import doesUserExist from '../../../graphql/doesUserExist';
import captureException from '../../../utils/sentry';
import { MouseEventTarget, ChangeEventTarget } from '../../../types/Event';
import { UserInfo } from '../../../types/UserInfo';
import { addErrorInDatadogRum } from '../../../utils/utilities';

interface Props {
  subscribe_to_lm?: string;
  signup_note?: string;
  voucher_code_label?: string;
  signup_title?: string;
  first_name_label?: string;
  last_name_label?: string;
  email_label?: string;
  next_btn?: string;
  login_label?: string;
  subscriptionTypes?: {
    three_month_label?: string;
    twelve_month_label?: string;
  };
  voucher: string;
  tescoVoucherType: string;
  prismicErrorData: {
    first_name_required?: string;
    first_name_invalid?: string;
    last_name_required?: string;
    last_name_invalid?: string;
    email_required?: string;
    email_invalid?: string;
    existing_email_message?: string;
  };
  handleValidateRecaptcha: () => Promise<any>;
  setUserInfo: (value: UserInfo) => void;
}

interface ValidationError {
  first_name: string;
  last_name: string;
  email: string;
}

const SignUp: FC<Props> = ({
  subscribe_to_lm,
  signup_note,
  voucher_code_label,
  signup_title,
  first_name_label,
  last_name_label,
  email_label,
  next_btn,
  login_label,
  subscriptionTypes,
  voucher,
  tescoVoucherType,
  prismicErrorData,
  handleValidateRecaptcha,
  setUserInfo,
}) => {
  const [values, setValues] = useState<
    Required<Pick<UserInfo, 'email' | 'firstName' | 'lastName' | 'receiveEmail'>>
  >({
    firstName: '',
    lastName: '',
    email: '',
    receiveEmail: false,
  });
  const [formErrors, setFormErrors] = useState<ValidationError>({
    first_name: '',
    last_name: '',
    email: '',
  });
  const [emailExisted, setEmailExisted] = useState(false);
  const [isSubmitProcessing, setIsSubmitProcessing] = useState(false);
  const [serverError, setServerError] = useState('');

  const handleUserExistedCheck = async (email: string) => {
    setIsSubmitProcessing(true);

    const { captchaSignature, validationData } = await handleValidateRecaptcha();
    const parameters = captchaSignature
      ? {
          email,
          validationData,
          captchaSignature,
        }
      : {
          email,
          validationData,
        };

    await createPublicClient
      .query({
        query: doesUserExist,
        variables: parameters,
      })
      .then((res) => {
        if (res.data?.doesUserExist) {
          setEmailExisted(true);
          setFormErrors({
            ...formErrors,
            email: prismicErrorData?.existing_email_message,
          } as ValidationError);
        } else {
          setUserInfo(values);
          navigate(`/tesco/confirm/?voucher=${voucher}`);
        }
      })
      .catch((error) => {
        setIsSubmitProcessing(false);
        setServerError(error.message);
        addErrorInDatadogRum(error);
        // Send error to sentry
        captureException({
          action: 'existingUserCheck',
          requestVariables: {
            email,
            validationData,
          },
          email: email.trim().toLowerCase(),
          ...error,
        });
      })
      .finally(() => {
        setIsSubmitProcessing(false);
      });
  };

  const handleChange = (field: string, event: ChangeEventTarget) => {
    setValues({
      ...values,
      [field]: field === 'receiveEmail' ? event?.target?.checked : event?.target?.value,
    });
    if (field === 'email' && emailExisted) {
      setEmailExisted(false);
      setFormErrors({
        ...formErrors,
        email: '',
      } as ValidationError);
    }
  };

  const handleValidate = () => {
    const firstNameError = validateSingleField('first_name', values.firstName);
    const lastNameError = validateSingleField('last_name', values.lastName);
    const emailError = validateSingleField('email', values.email);

    const errors = {
      first_name: firstNameError,
      last_name: lastNameError,
      email: emailError,
    };

    setFormErrors(errors);
    return errors;
  };

  const validateSingleField = (name: string, value: string) =>
    formValidator(['REQUIRED', name.toUpperCase()], value.trim(), {
      requiredError: (prismicErrorData as any)[`${name}_required`],
      invalidError: (prismicErrorData as any)[`${name}_invalid`],
    }).errorMsg;

  const handleSingleValidate = (field: string, event: MouseEventTarget) => {
    const error = validateSingleField(field, event.target.value);
    setFormErrors({ ...formErrors, [field]: error });
  };

  const handleSubmit = async () => {
    const errors = handleValidate();

    // If have at least 1 error, no call API
    if (Object.keys(errors).some((key) => (errors as any)[key])) {
      return;
    }

    await handleUserExistedCheck(values?.email?.trim()?.toLowerCase());
  };

  return (
    <div>
      <Title>
        {signup_title?.replace(
          '{subscription_type}',
          (subscriptionTypes as any)[`${tescoVoucherType?.toLowerCase()}_label`]
        )}
      </Title>
      <Note>
        {voucher_code_label}: {voucher}
      </Note>
      <Note>{signup_note}</Note>
      <Box
        id="form-wrapper"
        component="form"
        sx={{
          '& .MuiTextField-root': { width: '320px' },
          maxWidth: '320px',
          textAlign: 'center',
        }}
        noValidate
      >
        {serverError && <Alert content={serverError} />}
        <TextFieldNew
          label={first_name_label}
          defaultValue=""
          variant="filled"
          name="first_name"
          id="first-name-field"
          value={values.firstName}
          error={!!formErrors.first_name}
          helperText={formErrors.first_name}
          onBlur={(event: MouseEventTarget) => {
            handleSingleValidate('first_name', event);
          }}
          onChange={(event: ChangeEventTarget) => {
            handleChange('firstName', event);
          }}
        />
        <TextFieldNew
          label={last_name_label}
          defaultValue=""
          variant="filled"
          name="last_name"
          id="last-name-field"
          value={values.lastName}
          error={!!formErrors.last_name}
          helperText={formErrors.last_name}
          onBlur={(event: MouseEventTarget) => {
            handleSingleValidate('last_name', event);
          }}
          onChange={(event: ChangeEventTarget) => {
            handleChange('lastName', event);
          }}
        />
        <TextFieldNew
          className="email-text-field"
          label={email_label}
          variant="filled"
          name="email"
          id="email-field"
          value={values.email}
          error={!!formErrors.email}
          helperText={formErrors.email}
          onBlur={(event: MouseEventTarget) => {
            handleSingleValidate('email', event);
          }}
          onChange={(event: ChangeEventTarget) => {
            handleChange('email', event);
          }}
        />
        <div className="flex items-center">
          <BlackSwitch
            value={values.receiveEmail}
            onChange={(event: ChangeEventTarget) => {
              handleChange('receiveEmail', event);
            }}
            inputProps={{ 'aria-label': 'controlled' }}
            color="default"
          />
          <ToggleLabel>{subscribe_to_lm}</ToggleLabel>
        </div>
        <ButtonWrapper>
          <Button onClick={handleSubmit} loading={isSubmitProcessing}>
            {next_btn}
          </Button>
        </ButtonWrapper>
        <LoginLink
          to={`/login/?redirect_to=/tesco/?voucher=${voucher}`}
          state={{ tescoVoucherType }}
        >
          {login_label}
        </LoginLink>
      </Box>
    </div>
  );
};
export default SignUp;
