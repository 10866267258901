import { GraphQLErrors } from '@apollo/client/errors';

import { addErrorInDatadogRum } from '../../../utils/utilities';
import { GENERIC_ERROR, PromoErrorType } from '../constants';
import useGetPromoCodeInfo, { PromoInformation } from '../../../hooks/queries/useGetPromoCodeInfo';

interface GetPromoCodeInformationError extends GraphQLErrors {
  errorType?: PromoErrorType;
}

const useGetPromoInfo = (promoCode: string) => {
  const { error, loading, data } = useGetPromoCodeInfo({ variables: { promoCode } });

  const promoInfo: PromoInformation | undefined = data?.getPromoCodeInformation;
  const getPromoInfoServerError = error
    ?.graphQLErrors[0] as unknown as GetPromoCodeInformationError;
  let promoError: PromoErrorType | undefined;

  if (error) {
    addErrorInDatadogRum(error);
    promoError = getPromoInfoServerError?.errorType
      ? getPromoInfoServerError?.errorType
      : GENERIC_ERROR;
  }

  return {
    promoInformation: { ...promoInfo },
    loading,
    promoError,
  };
};

export default useGetPromoInfo;
