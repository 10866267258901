import { isBrowser } from '@services';
import { MutableRefObject, useEffect, useRef, useState } from 'react';

function useOnScreen<Ref extends HTMLElement>(
  defaultValue: boolean,
  rootMargin = '0px'
): [MutableRefObject<Ref | null>, boolean] {
  const ref = useRef<Ref>(null);
  const [isIntersecting, setIntersecting] = useState(defaultValue);

  useEffect(() => {
    if (!isBrowser()) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        setIntersecting(entry.isIntersecting);
      },
      {
        rootMargin,
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    // eslint-disable-next-line consistent-return
    return () => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions, react-hooks/exhaustive-deps
      ref.current && observer.unobserve(ref.current);
    };
  }, [rootMargin]);

  return [ref, isIntersecting];
}

export default useOnScreen;
