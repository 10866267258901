import React, { FC } from 'react';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { graphql, PageProps } from 'gatsby';

import { useTrackUserAttribute } from '@hooks';
import { withPrivateRoute, Metadata } from '@components/molecules';
import { Router } from '@reach/router';

import StartSurvey from './StartSurvey';
import EndSurvey from './EndSurvey';
import Question from './Question';
import { PageWrapper } from './styles';

interface PageContextProps {
  lang: string;
}

const PersonalisedOnboardingTemplate: FC<
  PageProps<Queries.GetPersonalisedOnboardingDataQuery, PageContextProps>
> = ({ data }) => {
  useTrackUserAttribute();
  const page = data.prismicOnboardingSurvey?.data;
  const { questions } = data.lesMillsPlusBackend?.survey || {};

  const questionComponents = questions?.map((question, index) => {
    // If this is the final question of the survey send you to the home page
    const nextLink = questions.length - 1 === index ? '/survey/end' : `/survey/${index + 1}/`;

    // If this is the first question of the survey, then the back link will be null
    const previousLink = index === 0 ? null : `/survey/${index - 1}/`;

    return (
      <Question
        key={index}
        path={`/${index}`}
        question={question}
        nextLink={nextLink}
        previousLink={previousLink}
        totalQuestions={questions.length}
        index={index}
      />
    );
  });

  if (page) {
    // We need a better approach when validating that prismic fields exist (Has the content editor actually uploaded an image)
    // Because all fields in prismic are optionl we have to do this validation ourselves
    // We don't want to have each component be desgned around prismic schema, since the components could be used for non prismic content, so this should happen
    // at the page template level
    if (page.background_image?.gatsbyImageData) {
      return (
        <>
          <Metadata title={page.meta_title} description={page.meta_description} />

          <PageWrapper height={`${window.innerHeight}px`}>
            <Router basepath="/survey">
              <StartSurvey
                image={page.background_image.gatsbyImageData}
                alt={page.background_image?.alt}
                surveyTitle={page.start_survey}
                path="/start"
              />
              {questionComponents}
              <EndSurvey surveyTitle={page.end_survey} path="/end" nextLink="/" />
            </Router>
          </PageWrapper>
        </>
      );
    }
    return <div>No background image provided</div>;
  }
  return <div>prismicOnboardingSurvey page document does not exist</div>;
};

export default withPrismicPreview(withPrivateRoute(PersonalisedOnboardingTemplate));

export const query = graphql`
  query GetPersonalisedOnboardingData($lang: String!) {
    lesMillsPlusBackend {
      survey(id: "onboarding") {
        id
        surveyVersion
        questions {
          questionId
          displayText
          questionIntent
          questionType
          answers {
            icon
            displayText
            attribute {
              key
              value
            }
          }
        }
      }
    }
    prismicOnboardingSurvey(lang: { eq: $lang }) {
      data {
        meta_title
        meta_description
        start_survey
        end_survey
        background_image {
          alt
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  }
`;
