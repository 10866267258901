import { getAutomationRecaptcha } from './utilities';
import { ReCaptchaInput } from '../types/Recaptcha';

const getRecaptchaV3 = async (executeRecaptcha?: (action?: string) => Promise<string>) => {
  let reCaptcha: ReCaptchaInput;
  const recaptchaAutomation = JSON.parse(getAutomationRecaptcha() as string);

  if (recaptchaAutomation) {
    reCaptcha = {
      nonce: recaptchaAutomation?.nonce,
      auth: recaptchaAutomation?.auth,
      version: 2,
      token: '',
    };
  } else if (executeRecaptcha) {
    // get recaptcha token v3
    const token = await executeRecaptcha('ValidateTescoVoucher');

    reCaptcha = {
      version: 3,
      token,
    };
  }
  return reCaptcha;
};

export default getRecaptchaV3;
