import { gql } from '@apollo/client';

const UpdateUser = gql`
  mutation updateAxiiUser($input: AxiiUserInput!) {
    updateAxiiUser(input: $input) {
      givenName
      familyName
      email
      addressStreet
      addressStreet2
      addressCountry
      addressPostalCode
      addressRegion
      addressLocality
    }
  }
`;

export default UpdateUser;
