enum SignUpStage {
  CreateAccount = 'Create Account',
  ConfirmEmail = 'Submit Email',
  SelectPlan = 'Checkout Started',
  PaymentComplete = 'Order Completed',
}

const CONSTANTS = {
  SEGMENT_WRITE_KEY: process.env.GATSBY_SEGMENT_WRITE_KEY,
};

export { CONSTANTS, SignUpStage };
