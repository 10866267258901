import React from 'react';
import styled from 'styled-components';
import { Link } from '@components/atoms';
import { COLORS } from '@themes';

interface LegalCountryNavigationProps {
  navs:
    | Array<{
        text: string;
        url: string;
      }>
    | undefined;
  withMargin?: boolean;
}

const NavigationContainer = styled.nav``;

const NavigationList = styled.ul`
  list-style-type: none;
  padding-left: 0;
  margin: 0;
`;

const NavigationItem = styled.li<{ withMargin?: boolean }>`
  font-family: 'Inter Regular';
  font-size: 16px;
  margin-left: ${(props) => (props.withMargin ? '2rem' : '0')};
`;

const StyledLink = styled((props: any) => <Link {...props} />)`
  color: ${COLORS.green};
  text-decoration: none;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
`;

const LegalCountryNavigation: React.FC<LegalCountryNavigationProps> = ({ navs, withMargin }) => (
  <NavigationContainer>
    <NavigationList>
      {navs?.map((navItem, index) => (
        <NavigationItem key={index} withMargin={withMargin}>
          <StyledLink to={navItem.url}>{navItem.text}</StyledLink>
        </NavigationItem>
      ))}
    </NavigationList>
  </NavigationContainer>
);

export default LegalCountryNavigation;
