import React, { FC, ReactNode } from 'react';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { Wrapper, ContentWrapper, StyledGatsbyImage } from './styles';

export interface GatsbyBackgroudImageProps {
  /**
   * Image to display   */
  image: IGatsbyImageData;
  /**
   * Image alt text - Used for accessibility
   */
  alt?: string;
  children?: ReactNode;
}

const GatsbyBackgroudImage: FC<GatsbyBackgroudImageProps> = ({ children, image, alt }) => (
  <Wrapper>
    <ContentWrapper>{children}</ContentWrapper>
    <StyledGatsbyImage image={image} alt={`Background - ${alt}`} />
  </Wrapper>
);

export default GatsbyBackgroudImage;
