import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import getHeaders from '../../graphql/getHeaders';

export interface Headers {
  getHeaders: {
    countryCode: string;
  };
}

const useGetHeaders = (options?: QueryHookOptions<Headers>): QueryResult<Headers> =>
  useQuery<Headers>(getHeaders, options);

export default useGetHeaders;
