import React, { useContext } from 'react';
import { VideoCardSlim } from '@lesmills-international/components';
import { ReactComponent as WorkoutHistoryPlaceholder } from '@assets/icons/workout-history-placholder.svg';

import { userContext } from '@context';
import { useWorkoutsQuery, useWorkoutPopularQuery, Workout } from '@backend';
import { formatDuration } from '@services';
import { contentClickTracking } from '@src/utils';

import {
  VideoSlimWrapper,
  TitleWrapper,
  WorkoutSlimComponentWrapper,
  Title,
  ViewAllLink,
  PlaceholderWrapper,
  PlaceholderText,
  VideoCardSlimWrapper,
} from './style';

interface Props {
  content: Queries.PrismicNewWebHomepageDataBodyVideoSlim;
}

interface WorkoutSlimProps {
  title: string;
  view_all_link?: string;
  view_all_text?: string;
  vimeoTicket: string;
  workouts: Workout[];
}

const workoutConvertToVideoCardProps = (
  workout: Workout,
  workoutLink: string,
  onClick: () => void
) => {
  const videoDuration = workout?.assets?.items?.[0]?.duration;
  const duration = formatDuration(videoDuration);

  const categories = workout?.categories || [];

  return {
    name: workout?.workoutName || '',
    equipmentIcons: workout?.equipment || [],
    duration,
    categories: [...categories],
    backgroundImg: workout?.imageWide,
    link: workoutLink,
    onClick,
  };
};

const WorkoutSlim: React.FC<WorkoutSlimProps> = ({
  title,
  view_all_link,
  view_all_text,
  workouts,
  analytics,
  pageName,
}) => {
  const showViewAllLink = view_all_text && workouts?.length >= 4;
  const validWorkouts = workouts && workouts?.length > 0;

  return (
    <WorkoutSlimComponentWrapper>
      <TitleWrapper>
        <Title>{title}</Title>
        {showViewAllLink && <ViewAllLink href={`${view_all_link}`}>{view_all_text}</ViewAllLink>}
      </TitleWrapper>

      {validWorkouts ? (
        <VideoCardSlimWrapper>
          {workouts?.map((workout, index) => {
            const onClick = () => {
              contentClickTracking({
                ...analytics,
                contentListTitle: title,
                contentLocation: `${pageName} - ${title}`,
                contentIndex: index + 1,
                contentTitle: workout?.workoutName,
                contentType: 'video',
                contentTopic: workout?.programName,
              });
            };
            const workoutLink = `/workout/${encodeURIComponent(workout?.workoutName || '')}`;
            const VideoCardSlimProps = workoutConvertToVideoCardProps(
              workout,
              workoutLink,
              onClick
            );
            return workout ? <VideoCardSlim {...VideoCardSlimProps} key={index} /> : null;
          })}
        </VideoCardSlimWrapper>
      ) : (
        <PlaceholderWrapper>
          <WorkoutHistoryPlaceholder />
          <PlaceholderText>Start a workout, it’ll show up here.</PlaceholderText>
        </PlaceholderWrapper>
      )}
    </WorkoutSlimComponentWrapper>
  );
};

const VideoSlim: React.FC<Props> = ({ content, analytics, pageName }) => {
  const { isNewUser, userEntitlements } = useContext(userContext);
  const vimeoTicket = userEntitlements?.LMOD?.vimeoUserTicket;

  const isNewUserOnly = content.slice_label === 'new_user_only' && !isNewUser;

  // TODO: will reuse down below logic when ListWorkoutHistory query is available to use
  // TODO: also need to add workouts={type === 'do_it_again' ? validHistoryWorkouts : filteredPopularWorkouts} as WorkoutSlim pass in property

  // const { data: workoutHistory } = useListWorkoutHistoryQuery({ skip: isNewUserOnly });

  // const orderedWorkoutHistory = sortByWorkoutFrequency(
  //   workoutHistory?.listWorkoutHistory?.items ?? []
  // );

  // const filteredWorkoutHistory = orderedWorkoutHistory.slice(0, 4);

  // const workoutsNameArray: string[] = [];
  // const workoutsProgramArray: string[] = [];

  const popularWorkoutsNameArray: string[] = [];
  const popularWorkoutsProgramArray: string[] = [];

  // filteredWorkoutHistory.forEach((item) => {
  //   workoutsNameArray.push(item?.workoutName!);
  //   workoutsProgramArray.push(item?.programName!);
  // });

  // const { data: historyWorkouts } = useWorkoutsQuery({
  //   variables: {
  //     programs: workoutsProgramArray,
  //     names: workoutsNameArray,
  //   },
  //   skip: isNewUserOnly,
  // });

  const { data: workoutPopular } = useWorkoutPopularQuery({
    variables: {
      product: 'LMOD',
      limit: 20,
    },
    skip: isNewUserOnly,
  });

  workoutPopular?.workoutPopular?.items.forEach((item) => {
    popularWorkoutsNameArray.push(item?.workoutName!);
    popularWorkoutsProgramArray.push(item?.programName!);
  });

  const { data: popularWorkouts } = useWorkoutsQuery({
    variables: {
      programs: popularWorkoutsProgramArray,
      names: popularWorkoutsNameArray,
    },
    skip: isNewUserOnly,
  });

  // const validHistoryWorkouts = historyWorkouts?.workouts?.filter((workout) => workout != null);
  const validPopularWorkouts = popularWorkouts?.workouts?.filter((workout) => workout != null);

  const filteredPopularWorkouts = validPopularWorkouts?.slice(0, 8);

  if (isNewUserOnly) {
    return null;
  }

  return (
    <VideoSlimWrapper>
      {content?.items?.map(({ title, view_all_link, view_all_text }, index) => (
        <WorkoutSlim
          title={title}
          view_all_link={view_all_link}
          view_all_text={view_all_text}
          vimeoTicket={vimeoTicket}
          analytics={analytics}
          workouts={filteredPopularWorkouts}
          key={index}
          pageName={pageName}
        />
      ))}
    </VideoSlimWrapper>
  );
};

export default VideoSlim;
