/* eslint-disable import/prefer-default-export */
import { deleteLocalStorage, getLocalStorage } from './utilities';
/**
 *
 * featureFlag: string
 * for full launch, the featureFlag value would be ALL
 * for soft launch, the featureFlag value would be countries string, e.g. GB&US
 * from soft launch to full launch, except changing the feature flag value to ALL
 * countryCode: string
 * the current country code
 * tieringFlag: boolean
 * true to display tiering plan
 */
export const getTieringLaunchFlag = (
  countryCode,
  tierProductCountries,
  checkTierLandingPageFlag
) => {
  if (checkTierLandingPageFlag) {
    // tiering=True will be included in the URL when redirecting from landing page
    // we store it as isTieringEnabled at creating account step
    const isTieringEnabled = JSON.parse(getLocalStorage('isTieringEnabled') || false);
    deleteLocalStorage('isTieringEnabled');
    if (!isTieringEnabled) return false;
  }

  if (!tierProductCountries || !countryCode || tierProductCountries.toLowerCase() === 'none')
    return false;
  if (tierProductCountries.toLowerCase() === 'all') return true;
  const launchCountries = tierProductCountries.toLowerCase().split('&');
  return launchCountries.includes(countryCode.toLowerCase());
};

// This is a temporary solution for filter the BAU plans - tiered product pitch
// All the existing BAU plans are assigned to premium tier
export const filterBauPlans = (plans) => {
  const bauPlans = plans?.filter(
    (plan) =>
      !plan?.tier || (plan?.tier && plan?.tier?.internal_tier_id.toLowerCase() === 'premium')
  );
  return bauPlans;
};
