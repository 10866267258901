import React, { FC } from 'react';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { graphql, PageProps } from 'gatsby';
import { useFlags } from 'gatsby-plugin-launchdarkly';

/* eslint-disable import/no-relative-packages */
import withPrivateRoute from '../../../gatsby-theme-engagement/src/components/molecules/withPrivateRoute';
import ChangeSubscriptionNoneTiering from '../components/changeSubscription/templateComponent/ChangeSubscriptionNoneTiering';
import ChangeSubscriptionTiering from '../components/changeSubscription/templateComponent/ChangeSubscriptionTiering';
import { getTieringLaunchFlag } from '../utils/tiering';
import useGetCountryCode from '../hooks/useGetCountryCode';

const ChangeSubscriptionTemplate: FC<PageProps<Queries.GetChangeSubscriptionQuery>> = ({
  data,
  location,
}) => {
  const { countryCode } = useGetCountryCode();
  const { rtTierProductCountries } = useFlags();
  const showTieringChangeSubscription = getTieringLaunchFlag(
    countryCode,
    rtTierProductCountries,
    false
  );

  const ChangeSubscriptionComponent = showTieringChangeSubscription
    ? ChangeSubscriptionTiering
    : ChangeSubscriptionNoneTiering;
  return <ChangeSubscriptionComponent pageData={data} location={location} />;
};

export default withPrismicPreview(withPrivateRoute(ChangeSubscriptionTemplate));

export const query = graphql`
  query GetChangeSubscription($lang: String!) {
    prismicChangeSubscriptionPage(lang: { eq: $lang }) {
      _previewable
      data {
        title
        available_subscription_title
        back_button_text
        back_button_url
        current_payment_details_title
        current_payment_details_description
        payment_edit_text
        terms_and_conditions
        cancel_anytime
        submit_switch_button_text
        submit_stay_button_text
        success_message_title
        success_message_description
        change_subscription_subtitle
        current_plan_text
        change_subscription_button_text
        warning_text
        term_and_condition_with_free_trial
        term_and_condition_with_billed_immediately
        term_and_condition_with_billed_next_cycle
        loading_text
        loading_process_text
        change_subscription_success_message_description
        get_started_button_text
        error_text_diff_region {
          richText
        }
        rollover_error_message
        rollover_title
        annual_text
        monthly_text
      }
    }
    prismicLesMillsPlusCommonLandingPage(lang: { eq: $lang }) {
      data {
        body {
          ...PricingSection
        }
      }
      _previewable
      lang
    }
    prismicLesMillsPlusTieringLandingPage(lang: { eq: $lang }) {
      data {
        body {
          ...PricingSectionTiering
        }
      }
      _previewable
      lang
    }
    prismicSignupChannels {
      data {
        signup_channel {
          name
        }
      }
    }
  }
`;
