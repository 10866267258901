import React from 'react';
import { Button } from '@lesmills-international/components';
import { RichText } from 'prismic-reactjs';
import { Description, Container, TryAgainButton, SecondaryButtonContainer } from './styles';
import { ReactComponent as RefreshIcon } from '../../../assets/icons/refresh.svg';

interface Props {
  description?: Queries.Maybe<Pick<Queries.PrismicRichTextField, 'text' | 'richText'>>;
  primaryButtonText?: string | null;
  primaryButtonOnClick?: () => void;
  secondaryButtonText?: string | null;
  secondaryButtonOnClick?: () => void;
}

const ErrorMessage = ({
  description,
  primaryButtonText,
  primaryButtonOnClick,
  secondaryButtonText,
  secondaryButtonOnClick,
}: Props) => (
  <Container>
    <Description>
      <RichText key={description?.text} render={description?.richText} />
    </Description>
    {primaryButtonText && (
      <Button
        style={{ width: '270px' }}
        label={primaryButtonText}
        ctaButton
        inverted
        variant="outlined"
        onClick={primaryButtonOnClick}
      />
    )}
    {secondaryButtonText && (
      <SecondaryButtonContainer>
        <RefreshIcon />
        <TryAgainButton onClick={secondaryButtonOnClick}>{secondaryButtonText}</TryAgainButton>
      </SecondaryButtonContainer>
    )}
  </Container>
);

export default ErrorMessage;
