import Auth from '@aws-amplify/auth';
import { ApolloClient, InMemoryCache, from, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import fetch from 'isomorphic-fetch';

import { isBrowser } from '../utils/utilities';

const httpLink = createHttpLink({
  fetch,
  uri: process.env.GATSBY_APP_SYNC_URL,
});

const createAuthLink = () =>
  setContext(async (_, { headers }) => {
    const user = await Auth.currentAuthenticatedUser();

    return {
      headers: {
        ...headers,
        Authorization: user.signInUserSession.idToken.jwtToken,
      },
    };
  });

export const createClient = new ApolloClient({
  ssrMode: !isBrowser,
  link: from([createAuthLink(), httpLink]),
  uri: `${process.env.GATSBY_APP_SYNC_URL}`,
  cache: new InMemoryCache(),
});

export const createPublicClient = new ApolloClient({
  ssrMode: !isBrowser,
  uri: `${process.env.GATSBY_APP_SYNC_URL}/public`,
  cache: new InMemoryCache(),
});
