import { useRef } from 'react';

const useScrollToElement = () => {
  const scrollRef = useRef<HTMLElement>(null);

  const handleScroll = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'start',
      });
    }
  };

  return { scrollRef, handleScroll };
};

export default useScrollToElement;
