import { graphql, useStaticQuery } from 'gatsby';

const useStaticPaymentQuery = () =>
  useStaticQuery<Queries.PaymentQuery>(graphql`
    query Payment {
      allPrismicPayment {
        nodes {
          id
          lang
          data {
            title
            add_address_manually
            address_1_field_error
            address_field_label
            address_is_not_valid_error
            cc_number_field_label
            cc_number_is_not_valid_error
            ccv_field_label
            ccv_is_not_valid_error
            city_field_error
            expiry_date_field_label
            field_is_not_valid_error
            first_payment_amount_label
            first_payment_date_label
            additional_tax
            month_is_not_valid_error
            paypal_field_error
            paypal_verify_error
            postal_code_field_error
            rollover_field_error
            state_field_error
            year_is_not_valid_error
            expiry_month_field_label
            expiry_year_field_label
            address_form_title
            address_1_field_label
            address_2_field_label
            city_field_label
            state_field_label
            postal_code_field_label
            country_field_label
            address_form_button
            chargify_error_message
            address_no_results_text
            address_default_text
            payment_options {
              payment_type
              payment_name
              button_label
              icon {
                alt
                copyright
                url
                gatsbyImageData
              }
            }
            update_payment_options {
              payment_type
              payment_name
              button_label
              icon {
                alt
                copyright
                url
                gatsbyImageData
              }
            }
            rollover_label
            rollover_description
            body {
              ... on PrismicPaymentDataBodyTermsAndConditions {
                items {
                  subscription_description {
                    richText
                    text
                  }
                }
                primary {
                  subscription_type {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

export default useStaticPaymentQuery;
