import { gql } from '@apollo/client';

const ConsumeActivationCode = gql`
  mutation consumeActivationCode(
    $activationCode: String!
    $signupInput: ActivationCodeSignupInput!
  ) {
    consumeActivationCode(activationCode: $activationCode, input: $signupInput) {
      status
      success
    }
  }
`;

export default ConsumeActivationCode;
