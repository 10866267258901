import { gql } from '@apollo/client';

const createAffiliateSubscriptionByChargifyTokenV2 = gql`
  mutation createAffiliateSubscriptionByChargifyTokenV2(
    $captcha_signature: CaptchaSignatureInput
    $recaptcha_token: String!
    $recaptcha_token_V3: String
    $offerId: String
    $affiliateId: String
    $transactionId: String
    $defaultToMonthly: Boolean
    $chargifyOfferHandle: String
    $voucherCode: String
  ) {
    createAffiliateSubscriptionByChargifyTokenV2(
      captcha_signature: $captcha_signature
      recaptcha_token: $recaptcha_token
      recaptcha_token_V3: $recaptcha_token_V3
      offer_id: $offerId
      affiliate_id: $affiliateId
      transaction_id: $transactionId
      default_to_monthly: $defaultToMonthly
      chargify_offer_handle: $chargifyOfferHandle
      voucher_code: $voucherCode
    ) {
      user_id
      product {
        product_handle
        name
        description
      }
    }
  }
`;

export default createAffiliateSubscriptionByChargifyTokenV2;
