import { themes } from '@lesmills-international/components';
import Box from '@mui/material/Box';
import { styled } from 'styled-components';

export const Form = styled(Box)(() => ({
  '& .MuiTextField-root': { margin: '8px 0', width: '320px' },
  maxWidth: '320px',
  '& .MuiInputBase-input:focus': { boxShadow: 'none' },
}));

export const FormDivider = styled.hr<{ themeColor?: 'white' | 'black' }>`
  margin: 20px auto;
  width: 100%;
  height: 2px;
  background-color: ${({ themeColor }) => themes[themeColor || 'white'].colors.borderTertiary};
  border: none;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  margin-top: 16px;

  & .MuiButton-root {
    margin: 0 1px;
  }
`;
