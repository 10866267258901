import {
  TableContainer as MUITableContainer,
  TableSortLabel as MUITableSortLabel,
  TableCell as MUITableCell,
  TablePagination as MUITablePagination,
  styled,
} from '@mui/material';
import { themes } from '@lesmills-international/components';
import { ReactComponent as SortArrowIcon } from '../../assets/icons/sort-arrow.svg';

export const TableContainer = styled(MUITableContainer)(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

export const TableSortLabel = styled(MUITableSortLabel)(({ theme }) => ({
  '&.MuiTableSortLabel-root': {
    color: `${themes[theme.themeColor].colors.contentPrimary} !important`,
    fontFamily: 'Inter SemiBold',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 800,
  },
}));

export const TableCellHeader = styled(MUITableCell)(() => ({
  '&.MuiTableCell-root': {
    border: 'none',
    padding: '0 0 12px 0',
  },
}));

export const TableCell = styled(MUITableCell)(({ theme }) => ({
  '&.MuiTableCell-root': {
    color: themes[theme.themeColor].colors.contentPrimary,
    fontFamily: 'Inter Regular',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 400,
    borderBottom: `1px solid ${themes[theme.themeColor].colors.borderTertiary}`,
    padding: '8px 0',
  },

  '& a': {
    fontFamily: 'Inter SemiBold',
    lineHeight: '20px',
    textDecoration: 'underline',
  },
}));

export const SortArrow = styled(SortArrowIcon)(({ disabled, direction }) => ({
  marginLeft: '8px',
  display: disabled ? 'none' : 'block',
  transform: direction === 'asc' ? 'rotate(360deg)' : 'rotate(180deg)',
}));

export const TablePagination = styled(MUITablePagination)(({ theme }) => ({
  '&.MuiTablePagination-root': {
    display: 'none',

    [theme.breakpoints.up('md')]: {
      alignSelf: 'flex-end',
      marginRight: '-20px',
      display: 'flex',
    },
  },

  '& .MuiTablePagination-toolbar': {
    padding: 0,
  },

  '& .MuiTablePagination-select, & .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows':
    {
      color: themes[theme.themeColor].colors.contentPrimary,
      fontFamily: 'Inter Regular',
      lineHeight: '24px',
      fontWeight: 400,
      fontSize: '16px',
    },

  '& .MuiTablePagination-selectIcon': {
    color: themes[theme.themeColor].colors.contentPrimary,
  },

  '& .MuiIconButton-root': {
    color: themes[theme.themeColor].colors.contentPrimary,

    '&:disabled': {
      color: themes[theme.themeColor].colors.contentTertiary,
    },

    '& svg': {
      width: '40px',
    },
  },
}));

export const MobileBillingHistoryTable = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  overflow: 'auto',

  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

export const MobileBillingHistoryWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  font-family: Inter Regular;
`;

export const MobileDate = styled('p')`
  font-size: 12px;
  line-height: 16px;
`;

export const MobileAmount = styled('p')`
  font-family: Inter SemiBold;
  font-size: 16px;
  line-height: 24px;
`;

export const MobileSubscription = styled('p')`
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 8px;
`;

export const MobileServicePeriodLabel = styled('p')`
  font-size: 12px;
  line-height: 16px;
`;

export const ServicePeriodWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
`;

export const MobileServicePeriod = styled('p')`
  font-size: 14px;
  line-height: 20px;
`;

export const DownloadLink = styled('a')`
  font-family: Inter SemiBold;
  text-decoration: underline;
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
`;

export const RowDivider = styled('hr')(({ theme }) => ({
  borderTop: `1px solid ${themes[theme.themeColor].colors.borderSecondary}`,
  margin: '8px 0',
}));

export const Note = styled('div')(({ theme }) => ({
  color: themes[theme.themeColor].colors.contentSecondary,
  fontFamily: 'Inter Regular',
  fontSize: '12px',
  lineHeight: '16px',
  marginTop: '16px',

  '& a': {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
}));

export const TableLoaderContainer = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const TableLoaderRow = styled('div')(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.up('md')]: {
    display: 'flex',
    columnGap: '36px',
    width: '100%',
  },
}));

export const MobileTableLoaderRow = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  columnGap: '36px',
  width: '100%',

  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

export const SkeletonRowDivider = styled('hr')(({ theme }) => ({
  borderTop: `1px solid ${themes[theme.themeColor].colors.borderTertiary}`,
  margin: '12px 0',
}));
