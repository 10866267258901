import React, { FC, useContext, useEffect, useState } from 'react';
import { UserProvider, authContext } from '@context';

const withPublicRoute = <Props extends {}>(Component: React.ComponentType<Props>) => {
  const ComponentWithPublicRoute: FC<Props> = (props) => {
    const { isLoading } = useContext(authContext);
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
      if (!isLoading) {
        setIsReady(true);
      }
    }, [isLoading]);

    if (!isReady) {
      return null;
    }

    return (
      <UserProvider>
        <Component {...props} />
      </UserProvider>
    );
  };

  return ComponentWithPublicRoute;
};

export default withPublicRoute;
