const getPricingPrismicData = (
  planCardPrismicBody: Array<{ slice_type: string; primary: object }>
) => {
  let planCardPrismicData = {};

  planCardPrismicBody.forEach((body: { slice_type: string; primary: object }) => {
    if (body.slice_type === 'pricing') {
      planCardPrismicData = body?.primary;
    }
  });
  return planCardPrismicData;
};

export default getPricingPrismicData;
