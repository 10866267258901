import styled, { keyframes } from 'styled-components';

const animationSpinner = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const SpinnerWapper = styled.div`
  -webkit-animation: ${animationSpinner} 2s infinite;
  -moz-animation: ${animationSpinner} 2s infinite;
  -o-animation: ${animationSpinner} 2s infinite;
  -ms-animation: ${animationSpinner} 2s infinite;
  animation: ${animationSpinner} 2s infinite;
`;

export default SpinnerWapper;
