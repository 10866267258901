import React, { FC } from 'react';

import { ReactComponent as InfoIcon } from '../../../assets/icons/info.svg';

import { Wrapper, Content } from './style';

interface Props {
  children: JSX.Element;
}

const WarningAlert: FC<Props> = ({ children }) => (
  <Wrapper>
    <InfoIcon />
    <Content>{children}</Content>
  </Wrapper>
);

export default WarningAlert;
