import styled from 'styled-components';

// Chargify section
export const CCNumberField = styled.div`
  & iframe {
    width: 100% !important;
  }
`;

export const FormFieldControl = styled.div`
  display: flex;
`;

export const ExpiryMonthField = styled.div`
  margin-right: 10px;
  width: 100px;

  & iframe {
    width: 100% !important;
  }
`;

export const ExpiryYearField = styled.div`
  margin-right: 10px;
  width: 100px;

  & iframe {
    width: 100% !important;
  }
`;

export const CCVField = styled.div`
  width: 100px;

  & iframe {
    width: 100% !important;
  }
`;

export const PayPalContainer = styled.div``;

export const PayPalButtonWrapper = styled.div`
  width: 100%;
  height: 48px;
  margin-top: 27px;
`;

export const ImgWrapper = styled.div`
  margin-bottom: 30px;
  text-align: center;
`;

export const PaypalSuccessBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 65px;
  background-color: ${(props) => props.theme.colors.whiteSmoke};
  margin-top: 19px;
  margin-bottom: 19px;
`;

export const PaypalErrorMsg = styled.div`
  color: ${(props) => props.theme.colors.errorRed};
  font-size: 12px;
  line-height: 18px;
`;

// Billing address section
export const BillingAddressWrapper = styled.div`
  margin-bottom: 14px;

  & .MuiFormControl-root.address-autocomplete-field {
    margin-bottom: 0;
  }
  & .MuiFormControl-root.address-autocomplete-field > .MuiInputLabel-root {
    margin-left: 0;
  }

  & .MuiFormControl-root.address-autocomplete-field > .MuiFilledInput-root {
    padding-top: 10px;
    border: 1px solid ${(props) => props.theme.colors.borderGrey};
  }

  &
    .MuiFormControl-root.address-autocomplete-field
    > .MuiFilledInput-root.Mui-focused
    .MuiInputBase-input {
    box-shadow: none;
  }

  & .MuiFormControl-root.address-autocomplete-field > .MuiFilledInput-root.Mui-focused {
    box-shadow: 0 0 0 1px ${(props) => props.theme.colors.lightGreyD9};
  }
`;

export const OptionLi = styled.li`
  font-size: 14px;
  letter-spacing: 0.02em;
  font-family: 'Inter Regular';
  & span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 17px;
  }
`;

export const AddManualAddress = styled.span`
  font-size: 12px;
  line-height: 20px;
  text-decoration-line: underline;
  margin: 0;
  cursor: pointer;
`;

// Add manual address dialog
export const DialogHeader = styled.div`
  position: relative;
`;

export const AddressFormTitle = styled.h2`
  font-size: 16px;
  line-height: 20px;
  font-family: 'Inter Medium';
  margin-bottom: 16px;
`;

export const PaymentDetails = styled.div`
  padding: 12px;
`;

export const PaymentDetailsItem = styled.p`
  font-size: 14px;
  line-height: 20px;
  display: flex;
  justify-content: space-between;
  margin: 0;
`;
