import styled from 'styled-components';
import { breakpoint } from '@themes';

export const MultiSelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  width: 304px;
  gap: 8px;
  ${breakpoint('md')`
      gap: 12px;
      width: 658px;
      justify-content: center;
  `}
`;

export const SingleSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  ${breakpoint('md')`
      gap: 12px;
      width: 340px;
  `}
`;
