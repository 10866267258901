import React, { useState, useEffect } from 'react';
import copy from 'copy-to-clipboard';
import styled, { css } from 'styled-components';

import { breakpoint } from '../../../themes/breakpoints';
import COLORS from '../../../themes/colors';
import { createClient } from '../../../services/client';
import getUser from '../../../graphql/getUser';
import getReferralLink from '../../../graphql/getReferralLink';
import { ReactComponent as Tick } from '../../../assets/icons/copied-tick.svg';

const Wrapper = styled.div`
  width: 100%;
  margin-top: 40px;
  ${breakpoint('md')`
    font-size: 14px;
    margin-top: 55px;
`};
`;

const CopyButton = styled.div`
  background-color: ${COLORS.darkGrey};
  font-size: 14px;
  text-align: center;
  color: ${COLORS.white};
  width: 100%;
  height: 50px;
  line-height: 50px;
  font-family: 'Inter SemiBold';
  letter-spacing: 0.08em;
  &:hover {
    cursor: pointer;
  }
  ${breakpoint('md')`
    font-size: 14px;
    width:82px;
`};
`;

const HrefWrapper = styled.div`
  font-size: 14px;
  text-align: center;
  width: 100%;
  line-height: 50px;
  height: 50px;
  margin-bottom: 16px;
  font-family: Inter Medium;
  border: 1px solid ${COLORS.whiteSmoke};
  transition: border-color 0.5s;
  transition-timing-function: ease-out;
  background-color: ${COLORS.whiteSmoke};
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 5px;
  ${(props) =>
    props.isCopied &&
    css`
      border-color: #c6c6c6;
    `}
  ${breakpoint('md')`

    white-space: nowrap;
    font-size: 16px;
    text-align: left;
    padding-left: 20px;
    width:440px;
    margin-bottom: 0px;

`};
`;

const LinkWrapper = styled.div`
  display: block;
  margin-top: 8px;
  font-size: 16px;
  ${breakpoint('md')`
    display: flex;
    justify-content:space-between;
    margin-top:8px;
`};
`;

const CopyLinkInstructions = styled.div`
  font-size: 14px;
  text-align: left;
  width: 100%;
  font-family: 'Inter SemiBold';
  text-align: center;
  ${breakpoint('md')`
    font-size: 16px;
    line-height: 22px;
    text-align: left;
`};
`;

const Button = styled.div`
  width: 100%;
  height: 100%;
  letter-spacing: 0.08em;
  position: relative;
`;

const CopyMessage = styled.div`
  position: absolute;
  top: 0px;
  transition: opacity 0.5s;
  text-align: center;
  width: 100%;
  transition-timing-function: ease-out;
  ${(props) =>
    props.isCopied &&
    css`
      opacity: 0;
    `}
`;

const CopiedMessage = styled.div`
  transition: opacity 0.5s;
  transition-timing-function: ease-out;
  position: absolute;
  top: 0px;
  opacity: 0;
  width: 100%;
  height: 100%;
  background-color: ${COLORS.accentGreen};
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) =>
    props.isCopied &&
    css`
      opacity: 1;
    `}
`;
const LinkCopier = ({ copyMessage, instructions }) => {
  const campaignId = process.env.GATSBY_GROWSURF_CAMPAIGN_ID;
  const [isCopied, setIsCopied] = useState(false);
  const [referralLinkText, setReferralLinkText] = useState('loading...');
  useEffect(() => {
    const getUserQuery = async () => {
      const userData = await createClient.query({ query: getUser });
      const email = userData.data?.getUser?.email;
      const referralLink = await createClient.query({
        query: getReferralLink,
        variables: { email, campaignId },
      });
      const referralLinkUrl =
        referralLink.data?.getReferralLink || 'There was an error getting your referral link';
      setReferralLinkText(referralLinkUrl);
    };
    getUserQuery();
  }, [campaignId]);
  const copyToClipboard = () => {
    copy(referralLinkText);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };
  return (
    <Wrapper id="rafLinkCopier">
      <CopyLinkInstructions id="rafCopyLinkInstructions">{instructions}</CopyLinkInstructions>
      <LinkWrapper id="rafLinkHref">
        <HrefWrapper id="rafHrefWrapper" className="rafHrefWrapper" isCopied={isCopied}>
          {referralLinkText}
        </HrefWrapper>
        <CopyButton id="rafCopyButton">
          <Button onClick={copyToClipboard}>
            <CopyMessage isCopied={isCopied}>{copyMessage}</CopyMessage>
            <CopiedMessage isCopied={isCopied}>
              <Tick />
            </CopiedMessage>
          </Button>
        </CopyButton>
      </LinkWrapper>
    </Wrapper>
  );
};

export default LinkCopier;
