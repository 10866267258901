import React, { FC } from 'react';
import { ReactComponent as Logo } from '@assets/lmi-plus-logo.svg';
import { graphql, useStaticQuery } from 'gatsby';
import { Link } from '@components/atoms';
import { FacebookLogo, InstagramLogo, TiktokLogo, YoutubeLogo } from 'phosphor-react';

const YEAR = new Date().getFullYear();

const Footer: FC = () => {
  const { prismicNavigation } = useStaticQuery<Queries.FooterQuery>(graphql`
    query Footer {
      prismicNavigation {
        data {
          footer_links {
            link {
              url
            }
            link_text
          }
        }
      }
    }
  `);

  return (
    <div className="px-10 bg-black md:border-0 mt-auto">
      <div className="pt-6 md:pt-12 pb-12 md:container md:mx-auto flex flex-col md:flex-row space-y-6 md:space-y-0 md:justify-between">
        <div className="flex flex-col md:flex-row items-center space-y-6 md:space-y-0 md:space-x-10">
          <div>
            <Logo className="text-white fill-current" />
          </div>

          <div className="space-x-6 text-center md:text-left">
            {prismicNavigation?.data?.footer_links?.map(
              (link, i) =>
                link?.link?.url && (
                  <Link key={i} to={link?.link?.url} className="text-12 text-white hover:underline">
                    {link?.link_text}
                  </Link>
                )
            )}

            <span className="text-12 text-white">
              &copy;2005-
              {YEAR}
            </span>
          </div>
        </div>

        <div className="flex items-center justify-center space-x-4">
          <Link
            to="http://www.facebook.com/lesmills"
            title="Facebook"
            target="_blank"
            className="text-white opacity-100 hover:opacity-50"
          >
            <FacebookLogo weight="fill" className="w-5 h-5" />
          </Link>
          <Link
            to="https://instagram.com/lesmills/"
            title="Instagram"
            target="_blank"
            className="text-white opacity-100 hover:opacity-50"
          >
            <InstagramLogo weight="fill" className="w-5 h-5" />
          </Link>
          <Link
            to="http://www.youtube.com/user/lesmillsgroupfitness"
            title="YouTube"
            target="_blank"
            className="text-white opacity-100 hover:opacity-50"
          >
            <YoutubeLogo weight="fill" className="w-5 h-5" />
          </Link>
          <Link
            to="https://www.tiktok.com/@lesmills"
            title="TikTok"
            target="_blank"
            className="text-white opacity-100 hover:opacity-50"
          >
            <TiktokLogo weight="fill" className="w-5 h-5" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
