import React from 'react';
import { Container, Dot } from './style';

export interface ProgressDotsProps {
  /**
   * Count of dots that should be visible
   */
  count: number;
  /**
   * Which dot should show as active
   */
  active: number;
}

const ProgressDots: React.FC<ProgressDotsProps> = ({ count, active }: ProgressDotsProps) => {
  const countList = [...Array(count).keys()];
  return (
    <Container>
      {countList.map((i) => (
        <Dot key={i} active={i === active} />
      ))}
    </Container>
  );
};

export default ProgressDots;
