/* eslint max-len: ["error", { "code": 120 }] */
import React, { useMemo } from 'react';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import ErrorImage from '../assets/images/404.svg';

import Layout from '../components/common/layout';
import PartnerError from '../components/partner/error/PartnerError';
import { getLocalStorage } from '../utils/utilities';
import { partnerActivationCodeStatus } from '../constants/partnerStatus';
import { PARTNER_CODE_ERROR } from '../constants/localStorageKeys';

const PartnerActivationCodeErrorTemplate = ({ data, pageContext }) => {
  const { prismicSignUpLayout, prismicPartnerInvalidPage } = data;

  const {
    redeemed_title: redeemedTitle,
    cancelled_title: cancelledTitle,
    expired_title: expiredTitle,
    invalid_title: invalidTitle,
    contact_message: contactMessage,
    button_text: normalButtonText,
    general_error_title: generalErrorTitle,
    general_error_button_text: generalErrorButtonText,
  } = prismicPartnerInvalidPage.data;

  const { lang } = pageContext;

  const errorCodeStatus = getLocalStorage(PARTNER_CODE_ERROR);
  const codeError = errorCodeStatus === partnerActivationCodeStatus.ERROR;

  const title = useMemo(() => {
    switch (errorCodeStatus) {
      case partnerActivationCodeStatus.EXPIRED:
        return expiredTitle;
      case partnerActivationCodeStatus.CANCELLED:
        return cancelledTitle;
      case partnerActivationCodeStatus.REDEEMED:
        return redeemedTitle;
      case partnerActivationCodeStatus.INVALID:
        return invalidTitle;
      default:
        return generalErrorTitle;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCodeStatus]);

  const buttonText = codeError ? generalErrorButtonText : normalButtonText;

  const handleClick = () => {
    if (codeError) {
      window.location.href = process.env.GATSBY_SUPPORT_PAGES_HOME;
    } else {
      window.location.href = process.env.GATSBY_LANDING_PAGES_HOME;
    }
  };

  return (
    <Layout
      data={prismicSignUpLayout.data}
      contentWidth={380}
      currentStep={0}
      omitClose
      lang={lang}
      pathName="/partner/error"
      mobileBottomMargin={120}
    >
      <PartnerError
        image={ErrorImage}
        title={title}
        description={partnerActivationCodeStatus.ERROR && contactMessage}
        buttonText={buttonText}
        onButtonClick={handleClick}
      />
    </Layout>
  );
};

export default withPrismicPreview(PartnerActivationCodeErrorTemplate);

export const query = graphql`
  query ($lang: String!) {
    prismicPartnerInvalidPage(lang: { eq: $lang }) {
      _previewable
      data {
        contact_message
        button_text
        redeemed_title
        cancelled_title
        invalid_title
        expired_title
        general_error_title
        general_error_message
        general_error_button_text
      }
    }
    prismicSignUpLayout(lang: { eq: $lang }) {
      _previewable
      data {
        logo_white {
          alt
          gatsbyImageData(width: 133, height: 33)
        }
        logo_black {
          alt
          gatsbyImageData(width: 133, height: 33)
        }
        background_image {
          alt
          gatsbyImageData
        }
        body {
          ... on PrismicSignUpLayoutDataBodyFooterNavigation {
            items {
              nav_label {
                text
              }
              nav_link {
                text
              }
            }
          }
        }
      }
    }
  }
`;
